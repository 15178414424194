import CloseIcon from '../Icons/CloseIcon';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import style from './Aside.module.scss';
import { useCallback } from 'react';
import { useWindowWidthContext } from '../../../contexts/WindowWidthContext';

const className = 'c-CommunityAside';

const Aside = ({ toRender, isHidden, onHide, showCloseIcon }) => {
  const { isGtEqMd } = useWindowWidthContext();

  const renderContent = useCallback(() => {
    return (
      <aside
        className={`${className} ${style[className]} ${
          isHidden ? 'hidden' : 'absolute md:relative'
        } ${className}__right_planel rounded-t-12 md:rounded-t-0 z-103 top-64 md:top-0 font-poppins transition-all min-w-full bg-white flex flex-col md:min-w-[300px] md:max-w-[300px] px-24 py-32 border-l-0 border-grey-neutral95`}
      >
        <div
          className={`${className}__content md:min-w-[280px] md:max-w-[280px] max-h-[100vh] relative md:fixed h-full pb-[128px] overflow-y-auto pr-16 hide-scrollbars`}
        >
          {showCloseIcon && (
            <div
              className={`${className}__close_button sticky flex justify-end top-0 right-0 cursor-pointer`}
              onClick={onHide}
            >
              <CloseIcon strokeColor={'#AAABAE'} />
            </div>
          )}
          {toRender && toRender()}
        </div>
      </aside>
    );
  }, [isHidden, onHide, showCloseIcon, toRender]);

  if (!isGtEqMd) {
    // For Mobile phones
    const modalEl = document.getElementById('modal');
    return createPortal(
      <div
        className={`${
          isHidden ? 'hidden' : 'fixed'
        }  top-0 left-0 w-full h-full bg-grey-backdrop z-999`}
      >
        {renderContent()}
      </div>,
      modalEl
    );
  }

  return renderContent();
};

Aside.propTypes = {
  toRender: PropTypes.func,
  isHidden: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onHide: PropTypes.func,
};

Aside.defaultProps = {
  isHidden: true,
  showCloseIcon: true,
};

export default Aside;
