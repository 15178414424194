export const SIGNUP_REQUESTOR = 'signupRequestor';
export const WHATSAPP_SIGNUP_REQUESTOR = 'whatsappSignup';

export const convertObjToQueryString = (obj) => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    );
  }
  return keyValuePairs.join('&');
};

export const redirectToUnifiedCheckout = ({
  communityInfo,
  entityType,
  entityInfo,
}) => {
  const communityId = communityInfo?._id;
  const communityCode = communityInfo?.code;
  const entityId = entityInfo?._id;
  const requestor = communityInfo?.isWhatsappExperienceCommunity
    ? WHATSAPP_SIGNUP_REQUESTOR
    : SIGNUP_REQUESTOR;

  let query = {
    communityId,
    communityCode,
    requestor,
  };

  if (entityType) query.sourceInfoType = entityType;
  if (entityId) query.sourceInfoOrigin = entityId;

  const queryStr = convertObjToQueryString(query);
  window.location.href = `https://nas.io/checkout-global?${queryStr}`;
};
