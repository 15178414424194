import PropTypes from 'prop-types';
import React from 'react';

export const LibraryIcon = ({
  width,
  height,
  displayType,
  fillColor,
  strokeColor,
}) =>
  displayType === 'outline' ? (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 20H11C11 20.4407 11.2885 20.8294 11.7102 20.9571C12.132 21.0848 12.5876 20.9214 12.8321 20.5547L12 20ZM13.1094 18.3359L13.9415 18.8906H13.9415L13.1094 18.3359ZM20 4H15V6H20V4ZM11 8V20H13V8H11ZM22 16V6H20V16H22ZM12.8321 20.5547L13.9415 18.8906L12.2774 17.7812L11.1679 19.4453L12.8321 20.5547ZM15.6056 18H20V16H15.6056V18ZM13.9415 18.8906C14.3124 18.3342 14.9368 18 15.6056 18V16C14.2681 16 13.0192 16.6684 12.2774 17.7812L13.9415 18.8906ZM20 16V18C21.1046 18 22 17.1046 22 16H20ZM15 4C12.7909 4 11 5.79086 11 8H13C13 6.89543 13.8954 6 15 6V4ZM20 6V6H22C22 4.89543 21.1046 4 20 4V6Z"
        fill={strokeColor}
      />
      <path
        d="M12 20L11.1679 20.5547C11.4124 20.9214 11.868 21.0848 12.2898 20.9571C12.7115 20.8294 13 20.4407 13 20H12ZM10.8906 18.3359L10.0585 18.8906L10.8906 18.3359ZM4 6H9V4H4V6ZM11 8V20H13V8H11ZM4 16V6H2V16H4ZM12.8321 19.4453L11.7226 17.7812L10.0585 18.8906L11.1679 20.5547L12.8321 19.4453ZM8.39445 16H4V18H8.39445V16ZM11.7226 17.7812C10.9808 16.6684 9.73186 16 8.39445 16V18C9.06316 18 9.68762 18.3342 10.0585 18.8906L11.7226 17.7812ZM2 16C2 17.1046 2.89543 18 4 18V16H4H2ZM9 6C10.1046 6 11 6.89543 11 8H13C13 5.79086 11.2091 4 9 4V6ZM4 4C2.89543 4 2 4.89543 2 6H4V6V4Z"
        fill={strokeColor}
      />
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M15 5H20C20.5523 5 21 5.44772 21 6V16C21 16.5523 20.5523 17 20 17H15.6056C14.6025 17 13.6658 17.5013 13.1094 18.3359L12 20V8C12 6.34315 13.3431 5 15 5Z"
        fill={fillColor}
      />
      <path
        d="M12 20H11C11 20.4407 11.2885 20.8294 11.7102 20.9571C12.132 21.0848 12.5876 20.9214 12.8321 20.5547L12 20ZM13.1094 18.3359L13.9415 18.8906H13.9415L13.1094 18.3359ZM20 4H15V6H20V4ZM11 8V20H13V8H11ZM22 16V6H20V16H22ZM12.8321 20.5547L13.9415 18.8906L12.2774 17.7812L11.1679 19.4453L12.8321 20.5547ZM15.6056 18H20V16H15.6056V18ZM13.9415 18.8906C14.3124 18.3342 14.9368 18 15.6056 18V16C14.2681 16 13.0192 16.6684 12.2774 17.7812L13.9415 18.8906ZM20 16V18C21.1046 18 22 17.1046 22 16H20ZM15 4C12.7909 4 11 5.79086 11 8H13C13 6.89543 13.8954 6 15 6V4ZM20 6V6H22C22 4.89543 21.1046 4 20 4V6Z"
        fill={fillColor}
      />
      <path
        d="M9 5H4C3.44772 5 3 5.44772 3 6V16C3 16.5523 3.44772 17 4 17H8.39445C9.39751 17 10.3342 17.5013 10.8906 18.3359L12 20V8C12 6.34315 10.6569 5 9 5Z"
        fill={fillColor}
      />
      <path
        d="M12 20L11.1679 20.5547C11.4124 20.9214 11.868 21.0848 12.2898 20.9571C12.7115 20.8294 13 20.4407 13 20H12ZM10.8906 18.3359L10.0585 18.8906L10.8906 18.3359ZM4 6H9V4H4V6ZM11 8V20H13V8H11ZM4 16V6H2V16H4ZM12.8321 19.4453L11.7226 17.7812L10.0585 18.8906L11.1679 20.5547L12.8321 19.4453ZM8.39445 16H4V18H8.39445V16ZM11.7226 17.7812C10.9808 16.6684 9.73186 16 8.39445 16V18C9.06316 18 9.68762 18.3342 10.0585 18.8906L11.7226 17.7812ZM2 16C2 17.1046 2.89543 18 4 18V16H4H2ZM9 6C10.1046 6 11 6.89543 11 8H13C13 5.79086 11.2091 4 9 4V6ZM4 4C2.89543 4 2 4.89543 2 6H4V6V4Z"
        fill={fillColor}
      />
    </svg>
  );

LibraryIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  displayType: PropTypes.oneOf(['outline', 'filled']),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

LibraryIcon.defaultProps = {
  width: '24',
  height: '24',
  fillColor: '#FBC91B',
  strokeColor: '#AAABAE',
};
