import React from 'react';

const Tool02 = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 24 24">
      <path
        fill="rgba(27, 27, 24, 0.65)"
        fillRule="evenodd"
        d="M2.293 1.293a1 1 0 011.023-.242l3 1A1 1 0 017 3v2.586l3.11 3.11a6.5 6.5 0 019.651-6.82 1 1 0 01.205 1.572l-2.628 2.628c-.21.21-.323.325-.4.414l-.008.01.009.01c.076.09.19.205.4.414l.237.238A8.143 8.143 0 0018 7.57l.01-.009c.09-.076.205-.19.414-.4l2.458-2.457a1 1 0 011.632.327 6.503 6.503 0 01-4.322 8.747l3.015 3.015a3.121 3.121 0 11-4.414 4.414L12 16.414l-4.793 4.793a3.121 3.121 0 11-4.414-4.414L9.086 10.5 5.586 7H3a1 1 0 01-.949-.684l-1-3a1 1 0 01.242-1.023l1-1zM5 5V3.72l-1.73-.576-.126.126L3.721 5H5zm6.206 6.208l-6.999 7a1.121 1.121 0 001.586 1.585l7.157-7.157.058-.058.128-.128.097-.097a1.994 1.994 0 01.476-.354c.097-.053.216-.11.36-.154a1.954 1.954 0 01.45-.078c.27-.022.538.005.787.073.104.029.209.053.316.075h.001l.059.011A4.526 4.526 0 0016.5 12 4.5 4.5 0 0021 7.415l-1.184 1.183c-.178.179-.352.353-.511.488a2.021 2.021 0 01-.687.402 2 2 0 01-1.236 0 2.023 2.023 0 01-.687-.402 9.175 9.175 0 01-.51-.488l-.283-.282c-.179-.178-.353-.352-.488-.511a2.022 2.022 0 01-.402-.687 2 2 0 010-1.236c.094-.29.254-.512.402-.686.135-.16.31-.333.488-.512l1.577-1.577a4.5 4.5 0 00-5.394 5.27l.004.018c.045.228.084.426.109.584.023.15.051.356.03.567-.01.11-.029.24-.073.385a1.955 1.955 0 01-.153.36c-.144.268-.382.505-.58.701l-.058.058-.156.156v.001l-.002.001zM13.414 15l4.793 4.793a1.121 1.121 0 001.586-1.586l-4.282-4.282a6.53 6.53 0 01-.275-.048 15.93 15.93 0 00-.584-.108 8.576 8.576 0 00-.28.272l-.008.01-.95.949z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Tool02;
