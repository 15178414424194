import * as yup from 'yup';

export const emptyOptionLabel = 'Choose One';

export const MAX_FILE_SIZE = 2000000;

export const IMAGE_REQUIRED_MESSAGE = 'Image is required.';

export const socialMediaContentProps = {
  label: `What's your content style?`,
  items: [
    'Travel',
    'Comedy',
    'Inspirational',
    'Family',
    'Tech/Product',
    'Education',
    'Fitness',
    'Art & Design',
    'Lifestyle',
    'Food',
    'Wellness',
    'Mental Health',
    'Beauty',
    'Politics',
    'Others',
  ],
  labels: undefined,
};

export const EQUIPMENT_SUBTEXT_MESSAGE =
  'This information is necessary for Creator Mastercourse students.';

export const editingSoftwareStaticProps = {
  label: 'Editing software',
  items: ['fcp', 'pp', 'others'],
  labels: {
    fcp: 'Final Cut Pro',
    pp: 'Premiere Pro',
    others: 'Others',
    '': emptyOptionLabel,
  },
};
export const cameraStaticProps = {
  label: 'Camera',
  items: ['dslr', 'phone', 'others'],
  labels: {
    dslr: 'DSLR',
    phone: 'Phone Camera',
    others: 'Others',
    '': emptyOptionLabel,
  },
};

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

export const basicInfoFormSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  description: yup.string().max(140),
  longDescription: yup.string().max(250).notRequired(),
  corporateEmail: yup.string().email('Not valid email.'),
  dialCodeId: yup.string().default(''),
  phoneNumber: yup.string().default(''),
  whatsAppNumber: yup.string().matches(phoneRegExp, {
    excludeEmptyString: true,
    message: 'Phone number is not valid',
  }),
});

export const commentPreferenceFormSchema = yup.object().shape({
  hasSubscribedToCommentEmails: yup.string().required(),
});

export const videoPreferenceFormSchema = yup.object().shape({
  subtitlePreference: yup.string().required(),
});

const addProtocol = (str) => {
  const protocol = str.substring(0, 4);

  return protocol === 'http' ? str : 'https://' + str;
};

const customUrlValidator = yup.string().transform(addProtocol).url();
const conditionalUrlValidation = (field) =>
  yup
    .string()
    .test(field, `Enter complete url: www.${field}.com/username`, (val) => {
      return val.length > 0 ? customUrlValidator.isValidSync(val) : true;
    });

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const fileValidator = yup
  .mixed()
  .test('fileSize', 'File is too large', (value) => {
    return value.length > 0 ? value[0].size < MAX_FILE_SIZE : true;
  })
  .test('fileType', 'Not supported format. (Add JPG or PNG file)', (value) =>
    value.length > 0 ? SUPPORTED_FORMATS.includes(value[0]?.type) : true
  );

export const socialMediaFormSchema = yup.object().shape({
  facebook: conditionalUrlValidation('facebook'),
  instagram: conditionalUrlValidation('instagram'),
  youtube: conditionalUrlValidation('youtube'),
  twitter: conditionalUrlValidation('twitter'),
  linkedin: conditionalUrlValidation('linkedin'),
});

export const creationsSchema = yup.object().shape({
  title: yup.string().max(50).required(),
  description: yup.string().max(250).required(),
  link: customUrlValidator.required(),
});

export const postsSchema = yup.object().shape({
  title: yup.string().max(50).required(),
  description: yup.string().max(140).required(),
  photo: fileValidator.notRequired(),
});
