import Auth from '../modules/auth';

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, id: action.id, user: action.user };

    case 'SIGNUP':
      return { ...state, user: action.user };

    case 'LOGOUT':
      return {};

    case 'SET_USER_PROFILE': {
      Auth.setUserData(action.user);
      return { ...state, user: action.user };
    }

    case 'UPDATE_USER_DATA': {
      const updatedUserData = {
        ...state.user,
        learner: action.user,
      };
      Auth.setUserData(updatedUserData);
      return { ...state, user: updatedUserData };
    }

    case 'SET_USER_TOKEN':
      return { ...state, id: action.id, token: action.token };

    default:
      return state;
  }
};

export default authReducer;
