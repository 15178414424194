export const setLoginFailed = (message) => ({
  type: 'SET_LOGIN_FAILED',
  message,
});

export const setResetPasswordFailed = (message) => ({
  type: 'SET_RESET_PASSWORD_FAILED',
  message,
});

export const setUpdateProfileStatus = (updateProfileStatus) => ({
  type: 'SET_UPDATE_PROFILE_STATUS',
  updateProfileStatus,
});

export const setSuccess = (message) => ({
  type: 'SET_SUCCESS',
  message,
});

export const setLoading = (load) => ({
  type: 'SET_LOADING',
  load,
});

export const setAnnouncement = (announcement) => ({
  type: 'SET_ANNOUNCEMENT_SHOWN_STATUS',
  announcement,
});
