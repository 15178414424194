import React from 'react';
import PropTypes from 'prop-types';

import './Divider.scss';

const className = 'c-Divider';

const Divider = ({ narrow }) => {
  const narrowClass = narrow ? `${className}--narrow` : '';

  return (
    <>
      <div className={`${className} ${narrowClass}`} />
    </>
  );
};

Divider.propTypes = {
  narrow: PropTypes.bool,
};

export default Divider;
