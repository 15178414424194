import { useCallback, useEffect, useState } from 'react';

import { getMembersData } from '../apiServices';
import { membershipsParamsMapping } from '../pages/MembersPage/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const PAGE_SIZE = 10;
const useMembersData = (communityId, activeTag, communityCode) => {
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalMembers, setTotalMembers] = useState(null);
  const setNextPage = () => {
    setPageNumber((prev) => prev + 1);
  };

  const getFilterAndSortQueries = useCallback(
    () => membershipsParamsMapping[activeTag],
    [activeTag]
  );

  const isValidCommunityMembers = useMemo(
    () =>
      data?.every((f) =>
        f?.subscriptions?.some((x) => x?.communityCode === communityCode)
      ),
    [data, communityCode]
  );

  const fetchAndSetData = useCallback(async () => {
    if (!communityId) {
      // Don't process if no community ID
      return;
    }
    setLoading(true);
    if (hasMore) {
      const responseData = await getMembersData({
        communityId,
        pageNum: pageNumber,
        paginate: 1,
        pageSize: PAGE_SIZE,
        ...getFilterAndSortQueries(),
        countryId: user?.learner?.countryId,
      });
      let formattedData = [];
      if (responseData?.error) {
        console.warn('Error: ', responseData.error);
        return;
      }
      if (!responseData.error && responseData?.data) {
        formattedData = responseData?.data;
        const { data: pageData, metadata } = formattedData;

        if (!metadata?.next) {
          setHasMore(false);
        }

        setData((prev) => [...prev, ...pageData]);
        setTotalMembers(metadata?.total);
      }
      setLoading(false);
    }
  }, [
    communityId,
    getFilterAndSortQueries,
    hasMore,
    pageNumber,
    user?.learner?.countryId,
  ]);

  useEffect(() => {
    if (!isValidCommunityMembers) {
      setData([]);
      setPageNumber(1);
      setHasMore(true);
    }
  }, [isValidCommunityMembers]);

  // In case of change of community Id or active tag, reset the page data and number
  useEffect(() => {
    setData([]);
    setPageNumber(1);
    setHasMore(true);
  }, [communityId, activeTag]);

  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);

  return { data, loading, pageNumber, setNextPage, hasMore, totalMembers };
};

export default useMembersData;
