import { checkIsWebView } from '../utility/webview';

const getSingupOriginData = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const showParam = urlSearchParams.get('showSignupSuccessPrompt');
  const entityTypeParam = urlSearchParams.get('signupOriginEntityType');
  const entityIdParam = urlSearchParams.get('signupOriginEntityId');

  if (showParam) {
    const show = true;
    const entityType = entityTypeParam || null;
    const entityId = entityIdParam || null;

    return { show, entityType, entityId };
  }

  return null;
};

const removeSignupOriginQueryParams = () => {
  // Get the current URL's search parameters
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Remove the query parameters you want to delete
  urlSearchParams.delete('showSignupSuccessPrompt');
  urlSearchParams.delete('signupOriginEntityType');
  urlSearchParams.delete('signupOriginEntityId');

  // Generate the new URL without the removed parameters
  const newURL = `${window.location.pathname}?${urlSearchParams.toString()}`;

  // Update the window's location with the new URL
  window.history.replaceState({}, document.title, newURL);
};

const defaultState = {
  loginFailed: false,
  resetPasswordFailed: false,
  updateProfileStatus: false,
  loading: false,
  announcement: true,
  isWebView: checkIsWebView(window?.navigator?.userAgent),
  signupOriginData: getSingupOriginData(),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LOGIN_FAILED':
      return {
        ...state,
        loginFailed: action.message,
      };

    case 'SET_RESET_PASSWORD_FAILED':
      return {
        ...state,
        resetPasswordFailed: action.message,
      };

    case 'SET_SUCCESS':
      return {
        ...state,
        success: action.message,
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.load,
      };

    case 'SET_UPDATE_PROFILE_STATUS':
      return {
        ...state,
        updateProfileStatus: action.updateProfileStatus,
      };

    case 'SET_ANNOUNCEMENT_SHOWN_STATUS':
      return {
        ...state,
        announcement: action.announcement,
      };

    case 'CLOSE_SIGNUP_SUCCESS_PROMPT':
      removeSignupOriginQueryParams();
      return {
        ...state,
        signupOriginData: null,
      };

    default:
      return state;
  }
};
