import {
  ALL_MEMBERSHIPS_FILTER,
  NAS_ACADEMY_MEMBERSHIPS_FILTER,
  NEAR_YOU_MEMBERSHIPS_FILTER,
  NEWEST_DATA_SORTING_PARAM,
  OLDEST_DATA_SORTING_PARAM,
} from '../../apiServices/constants';

export const ALL = 'all';
export const NEAR_YOU = 'near-you';
export const NEWEST = 'newest';
export const NAS_ACADEMY = 'nas-academy';

export const tags = [ALL, NEAR_YOU, NEWEST];

export const membershipsParamsMapping = {
  [ALL]: { filter: ALL_MEMBERSHIPS_FILTER, sort: OLDEST_DATA_SORTING_PARAM },
  [NEAR_YOU]: {
    filter: NEAR_YOU_MEMBERSHIPS_FILTER,
    sort: OLDEST_DATA_SORTING_PARAM,
  },
  [NEWEST]: { filter: '', sort: NEWEST_DATA_SORTING_PARAM },
  [NAS_ACADEMY]: {
    filter: NAS_ACADEMY_MEMBERSHIPS_FILTER,
    sort: OLDEST_DATA_SORTING_PARAM,
  },
};
