const keys = {
  baseURL: process.env.REACT_APP_AUTH_SERVER_URL,
  paymentURL: process.env.REACT_APP_PAYMENT_SERVER_URL,
  imagesBasePath: process.env.REACT_APP_CLOUDFRONT_URL,
  // gtmId: process.env.REACT_APP_GTM_ID,
  gtmContainerId: process.env.REACT_APP_GTM_CONTAINER_ID,
  mainWebsiteUrl: process.env.REACT_APP_MAIN_WEBSITE_URL,
  courseAssetBaseUrl: process.env.REACT_APP_COURSE_ASSET_BASE_URL,
  referFreindUrl: process.env.REACT_APP_REFER_FREIND_LINK,
  redeemFormLink: process.env.REACT_APP_REDEEM_FORM_LINK,
  configCatKey: process.env.REACT_APP_CONFIG_CAT_KEY,
  referralBaseLink: process.env.REACT_APP_REFERRAL_BASE_URL,
  nasIOBaseUrl: process.env.REACT_APP_NASIO_BASE_URL,
  metaphiSdkApiKey: process.env.REACT_APP_METAPHI_SDK_API_KEY,
  metaphiSdkAccountId: process.env.REACT_APP_METAPHI_SDK_ACCOUNT_ID,
  authApiBaseUrl: process.env.REACT_APP_LOGIN_ROUTE,
  soldOutResourcePaths: process.env.REACT_APP_SOLD_OUT_RESOURCES,
  newMemberPortalRedirection:
    process.env.REACT_APP_NEW_MEMBER_PORTAL_REDIRECTION === 'true',
  newResetPasswordRedirection:
    process.env.REACT_APP_RESET_PASSWORD_REDIRECTION === 'true',
};

export default keys;
