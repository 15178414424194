import keys from '../../config/keys';

const useNewMPPageRedirection = ({ queryString, communityId, newPagePath }) => {
  if (keys.newMemberPortalRedirection) {
    const params = new URLSearchParams(queryString);

    if (communityId) {
      params.append('activeCommunityId', communityId);
    }

    window.location.href = `${
      process.env.REACT_APP_NASIO_BASE_URL
    }${newPagePath}?${params.toString()}`;
    return true;
  }

  return false;
};

export default useNewMPPageRedirection;
