import TimeFormatter from '../modules/timeFormatter';
import { convertCourseTimeFromToMinAndHours } from '../modules/preRecordedCourseHelper';
import { formatCalendarEndDateTime, getLocalCourseTime } from './dateHelper';

export const LIVE_COURSE_TYPE = 'live';
export const ON_DEMAND_COURSE_TYPE = 'preRecorded';
export const ACTIVE_STATUS = 'active';
export const COMPLETED_STATUS = 'completed';

export const formatLiveCourses = (courses) =>
  courses?.map((course) => {
    if (course.bundlePurchase) {
      const {
        courseDescription,
        bundlePurchase,
        slotRequested,
        courseCode,
        imgData,
        type,
        courseDuration,
        sessionCount,
      } = course;

      return {
        sessionCount,
        code: courseCode,
        title: courseDescription,
        courseDuration,
        progress: 0,
        slotRequested,
        bundlePurchase,
        imgData,
        type,
      };
    } else {
      const {
        startDate,
        endDate,
        unitOffers,
        courseDescription,
        completeLessonCounts,
        lessonCounts,
        courseOfferId,
        imgData,
        type,
        frequency,
        code,
        courseDuration,
        sessionCount,
      } = course || {};
      const courseStart = TimeFormatter.getFormattedDate(startDate);
      const courseEnd = TimeFormatter.getFormattedDate(endDate);
      const progress = (completeLessonCounts / lessonCounts).toFixed(2) * 100;
      const lessonStartTime = unitOffers[0]?.startDate;
      const lessonEndTime = unitOffers[0]?.endDate;
      const formattedFrequency = frequency
        .map((date) => {
          return TimeFormatter.getFormattedDay(date);
        })
        .join(' & ');

      return {
        sessionCount,
        courseDate: formatCourseDate(courseStart, courseEnd),
        progress: progress ? progress : 0,
        title: courseDescription,
        courseId: courseOfferId,
        courseDuration,
        frequency: formattedFrequency,
        lessonTime: formatCourseTime(
          TimeFormatter.getFormattedTime(lessonStartTime),
          TimeFormatter.getFormattedTime(lessonEndTime)
        ),
        bundlePurchase: false,
        imgData,
        type,
        code,
      };
    }
  });

export const formatCourseDate = (courseStart, courseEnd) => {
  if (courseStart === courseEnd) {
    return courseStart;
  } else {
    const courseStartDateMonth = courseStart.split(' ');
    const courseEndDateMonth = courseEnd.split(' ');
    if (courseStartDateMonth[0] === courseEndDateMonth[0]) {
      return `${courseStartDateMonth[1]} - ${courseEndDateMonth[1]} ${courseStartDateMonth[0]}`;
    } else {
      return `${courseStart} - ${courseEnd}`;
    }
  }
};

export const formatCourseTime = (courseStart, courseEnd) => {
  if (courseStart === courseEnd) {
    return courseStart;
  } else {
    const courseStartPeriod = courseStart.split(' ');
    const courseEndPeriod = courseEnd.split(' ');
    if (courseStartPeriod[1] === courseEndPeriod[1]) {
      return `${courseStartPeriod[0]}-${courseEndPeriod[0]} ${courseStartPeriod[1]}`;
    } else {
      return `${courseStart}-${courseEnd}`;
    }
  }
};

export const formatOnDemandCourses = (courses) =>
  courses?.map((course) => {
    const {
      title,
      progress,
      totalVideos,
      courseOfferObjectId,
      imgData,
      type,
      courseDuration,
    } = course || {};

    return {
      title,
      progress,
      courseDuration,
      imgData,
      type,
      sessionCount: `${totalVideos} ${totalVideos > 1 ? 'videos' : 'video'}`,
      courseId: courseOfferObjectId,
    };
  });

export const getLessonDuration = (lessonStartTime, lessonEndTime) => {
  const timeDiff = new Date(lessonEndTime) - new Date(lessonStartTime);
  const timeObj = convertCourseTimeFromToMinAndHours(timeDiff / 1000);
  return timeObj;
};

export const getCourseCountdown = (startDate, endDate = new Date()) => {
  const countdown = TimeFormatter.calculateCountdown(endDate, startDate);
  const { days, hours, min, sec } = countdown;
  if (days && days > 0) {
    return `${days} ${days > 1 ? 'days' : 'day'}`;
  } else if (hours && hours > 0) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  } else if (min && min > 0) {
    return `${min} min`;
  } else if (sec && sec > 0) {
    return `${sec} sec`;
  }
};

export const countdownExpires = (countdown) => {
  const { years, days, hrs, mins, secs } = countdown || {};
  return years === 0 && days === 0 && hrs === 0 && mins === 0 && secs === 0;
};

export const currentTimeWithinRange = (startDate, endDate) => {
  const currentTime = Date.parse(new Date());

  return (
    currentTime >= Date.parse(new Date(startDate)) &&
    currentTime <= Date.parse(new Date(endDate))
  );
};

export const calculateDifferenceInTime = (endDate, startDate = new Date()) => {
  return Date.parse(endDate) - Date.parse(new Date(startDate));
};

/**
 * Get the course type from course code.
 * @param {string} courseCode The course code
 * @return {string} The course type
 */
export const courseType = (courseCode) => {
  if (courseCode.match(/^NT/)) return 'teen';
  else return 'general';
};

/**
 * Get the currency for the transaction of course with the given course code.
 * @param {string} courseCode The course code.
 * @return {string} The currency for the sale of the course with the given course code.
 */
export const getCourseCurrency = (courseCode) => {
  if (courseCode.match(/^NG/)) return 'USD';
  else if (courseCode.match(/^NC/) || courseCode.match(/^SG-NC/)) return 'SGD';
  else return 'USD';
};

/**
 * Format the date into DD MMM, YYYY
 * @param {date} dateString The date to be formatted
 * @return The formatted date into the form of DD MMM, YYYY. Example, 15 May, 2020
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
  );
};

/**
 * Get the net payable after discount (if any).
 * @param {object} course The course offer
 * @param {object} discount The discount to be applied
 * @return {number} The net amount of the course after discount (if any).
 */
export const getNetPayable = (course, discount) => {
  if (!discount) return course.courseFees;

  let discountApplied = 0;
  if (discount.type === 'percent_off') {
    discountApplied = course.courseFees * (discount.value / 100);
  } else if (discount.type === 'amount_off') {
    discountApplied = discount.value / 100;
  }
  return course.courseFees - discountApplied;
};

export const getNetCreditPayable = (course, credit) => {
  if (credit < course.courseFees) return course.courseFees - credit;
  else return 0;
};

/*
    Convention to follow

    "CourseOfferStatusId":
        1 -- pending (haven't start, still taking in enrollment)
        2 -- active (started, currently still teaching)
        3 -- completed (ended, no more class)
*/
export const processWisenetCourses = (courses) => {
  let slimCourses = {};

  for (let i in courses) {
    const course = courses[i];

    const data = course.Data;
    const description = data.Description;
    const courseOfferCode = data.Code;
    const courseOfferId = data.CourseOfferId;
    const courseStartDate = data.CourseOfferStartDate;
    const courseEndDate = data.CourseOfferEndDate;
    const maxRegistrations = data.MaxRegistrations;
    const courseFees = data.CourseFees;
    const deposit = data.Deposit;
    const courseCode = course.Relationships.Course.CourseCode;
    const courseOfferStatusId = data.CourseOfferStatusId;

    let location;
    if (course.Relationships.Location)
      location = course.Relationships.Location.Description;

    if (!(courseCode in slimCourses)) slimCourses[courseCode] = [];

    slimCourses[courseCode].push({
      courseCode: courseCode,
      courseOfferId: courseOfferId,
      courseOfferCode: courseOfferCode,
      description: description,
      courseStartDate: courseStartDate,
      courseEndDate: courseEndDate,
      maxRegistrations: maxRegistrations,
      courseFees: courseFees,
      deposit: deposit,
      courseOfferStatusId: courseOfferStatusId,
      location: location,
    });
  }

  return slimCourses;
};

/**
 * Convert the courses from wisenet into the format needed by front-end.
 * @param {array} courses Courses from wisenet
 * @return {array} Processed courses
 */
export const processWisenetCoursesNew = (courses) => {
  let slimCourses = {};

  const mappingCourseOfferStatus = {
    Pending: 1,
    Active: 2,
    Inactive: 3,
    Cancelled: 4,
  };

  for (let i in courses) {
    const course = courses[i];

    const data = course.Data;
    const description = data.CourseOfferDescription;
    const courseOfferCode = data.CourseOfferCode;
    const courseOfferId = data.CourseOfferId;
    const courseStartDate = data.OfferingStartDate;
    const courseEndDate = data.OfferingEndDate;
    const maxRegistrations = data.MaxRegistrations;
    const courseFees = data.CourseFees;
    const deposit = data.Deposit;
    const courseCode = data.Course.CourseCode;
    const courseOfferStatusId =
      mappingCourseOfferStatus[data.CourseOfferStatus];
    const location = data.Location;
    const vacancy = data.VacancyCount;

    if (!(courseCode in slimCourses)) slimCourses[courseCode] = [];

    slimCourses[courseCode].push({
      courseCode: courseCode,
      courseOfferId: courseOfferId,
      courseOfferCode: courseOfferCode,
      description: description,
      courseStartDate: courseStartDate,
      courseEndDate: courseEndDate,
      maxRegistrations: maxRegistrations,
      courseFees: courseFees,
      deposit: deposit,
      courseOfferStatusId: courseOfferStatusId,
      location: location,
      vacancy: vacancy,
    });
  }

  return slimCourses;
};

/*
    params courses must be processed by processWisenetCourses
*/

/**
 * Filter wisenet courses by course code and course offer status id.
 * @param {object} courses Courses from wisenet.
 * @param {string} filterCourseCategory Course code as a filter.
 * @param {number} filterCourseOfferStatusId Course offer status id as a filter.
 * @return {array} Filtered courses.
 */
export const filterWisenetCourses = (
  courses,
  filterCourseCategory,
  filterCourseOfferStatusId
) => {
  if (filterCourseCategory in courses)
    return courses[filterCourseCategory].filter(
      (c) => c.courseOfferStatusId === filterCourseOfferStatusId
    );
};

/**
 * Filter wisenet courses that are starting later than today or the specified 'today' date.
 * @param {array} courses Courses.
 * @param {date} todayDate Optional date as a base reference.
 * @return {array} Filtered courses that are later than the specified 'today' date.
 */
export const filterFutureWisenetCourses = (courses, todayDate) => {
  let today = todayDate ? todayDate : new Date();
  return courses.filter((course) => {
    const courseStartDate = new Date(course.courseStartDate);
    return today < courseStartDate;
  });
};

/**
 * Sort courses by start date in ascending order.
 * @param {array} courses Courses to be sorted
 * @return {array} Sorted courses in ascending order of start date.
 */
export const sortCoursesByStartDate = (courses) => {
  return courses.sort((c1, c2) => {
    const c1StartDate = new Date(c1.courseStartDate);
    const c2StartDate = new Date(c2.courseStartDate);

    if (c1StartDate > c2StartDate) {
      return 1;
    } else if (c1StartDate < c2StartDate) {
      return -1;
    } else {
      return 0;
    }
  });
};

/**
 * Comparator for course start date
 * @param {object} a Course A
 * @param {object} b Course B
 * @return {number} -1 if a start date is less than b start date, 1 if a start date is more than b start date, 0 otherwise.
 */
export const compareCoursesStartDate = (a, b) => {
  const aDate = new Date(a.courseStartDate);
  const bDate = new Date(b.courseStartDate);
  if (aDate < bDate) return -1;
  if (aDate > bDate) return 1;
  else return 0;
};

/**
 * Get the date, frequency, and end day of a course.
 * @param {object} course Course offer
 * @param {string} timezoneSelected The timezone to be converted to
 * @return {object} Object containing the local start date, end date, frequency of lesson, and end day in the selected timezone format.
 */
export const getCourseLocalDateAndTime = (course) => {
  const moment = require('moment-timezone');

  const frequency = course?.frequency?.localFrequency;
  const startLocalDateTime = course?.localCourseStartDate;
  const endLocalDateTime = course?.localCourseEndDate;

  return {
    startLocalDateTime: moment(startLocalDateTime),
    endLocalDateTime: moment(endLocalDateTime),
    displayFrequency: frequency,
    endLocalDay: moment(endLocalDateTime),
  };
};

export const calculateDateToCourse = (course) => {
  let diff =
    (Date.parse(new Date(course.ApplicationStartDate)) -
      Date.parse(new Date())) /
    1000;

  // clear countdown when date is reached
  if (diff <= 0) return false;

  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  };

  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = diff;

  return timeLeft;
};

export const isVacancyBelowThreshold = (
  maxRegistration,
  vacancy,
  thresholdPercent = 20
) => {
  const thresholdCount = parseInt(
    Math.floor(thresholdPercent * maxRegistration) / 100
  );
  return vacancy < thresholdCount;
};

export const getScheduledInStrFromCountdown = (countdown) => {
  const { years, days, hrs, mins, secs } = countdown || {};

  if (years) {
    const plurifiedYearsLabel = years > 1 ? 'Years' : 'Year';
    return `${years} ${plurifiedYearsLabel}`;
  }

  if (days) {
    const plurifiedDaysLabel = days > 1 ? 'Days' : 'Day';
    return `${days} ${plurifiedDaysLabel}`;
  }

  if (hrs) {
    const plurifiedHrsLabel = hrs > 1 ? 'Hours' : 'Hour';
    return `${hrs} ${plurifiedHrsLabel}`;
  }

  if (mins) {
    const plurifiedMinsLabel = mins > 1 ? 'Minutes' : 'Minute';
    return `${mins} ${plurifiedMinsLabel}`;
  }

  if (secs) {
    const plurifiedSecsLabel = secs > 1 ? 'Seconds' : 'Second';
    return `${secs} ${plurifiedSecsLabel}`;
  }

  return '';
};

export const getCountdownToDate = (dt) => {
  let diff = (Date.parse(new Date(dt)) - Date.parse(new Date())) / 1000;

  const countdown = {
    years: 0,
    days: 0,
    hrs: 0,
    mins: 0,
    secs: 0,
  };

  if (diff <= 0) {
    return countdown;
  }

  const SECONDS_IN_MIN = 60;
  const SECONDS_IN_HR = 60 * 60;
  const SECONDS_IN_DAY = 24 * SECONDS_IN_HR;
  const SECONDS_IN_YEAR = 365.25 * SECONDS_IN_DAY;

  // calculate time difference between now and expected date
  if (diff >= SECONDS_IN_YEAR) {
    countdown.years = Math.floor(diff / SECONDS_IN_YEAR);
    diff -= countdown.years * SECONDS_IN_YEAR;
  }
  if (diff >= SECONDS_IN_DAY) {
    countdown.days = Math.floor(diff / SECONDS_IN_DAY);
    diff -= countdown.days * SECONDS_IN_DAY;
  }
  if (diff >= SECONDS_IN_HR) {
    countdown.hrs = Math.floor(diff / SECONDS_IN_HR);
    diff -= countdown.hrs * SECONDS_IN_HR;
  }
  if (diff >= SECONDS_IN_MIN) {
    countdown.mins = Math.floor(diff / SECONDS_IN_MIN);
    diff -= countdown.mins * SECONDS_IN_MIN;
  }

  countdown.secs = diff;

  return countdown;
};

export const getScheduledInStr = (nextDate) => {
  const countdown = getCountdownToDate(nextDate);
  return getScheduledInStrFromCountdown(countdown);
};

export const getCoursePeriod = (courseOfferingDetails) => {
  const courseLocalTime = getLocalCourseTime(
    `${courseOfferingDetails?.localStartDate}`,
    `${courseOfferingDetails?.localEndDate}`
  );
  const formattedStartDate = courseLocalTime?.formattedStartDateTimeLocal ?? '';
  const formattedEndDate = courseLocalTime?.formattedEndDateTimeLocal ?? '';
  return formattedStartDate === formattedEndDate
    ? formattedStartDate
    : `${formattedStartDate} - ${formattedEndDate}`;
};

export const getFormattedCourseSchedule = (courseOfferingDetails) => {
  const courseFreq = courseOfferingDetails?.frequency?.localFrequency ?? '';
  const courseLocalTime = getLocalCourseTime(
    `${courseOfferingDetails?.localStartDate}`,
    `${courseOfferingDetails?.localEndDate}`
  );
  const { lessonStartTime, lessonEndTime } = courseLocalTime;
  const formattedCourseSchedule = `${courseFreq} ${lessonStartTime} - ${lessonEndTime}`;
  return formattedCourseSchedule;
};

export const getCalendarEvents = (courseOfferingDetails) => {
  const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    title: courseOfferingDetails?.courseDescription,
    description: `${timezoneId} ${courseOfferingDetails?.generalNotes}`,
    location: timezoneId,
    startTime: courseOfferingDetails?.localStartDate,
    endTime: formatCalendarEndDateTime(
      courseOfferingDetails?.localStartDate,
      courseOfferingDetails?.localEndDate
    ),
    recurring: {
      repeat: 'weekly',
      byDay: courseOfferingDetails?.frequency?.localFrequency
        ?.split('&')
        ?.map((day) => day?.trim()?.toUpperCase()?.substr(0, 2))
        ?.join(','),
      interval: 1,
      weekStart: 'SU',
      count: courseOfferingDetails?.numberOfLessons,
    },
  };
};

export const getCourseOfferingTypeLabel = (desc, type) => {
  const labels = {
    live: '(LIVE)',
    preRecorded: '(On-Demand)',
  };

  return `${desc} ${labels[type]}`;
};
