import {
  COMMUNITY_ONE_TIME_PAYMENT_ENTITIES,
  PAYMENT_FAILURE,
  PAYMENT_PENDING,
  PAYMENT_SUCCESS,
} from './constants';
import {
  signUpForEntity,
  verifyPaymentForEntitySignUp,
} from '../../services/checkoutServices/communityEventCheckoutServices';
import { useEffect, useRef, useState } from 'react';

import { getQueryParams } from '../../utility/domHelpers';
import { showToast } from '../../CommunityPortal/components/ToastContainer';
import { useSelector } from 'react-redux';
import useStripePayment from './useStripePayment';
import useXenditPayment from './useXenditPayment';
import { trackGeneralEvent } from '../../utility/analytics';
import { useCommunityPortalContext } from '../../CommunityPortal/contexts/CommunityPortalContext';
import { removeAllToasts } from '../../CommunityPortal/components/ToastContainer/ToastContainer';
import useDiscount, { DISCOUNT_ENTITY_TYPE } from './useDiscount';
import {
  getDetailedStripeErrorMessage,
  getStripePublicKeyFromPaymentMethods,
} from '../../pages/CheckoutPage/util';
import sessionStorageService from '../../utility/sessionStorageService';
import { useTranslation } from 'react-i18next';

const MAX_RETRIES_FOR_VERIFY_PAYMENT = 3;
const stripeModes = ['stripe', 'stripe-india', 'stripe-india-upi'];

export const COUNTRY_CHECKOUT_TYPE = {
  RECURRING: 'recurring',
  ONETIME: 'onetime',
};

const usePayment = ({
  entityId,
  entityType,
  communityId,
  communityCode,
  postSignUpCallback,
  checkoutUrl,
  fetchedPaymentMethods,
}) => {
  const { t } = useTranslation();
  const { activeCommunityData } = useCommunityPortalContext();
  const {
    discountInfo,
    isDiscountInfoLoading,
    discountCodeInput,
    setDiscountCodeInput,
    discountCodeInputError,
    getDiscountInfo,
    isDiscountHundredPercentage,
    setDiscountCodeInputError,
    removeAppliedDiscount,
    getHundredPercentDiscountAccess,
    isHundredPercentageDiscountAccessLoading,
  } = useDiscount();

  const [paymentInProgress, setPaymentInProgress] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [localPaymentMethods, setLocalPaymentMethods] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [verifyPaymentData, setVerifyPaymentData] = useState(null);
  const [verifyPaymentLoading, setVerifyPaymentLoading] = useState(false);
  const [entitySignupId, setEntitySignupId] = useState(null);
  const userData = useSelector((state) => state.user?.user);
  const [errorMessageForPayment, setErrorMessageForPayment] = useState('');
  const queryParams = getQueryParams();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [showUpiHelpModal, setShowUpiHelpModal] = useState(false);

  // porpose here is to find all the saved UPI methods and display it as it's own selectedable item
  const formatPaymentMethods = (rawPaymentMethods) => {
    let formattedPaymentMethods = [];
    let newPaymentMethod = {};
    rawPaymentMethods?.forEach((paymentMethod, outerIndex) => {
      if (paymentMethod.mode === 'stripe-india-upi') {
        paymentMethod?.methodsAllowed?.forEach((method, index) => {
          newPaymentMethod = {
            categoriesAllowed: ['UPI'],
            id: `${paymentMethod?.mode}-${index}`,
            mode: `stripe-india-upi`,
            publicKey: paymentMethod?.publicKey,
            methodsAllowed: [
              {
                category: 'UPI',
                type: 'UPI',
                icon:
                  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/Payment/svg/UPI.svg',
                oneTimeChannelProperties: [...method?.oneTimeChannelProperties],
                recurringChannelProperties: [],
              },
            ],
          };
          // make sure new UPI method is added to the end of the list if there is an existing UPI also
          if (
            !method?.oneTimeChannelProperties[0]?.value &&
            paymentMethod?.methodsAllowed?.length > 1
          ) {
            formattedPaymentMethods.splice(0, 0, newPaymentMethod);
          } else {
            formattedPaymentMethods.push(newPaymentMethod);
          }
        });
      } else {
        formattedPaymentMethods.push({
          ...paymentMethod,
          id: `${paymentMethod?.mode}-${outerIndex}`,
        });
      }
    });
    return formattedPaymentMethods;
  };

  useEffect(() => {
    if (fetchedPaymentMethods && fetchedPaymentMethods.length > 0) {
      const formattedPaymentMethods = formatPaymentMethods(
        fetchedPaymentMethods
      );
      setPaymentMethods(formattedPaymentMethods);
      setSelectedPaymentMethod(formattedPaymentMethods.at(-1));
    }
  }, [fetchedPaymentMethods]);

  const intervalRef = useRef();

  let stripeProps = useStripePayment();
  let xenditProps = useXenditPayment();

  const resetPaymentStatus = () => setPaymentStatus('');
  const setPaymentSuccess = () => setPaymentStatus(PAYMENT_SUCCESS);
  const setPaymentFailed = () => setPaymentStatus(PAYMENT_FAILURE);
  const setPaymentPending = () => setPaymentStatus(PAYMENT_PENDING);

  const {
    generateClientSecret,
    handleStripeCardPayment,
    handleStripeUpiPayment,
    setDisableAllowedToSaveCreditCardDetails,
    stripeError,
    setStripeError,
  } = stripeProps || {};
  const { createEWalletCharge } = xenditProps;

  // global level error handler
  const handlePaymentFailure = ({ errorMessage }) => {
    let payload = {
      paymentMethod: selectedPaymentMethod?.mode,
      communityCode,
      communityId,
    };
    if (entityType === COMMUNITY_ONE_TIME_PAYMENT_ENTITIES.EVENT) {
      payload.eventId = entityId;
    }
    if (entityType === COMMUNITY_ONE_TIME_PAYMENT_ENTITIES.FOLDER) {
      payload.folderId = entityId;
    }
    trackGeneralEvent('payment_failed', payload);
    setPaymentFailed();
    if (errorMessage) {
      removeAllToasts();
      const formattedErrorMessage = getDetailedStripeErrorMessage(
        t,
        errorMessage
      );
      showToast({
        text: formattedErrorMessage,
        nplVersion: 2,
        type: 'error',
        dismissOnClick: true,
      });
    }
  };

  // Payment helper functions
  const handlePaymentSuccess = () => {
    let data = {
      communityId: communityId,
      communityCode: communityCode,
    };

    if (entityType === COMMUNITY_ONE_TIME_PAYMENT_ENTITIES.EVENT) {
      data = {
        ...data,
        eventId: entityId,
      };
    } else if (entityType === COMMUNITY_ONE_TIME_PAYMENT_ENTITIES.FOLDER) {
      data = {
        ...data,
        folderId: entityId,
      };
    }

    trackGeneralEvent('payment_success', {
      ...data,
      paymentMethod: selectedPaymentMethod?.mode,
    });
    removeAllToasts();
    setPaymentSuccess();
    if (postSignUpCallback) postSignUpCallback();
    // getRedirectLink({ bookingId: signupId, isCommunity: true });
  };

  const onStripeCardPayClick = async (e) => {
    e.preventDefault();
    const isStripeSingleField = false;
    // make stripe payment
    const paymentSuccessful = await handleStripeCardPayment(
      { email: userData?.email },
      isStripeSingleField
    );
    // handle payment response
    if (paymentSuccessful) {
      handlePaymentSuccess();
    }
  };

  const onStripeUpiPayClick = async (e) => {
    e.preventDefault();
    // make stripe payment
    const paymentSuccessful = await handleStripeUpiPayment();
    // handle payment response
    if (paymentSuccessful) {
      handlePaymentSuccess();
    }
  };

  async function handleSignup() {
    try {
      if (entityId && entityType && communityId) {
        const { data, error } = await signUpForEntity(
          entityId,
          entityType,
          communityId
        );
        if (error) {
          throw new Error(error);
        }
        if (data?.data?.id) {
          sessionStorageService.setItem('entitySignupId', data?.data?.id);
          sessionStorageService.setItem(
            'entityPurchaseToken',
            data?.data?.access_token
          );
          setEntitySignupId(data?.data?.id);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (entityId && entityType && communityId) {
      handleSignup();
    }
  }, [entityId, entityType, communityId]);

  const initializeStripe = async () => {
    try {
      setDisableAllowedToSaveCreditCardDetails(true);

      if (entitySignupId && activeCommunityData) {
        const resp = await generateClientSecret({
          entitySignupId,
          entityType,
          communityId: communityId,
          endpoint:
            getStripePublicKeyFromPaymentMethods(fetchedPaymentMethods)
              ?.mode === 'INDIA'
              ? 'stripe-india-checkout'
              : 'stripe-checkout',
          discountInfo,
        });
        // Check if init stripe failed because of discount code, try without disount code
        if (resp?.error && discountInfo?.code) {
          showToast({
            text:
              'Error occured while applying discount code, please contact community manager or Nasio support.',
            type: 'error',
          });
          removeAppliedDiscount();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onXenditPayClick = async (e) => {
    e.preventDefault();

    try {
      if (entitySignupId) {
        await createEWalletCharge({
          entitySignupId,
          entityType,
          entityId: entityId,
          communityId,
          checkoutUrl,
          discountInfo,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Handle polling for OVO payment method

  useEffect(() => {
    if (
      stripeModes.includes(selectedPaymentMethod?.mode) &&
      !isDiscountHundredPercentage &&
      discountInfo?.value !== 100
    ) {
      initializeStripe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entitySignupId,
    setDisableAllowedToSaveCreditCardDetails,
    selectedPaymentMethod,
    activeCommunityData,
    discountInfo,
    isDiscountHundredPercentage,
  ]);

  // This is to handle the stripe error
  useEffect(() => {
    if (stripeError !== '') {
      handlePaymentFailure({ errorMessage: stripeError });
      setStripeError('');
    }
  }, [stripeError, setStripeError, handlePaymentFailure]);

  const verifyPayment = async (retry = 1) => {
    try {
      setVerifyPaymentLoading(true);
      const entitySignUpId =
        sessionStorageService.getItem('entitySignupId') ||
        queryParams?.entitySignupId;
      const communityID =
        communityId ||
        queryParams?.communityObjectId ||
        sessionStorageService.getItem('communityId');

      const { data, error } = await verifyPaymentForEntitySignUp(
        communityID,
        entityType,
        entitySignUpId
      );
      if (error) {
        if (retry < MAX_RETRIES_FOR_VERIFY_PAYMENT) {
          return await verifyPayment(retry + 1);
        } else {
          throw new Error(error);
        }
      }
      if (data) {
        setVerifyPaymentLoading(false);
        setVerifyPaymentData(data?.data);
        setPaymentSuccess();
        xenditProps.setShouldPollVerify(false);
        return data.data;
      }
    } catch (error) {
      setVerifyPaymentLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (
      xenditProps?.selectedXenditMethod?.type === 'ID_OVO' &&
      xenditProps.shouldPollVerify &&
      !intervalRef.current
    ) {
      console.log('ovo payment');
      showToast({
        text: `Please proceed to OVO to complete payment`,
        description: 'Complete the transaction in your application',
        type: 'warning',
        nplVersion: 2,
        dismissOnClick: false,
        autoCloseTime: 6000,
      });
      // create an interval to check if the payment is successful
      // setIsProcessing(true);
      intervalRef.current = setInterval(() => {
        verifyPayment();
        // setIsProcessing(false);
      }, 5000);
    }
    return () => {
      if (!xenditProps.shouldPollVerify && intervalRef.current)
        clearInterval(intervalRef.current);
    };
    // setIsProcessing(false);
  }, [verifyPayment, xenditProps]);

  // get discount code detail from the params
  useEffect(() => {
    const discountCode = queryParams?.discountCode ?? '';

    if (!discountCode || !entityId) return;

    setDiscountCodeInput(discountCode);
    getDiscountInfo({
      discountCode,
      communityCode: communityCode,
      entityObjectId: entityId,
      entityType: (() => {
        if (entityType === COMMUNITY_ONE_TIME_PAYMENT_ENTITIES.EVENT)
          return DISCOUNT_ENTITY_TYPE.EVENT;

        if (entityType === COMMUNITY_ONE_TIME_PAYMENT_ENTITIES.FOLDER)
          return DISCOUNT_ENTITY_TYPE.FOLDER;

        return '';
      })(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, entityType]);

  return {
    entitySignupId,
    // User Data for the contact information
    userData,
    // stripe exports
    stripeProps: {
      ...stripeProps,
      onStripeCardPayClick,
      onStripeUpiPayClick,
    },
    // xendit exports
    xenditProps: {
      ...xenditProps,
      onXenditPayClick,
    },
    // payment methods props exports
    paymentMethods,

    // Checkout Functions
    handlePaymentSuccess,
    handlePaymentFailure,
    verifyPayment,

    // local payments
    setLocalPaymentMethods,
    localPaymentMethods,

    // payment status
    paymentInProgress,
    setPaymentInProgress,
    paymentStatus,
    setPaymentSuccess,
    setPaymentFailed,
    setPaymentPending,
    resetPaymentStatus,
    verifyPaymentLoading,
    verifyPaymentData,
    setVerifyPaymentData,
    errorMessageForPayment,
    setErrorMessageForPayment,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    showUpiHelpModal,
    setShowUpiHelpModal,
    showUpiWaitingModal: stripeProps.showUpiWaitingModal,
    setShowUpiWaitingModal: stripeProps.setShowUpiWaitingModal,

    discountInfo,
    isDiscountInfoLoading,
    discountCodeInput,
    setDiscountCodeInput,
    discountCodeInputError,
    getDiscountInfo,
    isDiscountHundredPercentage,
    setDiscountCodeInputError,
    removeAppliedDiscount,
    getHundredPercentDiscountAccess,
    isHundredPercentageDiscountAccessLoading,
  };
};

export default usePayment;
