import React from 'react';

const ChatFilledIcon = () => {
  return (
    <svg
      className="c-ChatIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1B1B18"
        d="M15 10a6 6 0 01-9.21 5.07L3 16l.93-2.79A6 6 0 1115 10z"
      ></path>
      <path
        fill="#1B1B18"
        d="M5.79 15.07l.536-.844a1 1 0 00-.852-.105l.316.949zM3 16l-.949-.316a1 1 0 001.265 1.265L3 16zm.93-2.79l.949.316a1 1 0 00-.105-.852l-.844.536zM9 17a7 7 0 007-7h-2a5 5 0 01-5 5v2zm-3.746-1.086A6.972 6.972 0 009 17v-2a4.972 4.972 0 01-2.674-.774l-1.072 1.688zm.22-1.793l-2.79.93.632 1.898 2.79-.93-.632-1.898zm-1.525 2.195l.93-2.79-1.898-.632-.93 2.79 1.898.632zM2 10c0 1.376.398 2.662 1.086 3.746l1.688-1.072A4.972 4.972 0 014 10H2zm7-7a7 7 0 00-7 7h2a5 5 0 015-5V3zm7 7a7 7 0 00-7-7v2a5 5 0 015 5h2z"
      ></path>
      <path
        stroke="#1B1B18"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.338 15.99a6.002 6.002 0 008.872 3.08L21 20l-.93-2.79.142-.236A6 6 0 0015 8l-.225.004-.113.006"
      ></path>
    </svg>
  );
};

export default ChatFilledIcon;
