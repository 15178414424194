import MasterCardIcon from '../../../../../../CommunityPortal/components/Icons/MasterCardIcon';
import PropTypes from 'prop-types';
import VisaCardIcon from '../../../../../../CommunityPortal/components/Icons/VisaCardIcon';
import { formatDate } from '../../../../../../utility/courseHelper';
import { useWindowWidthContext } from '../../../../../../contexts/WindowWidthContext';
import { useTranslation } from 'react-i18next';
const className = 'c-BillingHistory';

const BillingHistory = ({ invoices, loading }) => {
  const { t } = useTranslation();

  const { isGtEqMd } = useWindowWidthContext();
  const renderCardIcon = (brand) => {
    if (brand === 'visa') {
      return <VisaCardIcon />;
    } else if (brand === 'mastercard') {
      return <MasterCardIcon />;
    }
    return null;
  };

  const renderPaymentMethod = (invoice) => {
    const { payment_method_details } = invoice;
    if (payment_method_details?.type === 'card') {
      const { brand, last4 } = payment_method_details.card;
      return (
        <div className="flex flex-row items-center my-12">
          {renderCardIcon(brand)}
          <span className="text-body-md">••••{last4}</span>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row items-center my-12">
          <span className="text-body-md">E-wallet</span>
        </div>
      );
    }
  };

  const renderSkeleton = () => {
    return [1, 2, 3, 4].map((t) => (
      <tr key={t} className="animate-pulse">
        <td colSpan={4} className="h-[30px]">
          <div className="w-full py-16 bg-grey-neutral90 rounded-8"></div>
        </td>
      </tr>
    ));
  };

  const getUTCDateStringFromCreated = (date) => {
    const dateObj = new Date(date);
    if (typeof date !== 'string') dateObj.setUTCSeconds(date);
    return dateObj.toISOString();
  };

  const renderMobileView = () => {
    return (
      <div className="grid grid-flow-row grid-cols-2 mt-24">
        <span className="font-semibold text-label-lg text-grey-neutral10">
          {t('details')}
        </span>
        <span className="font-semibold text-label-lg text-grey-neutral10 justify-self-end">
          {t('price')}
        </span>
        <hr className="col-span-2 mt-12 border-t-1 border-grey-neutral90" />
        {invoices.map((invoice, i) => (
          <>
            <div className="flex flex-col mt-16">
              <span className="text-body-md">
                {formatDate(getUTCDateStringFromCreated(invoice?.created))}
              </span>
              <span className="text-body-md">{invoice.description}</span>
              <span className="text-body-md">
                {renderPaymentMethod(invoice)}
              </span>
            </div>
            <div className="mt-16 justify-self-end">
              <span className="uppercase">{invoice.currency}</span>{' '}
              {new Intl.NumberFormat({
                currency: invoice.currency,
                maximumFractionDigits: 2,
                trailingZeroDisplay: 'lessPrecision',
              }).format(invoice.amount_captured / 100)}{' '}
            </div>
            <hr className="col-span-2 mt-12 border-t-1 border-grey-neutral90" />
          </>
        ))}
      </div>
    );
  };

  return (
    <div className={`${className} w-full flex flex-col`}>
      <div className="mt-16">
        <span className="font-medium text-heading-xs text-grey-neutral10">
          {t('billing-history')}
        </span>
      </div>
      {isGtEqMd && (
        <table className="w-full mt-16 table-auto">
          <thead className="text-left">
            <tr className="border-b-1 border-grey-neutral90">
              <th className="my-12 font-semibold text-label-lg text-grey-22">
                <div className="my-12">{t('date')}</div>
              </th>
              <th className="my-12 font-semibold text-label-lg text-grey-22">
                {t('description')}
              </th>
              <th className="my-12 font-semibold text-label-lg text-grey-22">
                {t('payment-method')}
              </th>
              <th className="my-12 font-semibold text-label-lg text-grey-22">
                {t('total')}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading && renderSkeleton()}
            {invoices?.map((invoice, i) => (
              <tr className="border-b-1 border-grey-neutral90 hover:bg-grey-neutral95">
                <td className="my-12 text-body-md">
                  {formatDate(getUTCDateStringFromCreated(invoice.created))}
                </td>
                <td className="my-12 text-body-md">{invoice.description}</td>
                <td className="my-12 text-body-md">
                  {renderPaymentMethod(invoice)}
                </td>
                <td className="my-12 text-body-md">
                  <span className="uppercase">{invoice.currency}</span>{' '}
                  {new Intl.NumberFormat({
                    currency: invoice.currency,
                    maximumFractionDigits: 2,
                    trailingZeroDisplay: 'lessPrecision',
                  }).format(invoice.amount_captured / 100)}{' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isGtEqMd && renderMobileView()}
    </div>
  );
};

BillingHistory.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

BillingHistory.defaultProps = {};

export default BillingHistory;
