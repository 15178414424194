import React, { useEffect } from 'react';
import './Checkbox.scss';

const Checkbox = ({ label, checked, ...props }) => {
  const className = 'c-Checkbox';
  const classNames = [className, checked ? ` ${className}--checked` : ''].join(
    ''
  );

  const checkmarkClassName = `${className}__checkmark`;
  const checkmarkClassNames = [
    checkmarkClassName,
    checked ? ` ${checkmarkClassName}--checked` : '',
  ].join('');

  return (
    <label className={classNames}>
      {label}
      <input type="checkbox" {...props} />
      <span className={checkmarkClassNames}></span>
    </label>
  );
};

export default Checkbox;
