import Cookies from 'js-cookie';
import config from './config.js';
import { getPrimaryDomain } from './stringHelpers.js';
import localStorageService from '../utility/localStorageService.js';
const { authCookieDomain } = config;
const ACCESS_TOKEN_KEY_COOKIE = 'accessTokenNA';
const REFRESH_TOKEN_KEY_COOKIE = 'refreshTokenNA';

class Auth {
  /**
   * Save the user data in session Storage
   *
   * @param {string} userData
   * @param {string} accessToken
   */
  static authenticateUser(userData, accessToken) {
    if (userData)
      localStorageService.setItem('userData', JSON.stringify(userData));

    Cookies.set(ACCESS_TOKEN_KEY_COOKIE, accessToken, {
      path: '/',
      domain: getPrimaryDomain(),
    });
  }

  static setTokens(accessToken, refreshToken) {
    Cookies.set(ACCESS_TOKEN_KEY_COOKIE, accessToken, {
      path: '/',
      domain: getPrimaryDomain(),
    });
    Cookies.set(REFRESH_TOKEN_KEY_COOKIE, refreshToken, {
      path: '/',
      domain: getPrimaryDomain(),
    });
  }

  /**
   * De-authenticate a user.
   * Remove a token from session Storage.
   *
   */
  static deauthenticateUser() {
    localStorageService.removeItem('userData');
    localStorageService.removeItem('course');
    localStorageService.removeItem('isEnrolledinCMC');
    Cookies.remove(ACCESS_TOKEN_KEY_COOKIE, {
      path: '/',
      domain: authCookieDomain,
    });
    Cookies.remove(REFRESH_TOKEN_KEY_COOKIE, {
      path: '/',
      domain: authCookieDomain,
    });
    Auth.deleteUriTokens();
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserToken() {
    return Cookies.get(ACCESS_TOKEN_KEY_COOKIE);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return Cookies.get(REFRESH_TOKEN_KEY_COOKIE);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserTokenFromCookies() {
    return Cookies.get(ACCESS_TOKEN_KEY_COOKIE, {
      path: '/',
      domain: authCookieDomain,
    });
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshTokenFromCookies() {
    return Cookies.get(REFRESH_TOKEN_KEY_COOKIE, {
      path: '/',
      domain: authCookieDomain,
    });
  }

  /**
   * return user data
   *
   * @returns {object}
   */
  static getUserData() {
    const userDataStr = localStorageService.getItem('userData');

    if (userDataStr === 'undefined') return null; // for backward compatability

    return userDataStr ? JSON.parse(userDataStr) : null;
  }

  /**
   * set user data
   *
   */
  static setUserData(userData) {
    if (userData)
      localStorageService.setItem('userData', JSON.stringify(userData));
  }

  static setReferralCode(referralCode) {
    if (referralCode) localStorageService.setItem('referralCode', referralCode);
  }

  static getReferralCode(referralCode) {
    return localStorageService.getItem('referralCode');
  }
  /**
   * set trainer data
   *
   */
  static setTrainerData(trainerData) {
    localStorageService.setItem('trainerData', JSON.stringify(trainerData));
  }

  static setIfUserEnrolledInCMC(value) {
    localStorageService.setItem('isEnrolledinCMC', value);
  }

  static getIfUserEnrolledInCMC() {
    return localStorageService.getItem('isEnrolledinCMC');
  }

  static clearIfUserEnrolledInCMC() {
    localStorageService.removeItem('isEnrolledinCMC');
  }

  static setUriTokens(token, refreshToken) {
    localStorageService.setItem('uriAccessToken', token);
    localStorageService.setItem('uriRefreshToken', refreshToken);
    this.setCookies(token, refreshToken);
  }

  static setCookies(token, refreshToken) {
    Cookies.set(ACCESS_TOKEN_KEY_COOKIE, token, {
      path: '/',
      domain: authCookieDomain,
    });
    Cookies.set(REFRESH_TOKEN_KEY_COOKIE, refreshToken, {
      path: '/',
      domain: authCookieDomain,
    });
  }

  static getUriTokens() {
    return {
      token: localStorageService.getItem('uriAccessToken') || '',
      refreshToken: localStorageService.getItem('uriRefreshToken') || '',
    };
  }

  static deleteUriTokens() {
    localStorageService.removeItem('uriAccessToken');
    localStorageService.removeItem('uriRefreshToken');
  }
}

export default Auth;
