import { deviceType, isIOS, isMobile, isTablet } from 'react-device-detect';

import config from '../config';
import { getTimezoneId } from '../../utility/dateHelper';

export const sharedRequestOptions = {
  withCredentials: true,
  headers: {
    device: deviceType,
    isios: isIOS,
    ismobile: isMobile,
    istablet: isTablet,
  },
};

export const UPCOMING_EVENTS_ENDPOINT = 'upcoming';
export const PAST_EVENTS_ENDPOINT = 'past';

export const NEWEST_DATA_SORTING_PARAM = 'desc';
export const OLDEST_DATA_SORTING_PARAM = 'asc';
export const ALL_MEMBERSHIPS_FILTER = 'all';
export const NAS_ACADEMY_MEMBERSHIPS_FILTER = 'nas';
export const NEAR_YOU_MEMBERSHIPS_FILTER = 'country';

export const communitiesURL = (id) =>
  id ? `${config.BASE_BE_URL}?activeCommunityId=${id}` : config.BASE_BE_URL;

export const lastInteractedCommunityURL = () =>
  `${process.env.REACT_APP_AUTH_SERVER_URL}/api/v1/last-interacted-community`;

export const communityInvoicesURL = () =>
  `${config.MAIN_WEBSITE_URL}/get-community-invoices`;
export const cancelCommunityURL = () =>
  `${config.MAIN_WEBSITE_URL}/community-cancel`;

export const eventsURL = (communityId, eventsType) =>
  `${config.BASE_BE_URL}/${communityId}/events/${eventsType}`;

export const upcomingEventsURL = (communityId) =>
  eventsURL(communityId, UPCOMING_EVENTS_ENDPOINT);
export const pastEventsURL = (communityId) =>
  eventsURL(communityId, PAST_EVENTS_ENDPOINT);

export const eventRegistrationURL = (eventId) =>
  `${
    config.BASE_BE_URL
  }/event/${eventId}/register?timezoneId=${getTimezoneId()}`;

export const bookingRegistrationURL = (communityId) =>
  `${config.BASE_BE_URL}/${communityId}/trainers/book`;

export const bookingCancellationURL = (communityId) =>
  `${config.BASE_BE_URL}/${communityId}/trainers/cancel`;

export const libraryURL = (communityId, dataType) =>
  `${config.BASE_BE_URL}/${communityId}/${dataType}`;

export const membersURL = (communityId) =>
  `${config.BASE_BE_URL}/${communityId}/memberships`;
export const libraryFolderURL = (communityId, folderType) =>
  `${config.BASE_BE_URL}/${communityId}/folders?type=${folderType}`;
export const libraryFolderGeneralURL = (communityId, folderType) =>
  `${config.BASE_BE_URL}/${communityId}/folders`;
export const librarySingleFolderURL = (communityId, folderId) =>
  `${config.BASE_BE_URL}/${communityId}/folder/${folderId}`;

export const librarySearchURL = (communityId, search) =>
  `${config.BASE_BE_URL}/${communityId}/search?filter=${search}`;

export const libraryFolderClickedURL = (communityFolderObjId) =>
  `${config.BASE_BE_URL}/folder/${communityFolderObjId}/log`;

export const adminCommunityURL = `${config.BASE_BE_URL}/admin-community`;
