import {
  basicInfoFormSchema,
  commentPreferenceFormSchema,
  socialMediaFormSchema,
  videoPreferenceFormSchema,
} from '../utility/profileValidation';
import {
  dialCodeOptions,
  getDialCodeById,
  getPhoneNumberWithoutDial,
} from '../utility/formHelper';
import {
  getProfileByRoleType,
  updateUserProfile,
} from '../services/userProfile';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Auth from '../modules/auth';
import { setUpdateProfileStatus } from '../actions/main';
import { setUser } from '../actions/user';
import { updateAuthUserData } from '../actions/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const isEqual = (obj1, obj2) =>
  obj1.firstName === obj2.firstName &&
  obj1.lastName === obj2.lastName &&
  obj1.description === obj2.description &&
  obj1.phoneNumber === obj2.phoneNumber &&
  obj1.whatsAppNumber === obj2.whatsAppNumber &&
  obj1.corporateEmail === obj2.corporateEmail &&
  obj1.corporateEmailSameAsLoginEmail === obj2.corporateEmailSameAsLoginEmail &&
  obj1.whatsAppNumberSameAsPhoneNumber === obj2.whatsAppNumberSameAsPhoneNumber;

// TODo to refactor this hook
// remove unused methods/constants

const useProfileData = ({ afterUserProfileUpdateCallback }) => {
  const user = useSelector((state) => state.user?.user);
  const dispatch = useDispatch();
  const updateProfileStatus = useSelector(
    (state) => state.main.updateProfileStatus
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isBasicInfoUpdateLoading, setIsBasicInfoUpdateLoading] = useState(
    false
  );
  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    const AuthStr = 'Bearer '.concat(Auth.getUserToken());
    updateProfile(() => getProfileByRoleType(AuthStr), false);
  }, []);

  const {
    register: registerBasicInfo,
    handleSubmit: handleBasicInfoSubmit,
    errors: errorsBasicInfo,
    getValues: getBasicInfoValues,
  } = useForm({
    resolver: yupResolver(basicInfoFormSchema),
    mode: 'onTouched',
  });

  const {
    register: registerSocialMedia,
    handleSubmit: handleSocialMediaSubmit,
    errors: errorsSocialMedia,
  } = useForm({
    resolver: yupResolver(socialMediaFormSchema),
    mode: 'onTouched',
  });

  const {
    register: registerCommentPreference,
    handleSubmit: handleCommentPreferenceSubmit,
    errors: errorsCommentPreference,
  } = useForm({
    resolver: yupResolver(commentPreferenceFormSchema),
    mode: 'onTouched',
  });

  const {
    register: registerVideoPreference,
    handleSubmit: handleVideoPreferenceSubmit,
    errors: errorsVideoPreference,
  } = useForm({
    resolver: yupResolver(videoPreferenceFormSchema),
    mode: 'onTouched',
  });

  const [
    whatsAppNumberSameAsPhoneNumber,
    setWhatsAppNumberSameAsPhoneNumber,
  ] = useState(user?.whatsAppNumberSameAsPhoneNumber);

  const [
    corporateEmailSameAsLoginEmail,
    setCorporateEmailSameAsLoginEmail,
  ] = useState(user?.corporateEmailSameAsLoginEmail);

  const updateProfile = async (
    service,
    shouldUpdateStatus = true,
    field = 'Profile',
    afterUpdateCallback
  ) => {
    if (shouldUpdateStatus) setIsLoading(true);

    await service()
      .then((doc) => {
        // TODO this is temp fix for not setting promo code
        const promoCode = Auth.getUserData().promoCode;
        const updatedData = Object.assign({}, doc.data.profile, { promoCode });

        Auth.setUserData(updatedData);
        dispatch(setUser(updatedData));
        dispatch(updateAuthUserData(updatedData));
        if (shouldUpdateStatus)
          dispatch(setUpdateProfileStatus(`${field} Updated!`));
        if (afterUpdateCallback) {
          afterUpdateCallback();
        }
      })
      .catch((err) => {
        const message = err.response?.data?.message
          ? err.response.data.message
          : 'Something went wrong';
        if (shouldUpdateStatus) dispatch(setUpdateProfileStatus(message));
      });
    if (shouldUpdateStatus) {
      setIsLoading(false);
      statusPop();
    }
  };

  const statusPop = () => {
    setShowStatus(true);
    setTimeout(() => {
      dispatch(setUpdateProfileStatus(false));
    }, 5000);
  };

  const updateBasicInfo = async (data) => {
    setIsBasicInfoUpdateLoading(true);
    const phoneNumber = `${getDialCodeById(
      data.dialCodeId
    )}${data.phoneNumber.trim()}`;
    let updatedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      whatsAppNumber: whatsAppNumberSameAsPhoneNumber
        ? ''
        : data.whatsAppNumber,
      description: data.description,
      whatsAppNumberSameAsPhoneNumber,
      phoneNumber: phoneNumber ? phoneNumber : user.phoneNumber,
      corporateEmail: corporateEmailSameAsLoginEmail ? '' : data.corporateEmail,
      corporateEmailSameAsLoginEmail: corporateEmailSameAsLoginEmail,
      countryId: data.countryId,
    };

    const AuthStr = 'Bearer '.concat(Auth.getUserToken());

    if (!isEqual(updatedData, user)) {
      await updateProfile(() => updateUserProfile(updatedData, AuthStr));
      // #TODO: remove this after fixing the issue on the backend
      // window.location.reload();
    }

    setIsBasicInfoUpdateLoading(false);
  };

  const updateSocialMedia = async (data) => {
    let updatedData = {
      socialMedia: [
        { type: 'facebook', link: data.facebook },
        { type: 'instagram', link: data.instagram },
        { type: 'youtube', link: data.youtube },
        { type: 'twitter', link: data.twitter },
        { type: 'linkedin', link: data.linkedin },
        { type: 'tiktok', link: data.tiktok },
        { type: 'opensea', link: data.opensea },
        { type: 'discord', link: data.discord },
        { type: 'dribble', link: data.dribble },
      ],
    };

    const AuthStr = 'Bearer '.concat(Auth.getUserToken());

    const isNotEqual = updatedData.socialMedia.some(
      (socialDetails, index) =>
        socialDetails?.link !== undefined && // Check that it is not undefined
        socialDetails?.link !== user.socialMedia[index]?.link // Check if old != new
    );

    if (isNotEqual) {
      await updateProfile(
        () => updateUserProfile(updatedData, AuthStr),
        true,
        'Social Media',
        afterUserProfileUpdateCallback
      );
    }
  };

  const updateCommentPreference = async (data) => {
    const AuthStr = 'Bearer '.concat(Auth.getUserToken());
    const hasSubscribedToCommentEmails =
      data.hasSubscribedToCommentEmails === 'true';
    if (
      data.hasSubscribedToCommentEmails &&
      user.hasSubscribedToCommentEmails !== hasSubscribedToCommentEmails
    ) {
      data.hasSubscribedToCommentEmails = hasSubscribedToCommentEmails;
      await updateProfile(
        () => updateUserProfile(data, AuthStr),
        true,
        'Comment Preference'
      );

      // #TODO: remove this after fixing the issue on the backend
      // window.location.reload();
    }
  };

  const updateVideoPreference = async (data) => {
    const AuthStr = 'Bearer '.concat(Auth.getUserToken());

    if (
      data.subtitlePreference &&
      user.subtitlePreference !== data.subtitlePreference
    ) {
      await updateProfile(
        () => updateUserProfile(data, AuthStr),
        true,
        'Video Preference'
      );
    }
  };

  const updateDiscordLoginId = async (data) => {
    const AuthStr = 'Bearer '.concat(Auth.getUserToken());

    if (
      data.subtitlePreference &&
      user.subtitlePreference !== data.subtitlePreference
    ) {
      await updateProfile(
        () => updateUserProfile(data, AuthStr),
        true,
        'Video Preference'
      );
    }
  };

  const basicInfoInputProps = [
    {
      label: 'First Name',
      name: 'firstName',
      defaultValue: user?.firstName,
      placeholder: 'First Name',
      required: true,
      errors: [errorsBasicInfo?.firstName],
      register: registerBasicInfo,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      defaultValue: user?.lastName,
      placeholder: 'Last Name',
      required: true,
      errors: [errorsBasicInfo.lastName],
      register: registerBasicInfo,
    },
    {
      label: 'Country Code',
      name: 'dialCodeId',
      defaultValue: user?.countryId,
      placeholder: 'Country Code',
      optionsList: dialCodeOptions,
      type: 'dropdown',
      required: true,
      errors: [errorsBasicInfo.dialCodeId],
      register: registerBasicInfo,
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      defaultValue: getPhoneNumberWithoutDial(user?.phoneNumber),
      placeholder: 'Phone Number',
      required: true,
      fluid: true,
      errors: [errorsBasicInfo.phoneNumber],
      register: registerBasicInfo,
    },
    {
      label: 'WhatsApp Number',
      name: 'whatsAppNumber',
      defaultValue: user?.whatsAppNumber,
      placeholder: 'WhatsApp Number',
      withCheckbox: true,
      checkboxLabel: 'My WhatsApp number is same as my contact number above.',
      checkboxName: 'whatsAppNumberSameAsPhone',
      checked: whatsAppNumberSameAsPhoneNumber,
      handleChecking: () =>
        setWhatsAppNumberSameAsPhoneNumber(!whatsAppNumberSameAsPhoneNumber),
      register: registerBasicInfo,
      errors: [errorsBasicInfo.whatsAppNumber],
      fluid: true,
    },
    {
      label: 'Business Email',
      name: 'corporateEmail',
      defaultValue: user?.corporateEmail,
      placeholder: 'Business Email',
      withCheckbox: true,
      checkboxLabel: 'My business e-mail is the same as my log in e-mail.',
      checkboxName: 'corporateEmailSameAsLoginEmail',
      checked: corporateEmailSameAsLoginEmail,
      handleChecking: () =>
        setCorporateEmailSameAsLoginEmail(!corporateEmailSameAsLoginEmail),
      register: registerBasicInfo,
      errors: [errorsBasicInfo.corporateEmail],
      fluid: true,
    },
    {
      label: 'Bio',
      name: 'description',
      defaultValue: user?.description,
      placeholder: 'Bio',
      required: false,
      errors: [errorsBasicInfo.description],
      register: registerBasicInfo,
      fluid: true,
    },
  ];

  const getSocialMediaInputProps = () => {
    const defaultValues = {};

    user?.socialMedia?.forEach(({ type, link }) => {
      defaultValues[type] = link;
    });

    return [
      {
        label: 'Facebook',
        name: 'facebook',
        defaultValue: defaultValues?.facebook,
        placeholder: 'Facebook',
        required: false,
        errors: [errorsSocialMedia.facebook],
        register: registerSocialMedia,
        showLogo: true,
      },
      {
        label: 'Instagram',
        name: 'instagram',
        defaultValue: defaultValues?.instagram,
        placeholder: 'Instagram',
        required: false,
        errors: [errorsSocialMedia.instagram],
        register: registerSocialMedia,
        showLogo: true,
      },
      {
        label: 'YouTube',
        name: 'youtube',
        defaultValue: defaultValues?.youtube,
        placeholder: 'YouTube',
        register: registerSocialMedia,
        errors: [errorsSocialMedia.youtube],
        showLogo: true,
      },
      {
        label: 'Twitter',
        name: 'twitter',
        defaultValue: defaultValues?.twitter,
        placeholder: 'Twitter',
        register: registerSocialMedia,
        errors: [errorsSocialMedia.twitter],
        showLogo: true,
      },
      {
        label: 'LinkedIn',
        name: 'linkedin',
        defaultValue: defaultValues?.linkedin,
        placeholder: 'LinkedIn',
        register: registerSocialMedia,
        errors: [errorsSocialMedia.linkedin],
        showLogo: true,
      },
    ];
  };

  const commentPreferenceInputProps = [
    {
      label: '',
      name: 'hasSubscribedToCommentEmails',
      defaultValue: user?.hasSubscribedToCommentEmails,
      required: false,
      errors: [errorsCommentPreference.hasSubscribedToCommentEmails],
      register: registerCommentPreference,
      type: 'dropdown',
      onChange: handleCommentPreferenceSubmit(updateCommentPreference),
      optionsList: [
        {
          label: 'On',
          value: true,
        },
        {
          label: 'Off',
          value: false,
        },
      ],
    },
  ];

  const videoPreferenceInputProps = [
    {
      label: 'Video Subtitles',
      name: 'subtitlePreference',
      defaultValue: user?.subtitlePreference,
      required: false,
      errors: [errorsVideoPreference.subtitlePreference],
      register: registerVideoPreference,
      type: 'dropdown',
      onChange: handleVideoPreferenceSubmit(updateVideoPreference),
      optionsList: [
        {
          label: 'English',
          value: 'en',
        },
        {
          label: 'Arabic',
          value: 'ar',
        },
        {
          label: 'Bengali',
          value: 'bn',
        },
        {
          label: 'French',
          value: 'fr',
        },
        {
          label: 'Hebrew',
          value: 'he',
        },
        {
          label: 'Hindi',
          value: 'hi',
        },
        {
          label: 'Indonesian',
          value: 'id',
        },
        {
          label: 'Malay',
          value: 'ms',
        },
        {
          label: 'Portuguese',
          value: 'pt',
        },
        {
          label: 'Spanish',
          value: 'es',
        },
        {
          label: 'Japanese',
          value: 'ja',
        },
        {
          label: 'Tagalog',
          value: 'tl',
        },
        {
          label: 'Chinese-Simplified',
          value: 'zh-Hans',
        },
        {
          label: 'Chinese-Traditional',
          value: 'zh-Hant',
        },
      ],
    },
  ];

  return {
    user,
    updateProfileStatus,
    isLoading,
    showStatus,
    basicInfoInputProps,
    commentPreferenceInputProps,
    videoPreferenceInputProps,
    socialMediaInputProps: getSocialMediaInputProps(),
    updateBasicInfo: handleBasicInfoSubmit(updateBasicInfo),
    updateSocialMedia: updateSocialMedia,
    updateCommentPreference: handleCommentPreferenceSubmit(
      updateCommentPreference
    ),
    updateVideoPreference: handleVideoPreferenceSubmit(updateVideoPreference),
    updateSocialMediaHandleSubmit: handleSocialMediaSubmit,
    updateSocialMediaCallback: updateSocialMedia,
    errorsBasicInfo,
    registerBasicInfo,
    getBasicInfoValues,
    isBasicInfoUpdateLoading,
  };
};

export default useProfileData;
