import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getInitialLocale } from './utility/locale';

const namespace = 'member';
const lang = getInitialLocale();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    // resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    fallbackLng: lang,
    ns: namespace,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// Function to fetch translations from an external URL
const fetchTranslations = async (namespace, lang) => {
  try {
    const langResponse = await fetch(
      `https://localization.nas.io/production/${namespace}/${lang.toLowerCase()}.json`
    );
    const langTranslations = await langResponse.json();

    // if lang is not english, then fetch english as well. To be used as fallback
    if (lang !== 'en') {
      const enLangResponse = await fetch(
        `https://localization.nas.io/production/${namespace}/en.json`
      );
      const enLangTranslations = await enLangResponse.json();

      return {
        [lang]: { translation: langTranslations },
        en: {
          translation: enLangTranslations,
        },
      };
    }

    return { [lang]: { translation: langTranslations } };
    // return { [lang]: { [namespace]: translations } };
  } catch (error) {
    console.error('Error loading translations:', error);
    return {};
  }
};

// Function to set the loaded translations for a specific namespace and language
const setTranslations = async (namespace, lang) => {
  try {
    const resources = await fetchTranslations(namespace, lang);

    if (lang === 'en') {
      i18n.addResourceBundle(lang, namespace, resources[lang].translation);
    } else {
      i18n.addResourceBundle(lang, namespace, {
        ...resources.en.translation,
        ...resources[lang].translation,
      });
    }
  } catch (e) {
    console.warn('Error setting translation ', e);
  }
};

export const loadTranslations = async () => {
  await setTranslations(namespace, lang);
};

export default i18n;
