import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-InfoCircleOutlineIcon';
const InfoCircleOutlineIcon = ({
  width,
  height,
  customClassNames,
  fillColor,
}) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 2.66677C5.05464 2.66677 2.66683 5.05458 2.66683 8.0001C2.66683 10.9456 5.05464 13.3334 8.00016 13.3334C10.9457 13.3334 13.3335 10.9456 13.3335 8.0001C13.3335 5.05458 10.9457 2.66677 8.00016 2.66677ZM1.3335 8.0001C1.3335 4.3182 4.31826 1.33344 8.00016 1.33344C11.6821 1.33344 14.6668 4.3182 14.6668 8.0001C14.6668 11.682 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.682 1.3335 8.0001Z"
        fill={fillColor ? fillColor : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 6.66681C8.36835 6.66681 8.66683 6.96529 8.66683 7.33348V10.6668C8.66683 11.035 8.36835 11.3335 8.00016 11.3335C7.63197 11.3335 7.3335 11.035 7.3335 10.6668V7.33348C7.3335 6.96529 7.63197 6.66681 8.00016 6.66681Z"
        fill={fillColor ? fillColor : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.30029 5.33348C7.30029 4.96529 7.59877 4.66681 7.96696 4.66681H8.03363C8.40182 4.66681 8.70029 4.96529 8.70029 5.33348V5.40014C8.70029 5.76833 8.40182 6.06681 8.03363 6.06681H7.96696C7.59877 6.06681 7.30029 5.76833 7.30029 5.40014V5.33348Z"
        fill={fillColor ? fillColor : 'currentColor'}
      />
    </svg>
  );
};

InfoCircleOutlineIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  customClassNames: PropTypes.string,
};

InfoCircleOutlineIcon.defaultProps = {
  width: '16',
  height: '16',
};

export default InfoCircleOutlineIcon;
