import React from 'react';
import Modal from '../../../../components/Modal';
import { PASSWORD_MODAL } from '../NewProfilePage/constants';
import PasswordSettingWidget from '../PasswordSettingWidget';
import PropTypes from 'prop-types';
const ShowPasswordResetUpdateInput = ({ showModal, setShowModal }) => {
  return (
    <div>
      <Modal
        open={showModal === PASSWORD_MODAL}
        onClose={() => setShowModal(null)}
      >
        <PasswordSettingWidget />
      </Modal>
    </div>
  );
};

ShowPasswordResetUpdateInput.propTypes = {
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
};

export default ShowPasswordResetUpdateInput;
