import PropTypes from 'prop-types';

const VisaCardIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 60 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8756 19.6456H9.72627L5.86489 4.48094C5.68161 3.78336 5.29246 3.16666 4.72003 2.87601C3.29147 2.14558 1.71729 1.56426 0 1.27108V0.687239H8.2952C9.44006 0.687239 10.2987 1.56426 10.4418 2.58282L12.4453 13.5216L17.5922 0.687239H22.5984L14.8756 19.6456ZM25.4606 19.6456H20.5974L24.6019 0.687239H29.4651L25.4606 19.6456ZM35.7568 5.93928C35.8999 4.9182 36.7585 4.33436 37.7603 4.33436C39.3344 4.18776 41.0492 4.48095 42.4803 5.20885L43.3389 1.12702C41.9079 0.543184 40.3337 0.25 38.9051 0.25C34.1851 0.25 30.7505 2.87602 30.7505 6.5206C30.7505 9.29321 33.1833 10.749 34.9006 11.626C36.7585 12.5005 37.474 13.0844 37.3309 13.9589C37.3309 15.2706 35.8999 15.8545 34.4713 15.8545C32.754 15.8545 31.0367 15.4172 29.4651 14.6868L28.6064 18.7711C30.3237 19.499 32.1816 19.7922 33.8989 19.7922C39.1913 19.9363 42.4803 17.3128 42.4803 13.375C42.4803 8.41618 35.7568 8.12553 35.7568 5.93928ZM59.5 19.6456L55.6386 0.687239H51.491C50.6324 0.687239 49.7737 1.27108 49.4875 2.14558L42.3372 19.6456H47.3434L48.3427 16.8755H54.4938L55.0662 19.6456H59.5ZM52.2066 5.7927L53.6351 12.9378H49.6306L52.2066 5.7927Z"
      fill="#172B85"
    />
  </svg>
);

VisaCardIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

VisaCardIcon.defaultProps = {
  width: '60',
  height: '20',
};

export default VisaCardIcon;
