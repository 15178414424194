import { format, isAfter, isBefore, isPast } from 'date-fns';

import moment from 'moment-timezone';

/**
 * Get the 12 hour format from a 24hours formatted hour and mins
 * @param {number} hour Hours between 0 and 23 (inclusive)
 * @param {number} mins Minutes between 0 and 59 (inclusive)
 * @return {string} The time in 12 hours format - HH:MM AM/PM
 */
export const getHours = (hour, mins) => {
  const time = hour > 12 ? hour % 12 : hour;
  const format = hour >= 12 ? ' PM' : ' AM';
  const minutes = mins >= 10 ? mins : `0${mins}`;
  return `${time}:${minutes}${format}`;
};

/**
 * Format a date into DD MMM YYYY
 * @param {date} dateString
 * @return {string} Date format in DD MMM YYYY
 */
export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

/**
 * Format a date into DD MMM YYYY HH:MM
 * @param {date} dateString
 * @return {string} Date format in DD MMM YYYY HH:MM
 */
export const formatDateTime = (dateString, withTime = true) => {
  const date = new Date(dateString);
  if (withTime) {
    return `${date.getDate()} ${getMonthName(
      date.getMonth()
    )} ${date.getFullYear()} ${getHours(date.getHours(), date.getMinutes())}`;
  }
  return `${date.getDate()} ${getMonthName(
    date.getMonth()
  )} ${date.getFullYear()}`;
};

/**
 * Get the name of the month
 * @param {number} monthIndex Index of the month. 0 = January, 1 = February, ..., 11 = December
 * @return {string} Name of the month
 */
export const getMonthName = (monthIndex, short = false) => {
  const months = short
    ? [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
    : [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
  return months[monthIndex];
};

export const getDayName = (dayIndex, short = false) => {
  const days = short
    ? ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
    : [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
  return days[dayIndex];
};

/**
 * Convert date into client's browser timezone
 * @param {date} dateString Date to be converted
 * @return {date} A date converted to client's browser timezone.
 */
export const convertToBrowserTimeZone = (dateString) => {
  return new Date(dateString).toLocaleString('en-US', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

export const convertMinsToHrsMins = (mins) => {
  try {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
  } catch (err) {
    console.log('Error converting mins to hr mins ', err);
  }
};

/**
 * Get the timezone offset in GMT
 * @param {date} dateObject The date to get the timezone offset from
 * @return {string} The timezone offset in GMT
 */
export const getTimeZoneOffset = (dateObject) => {
  let offset = dateObject.getTimezoneOffset();
  const sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  offset = convertMinsToHrsMins(offset);
  return 'GMT ' + sign + offset;
};

/*

*/

/**
 * Create date as UTC
 * @param {number} year The full year of the date in UTC
 * @param {number} month The month of the date in UTC. Note that month January is 1 and month December is 12 (different from Javascript default Date implementation)
 * @param {number} day The day of the month in UTC.
 * @param {number} hour The hour of the day in UTC.
 * @param {number} minute The minute in UTC.
 * @param {number} second The second in UTC.
 *
 * @return {date} A date with the UTC details
 */
export const createDateAsUTC = (year, month, day, hour, minute, second) => {
  const date = new Date(
    Date.UTC(year, parseInt(month) - 1, day, hour, minute, second)
  );
  return date;
};

/**
 * Convert date in ISO format to an object with the year, month, date, hours, minutes, and seconds.
 * @param {date} dateTimeString Date in ISO format. Must be in the format of yyyy-mm-ddThh:mm:ss e.g. 2020-07-23T04:30:15
 * @return {object} A custom date object
 */
export const createDateTimeObject = (dateTimeString) => {
  const dateTimeToken = dateTimeString.split('T');
  const dateString = dateTimeToken[0];
  const timeString = dateTimeToken[1];

  const dateToken = dateString.split('-');
  const timeToken = timeString.split(':');

  return {
    year: dateToken[0],
    month: dateToken[1],
    date: dateToken[2],
    hours: timeToken[0],
    minutes: timeToken[1],
    seconds: timeToken[2],
  };
};

/**
 *
 * @param {"2020-09-23T04:30:00"} dateTimeString
 * @param {"13"} customHoursString is the hours in 24-hour format
 * @param {"45"} customMinutesString is the minutes
 * @param {"Moment timezone"} timezone
 */
export const convertFromStringToUTCDateTime = (
  dateTimeString,
  customHourString,
  customMinutesString
) => {
  const dateTimeObject = createDateTimeObject(dateTimeString);
  const startDateUTC = createDateAsUTC(
    dateTimeObject.year,
    dateTimeObject.month,
    dateTimeObject.date,
    dateTimeObject.hours,
    dateTimeObject.minutes,
    dateTimeObject.seconds
  );
  startDateUTC.setUTCHours(customHourString);
  startDateUTC.setUTCMinutes(customMinutesString);

  return startDateUTC;
};

/**
 * Convert time from 12 hour format to 24 hour format.
 * @param {string} time12h Time in 12hour format
 * @param {string} hourMinuteSeparator The string that separates the hours from minutes.
 * @param {string} amPmSeparator The string that separates the modifier (AM/PM) from the time
 * @return {string} The time in 24 hour format
 */
export const convertTime12To24 = (
  time12h,
  hourMinuteSeparator,
  amPmSeparator
) => {
  let time;
  let modifier;
  let hours;
  let minutes;

  // IF format is 1.03AM
  const trimmedTime12h = time12h.trim().toUpperCase();
  if (!amPmSeparator && trimmedTime12h.match(/AM|PM/)) {
    const { index } = trimmedTime12h.match(/AM|PM/);
    time = trimmedTime12h.slice(0, index);
    modifier = trimmedTime12h.slice(index);
  } else {
    // IF format is 1.03 PM
    [time, modifier] = trimmedTime12h.split(' ');
  }

  [hours, minutes] = time.split(hourMinuteSeparator);

  hours = hours.trim();
  minutes = minutes.trim();
  if (hours === '12') {
    hours = '00';
  }

  if (modifier.toUpperCase() === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

/**
 * Convert time from 24 hour format to 12 hour format.
 * @param {string} time24h Time in 24hour format
 * @param {string} currentHourMinuteSeparator The string that separates the hours from minutes.
 * @param {string} newHourMinuteSeparator The string that separates the hours from minutes
 * @return {string} The time in 12 hour format
 */
export const convertTime24To12 = (
  time24h,
  currentHourMinuteSeparator,
  newHourMinuteSeparator
) => {
  const [hours, minutes] = time24h.split(currentHourMinuteSeparator);

  let modifier;
  let newHours;
  // Validate hours
  if (parseInt(hours) === 0) newHours = 12;
  else if (parseInt(hours) >= 1 && parseInt(hours) <= 12)
    newHours = parseInt(hours);
  else if (parseInt(hours) >= 13 && parseInt(hours) <= 23)
    newHours = parseInt(hours) - 12;
  else
    throw new Error(
      'Hours is out of range. Hours should be between 0 to 23 (inclusive).'
    );

  // Validate minutes
  if (parseInt(minutes) < 0 || parseInt(minutes) >= 60)
    throw new Error(
      'Minutes is out of range. Minutes should be between 0 to 59 (inclusive).'
    );

  // Identify identifier for PM or AM
  if (parseInt(hours) >= 12 && parseInt(hours) <= 23) {
    modifier = 'PM';
  } else if (parseInt(hours) >= 0 && parseInt(hours) <= 11) modifier = 'AM';
  else throw new Error('Invalid 12-hour time format');

  const separator = newHourMinuteSeparator || '.';

  return `${newHours}${separator}${minutes} ${modifier}`;
};

/**
 * Get the time difference between a 24 hour start time and 24 hour end time
 * @param {string} timing The start time to end time in 24 hour format
 * @return {string} The custom time diff object that contain the hours and minutes difference
 */
export const getTimeDiff = (timing) => {
  // example 07:00 - 09:30
  // example 23:00 - 01:30
  // example 7:30 - 09:15

  const [startTime, endTime] = timing.split('-');
  let [startHour, startMin] = startTime.split(':');
  let [endHour, endMin] = endTime.split(':');

  startHour = parseInt(startHour);
  startMin = parseInt(startMin);

  endHour = parseInt(endHour);
  endMin = parseInt(endMin);

  let diffHour;
  let diffMin = 0;
  if (startHour <= endHour) diffHour = endHour - startHour;
  else diffHour = endHour - startHour + 24;

  if (startMin <= endMin) diffMin = endMin - startMin;
  else {
    diffMin = endMin - startMin + 60;
    diffHour -= 1;
  }

  return {
    diffHours: diffHour,
    diffMinutes: diffMin,
  };
};

/**
 * Get the timezone offset from a given timezone name.
 * @param {string} timezone Timezone name in accordance to IANA
 * @return {string} The timezone offset
 */
export const getTimezoneOffsetFromTimezone = (timezone) => {
  return moment.tz(timezone).format('Z');
};

/**
 * Get updated lesson days based on timezone.
 * The conversion of date and time may shift the day (e.g. Monday to Tuesday or Sunday)
 * depending on the GMT offset.
 * @param {string} startTimeUTC Start time of the course offer in UTC and in 24 hour format.
 * @param {string} timezone Timezone name in accordance to IANA
 * @param {string} lessonDays Days of lesson within a week. Each day is separated by '&'. Example ('Mon & Wed & Fri')
 * @return {array} Updated lesson days after converting the date to the given timezone
 */
export const getUpdatedLessonDays = (startTimeUTC, timezone, lessonDays) => {
  const timezoneOffset = moment.tz(timezone).format('Z');

  let [startHoursUTC, startMinutesUTC] = startTimeUTC.split(':');
  let [offsetHours, offsetMinutes] = timezoneOffset.split(':');
  const plusOrMinus = offsetHours[0];

  startHoursUTC = parseInt(startHoursUTC);
  startMinutesUTC = parseInt(startMinutesUTC);

  offsetHours = parseInt(offsetHours);
  offsetMinutes = parseInt(offsetMinutes);

  let offsetDays = 0;
  const afterOffsetHours = startHoursUTC + offsetHours;
  if (afterOffsetHours < 0) offsetDays = -1;
  else if (afterOffsetHours > 23) offsetDays = 1;

  if (
    plusOrMinus === '+' &&
    startMinutesUTC + offsetMinutes >= 60 &&
    offsetDays !== 1
  )
    offsetDays += 1;
  else if (
    plusOrMinus === '-' &&
    startMinutesUTC - offsetMinutes < 0 &&
    offsetDays !== -1
  )
    offsetDays -= 1;

  // convert lesson days to integer
  const lessonDaysArr = ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
  const lessonDaysObj = {
    MON: 0,
    MONDAY: 0,
    TUE: 1,
    TUES: 1,
    TUESDAY: 1,
    WED: 2,
    WEDNESDAY: 2,
    THUR: 3,
    THURS: 3,
    THURSDAY: 3,
    FRI: 4,
    FRIDAY: 4,
    SAT: 5,
    SATURDAY: 5,
    SUN: 6,
    SUNDAY: 6,
  };

  const givenLessonDays = lessonDays.split('&'); // ['Mon', 'Fri', 'Sun']
  const newLessonDays = [];
  for (const i in givenLessonDays) {
    const day = givenLessonDays[i].trim();
    let afterOffsetDays = lessonDaysObj[day.toUpperCase()] + offsetDays;

    if (afterOffsetDays < 0) afterOffsetDays = 6;
    else if (afterOffsetDays > 6) afterOffsetDays = 0;

    newLessonDays.push(lessonDaysArr[afterOffsetDays]);
  }

  return newLessonDays;
};

/**
 * Get the upcoming date of the selected day.
 * For example:
 *      - If today is Tuesday (21-July-2020) and you want to get the upcoming Thursday, you will get 30-July-2020.
 *      - If today is Friday (24-July-2020) and you want to get the upcoming Monday, you will get 27-July-2020.
 * @param {date} startDate Start date to be used as a base date and time
 * @param {number} targetDay Day of the week for the upcoming date you want. Sunday = 0, Monday = 1, ... Saturday = 6.
 * @param {number} targetStartUTCHours Hours in UTC 24 hour format for the upcoming date you want.
 * @param {number} targetStartUTCMinutes  Minutes in UTC 24 hour format for the upcoming date you want.
 * @return {date} The upcoming date based on the day you want.
 */
export const getDateOfNextDay = (
  startDate,
  targetDay,
  targetStartUTCHours,
  targetStartUTCMinutes
) => {
  const offset = targetDay - startDate.getUTCDay();
  const nextWeek = startDate;

  if (offset > 0) nextWeek.setUTCDate(startDate.getUTCDate() + offset);
  else if (offset < 0) nextWeek.setUTCDate(startDate.getUTCDate() + offset + 7);
  else if (
    startDate.getUTCHours() > targetStartUTCHours ||
    (startDate.getUTCHours() === targetStartUTCHours &&
      startDate.getUTCMinutes() > targetStartUTCMinutes)
  )
    nextWeek.setUTCDate(startDate.getUTCDate() + offset + 7);

  nextWeek.setUTCHours(targetStartUTCHours);
  nextWeek.setUTCMinutes(targetStartUTCMinutes);

  return nextWeek;
};

export const getDayOfWeek = (dayString) => {
  const daysOfWeek = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tues',
    3: 'Wednes',
    4: 'Thurs',
    5: 'Fri',
    6: 'Satur',
    7: 'Sun',
  };

  return daysOfWeek[dayString.getDay()];
};

export const getLocalDate = (gmtDate, time) => {
  time = time
    .replace('.', ':')
    .replace('am', ' AM GMT')
    .replace('pm', ' PM GMT');
  const date = moment
    .utc(`${gmtDate.split('T')[0]} ${time}`, 'YYYY-MM-DD HH:mm A')
    .toDate();
  return date;
};

export const getFormattedDateRange = (start, end, shortFormat, longFormat) => {
  const startDateFormatted = new Date(start).toLocaleString(
    'en-US',
    shortFormat
  );
  const endDateFormatted = new Date(end).toLocaleString('en-US', longFormat);

  const dateToRender =
    startDateFormatted === endDateFormatted.split(',')[0]
      ? endDateFormatted
      : `${startDateFormatted} - ${endDateFormatted}`;

  return dateToRender;
};

export const formatAMPM = (date, cap = false) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm;
  if (cap) {
    ampm = hours >= 12 ? 'PM' : 'AM';
  } else {
    ampm = hours >= 12 ? 'pm' : 'am';
  }
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

/**
 * @param {*} startDate
 * @param {*} endDate
 * @param {string} courseTiming
 */
export const getLocalCourseTime = (startDate, endDate) => {
  let startDateTimeLocal = new Date(startDate);
  let endDateTimeLocal = new Date(endDate);
  return {
    startDateTimeLocal,
    endDateTimeLocal,
    formattedStartDateTimeLocal: formatDateTime(startDateTimeLocal, false),
    formattedEndDateTimeLocal: formatDateTime(endDateTimeLocal, false),
    lessonStartTime: formatAMPM(startDateTimeLocal, true),
    lessonEndTime: formatAMPM(endDateTimeLocal, true),
    firstLessonDay: getDayName(startDateTimeLocal.getDay()),
    secondLessonDay: getDayName(endDateTimeLocal.getDay()),
  };
};

export const getTimezoneId = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
};

export const longDateFormat = 'EEEE, d MMMM yyyy';

export const courseBannerDateFormat = longDateFormat;

export const formatCourseDateForBanner = (courseDate) => {
  return courseDate ? format(new Date(courseDate), courseBannerDateFormat) : '';
};

export const getDateWithShortNamedMonth = (date) => {
  const dateFormat = 'd MMM, R';
  return date ? format(new Date(date), dateFormat) : '';
};

export const formatCalendarEndDateTime = (startDate, endDate) => {
  if (startDate && endDate) {
    const formatStartDate = new Date(startDate);
    const formatEndDate = new Date(endDate);

    const startDateHours = formatStartDate.getHours();
    const endDateHours = formatEndDate.getHours();
    const endDateMinutes = formatEndDate.getMinutes();

    formatStartDate.setHours(endDateHours);
    formatStartDate.setMinutes(endDateMinutes);

    if (endDateHours < startDateHours) {
      formatStartDate.setDate(formatStartDate.getDate() + 1);
    }

    return formatStartDate;
  }
  return new Date();
};

export const convertMsToTime = (msDuration) => {
  const h = Math.floor(msDuration / 1000 / 60 / 60);
  const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
  const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

  return {
    hrs: h,
    mins: m,
    secs: s,
  };
};

export const addHoursToDate = (date, hours) => {
  try {
    const result = new Date(date);
    result.setTime(result.getTime() + hours * 60 * 60 * 1000);
    return result;
  } catch {
    return date;
  }
};

export const isInActiveDatetimeWindow = (windowStart, windowEnd) => {
  const currentMS = Date.now();
  const startMS = new Date(windowStart);
  const endMS = new Date(windowEnd).time;

  return isAfter(currentMS, startMS) && isBefore(currentMS, endMS);
};

export const isDateInPast = (date) => {
  try {
    return isPast(new Date(date));
  } catch {
    return null;
  }
};

export const computeLiveSessionHourDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return (
    Math.round(((end.getTime() - start.getTime()) / (1000 * 60 * 60)) * 2) / 2
  );
};
