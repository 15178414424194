import React from 'react';

const className = 'c-LinkedIconV2Colored';
const LinkedIconV2Colored = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <rect
        x="3.29297"
        y="3.7948"
        width="17.4103"
        height="17.4103"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.0521 2C2.19512 2 1.5 2.67395 1.5 3.50433V21.4962C1.5 22.3266 2.19512 23 3.0521 23H20.9479C21.8052 23 22.5 22.3266 22.5 21.4959V3.50433C22.5 2.67395 21.8052 2 20.9479 2H3.0521ZM7.86578 10.0974V19.58H4.69394V10.0974H7.86578ZM8.07476 7.16479C8.07476 8.07477 7.38629 8.80297 6.28041 8.80297H6.28014H6.25962C5.19504 8.80297 4.50685 8.07477 4.50685 7.16479C4.50685 6.23415 5.21583 5.52633 6.30148 5.52633C7.38629 5.52633 8.05397 6.23415 8.07476 7.16479ZM12.7929 19.58H9.62132C9.62159 19.5241 9.66276 10.9844 9.6216 10.0977H12.7932V11.4401C13.2147 10.7941 13.9693 9.87517 15.6518 9.87517C17.7386 9.87517 19.3031 11.2305 19.3031 14.143V19.58H16.1316V14.5077C16.1316 13.2328 15.6723 12.3633 14.5249 12.3633C13.6485 12.3633 13.1266 12.9496 12.8974 13.5162C12.8137 13.7183 12.7929 14.002 12.7929 14.2851V19.58ZM9.62132 19.5803V19.58C9.62132 19.5802 9.62132 19.5803 9.62132 19.5803Z"
        fill="#2867B2"
      />
    </svg>
  );
};

export default LinkedIconV2Colored;
