import * as yup from 'yup';

export const validate = async ({ schema, formData, setErrors }) => {
  setErrors({});

  let isValid = await schema
    .validate(formData, {
      abortEarly: false,
    })
    .then(() => true)
    .catch((validationErr) => {
      if (Array.isArray(validationErr.inner)) {
        let errors = {};
        validationErr.inner.forEach(
          ({ path, message }) => (errors[path] = message)
        );
        setErrors(errors);
      }
      return false;
    });

  return isValid;
};

export const isValidUrl = ({ url }) => {
  if (!url) return false;

  let schema = yup.string().url();
  return schema.isValidSync(url);
};
