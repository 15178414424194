import {
  CLASSES_PORTAL,
  CLASSES_PORTAL_ROOT,
  COMMUNITY_PORTAL,
  COMMUNITY_PORTAL_ROOT,
  CREATOR_PORTAL,
  CREATOR_PORTAL_ROOT,
} from '../../constants';

export const getActivePortal = (path) => {
  const pathRoot = `/${path.split('/')?.[1]}`;

  let activePortal = '';
  switch (pathRoot) {
    case CLASSES_PORTAL_ROOT:
      activePortal = CLASSES_PORTAL;
      break;
    case COMMUNITY_PORTAL_ROOT:
      activePortal = COMMUNITY_PORTAL;
      break;
    case CREATOR_PORTAL_ROOT:
      activePortal = CREATOR_PORTAL;
      break;
    default:
      activePortal = CLASSES_PORTAL;
      break;
  }

  return activePortal;
};

export const getCommunityPortalPath = (pageEndpoint) => (communityId) =>
  `/communities/${communityId}/${pageEndpoint}`;
