import './App.scss';

import React, { Suspense, lazy } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { CommunityPortalContextProvider } from './CommunityPortal/contexts/CommunityPortalContext';
import ErrorPage from './components/Common/ErrorPage/ErrorPage';
// import EventCheckoutPage from './pages/CheckoutPage/Events';
import { EventsCheckoutContextProvider } from './contexts/EventsCheckoutContext/EventsCheckoutContext';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import { LibraryCheckoutContextProvider } from './contexts/LibraryCheckoutContext/LibraryCheckoutContext';
// import LibraryCheckoutPage from './pages/CheckoutPage/Library';
import LoginPage from './pages/LoginPage';
import PortalModeWrapper from './PortalModeWrapper';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router';
import ResetPasswordPage from './pages/ResetPasswordPage';
import RouterContextProvider from './PortalModeWrapper/contexts/RouterContext';
import SetUserBasicDataModal from './components/SetUserBasicDataModal/index';
import SignupPage from './pages/SignupPage';
// import StripeWrapper from './components/StripeWrapper/EventsStripeWrapper';
import { ToastContainer } from './CommunityPortal/components/ToastContainer';
import { communityPortalPaths } from './PortalModeWrapper/constants';
import config from './modules/config';
import configStore from './stores/configureStore';
import history from './modules/history';
import { hotjar } from 'react-hotjar';
import { settingsPagePaths } from './pages/SettingPage/constants';
// import EventsStripeWrapper from './components/StripeWrapper/EventsStripeWrapper';
// import LibraryStripeWrapper from './components/StripeWrapper/LibraryStripeWrapper';

// const AuthWrapper = lazy(() => import('./components/AuthWrapper/index'));
import AuthWrapper from './components/AuthWrapper/index';

const FacebookCommunityAuthPage = lazy(() =>
  import('./pages/FacebookCommunityAuthPage')
);

const store = configStore();

const { app_env } = config;

if (app_env === 'production') {
  hotjar.initialize(2272125, 6);
}

const className = 'c-App';

const rootRoutePath = '/';
const facebookCommunityAuthPath = '/oauth';
const newLayoutPaths = [
  rootRoutePath,
  ...communityPortalPaths,
  ...settingsPagePaths,
];

function App() {
  return (
    <div className={className}>
      <Router
        history={history}
        getUserConfirmation={(message, callback) => {
          const allowTransition = window.confirm(message);
          callback(allowTransition);
        }}
      >
        <Provider store={store}>
          <Suspense
            fallback={<div style={{ background: '#2c2d2e', height: '100%' }} />}
          >
            <SetUserBasicDataModal />
            <Switch>
              <Route exact path={newLayoutPaths}>
                <AuthWrapper>
                  <RouterContextProvider>
                    <CommunityPortalContextProvider>
                      <PortalModeWrapper />
                    </CommunityPortalContextProvider>
                  </RouterContextProvider>
                </AuthWrapper>
              </Route>

              <Route exact path={facebookCommunityAuthPath}>
                <AuthWrapper>
                  <RouterContextProvider>
                    <CommunityPortalContextProvider>
                      <FacebookCommunityAuthPage />
                    </CommunityPortalContextProvider>
                  </RouterContextProvider>
                </AuthWrapper>
              </Route>

              <Route
                exact
                path="/event-checkout"
                component={() => (
                  <AuthWrapper>
                    <CommunityPortalContextProvider>
                      <EventsCheckoutContextProvider>
                        {/* <EventsStripeWrapper>
                          <EventCheckoutPage />
                          <ToastContainer />
                        </EventsStripeWrapper> */}
                      </EventsCheckoutContextProvider>
                    </CommunityPortalContextProvider>
                  </AuthWrapper>
                )}
              />

              <Route
                exact
                path="/library-checkout"
                component={() => (
                  <AuthWrapper>
                    <CommunityPortalContextProvider>
                      <LibraryCheckoutContextProvider>
                        {/* <LibraryStripeWrapper>
                          <LibraryCheckoutPage />
                        </LibraryStripeWrapper> */}
                      </LibraryCheckoutContextProvider>
                    </CommunityPortalContextProvider>
                  </AuthWrapper>
                )}
              />

              <Route
                exact
                path="/login"
                component={() => (
                  <AuthWrapper>
                    <LoginPage />
                  </AuthWrapper>
                )}
              />
              <Route exact path="/sign-up" component={() => <SignupPage />} />

              <Route
                exact
                path="/forget-password"
                component={() => (
                  <ForgetPasswordPage title={'Forgot your password?'} />
                )}
              />
              <Route
                exact
                path="/recover-password"
                component={() => (
                  <ForgetPasswordPage title={'Reset your password'} />
                )}
              />
              <Route
                exact
                path="/reset-password"
                component={() => <ResetPasswordPage />}
              />

              <Route
                exact
                path="/logout"
                render={() => <Redirect to="/login" />}
              />
              <Route exact to="*" component={ErrorPage} />
            </Switch>
          </Suspense>
        </Provider>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
