import {
  REDEEM_FORM_LINK,
  SETTINGS_PAGE_MEMBERSHIP,
  SETTINGS_PAGE_MEMBERSHIP_PATH,
  SETTINGS_PAGE_PREFERENCES,
  SETTINGS_PAGE_PREFERENCES_PATH,
  SETTINGS_PAGE_PROFILE,
  SETTINGS_PAGE_PROFILE_PATH,
  SETTINGS_PAGE_REFERRAL,
  SETTINGS_PAGE_REFERRAL_PATH,
  pagesList,
  referralWidgetStaticProps,
} from './constants';
import { Route, Switch, withRouter } from 'react-router';
import { useCallback, useEffect, useState } from 'react';

import Auth from '../../modules/auth';
import MembershipPage from './pages/MembershipPage';
import NewPreferencesPage from './components/NewPreferencesPage';
import NewProfilePage from './components/NewProfilePage/NewProfilePage';
import NewReferralPage from '../NewReferralPage';
import { REFERRAL_BASE_LINK } from '../../constants/links';
import SettingsNavigation from './components/SettingsNavigation';
import { getActiveSettingsPage } from './utils/linkUtils';
import { useCommunitiesData } from '../../CommunityPortal/hooks';
import useCommunitiesInvoiceData from '../../CommunityPortal/hooks/useCommunitiesInvoiceData';
import useProfileData from '../../hooks/userProfileData';
import { useWindowWidthContext } from '../../contexts/WindowWidthContext';
import { parse } from 'query-string';
import history from '../../modules/history';
import localStorageService from '../../utility/localStorageService';
import { useTranslation } from 'react-i18next';

const className = 'c-SettingsPageV2';

const SettingsPageV2 = (props) => {
  const {
    user,
    updateProfileStatus,
    isLoading,
    showStatus,
    basicInfoInputProps,
    socialMediaInputProps,
    videoPreferenceInputProps,
    commentPreferenceInputProps,
    updateBasicInfo,
    updateSocialMedia,
    updateVideoPreference,
    updateCommentPreference,
    errorsBasicInfo,
    registerBasicInfo,
    getBasicInfoValues,
    isBasicInfoUpdateLoading,
  } = useProfileData({});
  const { t } = useTranslation();
  const { isGtEqMd: isDesktopView } = useWindowWidthContext();

  const [activeCommunityCode, setActiveCommunityCode] = useState(null);
  const {
    communities,
    loading,
    onCancelSubscriptionClick,
  } = useCommunitiesData(activeCommunityCode);
  const { invoices, loading: invoiceLoading } = useCommunitiesInvoiceData(
    activeCommunityCode
  );

  const getReferralWidgetProps = useCallback(() => {
    const promoCode = Auth.getReferralCode();
    const referralUrl = `${REFERRAL_BASE_LINK}${promoCode}`;
    const { redeemBox, ...restStaticProps } = referralWidgetStaticProps(t);
    const referralCredit = user?.credits?.referralCredit || 0;
    const giftCredit = user?.credits?.giftCredit || 0;

    const formattedRedeemBoxProps = Object.assign({}, redeemBox, {
      referralCredit,
      giftCredit: giftCredit,
      ctaHandler:
        referralCredit < 50
          ? null
          : () => {
              window.open(REDEEM_FORM_LINK, '_blank');
            },
    });

    //AWS check
    const textToShare = t(
      'learn-from-the-worlds-best-creator-at-nas-academy-start-your-creator-journey-now-and-get-20-off-on-nas-academy-using-my-code-promocode-use-the-link-to-auto-apply-the-discount'
    );
    const formattedProps = Object.assign({}, restStaticProps, {
      referralProps: {
        url: referralUrl,
        code: promoCode,
        title: textToShare,
        text: textToShare,
      },
      shareCTAOnClick: () => {
        if (navigator.share) {
          navigator.share({
            url: referralUrl,
            title: textToShare,
            text: textToShare,
          });
        }
      },
      isDesktop: isDesktopView,
      redeemBox: formattedRedeemBoxProps,
      user: user,
    });

    return formattedProps;
  }, [isDesktopView, user]);
  const [activePage, setActivePage] = useState(SETTINGS_PAGE_REFERRAL.name);

  const getNavigationItems = useCallback(() => {
    const navigationHandlers = {
      [SETTINGS_PAGE_REFERRAL.name]: SETTINGS_PAGE_REFERRAL_PATH,
      [SETTINGS_PAGE_MEMBERSHIP.name]: SETTINGS_PAGE_MEMBERSHIP_PATH,
      [SETTINGS_PAGE_PREFERENCES.name]: SETTINGS_PAGE_PREFERENCES_PATH,
      [SETTINGS_PAGE_PROFILE.name]: SETTINGS_PAGE_PROFILE_PATH,
    };
    // we want to hide the referral page if the promocode is undefined
    // Memberships are available only for community members
    const hiddenStatus = {
      [SETTINGS_PAGE_REFERRAL.name]:
        !user?.promoCode && !localStorageService.getItem('referralCode'),
      [SETTINGS_PAGE_MEMBERSHIP.name]: communities?.length === 0 ?? true,
    };
    const navItems = pagesList.map(({ name, label, labelKey, Icon }) => ({
      label: t(labelKey) || label,
      Icon,
      link: navigationHandlers[name],
      isActive: name === activePage,
      hidden: hiddenStatus[name],
    }));
    return navItems;
  }, [activePage, communities?.length, user?.promoCode]);

  const getFilteredProfileModalInputFields = useCallback(() => {
    const fieldsNames = [
      'firstName',
      'lastName',
      'dialCodeId',
      'phoneNumber',
      'description',
    ];
    const filteredInputProps = basicInfoInputProps.filter((f) =>
      fieldsNames.includes(f.name)
    );
    return filteredInputProps;
  }, [basicInfoInputProps]);

  const renderPage = useCallback(
    () => (
      <Switch>
        <Route exact path={SETTINGS_PAGE_REFERRAL_PATH}>
          <NewReferralPage {...getReferralWidgetProps()} />
        </Route>
        <Route exact path={SETTINGS_PAGE_PROFILE_PATH}>
          <NewProfilePage
            user={user}
            updateProfile={updateBasicInfo}
            inputProps={getFilteredProfileModalInputFields()}
            isDesktopView={isDesktopView}
            promoCode={user?.promoCode}
            userSocialMediaLinks={user?.socialMedia}
            updateSocialMedia={updateSocialMedia}
            inputPropsSocialMedia={socialMediaInputProps}
            errorsBasicInfo={errorsBasicInfo}
            registerBasicInfo={registerBasicInfo}
            getBasicInfoValues={getBasicInfoValues}
            isBasicInfoUpdateLoading={isBasicInfoUpdateLoading}
          />
        </Route>
        <Route exact path={SETTINGS_PAGE_MEMBERSHIP_PATH}>
          <MembershipPage
            communities={communities}
            activeCommunityCode={activeCommunityCode}
            invoiceLoading={invoiceLoading}
            invoices={invoices}
            loading={loading}
            onCancelSubscriptionClick={onCancelSubscriptionClick}
            setActiveCommunityCode={setActiveCommunityCode}
          />
        </Route>

        <Route exact path={SETTINGS_PAGE_PREFERENCES_PATH}>
          <NewPreferencesPage
            isLoading={isLoading}
            updateProfile={updateCommentPreference}
            updateCommentPreference={updateCommentPreference}
            updateVideoPreference={updateVideoPreference}
            commentPreferenceInputProps={commentPreferenceInputProps}
            videoPreferenceInputProps={videoPreferenceInputProps}
            updateProfileStatus={updateProfileStatus}
            showStatus={showStatus}
            inputProps={commentPreferenceInputProps}
          />
        </Route>
      </Switch>
    ),
    [
      getReferralWidgetProps,
      user,
      updateBasicInfo,
      getFilteredProfileModalInputFields,
      isDesktopView,
      updateSocialMedia,
      socialMediaInputProps,
      errorsBasicInfo,
      registerBasicInfo,
      getBasicInfoValues,
      isBasicInfoUpdateLoading,
      communities,
      activeCommunityCode,
      invoiceLoading,
      invoices,
      loading,
      onCancelSubscriptionClick,
      isLoading,
      updateCommentPreference,
      updateVideoPreference,
      commentPreferenceInputProps,
      videoPreferenceInputProps,
      updateProfileStatus,
      showStatus,
    ]
  );

  useEffect(() => {
    setActivePage(getActiveSettingsPage());
    // Necessary reaction on chagning the URL
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  // set active active community code if param is present on url
  useEffect(() => {
    const params = parse(props.location.search);

    if (communities?.length > 0 && params.communityCode) {
      const selectedCommunity = communities.find(
        (community) => community.code === params.communityCode
      );

      if (selectedCommunity) setActiveCommunityCode(params.communityCode);
      else
        history.replace({
          search: '',
        }); // clear query
    }
  }, [
    communities,
    communities?.length,
    props.location.search,
    props.match.params,
  ]);

  return (
    <div className="flex flex-row justify-center w-full font-poppins">
      <div
        className={`${className} flex flex-col px-0 w-full min-h-100vh md:px-[24px] lg:px-[12px] lg:flex-row lg:max-w-[1160px]`}
      >
        <div className="z-1 w-full lg:h-full lg:fixed lg:w-[216px]">
          <SettingsNavigation pageItems={getNavigationItems()} user={user} />
        </div>

        <div
          className={`${className}__main_page z-0 relative flex-1 mt-56 lg:ml-[230px] lg:mt-0 xl:w-[920px]`}
        >
          {renderPage()}
        </div>
      </div>
    </div>
  );
};

SettingsPageV2.propTypes = {};

SettingsPageV2.defaultProps = {};

export default withRouter(SettingsPageV2);
