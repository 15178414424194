import Axios from 'axios';
import { MAX_FILE_SIZE } from '../utility/profileValidation';
import keys from '../config/keys';
import protectedAxiosRequest from './helper/protectedAxiosRequest';

const setUpdateUserProfileReqOptions = (
  token,
  contentType = 'application/json'
) => ({
  headers: {
    'Content-Type': contentType,
    Authorization: token,
    accept: '*/*',
  },
  maxContentLength: MAX_FILE_SIZE,
  maxBodyLength: MAX_FILE_SIZE,
});

const formatAsFormData = (data) => {
  const formData = new FormData();

  for (let key in data) {
    formData.append(key, data[key]);
  }

  return formData;
};

export const updateUserProfile = (payload, token) =>
  Axios.put(
    `${keys.baseURL}/api/v1/update-profile`,
    payload,
    setUpdateUserProfileReqOptions(token)
  );

export const updateUserProfileV2 = async (payload) => {
  try {
    const path = `${keys.baseURL}/api/v1/update-profile`;

    const res = await protectedAxiosRequest.put(path, payload);
    return { data: res.data, error: false };
  } catch (e) {
    return {
      error: e?.response?.data?.info ?? 'Something went wrong!',
    };
  }
};

export const removeContentItemAndUpdateUserProfile = (
  contentType,
  contentId,
  token
) =>
  Axios.patch(
    `${keys.baseURL}/api/v1/user-profile/contents/${contentType}`,
    { contentId },
    setUpdateUserProfileReqOptions(token)
  );

export const addContentItemAndUpdateUserProfile = (
  payload,
  contentType,
  token
) =>
  Axios.post(
    `${keys.baseURL}/api/v1/user-profile/contents/${contentType}`,
    formatAsFormData(payload),
    setUpdateUserProfileReqOptions(token, 'multipart/form-data')
  );

export const editContentItemAndUpdateUserProfile = (
  payload,
  contentType,
  token
) => {
  const withPhoto = payload.photo !== undefined ? 'true' : 'false';
  return Axios.put(
    `${keys.baseURL}/api/v1/user-profile/contents/${contentType}?withPhoto=${withPhoto}`,
    formatAsFormData(payload),
    setUpdateUserProfileReqOptions(token, 'multipart/form-data')
  );
};

export const getProfileByRoleType = (token) =>
  protectedAxiosRequest.get(`${keys.baseURL}/api/v1/user-profile/contents`);

export const uploadPhoto = async (data, config) =>
  protectedAxiosRequest.post(
    `${keys.baseURL}/api/v1/photo-upload/`,
    data,
    config
  );

export const updateUserPhoneConfirmation = () =>
  protectedAxiosRequest.put(`${keys.baseURL}/api/v1/confirm-phoneNumber`);
