import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  checkIfMoreThanBreakpoint,
  checkIsDesktop,
  checkIsMobileLandscape,
  COMMUNITY_PORTAL_BREAKPOINTS,
  isDesktop,
  isMobileLandScape
} from '../../utility/domHelpers';

const WindowWidthContext = createContext();

export const WindowWidthContextProvider = ({ children }) => {
  const [isGtEqSm, setIsGtEqSm] = useState(false);
  const [isGtEqMd, setIsGtEqMd] = useState(false);
  const [isGtEqLg, setIsGtEqLg] = useState(false);
  const [isGtEqXl, setIsGtEqXl] = useState(false);

  // Default is SM
  const breakpoint = useMemo(() => {
    if (isGtEqXl) return 'xl';
    if (isGtEqLg) return 'lg';
    if (isGtEqMd) return 'md';
    if (isGtEqSm) return 'sm';
    return 'xs';
  }, [isGtEqLg, isGtEqMd, isGtEqSm, isGtEqXl]);

  const [isDesktopView, setIsDesktopView] = useState(isDesktop);
  const [isMobileLandscape, setIsMobileLandscape] = useState(isMobileLandScape);

  const updateModeOnWindowChange = () => {
    setIsGtEqSm(checkIfMoreThanBreakpoint(COMMUNITY_PORTAL_BREAKPOINTS.sm));
    setIsGtEqMd(checkIfMoreThanBreakpoint(COMMUNITY_PORTAL_BREAKPOINTS.md));
    setIsGtEqLg(checkIfMoreThanBreakpoint(COMMUNITY_PORTAL_BREAKPOINTS.lg));
    setIsGtEqXl(checkIfMoreThanBreakpoint(COMMUNITY_PORTAL_BREAKPOINTS.xl));

    setIsDesktopView(checkIsDesktop());
    setIsMobileLandscape(checkIsMobileLandscape());
  };

  const initListener = useCallback(() => {
    // Needed to call the function for first time load
    updateModeOnWindowChange();
    window.addEventListener('resize', () => updateModeOnWindowChange());
  }, []);

  const removeListener = useCallback(() => {
    window.removeEventListener('resize', updateModeOnWindowChange);
  }, []);

  useEffect(() => {
    initListener();

    return removeListener();
  }, [initListener, removeListener]);

  const value = {
    isDesktopView,
    isMobileLandscape,
    breakpoint,
    isGtEqSm,
    isGtEqMd,
    isGtEqLg,
    isGtEqXl,
  };

  return (
    <WindowWidthContext.Provider value={value}>
      {children}
    </WindowWidthContext.Provider>
  );
};

export const useWindowWidthContext = () => useContext(WindowWidthContext);
