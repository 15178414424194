import React from 'react';
import AuthenticationWrapper from '../../components/Authentication/AuthenticationWrapper/AuthenticationWrapper';
import SignupForm from '../../components/Authentication/SignupForm';

const SignupPage = () => {
  const className = 'c-SignupPage';

  return (
    <div className={className}>
      <AuthenticationWrapper formChildren={<SignupForm />} />
    </div>
  );
};

export default SignupPage;
