import { getAxiosError } from '../../services/helper';
import protectedAxiosRequest from '../../services/helper/protectedAxiosRequest';
import config from '../config';
import {
  eventRegistrationURL,
  eventsURL,
  sharedRequestOptions,
} from './constants';

const { BASE_BE_URL } = config;

export const getEventsData = async (communityId, eventsType, params) => {
  try {
    return await protectedAxiosRequest.get(eventsURL(communityId, eventsType), {
      ...sharedRequestOptions,
      params,
    });
  } catch (error) {
    return getAxiosError(error);
  }
};

export const postEventRegistration = async (eventId) => {
  try {
    return await protectedAxiosRequest.post(
      eventRegistrationURL(eventId),
      sharedRequestOptions
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const cancelEventAttendanceService = async ({
  communityId,
  eventId,
  userId,
}) => {
  try {
    return await protectedAxiosRequest.put(
      `${BASE_BE_URL}/${communityId}/events/${eventId}/attendees/${userId}/not-going`
    );
  } catch (error) {
    return getAxiosError(error);
  }
};
