export const SHOW_START_COMMUNITY_MODAL = 'SHOW_START_COMMUNITY_MODAL';
export const DEMO_MEMBER_PORTAL_NAV_SETTINGS =
  'DEMO_MEMBER_PORTAL_NAV_SETTINGS';
export const DEMO_MEMBER_PORTAL_JOIN_CHAT = 'DEMO_MEMBER_PORTAL_JOIN_CHAT';
export const DEMO_TOGGLE_TO_CM_VIEW = 'DEMO_TOGGLE_TO_CM_VIEW';
export const DEMO_CLICK_MP_LOGOUT = 'DEMO_CLICK_MP_LOGOUT';
export const DEMO_MP_GET_EVENT_TICKET_CLICK = 'DEMO_MP_GET_EVENT_TICKET_CLICK';
export const DEMO_MP_PURCHASE_FOLDER_CLICK = 'DEMO_MP_PURCHASE_FOLDER_CLICK';
export const DEMO_MEMBER_PORTAL_LEAVE_COMMUNITY =
  'DEMO_MEMBER_PORTAL_LEAVE_COMMUNITY';

export function isSessionInSandboxEnv() {
  try {
    return window !== window.parent;
  } catch (e) {
    return false;
  }
}

export function handleGetEventTicketClickInDemo() {
  showStartOnboardingModalInDemo(DEMO_MP_GET_EVENT_TICKET_CLICK);
}

export function handlePurchaseFolderClickInDemo() {
  showStartOnboardingModalInDemo(DEMO_MP_PURCHASE_FOLDER_CLICK);
}

export function handleSettingsClickInDemo() {
  showStartOnboardingModalInDemo(DEMO_MEMBER_PORTAL_NAV_SETTINGS);
}

export function handleJoinChatClickInDemo() {
  showStartOnboardingModalInDemo(DEMO_MEMBER_PORTAL_JOIN_CHAT);
}

export function handleLeaveCommunityClickInDemo() {
  showStartOnboardingModalInDemo(DEMO_MEMBER_PORTAL_LEAVE_COMMUNITY);
}

export function showStartOnboardingModalInDemo(locationOfCta) {
  if (isSessionInSandboxEnv()) {
    const targetOrigin = '*';
    const message = {
      fromDemo: true,
      type: SHOW_START_COMMUNITY_MODAL,
      locationOfCta,
    };
    window.parent.postMessage(message, targetOrigin);
  }
}

export function switchDemoToCmView() {
  if (isSessionInSandboxEnv()) {
    const targetOrigin = '*';
    const message = {
      fromDemo: true,
      type: DEMO_TOGGLE_TO_CM_VIEW,
    };
    window.parent.postMessage(message, targetOrigin);
  }
}

export function handleLogoutClickFromProductDemo() {
  if (isSessionInSandboxEnv()) {
    const targetOrigin = '*';
    const message = {
      fromDemo: true,
      type: DEMO_CLICK_MP_LOGOUT,
    };
    window.parent.postMessage(message, targetOrigin);
  }
}
