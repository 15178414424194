import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { startLogin } from '../../../actions/auth';
import history from '../../../modules/history';
import { loginFormSchema } from '../../../pages/LoginPage/constants';
import { shortLogoImageData } from '../../../utility/constants';
import { validate } from '../../../utility/validationHelper';
import Button from '../../Button';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import Input from '../../Form/Input';
import Image from '../../Image';
import PasswordExpiredModal from '../../PasswordExpiredModal';
import SocialLogins from '../../SocialLogins';
import './LoginForm.scss';
import keys from '../../../config/keys';

const className = 'c-LoginForm';

const LoginForm = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [focusPassword, setFocusPassword] = useState(false);

  const [showPasswordExpiredModal, setShowPasswordExpiredModal] = useState(
    false
  );

  const handleResetPassword = () => {
    setShowPasswordExpiredModal(false);
    history.push('/forget-password');
  };

  const isWebView = useSelector((state) => state.main.isWebView);

  const login = async (e) => {
    e.preventDefault();

    const isValid = await validate({
      schema: loginFormSchema,
      formData,
      setErrors,
    });

    if (!isValid) {
      return;
    }

    const email = formData.email;
    const password = formData.password;
    props.dispatch(startLogin(email, password, props.currentRoute));
  };

  const onFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: '' });
    }
  };

  useEffect(() => {
    const parseObj = parse(window.location.search);

    if (parseObj.email) {
      setFocusPassword(true);
      setFormData((prev) => {
        return { ...prev, email: parseObj.email };
      });
    }
  }, []);

  return (
    <div className={className}>
      <Image {...shortLogoImageData} />
      <div className="mb-12 font-semibold text-grey-neutral10 font-poppins text-heading-sm lg:text-heading-md">
        Member login
      </div>
      <div className="mb-24 font-poppins text-label-sm lg:text-label-md">
        <span className="text-light-text text-grey-neutral50">
          Logging in as a community manager?{' '}
        </span>
        <span className="underline">
          <a
            className="font-medium text-yellow-yellow50"
            href={`${keys.nasIOBaseUrl}/portal`}
          >
            Click here
          </a>
        </span>
      </div>
      {!isWebView ? (
        <>
          <SocialLogins isLoginForm={true}/>
          <div className={`${className}__or`}>
            <div className={`${className}__or__text`}>OR</div>
          </div>
        </>
      ) : null}
      <form className={`${className}__form`} onSubmit={login}>
        <Input
          type="email"
          value={formData?.email}
          name="email"
          focusOnLoad={true}
          onChange={onFieldChange}
          label="Email"
          placeholder="Enter your email"
          error={errors?.email}
          v2
        />
        <Input
          type="password"
          value={formData?.password}
          name="password"
          onChange={onFieldChange}
          placeholder="Enter Password"
          eyeIcon
          label="Password"
          error={errors?.password}
          v2
        />
        <div className={`${className}__form__link`}>
          <a
            title="Forgot Password"
            href="/forget-password"
            rel="noreferrer"
            target="_blank"
          >
            Forgot your password?
          </a>
        </div>
        {props.loginFailed ? (
          <div className={`${className}__error-message`}>
            {props.loginFailed}
          </div>
        ) : (
          <div />
        )}
        {props.loading ? (
          <LoadingCircle />
        ) : (
          <Button displayType="primary" type="submit" fluid>
            Login
          </Button>
        )}
      </form>
      <PasswordExpiredModal
        show={showPasswordExpiredModal}
        onClickLogin={() => setShowPasswordExpiredModal(false)}
        onClose={() => setShowPasswordExpiredModal(false)}
        onClickReset={handleResetPassword}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentRoute: state.routes.currentRoute,
  loading: state.main.loading,
  loginFailed: state.main.loginFailed,
});

export default connect(mapStateToProps)(withRouter(LoginForm));
