export const CONTACT_US_EMAIL = 'hello@nasacademy.com';

export const shortLogoImageData = {
  alt: 'NasAcademy',
  mobileImgData: {
    meta: { width: 32, height: 32 },
    src:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/nas_academy_icon_dark.svg',
  },
};

// urls
export const COHORT_MY_CLASS_PAGE_PATH = '/cohort/my-class/:courseOfferId';
export const COHORT_TODAY_PAGE_PATH = '/cohorts/:courseOfferId/today';

export const COHORT_FOCUS_VIEW_PAGE_PATH =
  '/cohort/my-class-focus/:courseOfferId/unit-offer/:unitOfferObjectId';

// TODO: Remove - temp
// http://localhost:3001/cohort/my-class-focus/619b9206762842009e4c8bee/unit-offer/3279
