import React from 'react';

function FacebookGreyIcon({ customClassName }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      className={'  c-FacebookGreyIcon ' + customClassName}
    >
      <path
        d="M30 15C30 6.71484 23.2852 0 15 0C6.71484 0 0 6.71484 0 15C0 23.2852 6.71484 30 15 30C15.0879 30 15.1758 30 15.2637 29.9941V18.3223H12.041V14.5664H15.2637V11.8008C15.2637 8.5957 17.2207 6.84961 20.0801 6.84961C21.4512 6.84961 22.6289 6.94922 22.9688 6.99609V10.3477H21C19.4473 10.3477 19.1426 11.0859 19.1426 12.1699V14.5605H22.8633L22.377 18.3164H19.1426V29.4199C25.4121 27.6211 30 21.8496 30 15Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
}

export default FacebookGreyIcon;
