import {
  DISCOVER_MORE_CLASSES_LINK,
  DISCOVER_MORE_LINK,
  EVENT_LOCATION_TEXT,
} from '../constants';
import {
  EVENTS_PAGE,
  HOME_PAGE,
  LIBRARY_PAGE,
  MEET_AN_EXPERT_PAGE,
  MEMBERS_PAGE,
} from '../constants/pages';

import Auth from '../../modules/auth.js';
import config from '../../modules/config.js';
import {
  EVENT_TYPE_IN_PERSON,
  EVENT_TYPE_LIVE,
} from '../pages/LiveSessionsPage/constants';

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const openManagerPortal = (communityId) => {
  const {
    tokens: uriToken,
    refreshToken: uriRefreshToken,
  } = Auth.getUriTokens();
  const accessToken =
    uriToken && uriToken !== '' ? uriToken : Auth.getUserToken();
  const refreshToken =
    uriRefreshToken && uriRefreshToken !== ''
      ? uriRefreshToken
      : Auth.getRefreshToken();
  openInNewTab(
    `${process.env.REACT_APP_NASIO_BASE_URL}/portal?token=${accessToken}&refreshToken=${refreshToken}&activeCommunityId=${communityId}`
  );
};

export const getActiveCommunityPortalPage = () => {
  let activePage = HOME_PAGE.name;
  const pathItems = window.location.pathname.split('/');

  const pagesData = [
    { name: MEET_AN_EXPERT_PAGE.name, endpoint: MEET_AN_EXPERT_PAGE.endpoint },
    {
      name: EVENTS_PAGE.name,
      endpoint: EVENTS_PAGE.endpoint,
    },
    {
      name: LIBRARY_PAGE.name,
      endpoint: LIBRARY_PAGE.endpoint,
    },
    {
      name: MEMBERS_PAGE.name,
      endpoint: MEMBERS_PAGE.endpoint,
    },
    { name: HOME_PAGE.name, endpoint: HOME_PAGE.endpoint },
  ];
  for (let i = 0; i < pagesData.length; i++) {
    if (pathItems.includes(pagesData[i].endpoint)) {
      activePage = pagesData[i].name;
      break;
    }
  }

  return activePage;
};

export const discoverCommunitiesLinkOpener = () => {
  openInNewTab(DISCOVER_MORE_LINK);
};

export const discoverClassesLinkOpener = () => {
  openInNewTab(DISCOVER_MORE_CLASSES_LINK);
};

export const resolveEventSlugUrl = (communityLink, slug) => {
  const communityLinkWithoutSlash = communityLink.replace(/\//g, '');
  const slugWithoutSlash = slug.replace(/\//g, '');
  if (config.isDevEnv) {
    return `http://localhost:3000/${communityLinkWithoutSlash}/${slugWithoutSlash}`;
  }
  return `https://nas.io/${communityLinkWithoutSlash}/${slugWithoutSlash}`;
};

export const getEventProminentUrlProps = ({ t, eventInfo }) => {
  const EVENT_LOCATION_TEXT = {
    LIVE: t('online'),
    IN_PERSON: t('in-person'),
  };

  const isRegistered = eventInfo?.registered;

  if (eventInfo.type === EVENT_TYPE_LIVE) {
    const locationText = isRegistered
      ? eventInfo?.liveLink ?? EVENT_LOCATION_TEXT.LIVE
      : EVENT_LOCATION_TEXT.LIVE;
    return {
      type: eventInfo.type,
      text: locationText.trim(),
      showCopyIcon: locationText !== EVENT_LOCATION_TEXT.LIVE,
    };
  }

  if (eventInfo.type === EVENT_TYPE_IN_PERSON) {
    const locationText = isRegistered
      ? eventInfo?.inPersonLocation ?? EVENT_LOCATION_TEXT.IN_PERSON
      : EVENT_LOCATION_TEXT.IN_PERSON;
    return {
      type: eventInfo.type,
      text: locationText.trim(),
      showCopyIcon: locationText !== EVENT_LOCATION_TEXT.IN_PERSON,
    };
  }

  return {};
};

export const getFormattedEventDescription = (event) => {
  if (!event) return '';

  if (event.type === EVENT_TYPE_LIVE)
    return `Meeting link: ${event.liveLink}\n\n${event?.description}`;
  if (event.type === EVENT_TYPE_IN_PERSON)
    return `Location: ${event.inPersonLocation}\n\n${event?.description} `;
  return '';
};
