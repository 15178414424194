import useOutsideComponentClickDetect from './useOutsideComponentClickDetect';
import { useRef } from 'react';

const { useState } = require('react');
const { useEffect } = require('react');
const { getLibrarySearchData } = require('../apiServices/libraryService');

const useSearchDataLibrary = (communityObjectId) => {
  const [search, setSearch] = useState('');
  const searchDropDownRef = useRef();
  const [searchData, setData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  useOutsideComponentClickDetect(searchDropDownRef, () =>
    setShowSearchDropDown(false)
  );

  const onSearchBoxFocus = () => {
    if (search !== '' && showSearchDropDown === false) {
      setShowSearchDropDown(true);
    }
  };
  useEffect(() => {
    if (search !== '') {
      setShowSearchDropDown(true);
    }
    if (search === '') {
      setShowSearchDropDown(false);
    }
    const fetchData = async () => {
      if (!communityObjectId) {
        // Don't process if no community ID
        return;
      }
      setSearchLoading(true);

      try {
        const response = await getLibrarySearchData(communityObjectId, search);
        setData(response.data);
      } catch (error) {
        setError(error);
      }
      setSearchLoading(false);
    };
    if (search !== '') {
      fetchData();
    }
  }, [communityObjectId, search]);

  return {
    searchData,
    searchLoading,
    error,
    showSearchDropDown,
    search,
    setSearch,
    searchDropDownRef,
    onSearchBoxFocus,
    setShowSearchDropDown,
  };
};

export default useSearchDataLibrary;
