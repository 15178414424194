import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { startSignUp } from '../../../actions/auth';
import { parse } from 'query-string';
import Button from '../../Button';

const className = 'c-SignupForm c-Form';

function SignupForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const parseObj = parse(window.location.search);

    if (parseObj.email && parseObj.token) {
      setEmail(parseObj.email);
      setToken(parseObj.token);
    }
  }, [props.loginFailed, props.loading]);

  const signUp = (e) => {
    e.preventDefault();
    props.dispatch(startSignUp(email, password, confirmPassword, token));
    setPassword('');
    setConfirmPassword('');
    setEmail('');
  };

  return (
    <div className={className}>
      <form onSubmit={signUp} className="flex flex-col">
        <input
          type="email"
          required
          value={email}
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          required
          value={password}
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <input
          type="password"
          required
          value={confirmPassword}
          name="confirmPassword"
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
        />
        {props.loading ? (
          <LoadingCircle />
        ) : (
          <Button displayType="primary" fluid>
            Sign Up
          </Button>
        )}
      </form>
      <div className="login-input-form">
        {props.loginFailed ? (
          <div className={`${className}__error-message`}>
            {props.loginFailed}
          </div>
        ) : (
          <div />
        )}
        {props.success ? (
          <div className={`${className}__success-message`}>{props.success}</div>
        ) : (
          <div />
        )}
        <div className={`${className}__link`}>
          <Link to="/login">Login</Link>
        </div>
        <div className={`${className}__forget-password`}>
          <Link to="/forget-password">Forget your password?</Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentRoute: state.routes.currentRoute,
  loading: state.main.loading,
  loginFailed: state.main.loginFailed,
  success: state.main.success,
});

export default connect(mapStateToProps)(withRouter(SignupForm));
