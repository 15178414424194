import PropTypes from 'prop-types';
import React from 'react';

export const HomeIcon = ({
  width,
  height,
  displayType,
  fillColor,
  strokeColor,
}) => (
  <svg width={width} height={height} viewBox="0 0 18 19" fill="none">
    <path
      d="M16.6585 7.70104L9.6585 1.57604C9.28148 1.24614 8.71852 1.24614 8.3415 1.57604L1.3415 7.70104C1.12448 7.89092 1 8.16525 1 8.45361V16.9998C1 17.5521 1.44772 17.9998 2 17.9998H6C6.55228 17.9998 7 17.5521 7 16.9998V12.9998C7 12.4476 7.44772 11.9998 8 11.9998H10C10.5523 11.9998 11 12.4476 11 12.9998V16.9998C11 17.5521 11.4477 17.9998 12 17.9998H16C16.5523 17.9998 17 17.5521 17 16.9998V8.45361C17 8.16525 16.8755 7.89093 16.6585 7.70104Z"
      fill={displayType === 'outline' ? 'none' : fillColor}
      stroke={displayType === 'outline' ? strokeColor : fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

HomeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  displayType: PropTypes.oneOf(['outline', 'filled']),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

HomeIcon.defaultProps = {
  width: '18',
  height: '19',
  fillColor: '#FBC91B',
  strokeColor: '#AAABAE',
};
