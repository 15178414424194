import { COMMUNITY_PORTAL_PATH, CREATOR_PORTAL_PATH } from '../constants';

import history from '../../modules/history';

const readCurrentPath = () => history.location.pathname;
const goToPortalPage = (path) => history.push(path);

const isOnCommunityPortal = () => {
  const pathItems = history.location.pathname.split('/');

  return pathItems.includes(COMMUNITY_PORTAL_PATH);
};

const isOnCreatorPortal = () => {
  const pathItems = window.location.pathname.split('/');

  return pathItems.includes(CREATOR_PORTAL_PATH);
};

const isOnSettingsPortal = () => {
  const pathItems = window.location.pathname.split('/');

  return pathItems.includes('setting');
};

const prefixWithHttps = (url) => {
  if (url && !url.toLowerCase().startsWith('http')) {
    return `https://${url}`;
  }

  return url;
};

const urlUtils = {
  readCurrentPath,
  goToPortalPage,
  isOnCommunityPortal,
  isOnCreatorPortal,
  isOnSettingsPortal,
  prefixWithHttps,
};

export default urlUtils;
