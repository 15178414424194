import React, { useCallback } from 'react';
import history from '../../../../../modules/history';

const SingleSearchResultContainer = ({ searchData, setShowSearchDropDown }) => {
  const renderImage = useCallback(() => {
    return (
      <img
        src={searchData?.thumbnail}
        alt=""
        className="object-cover w-64 h-64 min-w-[64px] rounded-8"
      />
    );
  }, [searchData?.thumbnail, searchData?.type]);

  const renderContent = useCallback(() => {
    return (
      <div
        className="flex flex-row max-h-[88px] py-12 px-16"
        onClick={() => {
          // this is a folder
          const currentUrl = window.location.href;
          const newUrl = `${currentUrl}${
            currentUrl.slice(-1) !== '/' ? '/' : ''
          }${searchData?.communityFolderObjectId}`;
          history.push(newUrl);
          setShowSearchDropDown(false);
        }}
      >
        <div className="mr-16">{renderImage()}</div>{' '}
        <div className="flex flex-col my-auto">
          <span>
            {' '}
            {searchData?.title?.slice(0, 40)}
            {searchData?.title?.length > 40 && '...'}
          </span>
        </div>
      </div>
    );
  }, [
    renderImage,
    searchData?._id,
    searchData?.communityFolderObjectId,
    searchData.folderType,
    searchData.title,
    searchData.type,
    setShowSearchDropDown,
  ]);

  return renderContent();
};

export default SingleSearchResultContainer;
