import './Modal.scss';

import React, { useLayoutEffect } from 'react';

import CloseIcon from '../../../components/Icons/CloseIcon';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

// FOR ALEK, PJ: temp copied from the old codebase

const Modal = ({
  open,
  onClose,
  closeOnBackdropClick,
  customBaseClass,
  customContainerClass,
  customInnerClass,
  customBackdropClass,
  customBaseStyle,
  customContainerStyle,
  customInnerStyle,
  customBackdropStyle,
  children,
  showCloseIcon,
  showCloseOnPageEnd,
  customCloseIcon,
  customCloseIconClass,
}) => {
  const className = 'c-Modal';
  const baseClassNames = [className, open ? ` ${className}--open` : ''].join(
    ''
  );
  const modalEl = document.getElementById('modal');

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (document.body) {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = originalStyle;
      }
    }

    return () => {
      if (document.body) return (document.body.style.overflow = 'visible');
    };
  }, [open]);

  if (!modalEl) return null;

  const handleBackdropClick = () => {
    if (closeOnBackdropClick) onClose();
  };

  return createPortal(
    <div
      className={`${baseClassNames} ${customBaseClass}`}
      style={customBaseStyle}
    >
      <div
        className={`${className}__backdrop z-1 ${customBackdropClass}`}
        style={customBackdropStyle}
        onClick={handleBackdropClick}
      />
      {showCloseOnPageEnd && showCloseIcon && (
        <div
          className={`${className}__close-modal z-2 ${customCloseIconClass}`}
          onClick={onClose}
        >
          {customCloseIcon ?? <CloseIcon />}
        </div>
      )}
      <div
        className={`${className}__container ${customContainerClass}`}
        style={customContainerStyle}
      >
        {!showCloseOnPageEnd && showCloseIcon && (
          <div
            className={`${className}__close-modal ${customCloseIconClass}`}
            onClick={onClose}
          >
            {customCloseIcon ?? <CloseIcon />}
          </div>
        )}
        <div
          className={`${className}__inner ${customInnerClass}`}
          style={customInnerStyle}
        >
          {children}
        </div>
      </div>
    </div>,
    modalEl
  );
};

Modal.defaultProps = {
  open: false,
  showCloseIcon: true,
  closeOnBackdropClick: true,
  showCloseOnPageEnd: false,
  customBaseClass: '',
  customContainerClass: '',
  customInnerClass: '',
  customBackdropClass: '',
  customCloseIconClass: '',
  customBaseStyle: {},
  customContainerStyle: {},
  customInnerStyle: {},
  customBackdropStyle: {},
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  closeOnBackdropClick: PropTypes.bool,
  customBaseClass: PropTypes.string.isRequired,
  customContainerClass: PropTypes.string.isRequired,
  customInnerClass: PropTypes.string.isRequired,
  customCloseIconClass: PropTypes.string,
  customBackdropClass: PropTypes.string,
  customBaseStyle: PropTypes.object,
  customContainerStyle: PropTypes.object,
  customInnerStyle: PropTypes.object,
  customBackdropStyle: PropTypes.object,
  showCloseIcon: PropTypes.bool,
  customCloseIcon: PropTypes.elementType,
  showCloseOnPageEnd: PropTypes.bool,
};

export default Modal;
