import React from 'react';
import { PropTypes } from 'prop-types';

function EyeVisible({ isOpen }) {
  const className = 'c-EyeVisible';

  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <g>
        <path
          fill="#000"
          fillOpacity="0.5"
          fillRule="evenodd"
          d={
            isOpen
              ? 'M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm-3-5c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3z'
              : 'M2.71 4.54a.996.996 0 010-1.41c.39-.39 1.03-.39 1.42 0l16.31 16.33a.996.996 0 11-1.41 1.41l-2.72-2.72c-1.34.52-2.79.82-4.31.82-5 0-9.27-3.11-11-7.5.77-1.97 2.06-3.67 3.68-4.96L2.71 4.54zM17 11.47c0-2.76-2.24-5-5-5-.51 0-1 .1-1.47.24L8.36 4.54c1.15-.37 2.37-.57 3.64-.57 5 0 9.27 3.11 11 7.49-.69 1.76-1.79 3.3-3.18 4.53l-3.06-3.06c.14-.46.24-.95.24-1.46zm-5 5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07l1.57 1.57c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z'
          }
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

EyeVisible.defaultProps = {
  isOpen: true,
};

EyeVisible.propTypes = {
  isOpen: PropTypes.bool,
};

export default EyeVisible;
