export const DISCOVER_MORE_LINK = 'https://nasacademy.com/communities';
export const DISCOVER_MORE_CLASSES_LINK = 'https://nasacademy.com/courses';
export const COMMUNITY_EXPIRED = 'Expired'; // Status, not a string
export const COMMUNITY_RENEWAL_AVAILABLE = 'Renewal_Available';
export const COMMUNITY_AUTO_RENEWAL_SOON = 'Auto_Renewal_Soon';
export const COMMUNITY_GRACE_PERIOD = 'Grace_Period';

export const defaultHostProfileImage =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/png/placeholder-guest-profile-picture.png';

export const NASIO_LIGHT_IMAGE_DATA = {
  alt: 'icon',
  mobileImgData: {
    src:
      'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/png/nasio_mobile_banner.png',
    webpSrc:
      'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/png/nasio_mobile_banner.png',
    meta: {
      width: 56,
      height: 56,
    },
  },
};
