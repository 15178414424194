import axios from 'axios';
import { cancelCommunityURL } from './constants';
import { getAxiosError } from '../../services/helper';
import sign from 'jwt-encode';
import protectedAxiosRequest from '../../services/helper/protectedAxiosRequest';
import config from '../config';

export const cancelSubscription = async (
  stripeSubscriptionId,
  cancellationReason
) => {
  try {
    debugger;
    const mainWebsiteJwtToken = sign(
      { stripeSubscriptionId },
      process.env.REACT_APP_JWT_SECRET
    );
    const response = await axios.post(
      `${cancelCommunityURL()}`,
      { cancellationReason },
      { headers: { Authorization: `Bearer ${mainWebsiteJwtToken}` } }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return getAxiosError(err);
  }
};

export const cancelFreeOrTokenGatedSubscription = async ({
  cancellationReason,
  communityCode,
  communityId,
  subscriptionObjectId,
}) => {
  try {
    const response = await protectedAxiosRequest.put(
      `${config.BASE_BE_URL}/leave-community/${communityId}`,
      {
        cancellationReason,
        communityCode,
        subscriptionObjectId,
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return getAxiosError(err);
  }
};
