import { SETTINGS_PAGE_REFERRAL, pagesList } from '../constants';

export const getActiveSettingsPage = () => {
  let activePage = SETTINGS_PAGE_REFERRAL.name;
  const pathItems = window.location.pathname.split('/');
  return (
    pagesList.find(({ name, endpoint }) => {
      return pathItems.includes(endpoint);
    })?.name ?? activePage
  );
};
