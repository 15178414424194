import PropTypes from 'prop-types';

const MasterCardIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 56 35" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.9474 30.0734C24.9873 32.5685 21.1475 34.0747 16.9517 34.0747C7.58955 34.0747 0 26.5755 0 17.3247C0 8.07394 7.58955 0.574707 16.9517 0.574707C21.1475 0.574707 24.9873 2.08095 27.9474 4.57606C30.9076 2.08095 34.7473 0.574707 38.9432 0.574707C48.3053 0.574707 55.8949 8.07394 55.8949 17.3247C55.8949 26.5755 48.3053 34.0747 38.9432 34.0747C34.7473 34.0747 30.9076 32.5685 27.9474 30.0734Z"
      fill="#ED0006"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.9492 30.0734C31.5941 27.0011 33.9052 22.4296 33.9052 17.3247C33.9052 12.2198 31.5941 7.64831 27.9492 4.57605C30.9093 2.08095 34.7491 0.574707 38.9449 0.574707C48.3071 0.574707 55.8967 8.07394 55.8967 17.3247C55.8967 26.5755 48.3071 34.0747 38.9449 34.0747C34.7491 34.0747 30.9093 32.5685 27.9492 30.0734Z"
      fill="#F9A000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.9481 30.0734C31.5929 27.0011 33.9041 22.4296 33.9041 17.3248C33.9041 12.2199 31.5929 7.64843 27.9481 4.57617C24.3033 7.64843 21.9922 12.2199 21.9922 17.3248C21.9922 22.4296 24.3033 27.0011 27.9481 30.0734Z"
      fill="#FF5E00"
    />
  </svg>
);

MasterCardIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

MasterCardIcon.defaultProps = {
  width: '56',
  height: '35',
};

export default MasterCardIcon;
