import React from 'react';

const HouseFilledIcon = () => {
  return (
    <svg
      className="c-HouseFilledIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M10.683 2.824l-7 6.125 7-6.125zm2.634 0l7 6.125A2 2 0 0121 10.454V19a2 2 0 01-2 2h-4a2 2 0 01-2-2v-4h-2v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-8.546a2 2 0 01.683-1.505"
        clipRule="evenodd"
      ></path>
      <path
        fill="#191C1E"
        d="M13.317 2.824a2 2 0 00-2.634 0l-7 6.125A2 2 0 003 10.454V19a2 2 0 002 2h4a2 2 0 002-2v-4h2v4a2 2 0 002 2h4a2 2 0 002-2v-8.546a2 2 0 00-.683-1.505l-7-6.125z"
      ></path>
    </svg>
  );
};

export default HouseFilledIcon;
