import {
  folderClickLogOnLibraryPage,
  getLibraryFoldersData,
  getLibrarySingleFolderData,
} from '../apiServices/libraryService';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useLibraryData = (communityId, folderId) => {
  const { t } = useTranslation();
  const SORT_STATE_NEWEST = t('newest');
  const SORT_STATE_OLDEST = t('oldest');
  const SORT_STATE_DEFAULT = t('default');

  const [loading, setLoading] = useState(false);
  const [dataForLibrary, setDataForLibrary] = useState([]);
  const [selectedFolderData, setSelectedFolderData] = useState(null);
  const [sortState, setSortState] = useState(SORT_STATE_DEFAULT);

  const getSortedData = (data) => {
    const sortedData = data?.sort((a, b) => {
      return new Date(a?.createdAt) - new Date(b?.createdAt);
    });
    return sortedData;
  };

  const selectSortState = (selectedState) => {
    setSortState(selectedState);
    let sortedData = [];
    if (selectedState === SORT_STATE_OLDEST) {
      sortedData = getSortedData(dataForLibrary);
    } else if (selectedState === SORT_STATE_NEWEST) {
      sortedData = getSortedData(dataForLibrary).reverse();
    } else {
      dataForLibrary.sort((a, b) => a.index - b.index);
      sortedData = dataForLibrary;
    }
    setDataForLibrary(sortedData);
  };

  const fetchAndSetAllDataForLibrary = useCallback(async () => {
    if (communityId) {
      setLoading(true);
      const data = await getLibraryFoldersData(communityId);

      let foldersData = data?.data;
      // remove all isDraft folder
      foldersData =
        foldersData?.filter((item) => {
          return !item?.isDraft;
        }) ?? [];
      // sort default on fetching
      foldersData.sort((a, b) => a.index - b.index);
      setDataForLibrary(foldersData);
      setLoading(false);
    }
  }, [communityId]);

  const fetchAndSetSelectedFolderData = async (selectedFolderId) => {
    setLoading(true);
    const libraryDataForSingleFolder = await getLibrarySingleFolderData(
      communityId,
      selectedFolderId
    );
    // :TODO: add click tracking endpoints
    await folderClickLogOnLibraryPage(selectedFolderId);
    setSelectedFolderData(libraryDataForSingleFolder?.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchAndSetAllDataForLibrary();
  }, [communityId]);

  useEffect(() => {
    if (folderId && communityId) {
      fetchAndSetSelectedFolderData(folderId);
    }
  }, [folderId, communityId]);

  return {
    loading,
    dataForLibrary,
    setSortState,
    selectSortState,
    sortState,
    selectedFolderData,
  };
};

export default useLibraryData;
