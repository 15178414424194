import { useState, useEffect } from 'react';
import { convertQueryStringToJson } from '../modules/stringHelpers';

export const COUPON_URI_KEY = 'coupon';
export const COUPON_SESSION_KEY = 'couponQueryParam';

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const queryStr = window?.location?.search || '';
    const queryParamsJson = convertQueryStringToJson(queryStr);
    setQueryParams(queryParamsJson);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.location?.search]);

  return {
    queryParams,
  };
};

export default useQueryParams;
