import React from 'react';

const CaretLeftIcon = ({ customClassName }) => {
  const className = 'c-CaretLeftIcon';

  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <path
        d="M9.24263 2.34317L7.82842 0.928955L0.757385 8.00001L7.82845 15.0711L9.24267 13.6569L3.5858 8L9.24263 2.34317Z"
        fill="black"
        fillOpacity="0.82"
      />
    </svg>
  );
};

export default CaretLeftIcon;
