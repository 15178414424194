import React, { useEffect, useState } from 'react';

import { bookTrainerForCalendly } from '../apiServices';
import { cancelTrainerBookingForCalendly } from '../apiServices/communityBookingService';

const useCommunitiesBookingData = (communityId, existingBookings) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(existingBookings ?? []);

  const getBookingDetails = async (eventUri, inviteeUri) => {
    setLoading(true);
    const response = await bookTrainerForCalendly(
      communityId,
      eventUri,
      inviteeUri
    );
    if (response.status === 200) {
      setData([response.data]);
      setLoading(false);
    }
  };

  const cancelBooking = async (cancelReason, setcancel, cancel) => {
    setLoading(true);
    const reponse = await cancelTrainerBookingForCalendly(
      communityId,
      cancelReason
    );
    if (reponse.status === 200) {
      setData([]);
      setcancel(!cancel);
      setLoading(false);
    }
  };

  useEffect(() => {
    setData(existingBookings);
  }, [existingBookings]);

  // Reset Data in case there's a change in community Id
  useEffect(() => {
    setData([]);
  }, [communityId]);

  return {
    loading,
    data,
    getBookingDetails,
    cancelBooking,
  };
};

export default useCommunitiesBookingData;
