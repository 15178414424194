import {
  NEXT_BILLING_DATE,
  SUBSCRIPTION_END_DATE,
  SUBSCRIPTION_WILL_END,
  UNSUBSCRIBE_TEXT,
} from './constants';
import Image, { ImageDataType } from '../../../../../../components/Image';
import {
  formatDateInDDMMYYYY,
  getDifferenceBetweenDatesInDays,
} from '../../../../../../CommunityPortal/utils/dateUtils';

import Button from '../../../../../../CommunityPortal/components/Button';
import PropTypes from 'prop-types';
import { isDateInPast } from '../../../../../../utility/dateHelper';
import { useCallback } from 'react';
import { useWindowWidthContext } from '../../../../../../contexts/WindowWidthContext';
import { useTranslation } from 'react-i18next';

const className = 'c-SubscriptionCard';
const SubscriptionCard = ({
  code,
  name,
  img,
  nextBillingDate,
  pricePerMonthString,
  status,
  onCtaClick,
  isActive,
  isFreeCommunity,
  isPaidCommunity,
  isFreeTrial,
  isTokenGated,
  isManualEnrolment,
  discountedCommunity,
  isDiscountedPricing,
  isSubscriptionFree,
  isSubscriptionPaid,
  discountTagText,
}) => {
  const { t } = useTranslation();
  const isAlreadyCancelled = status === 'Cancelled';
  const daysBeforeBillingDate = getDifferenceBetweenDatesInDays(
    new Date(),
    nextBillingDate
  );
  const { isGtEqMd } = useWindowWidthContext();
  const cancelledSubscriptionDivClass =
    isAlreadyCancelled && isGtEqMd && 'ml-auto';

  const getCTAText = useCallback(() => {
    if (isActive) {
      // This is when they are in the active state
      if (status === 'Cancelled') {
        if (isDateInPast(nextBillingDate)) {
          return t('unsubscribe');
        } else {
          return `${SUBSCRIPTION_WILL_END(t)}${daysBeforeBillingDate + 1} ${
            daysBeforeBillingDate > 1 ? 'days' : 'day'
          }`;
        }
      } else {
        return t('cancel-subscription');
      }
    } else {
      // List of subscriptions page
      return t('manage-subscription');
    }
  }, [daysBeforeBillingDate, isActive, nextBillingDate, status]);

  const getPricePerMonthString = () => {
    if (isSubscriptionFree) {
      return t('free');
    }
    if (isTokenGated) {
      return 'NFT-gated';
    }
    if (isManualEnrolment) {
      return t('free-access');
    }

    return pricePerMonthString;
  };

  const getDiscountString = () => {
    if (isFreeTrial) {
      return t('free-trial');
    }
    if (isDiscountedPricing) {
      return t('perc_off', { perc: discountedCommunity?.couponValue });
    }
    return '';
  };

  const shouldShowCancelButton = () => {
    if (isSubscriptionFree) {
      return false;
    }
    if (isTokenGated) {
      return false;
    }
    if (isManualEnrolment) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={`${className} md:max-w-[576px] border-1 border-grey-neutral90 rounded-8 p-16 flex flex-col`}
    >
      <div className="flex flex-row items-center">
        <div className="min-w-[56px] max-w-[56px] h-56">
          <Image
            {...img}
            customImgClassname={
              'rounded-full bg-cover bg-center min-w-[56px] max-w-[56px] h-56'
            }
          />
        </div>
        <div className="ml-16">
          <span className="font-semibold text-label-lg">{name}</span>
        </div>
      </div>
      <div className="flex flex-col mt-20 md:items-center md:flex-row md:justify-between">
        <span className="text-body-sm">
          {isAlreadyCancelled
            ? `${SUBSCRIPTION_END_DATE(t)} ${formatDateInDDMMYYYY(
                nextBillingDate
              )}`
            : nextBillingDate &&
              `${NEXT_BILLING_DATE(t)} ${formatDateInDDMMYYYY(
                nextBillingDate
              )}`}
        </span>
        <span
          className={`font-medium md:justify-self-end text-body-lg ${cancelledSubscriptionDivClass} `}
        >
          {getPricePerMonthString()}
        </span>
      </div>

      {isDiscountedPricing && (
        <div className="flex flex-row items-center justify-between mt-16">
          <span className="font-normal text-body-sm">
            {t('discount-applied')}
          </span>
          <div className="flex flex-row items-center font-normal bg-[#EBFAF0] px-6 py-4 rounded-8 border-1 border-[#B4DFC4]">
            <span className="text-[#18774C] text-label-md font-normal mr-8">
              {discountTagText}
            </span>
            <span className="text-[#B4DFC4] mr-8">|</span>
            <span className="text-[#18774C] text-label-md font-normal ">
              {discountedCommunity?.couponName}
            </span>
          </div>
        </div>
      )}
      {shouldShowCancelButton() && (
        <>
          <hr className="mt-20 border-t-1 border-grey-neutral90" />
          <div className="flex flex-row justify-end mt-16">
            <Button
              displayType={'plain'}
              size="sm"
              onClick={() => onCtaClick(code)}
              disabled={isActive && isAlreadyCancelled}
            >
              <span
                className={`font-medium text-button-xs ${
                  isActive && isAlreadyCancelled ? '' : 'text-bluple-60'
                }`}
              >
                {getCTAText()}
              </span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export const SubscriptionCardDataType = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string,
  img: PropTypes.shape(ImageDataType),
  nextBillingDate: PropTypes.string,
  pricePerMonthString: PropTypes.string,
  onCtaClick: PropTypes.func,
  isActive: PropTypes.bool,
  status: PropTypes.string,
};

SubscriptionCard.propTypes = SubscriptionCardDataType;

SubscriptionCard.defaultProps = {
  isActive: false,
};

export default SubscriptionCard;
