import keys from '../../../config/keys';

const useNewResetPasswordPageRedirection = ({ queryString, newPagePath }) => {
  if (keys.newResetPasswordRedirection) {
    const params = new URLSearchParams(queryString);

    window.location.href = `${
      process.env.REACT_APP_NASIO_BASE_URL
    }${newPagePath}?${params.toString()}`;
    return true;
  }
  return false;
};

export default useNewResetPasswordPageRedirection;
