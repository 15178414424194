import React from 'react';
import CommonSettingWidget from '../CommonSettingWidget';
import ResetPasswordContainer from '../../../../components/ResetPasswordContainer/ResetPasswordContainer';

import './PasswordSettingWidget.scss';
import { useTranslation } from 'react-i18next';

const PasswordSettingWidget = () => {
  const className = 'c-PasswordSettingWidget';

  const { t } = useTranslation();

  const renderMainWidgetContent = () => (
    <ResetPasswordContainer flowType="setupFlow" showLabel={true} />
  );

  const renderPasswordrulesContent = () => {
    return (
      <div
        className={` ${className}__password-rules-main-container bg-[#f2f2f2] p-16 mb-12 `}
      >
        <p className="font-poppins font-normal text-label-sm ">
          {t('your-password-must-be-have-at-least')}{' '}
        </p>
        <ul className={`${className}__password-rules `}>
          <div className={`${className}__password-rules__item`}>
            <div className={`${className}__password-rules__item__icon`}>
              <i className="fas fa-check-circle" />
            </div>
            <div className={`${className}__password-rules__item__text`}>
              <li
                style={{ listStyle: 'inside' }}
                className="font-poppins font-normal text-label-sm "
              >
                {t('8-characters-long')}
              </li>
            </div>
          </div>
          <div className={`${className}__password-rules__item`}>
            <div className={`${className}__password-rules__item__icon`}>
              <i className="fas fa-check-circle" />
            </div>
            <div className={`${className}__password-rules__item__text`}>
              <li
                style={{ listStyle: 'inside', wordBreak: 'break-word' }}
                className="font-poppins font-normal text-label-sm "
              >
                {t('1-lowercase-letter-and-1-uppercase-letter')}
              </li>
            </div>
          </div>
          <div className={`${className}__password-rules__item`}>
            <div className={`${className}__password-rules__item__icon`}>
              <i className="fas fa-check-circle" />
            </div>
            <div className={`${className}__password-rules__item__text`}>
              <li
                style={{ listStyle: 'inside' }}
                className="font-poppins font-normal text-label-sm "
              >
                {t('1-number')}
              </li>
            </div>
          </div>
        </ul>
      </div>
    );
  };
  return (
    <div className={className}>
      <CommonSettingWidget header={t('change-password')}>
        {renderPasswordrulesContent()}
        {renderMainWidgetContent()}
      </CommonSettingWidget>
    </div>
  );
};

export default PasswordSettingWidget;
