import { getAxiosError } from './helper';
import protectedAxiosRequest from './helper/protectedAxiosRequest';

const baseURL = `/api/v1`;

export const updateUserBasicProfile = async (payload) => {
  try {
    const apiPath = `${baseURL}/update-basic-profile`;
    return await protectedAxiosRequest.post(apiPath, payload);
  } catch (error) {
    return getAxiosError(error);
  }
};
