import React, { useCallback, useEffect, useRef, useState } from 'react';
import Auth from '../../../../modules/auth';
import { setUser } from '../../../../actions/user';
import { useDispatch } from 'react-redux';
import { uploadPhoto } from '../../../../services/userProfile';
import CameraIcon from '../../../../components/Icons/CameraIcon';
import './ProfileImageSetting.scss';
import AvatarEditor from 'react-avatar-editor';
import LoadingCircle from '../../../../components/Common/LoadingCircle/LoadingCircle';
import Button from '../../../../components/Button';
import { useTranslation } from 'react-i18next';

const MAX_IMG_SIZE = 3_145_728;
const DEFAULT_IMG =
  'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg';

// TODO move all functions to external hooks

const ProfileImageSetting = (props) => {
  const className = 'c-ProfileImageSetting';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getImage = useCallback(() => {
    const userData = Auth.getUserData();
    if (userData?.learner?.profileImage) {
      const profilePicture = userData?.learner?.profileImage;
      return profilePicture;
    } else if (userData?.profileImage) {
      // we are doing this because in the initial phase the userData stored in the localStorage is different i.e. the profile image is not inside the learner
      const profileImage = userData?.profileImage;
      return profileImage;
    } else {
      return DEFAULT_IMG;
    }
  }, []);

  const imageInputRef = useRef(null);
  const editorRef = useRef(null);
  const [editorShow, setEditorShow] = useState(false);
  const [imageFile, setImageFile] = useState({
    file: null,
    link: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const handleUploadImage = () => {
    if (!!imageFile.file) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async function (blob) {
        const formData = new FormData();
        formData.append('photo', blob);
        setImageUploadLoading(true);

        await uploadPhoto(formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: '*/*',
          },
        })
          .then((doc) => {
            let updatedData = {
              ...props.user,
              profileImage: doc.data?.photoUrl,
            };

            Auth.setUserData(updatedData);
            dispatch(setUser(updatedData));

            setImageFile({
              ...imageFile,
              image: doc?.data?.photoUrl,
            });
            setErrorMessage('');
            setEditorShow(false);
          })
          .catch((err) => {
            setErrorMessage(t('something-went-wrong-try-again'));
          });
        setImageUploadLoading(false);
      });
    } else setErrorMessage(t('choose-a-file-first'));
  };

  const handleChange = (e) => {
    setErrorMessage('');
    if (!!e.target.files[0] && checkImageSize(e.target.files[0])) {
      if (!!e.target.files[0]) {
        let file = e.target.files[0];
        let link = window.URL.createObjectURL(e.target.files[0]);
        setEditorShow(true);
        setImageFile({
          file,
          link,
        });
      }
    }
  };

  const checkImageSize = (file) => {
    setImageFile({ file: null, link: null });
    if (file.size > MAX_IMG_SIZE) {
      setErrorMessage(t('file-size-must-be-less-than-3-megabytes'));
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    setEditorShow(false);
    setImageFile({ file: null, link: null });
    imageInputRef.current.value = '';
  };

  useEffect(() => {
    const imageData = getImage();
    setImageFile({ file: null, link: imageData });
  }, []);

  return (
    <div className={className}>
      <div className={`${className}__img__wrapper`}>
        <div className={`${className}__avatar__upload`}>
          <div className={`${className}__avatar__edit`}>
            <input
              id="imageUpload"
              type="file"
              accept={t('png-jpeg-jpg')}
              ref={imageInputRef}
              onChange={handleChange}
            />
            <label
              htmlFor="imageUpload"
              className={`${className}__avatar__edit__svg__container`}
            >
              <CameraIcon />
            </label>
          </div>
          <div className={`${className}__avatar__preview__container`}>
            <div
              id="imagePreview"
              style={{
                backgroundImage: `url(${getImage()})`,
              }}
              className={`${className}__avatar__preview`}
            />
          </div>
        </div>
        <div
          className={`${className}__avatar__upload__container`}
          style={{ display: editorShow ? 'flex' : 'none', marginTop: '30px' }}
        >
          <p className={`${className}__avatar__upload__label`}>
            {t('drag-your-picture-to-fit-the-viewport')}
          </p>
          <AvatarEditor
            ref={editorRef}
            image={!!imageFile.link ? imageFile.link : ''}
            width={750}
            height={750}
            border={25}
            color={[0, 0, 0, 0.5]}
            scale={1}
            rotate={0}
          />
          {imageUploadLoading ? (
            <LoadingCircle />
          ) : (
            <div className={`${className}__avatar__upload__options`}>
              <Button displayType="secondary" onClick={handleCancel}>
                {t('cancel')}
              </Button>
              <Button displayType="form-primary" onClick={handleUploadImage}>
                {t('save')}
              </Button>
            </div>
          )}
        </div>
        <div>{errorMessage.length ? errorMessage : null}</div>
      </div>
    </div>
  );
};

export default ProfileImageSetting;
