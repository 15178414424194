import React from 'react';
import PropTypes from 'prop-types';

import './PasswordInput.scss';
import Input from '../../../Form/Input';

const className = 'c-ResetPasswordContainer-PasswordInput';

const PasswordInput = ({
  name,
  value,
  placeholder,
  legend,
  onChange,
  showLabel,
  label,
}) => (
  <div className={className}>
    {showLabel && <div className={`${className}__label`}>{label}</div>}
    <div>
      <Input
        onChange={onChange}
        type="password"
        name={name}
        placeholder={placeholder}
        value={value}
        eyeIcon
        required
      />
      {/* {legend && <div className={`${className}__legend`}>{legend}</div>} */}
    </div>
  </div>
);

export const PasswordInputDataStructure = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  legend: PropTypes.string,
};

PasswordInput.propTypes = PasswordInputDataStructure;

export default PasswordInput;
