import React from 'react';

const CalendarDaysIcon = () => {
  return (
    <svg
      className="c-CalendarDaysIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5zm16 0H5v14h14V5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M15 16a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H16a1 1 0 01-1-1V16zM11 16a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H12a1 1 0 01-1-1V16zM7 16a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H8a1 1 0 01-1-1V16zM15 12a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H16a1 1 0 01-1-1V12zM11 12a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H12a1 1 0 01-1-1V12zM7 12a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H8a1 1 0 01-1-1V12zM3 8a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM16 1a1 1 0 011 1v2a1 1 0 11-2 0V2a1 1 0 011-1zM8 1a1 1 0 011 1v2a1 1 0 01-2 0V2a1 1 0 011-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CalendarDaysIcon;
