import {
  useCommunitiesData,
  useCommunityEventsData,
  useLibraryData,
  useMembersData,
  useSearchDataLibrary,
} from '../hooks';

import { HOME_PAGE } from '../constants/pages';
import { createContext } from 'react';
import history from '../../modules/history';
import { storageUtils } from '../../PortalModeWrapper/utils';
import { tags } from '../pages/MembersPage/constants';
import { useCallback } from 'react';
import useCommunitiesBookingData from '../hooks/useCommunitieBookingData';
import useCommunitiesInvoiceData from '../hooks/useCommunitiesInvoiceData';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getCurrentCountry } from '../../services/checkoutServices/communityEventCheckoutServices';

export const CommunityPortalContext = createContext();

export const CommunityPortalContextProvider = ({ children }) => {
  const { folderId } = useMemo(() => {
    const activePagePaths = window.location.pathname.split('/');
    const [
      root,
      community_portal,
      activeCommunityId,
      route,
      folderId,
    ] = activePagePaths;
    return { activeCommunityId, folderId };
  }, [window.location.pathname]);

  const user = useSelector((state) => {
    return state?.auth?.user;
  });
  const [activeCommunity, setActiveCommunity] = useState(null);

  const [activePage, setActivePage] = useState(HOME_PAGE.name);
  // const [activeTag, setActiveTag] = useState(tags?.[0]);
  const [activeTag, setActiveTag] = useState(tags?.[0]);
  const [country, setCountry] = useState(null);
  const [showNavigationSidebar, setShowNavigationSidebar] = useState(true);

  const {
    doesCommunityIdExist,
    activeCommunityData,
    communities,
    loading,
    getCommunitiesData,
  } = useCommunitiesData(activeCommunity);

  const allCommunities = useMemo(() => {
    return communities?.sort((a, b) => {
      if (a.isManager && !b.isManager) return -1;
      if (!a.isManager && b.isManager) return 1;
      return a.name.localeCompare(b.name);
    });
  }, [communities]);

  const {
    data: membersData,
    loading: membersDataLoading,
    pageNumber,
    setNextPage,
    hasMore,
    totalMembers,
  } = useMembersData(
    activeCommunityData?._id,
    activeTag,
    activeCommunityData?.code
  );

  const getCountryData = async () => {
    const { data, error } = await getCurrentCountry();
    if (error) return;
    setCountry(data?.country);
  };

  useEffect(() => {
    if (country) return;
    getCountryData();
  }, []);

  const {
    data: eventsData,
    loading: eventsDataLoading,
    fetchMorePastEvents,
    fetchMoreUpcomingEvents,
    registerForEvent,
    cancelEventRegistration,
  } = useCommunityEventsData(activeCommunityData?._id);

  const {
    loading: bookingLoading,
    data: calendarData,
    getBookingDetails,
    cancelBooking,
  } = useCommunitiesBookingData(
    activeCommunityData?._id,
    activeCommunityData?.bookings
  );
  const {
    dataForLibrary,
    selectedFolderData,
    setSortState,
    selectSortState,
    sortState,
    loading: libraryLoading,
  } = useLibraryData(activeCommunityData?._id, folderId);

  const {
    searchData,
    searchLoading,
    error: searchError,
    showSearchDropDown,
    search,
    setSearch,
    searchDropDownRef,
    onSearchBoxFocus,
    setShowSearchDropDown,
  } = useSearchDataLibrary(activeCommunityData?._id);

  const { invoices, loading: invoiceLoading } = useCommunitiesInvoiceData(
    activeCommunityData?.code
  );

  const updateActiveCommunity = useCallback((id, shouldRedirect = true) => {
    // Safety check to make sure the community exists
    if (id && id !== 'undefined') {
      setActiveCommunity(id);
      storageUtils.storeActiveCommunity(id);
      const activePagePaths = window.location.pathname.split('/');
      const [
        root,
        community_portal,
        activeCommunityId,
        route,
        subroute,
      ] = activePagePaths;

      if (route && shouldRedirect) {
        if (subroute) history.push(`/communities/${id}/${route}/${subroute}`);
        else history.push(`/communities/${id}/${route}`);
      }
    }
  }, []);

  useEffect(() => {
    if (!user?.isUserBasicProfileIncomplete && !communities) {
      getCommunitiesData();
    }
  }, [activeCommunity, communities, getCommunitiesData, user]);

  const value = {
    country,
    // Community Data
    activeCommunity,
    setActiveCommunity,
    doesCommunityIdExist,
    activeCommunityData,
    updateActiveCommunity,
    communities: allCommunities,
    loading,
    getCommunitiesData,

    // Tab data
    activePage,
    setActivePage,
    setShowNavigationSidebar,
    showNavigationSidebar,

    // Members data
    activeTag,
    setActiveTag,
    membersData,
    membersDataLoading,
    pageNumber,
    setNextPage,
    hasMore,
    totalMembers,

    // Events Data
    eventsData,
    eventsDataLoading,
    fetchMorePastEvents,
    fetchMoreUpcomingEvents,
    registerForEvent,
    cancelEventRegistration,

    // Booking Data
    bookingLoading,
    calendarData,
    getBookingDetails,
    cancelBooking,

    // Library Data
    dataForLibrary,
    searchData,
    searchLoading,
    searchError,
    showSearchDropDown,
    setSortState,
    selectSortState,
    sortState,
    search,
    setSearch,
    searchDropDownRef,
    onSearchBoxFocus,
    selectedFolderData,
    setShowSearchDropDown,
    libraryLoading,

    // Invoices Data
    invoices,
    invoiceLoading,
  };

  return (
    <CommunityPortalContext.Provider value={value}>
      {children}
    </CommunityPortalContext.Provider>
  );
};

export const useCommunityPortalContext = () =>
  useContext(CommunityPortalContext);
