import React from 'react';

const ChatIcon = () => {
  return (
    <svg
      className="c-ChatFilledIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M2 10a7 7 0 0113.33-2.992 7 7 0 015.832 10.316l.787 2.36a1 1 0 01-1.265 1.265l-2.36-.787a7 7 0 01-9.653-3.17 6.967 6.967 0 01-2.995-.83l-2.36.787a1 1 0 01-1.265-1.265l.787-2.36A6.973 6.973 0 012 10zm7.329 4.99a5.023 5.023 0 01-3.003-.764 1 1 0 00-.852-.105l-.893.298.298-.893a1 1 0 00-.105-.852 5 5 0 114.554 2.315zm1.5 1.769A4.996 4.996 0 0015 19a4.97 4.97 0 002.674-.774 1 1 0 01.852-.105l.893.298-.297-.893a1 1 0 01.092-.832l.136-.226a5.002 5.002 0 00-3.408-7.38 7.003 7.003 0 01-5.112 7.67z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ChatIcon;
