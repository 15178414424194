import React from 'react';

const CameraIcon = () => {
  const className = 'c-CameraIcon bg-[#E1E2E5]';

  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      className={className}
    >
      <path
        d="M5.75 0.5L4.3775 2H2C1.175 2 0.5 2.675 0.5 3.5V12.5C0.5 13.325 1.175 14 2 14H14C14.825 14 15.5 13.325 15.5 12.5V3.5C15.5 2.675 14.825 2 14 2H11.6225L10.25 0.5H5.75ZM8 11.75C5.93 11.75 4.25 10.07 4.25 8C4.25 5.93 5.93 4.25 8 4.25C10.07 4.25 11.75 5.93 11.75 8C11.75 10.07 10.07 11.75 8 11.75Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default CameraIcon;
