import React, { useEffect, useState } from 'react';
import { EDIT_PROFILE_MODAL } from '../NewProfilePage/constants';
import PropTypes from 'prop-types';
import Modal from '../../../../CommunityPortal/components/Modal';
import Input from '../../../../components/Form/Input';
import './EditProfilePage.scss';
import RoundedButton from '../../../../CommunityPortal/components/RoundedButton';
import Select from '../../../../components/Form/Select';
import { countryList } from '../../../../data/countryList';
import {
  dialCodeOptions,
  getDialCodeIdFromPhoneNumber,
  getPhoneNumberWithoutDial,
  getDialCodeById,
} from '../../../../utility/formHelper';
import NPLAlert from '../../../../CommunityPortal/components/NPLAlert';
import CloseIcon from '../../../../CommunityPortal/components/Icons/CloseIcon';
import { useTranslation } from 'react-i18next';

const className = 'c-EditProfilePage';

const getPhoneNumberWithDialCode = (user) => {
  const dialCode = getDialCodeById(user?.dialCodeId);

  return `${dialCode}${user.phoneNumber}`;
};

const arePhoneNumberEqual = (a, b) =>
  a?.phoneNumber === getPhoneNumberWithDialCode(b);

const EditProfilePage = ({
  showModal,
  setShowModal,
  updateProfile,
  user,
  errorsBasicInfo,
  registerBasicInfo,
  getBasicInfoValues,
  isBasicInfoUpdateLoading,
}) => {
  const { t } = useTranslation();

  const [showPhoneUpdateModal, setShowPhoneUpdateModal] = useState(false);
  const [isPhoneNumberEdited, setIsPhoneNumberEdited] = useState(false);
  const dialCodeValue = getDialCodeIdFromPhoneNumber(user?.phoneNumber) ?? '';
  const phoneNumberWithoutDialCode =
    getPhoneNumberWithoutDial(user?.phoneNumber) ?? '';
  const isEditedPhoneNumberEqual = arePhoneNumberEqual(
    user,
    getBasicInfoValues()
  );

  const [description, setDescription] = useState('');
  const MAX_CHARACTERS = 140;

  const handleDescriptionChange = (event) => {
    const inputText = event.target.value;

    if (inputText.length <= MAX_CHARACTERS) {
      setDescription(inputText);
    }
  };

  useEffect(() => {
    setDescription(user?.description);
  }, [user?.description]);

  return (
    <div>
      {showModal === EDIT_PROFILE_MODAL && (
        <Modal
          open={showModal === EDIT_PROFILE_MODAL}
          onClose={() => setShowModal(null)}
          customContainerClass="!p-0 !rounded-28 !max-w-[640px]"
          customInnerClass="max-h-[80vh] overflow-y-auto"
          customCloseIcon={<CloseIcon width={18} height={18} />}
          customCloseIconClass="!top-[18px] !right-[24px]"
        >
          <div className={className}>
            <div className="border-b-1 border-npl-neutral-light-6">
              <div className="text-heading-xs font-semibold px-24 py-16">
                {t('edit-account-information')}
              </div>
            </div>
            <form className="p-24">
              <div className="space-y-24">
                <Input
                  name="email"
                  label={t('email-address')}
                  defaultValue={user?.email}
                  disabled={true}
                  className="border-npl-neutral-light-3 bg-npl-neutral-light-3 text-npl-text-icon-on-light-surface-tertiary"
                  register={registerBasicInfo}
                />
                <Input
                  name="firstName"
                  label={t('first-name')}
                  defaultValue={user?.firstName ?? ''}
                  error={errorsBasicInfo?.firstName}
                  register={registerBasicInfo}
                />
                <Input
                  name="lastName"
                  label={t('last-name')}
                  defaultValue={user?.lastName ?? ''}
                  error={errorsBasicInfo?.lastName}
                  register={registerBasicInfo}
                />

                <div>
                  <div className="flex space-x-16 items-end">
                    <Select
                      label={t('phone-number')}
                      name="dialCodeId"
                      defaultValue={dialCodeValue}
                      optionsList={dialCodeOptions}
                      register={registerBasicInfo}
                    />
                    <Input
                      name="phoneNumber"
                      defaultValue={phoneNumberWithoutDialCode}
                      register={registerBasicInfo}
                      onChange={() => setIsPhoneNumberEdited(true)}
                    />
                  </div>
                  {errorsBasicInfo?.phoneNumber && (
                    <p className="text-label-sm text-npl-error-light-9">
                      {errorsBasicInfo?.phoneNumber}
                    </p>
                  )}
                  <p className="mt-6 text-body-sm text-npl-text-icon-on-light-surface-secondary">
                    {t(
                      'this-number-will-be-used-for-all-methods-of-communication'
                    )}
                  </p>
                  {isPhoneNumberEdited && (
                    <NPLAlert
                      type="warning"
                      subtitle={t(
                        'note-if-there-is-a-mismatch-between-numbers-you-will-be-removed-from-the-chat-group'
                      )}
                      customClassName="py-12 mt-12"
                    />
                  )}
                </div>

                <Select
                  label={t('country')}
                  name="countryId"
                  error={errorsBasicInfo?.countryId}
                  optionsList={countryList.map((country) => ({
                    label: country.name,
                    value: country.id,
                  }))}
                  defaultValue={user?.countryId}
                  register={registerBasicInfo}
                />
                <div className={`${className}__bio`}>
                  <div className="flex items-center space-x-8 mb-[6px]">
                    <p className="text-label-md text-npl-text-icon-on-light-surface-primary font-medium">
                      {t('bio')}
                    </p>
                    <span className="text-label-sm text-npl-text-icon-on-light-surface-secondary">
                      (Optional)
                    </span>
                  </div>
                  <div className="flex flex-col space-y-8">
                    <textarea
                      name="description"
                      className="text-body-md w-full rounded-12 border-npl-neutral-light-6 border-1 px-16 py-12"
                      ref={registerBasicInfo}
                      defaultValue={user?.description ?? ''}
                      onChange={handleDescriptionChange}
                      value={description}
                      error={errorsBasicInfo?.description}
                    ></textarea>
                    <p className="self-end text-label-md">
                      {description?.length}/{MAX_CHARACTERS}
                    </p>
                    {errorsBasicInfo?.phoneNumber && (
                      <p className="text-label-sm text-npl-error-light-9">
                        {errorsBasicInfo?.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="border-t-1 border-npl-neutral-light-6">
              <div className="text-heading-xs font-semibold px-24 py-16 flex items-center justify-end space-x-12">
                <RoundedButton
                  displayType="outline"
                  onClick={() => setShowModal(null)}
                >
                  {t('cancel')}
                </RoundedButton>
                <RoundedButton
                  displayType="primary"
                  onClick={async () => {
                    if (!isEditedPhoneNumberEqual) {
                      setShowPhoneUpdateModal(true);
                      return;
                    }

                    await updateProfile();
                    setShowModal(null);
                    setIsPhoneNumberEdited(false);
                  }}
                  isLoading={isBasicInfoUpdateLoading}
                >
                  {t('save')}
                </RoundedButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showPhoneUpdateModal && (
        <Modal
          open={showPhoneUpdateModal}
          onClose={() => setShowPhoneUpdateModal(false)}
          customContainerClass="!rounded-28 !max-w-[500px]"
          showCloseIcon={false}
        >
          <div className="font-poppins">
            <p className="text-heading-md font-semibold text-npl-text-icon-on-light-surface-primary mb-8">
              {t(
                'are-you-sure-you-want-to-change-your-phone-number-to-phone-number',
                {
                  phoneNumber: getPhoneNumberWithDialCode(getBasicInfoValues()),
                }
              )}
            </p>
            <p className="text-body-md text-npl-text-icon-on-light-surface-secondary mb-24">
              {t(
                'if-there-is-a-mismatch-between-numbers-you-will-be-removed-from-the-chat-group'
              )}
            </p>
            <div className="flex space-x-12 justify-end">
              <RoundedButton
                displayType="outline"
                onClick={() => setShowPhoneUpdateModal(false)}
              >
                {t('no')}
              </RoundedButton>
              <RoundedButton
                displayType="primary"
                onClick={async () => {
                  await updateProfile();
                  setShowModal(null);
                  setShowPhoneUpdateModal(false);
                  setIsPhoneNumberEdited(false);
                }}
                isLoading={isBasicInfoUpdateLoading}
              >
                {t('yes')}
              </RoundedButton>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

EditProfilePage.propTypes = {
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  inputProps: PropTypes.object,
  updateProfile: PropTypes.func,
  user: PropTypes.object,
};
export default EditProfilePage;
