import CalendarIconV2 from '../../components/Icons/CalendarIconV2';
import GiftIconV2 from '../../components/Icons/GiftIconV2';
import ProfileIconLight from '../../components/Icons/ProfileIconLight';
import SettingIcon from '../../components/Icons/SettingIcon';
import i18n from '../../i18n';

const { t } = i18n;

export const PROFILE_TAB_KEY = 'profileTab';
export const SOCIAL_MEDIA_TAB_KEY = 'socialMediaTab';
export const VIDEO_TAB_KEY = 'videoTab';
export const COMMENT_TAB_KEY = 'commentTab';
export const PASSWORD_TAB_KEY = 'passwordTab';
export const REFERRAL_TAB_KEY = 'referralTab';

function ReferralStyle({ t }) {
  return (
    <>
      {' '}
      <p className="mb-12 text-black text-heading-md ">{t('referral')}</p>
      <hr />
    </>
  );
}

function TextforReferral({ t }) {
  return (
    <p className="mb-12 " style={{ marginBottom: '12px' }}>
      {t('refer-a-friend')}
    </p>
  );
}

function TagLineForReferral({ t }) {
  return (
    <>
      <p
        className="mt-12 mb-12 font-normal text-label-lg font-poppins"
        style={{ marginBottom: '12px', marginTop: '12px' }}
      >
        {t(
          'share-this-code-you-and-your-friend-will-get-20-benefits-on-the-class'
        )}{' '}
        <br />
        fees.
      </p>
    </>
  );
}

function ShareCTALabelforrefferral({ t }) {
  return (
    <>
      <p>{t('share')}</p>
    </>
  );
}
export const referralWidgetStaticProps = (t) => ({
  header: <ReferralStyle t={t} />,
  text: [
    {
      text: <TextforReferral t={t} />,
      isNewLine: true,
      isBolder: true,
    },
    {
      text: <TagLineForReferral t={t} />,
    },
    // {
    //   text: 'See how referral works.',
    //   isAnchorText: true,
    //   isNewLine: true,
    //   link: 'https://nas.academy/refer-a-friend',
    // },
  ],
  redeemBox: {
    title: t('referral-credits'),
    ctaLabel: t('cash-out'),
    note: [
      { text: '*', isBolder: true },
      { text: t('minimum-50-usd-referral-credits-required-to-cash-out') },
    ],
    noCreditText: t('make-a-referral-to-start-earning-credits'),
  },
  shareCTALabel: <ShareCTALabelforrefferral t={t} />,
});

export const REDEEM_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSctIEj5N2HrZSDhLNfOIm7TkC9M_rCOj2llOP2YS8ZVFjAXWg/viewform';

export const SETTINGS_PAGE_REFERRAL_ENDPOINT = 'referral';
export const SETTINGS_PAGE_PROFILE_ENDPOINT = 'profile';
export const SETTINGS_PAGE_MEMBERSHIP_ENDPOINT = 'membership';
export const SETTINGS_PAGE_PREFERENCES_ENDPOINT = 'preferences';

export const SETTINGS_PAGE_REFERRAL_PATH = '/setting/referral';
export const SETTINGS_PAGE_PROFILE_PATH = '/setting/profile';
export const SETTINGS_PAGE_MEMBERSHIP_PATH = '/setting/membership';
export const SETTINGS_PAGE_PREFERENCES_PATH = '/setting/preferences';

export const settingsPagePaths = [
  SETTINGS_PAGE_PROFILE_PATH,
  SETTINGS_PAGE_MEMBERSHIP_PATH,
  SETTINGS_PAGE_PREFERENCES_PATH,
];

export const SETTINGS_PAGE_REFERRAL = {
  name: 'SETTINGS_PAGE_REFERRAL',
  label: t('referral'),
  labelKey: 'referral',
  Icon: GiftIconV2,
  endpoint: SETTINGS_PAGE_REFERRAL_ENDPOINT,
};

export const SETTINGS_PAGE_PROFILE = {
  name: 'SETTINGS_PAGE_PROFILE',
  label: t('profile'),
  labelKey: 'profile',
  Icon: ProfileIconLight,
  endpoint: SETTINGS_PAGE_PROFILE_ENDPOINT,
};

export const SETTINGS_PAGE_MEMBERSHIP = {
  name: 'SETTINGS_PAGE_MEMBERSHIP',
  label: t('membership-and-billing'),
  labelKey: 'membership-and-billing',
  Icon: CalendarIconV2,
  endpoint: SETTINGS_PAGE_MEMBERSHIP_ENDPOINT,
};

export const SETTINGS_PAGE_PREFERENCES = {
  name: 'SETTINGS_PAGE_PREFERENCES',
  label: t('preferences'),
  labelKey: 'preferences',
  Icon: SettingIcon,
  endpoint: SETTINGS_PAGE_PREFERENCES_ENDPOINT,
};

export const pagesList = [SETTINGS_PAGE_PROFILE, SETTINGS_PAGE_MEMBERSHIP];
