import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLibrarySingleFolderData } from '../../CommunityPortal/apiServices/libraryService';
import { useCommunityPortalContext } from '../../CommunityPortal/contexts/CommunityPortalContext';
import { COUNTRY_CHECKOUT_TYPE } from '../../hooks/payment/usePayment';
import { STORED_ACTIVE_COMMUNITY_SESSION_CODE } from '../../PortalModeWrapper/constants';
import {
  getCommunityInfoForCheckout,
  getPaymentMethods,
} from '../../services/checkoutServices/communityEventCheckoutServices';
import { getQueryParams } from '../../utility/domHelpers';
import sessionStorageService from '../../utility/sessionStorageService';
import { redirectToUnifiedCheckout } from '../../utility/checkoutHelper';
export const LibraryCheckoutContext = createContext();
export const LibraryCheckoutContextProvider = ({ children }) => {
  const [checkoutFolderInfo, setCheckoutFolderInfo] = useState('');
  const [communityInfo, setCommunityInfo] = useState();
  const userData = useSelector((state) => state?.user?.user);
  const [folderCommunityInfoLoader, setFolderCommunityInfoLoader] = useState(
    true
  );

  const queryParams = getQueryParams();
  const [fetchedPaymentMethods, setFetchedPaymentMethods] = useState(null);
  const { country } = useCommunityPortalContext();

  // This deterimines what payment methods gets shown. The list of methods allowed in country is returned from BE.
  const getPaymentMethodsHandler = async (communityId) => {
    const { data, error } = await getPaymentMethods({
      country,
      communityId,
      checkoutType: COUNTRY_CHECKOUT_TYPE.ONETIME,
    });
    if (error) return;

    setFetchedPaymentMethods(data?.paymentModesAllowed);
  };

  const getLibraryFolderInformation = async (folderId, communityObjectId) => {
    try {
      const { data, error } = await getLibrarySingleFolderData(
        communityObjectId,
        folderId
      );
      if (error) {
        throw new Error(error);
      }

      if (data) {
        setCheckoutFolderInfo(data);
        setFolderCommunityInfoLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCommunityInfo = async (communityObjectId) => {
    try {
      const { data, error } = await getCommunityInfoForCheckout(
        communityObjectId
      );

      if (data) {
        setCommunityInfo(data);
      }
      if (error) {
        throw new Error(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (queryParams) {
      const { entityId, communityObjectId } = queryParams;

      if (entityId && communityObjectId) {
        getLibraryFolderInformation(entityId, communityObjectId);
        getCommunityInfo(communityObjectId);

        sessionStorageService.setItem(
          STORED_ACTIVE_COMMUNITY_SESSION_CODE,
          communityObjectId
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (queryParams && country && userData) {
      const { communityObjectId } = queryParams;
      getPaymentMethodsHandler(communityObjectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, userData]);

  useEffect(() => {
    if (userData && communityInfo && checkoutFolderInfo) {
      redirectToUnifiedCheckout({
        communityInfo,
        entityType: 'folder',
        entityInfo: checkoutFolderInfo,
      });
    }
  }, [checkoutFolderInfo, communityInfo, userData]);

  const value = {
    checkoutFolderInfo,
    setCheckoutFolderInfo,
    communityInfo,
    setCommunityInfo,
    folderCommunityInfoLoader,
    fetchedPaymentMethods,
    country,
  };

  return (
    <LibraryCheckoutContext.Provider value={value}>
      {children}
    </LibraryCheckoutContext.Provider>
  );
};

export const useLibraryCheckoutContext = () =>
  useContext(LibraryCheckoutContext);
