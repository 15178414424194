import React from 'react';

const className = 'c-WebsiteIconV2';
const WebsiteIconV2 = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <path
        d="M12.3203 21.4836C13.6968 21.2881 14.9034 19.8514 15.6628 17.7298C14.5651 17.484 13.445 17.352 12.3203 17.3363V21.4836Z"
        fill="#222222"
      />
      <path
        d="M14.5201 21.146C14.6014 21.1224 14.6825 21.0987 14.7629 21.0726C14.8308 21.0508 14.8977 21.0273 14.9646 21.0038C15.0438 20.9765 15.1226 20.9484 15.2012 20.9186C15.2681 20.8931 15.3344 20.8661 15.4008 20.8391C15.4775 20.8069 15.554 20.7748 15.6297 20.7426C15.6958 20.7136 15.7615 20.6831 15.8271 20.6525C15.9014 20.6174 15.9754 20.5818 16.0488 20.5447C16.1132 20.5119 16.1774 20.4784 16.2418 20.444C16.3139 20.4054 16.3855 20.366 16.4567 20.3254C16.5211 20.289 16.5831 20.2524 16.6458 20.2147C16.7155 20.1718 16.785 20.1289 16.8539 20.086C16.9157 20.0468 16.9772 20.0069 17.038 19.9657C17.1059 19.92 17.1728 19.8724 17.2394 19.8248C17.2992 19.7819 17.3588 19.7392 17.4172 19.6962C17.4816 19.6465 17.5476 19.5952 17.6121 19.5437C17.6695 19.4979 17.7267 19.4526 17.783 19.4056C17.8473 19.3522 17.9089 19.2969 17.9712 19.2416C18.0259 19.1931 18.0809 19.145 18.1343 19.0952C18.1957 19.0379 18.2555 18.9785 18.3154 18.9196C18.3672 18.8684 18.4197 18.818 18.4704 18.7655C18.4798 18.7558 18.4888 18.7452 18.4985 18.7352C17.7969 18.3728 17.0578 18.0883 16.2943 17.887C15.8972 19.1533 15.1905 20.3009 14.2383 21.2255C14.2644 21.2187 14.2904 21.2132 14.3165 21.2065C14.3853 21.1872 14.4528 21.166 14.5201 21.146Z"
        fill="#222222"
      />
      <path
        d="M20.9989 12.8232H17.1417C17.1271 14.3293 16.9086 15.8264 16.4922 17.2739C17.3413 17.5007 18.1608 17.8268 18.9336 18.2454C20.2025 16.7161 20.9288 14.8093 20.9989 12.8232Z"
        fill="#222222"
      />
      <path
        d="M12.3203 12.1797H16.4981C16.4818 10.7269 16.2686 9.28308 15.8648 7.88745C14.7014 8.15275 13.5133 8.29444 12.3203 8.31015V12.1797Z"
        fill="#222222"
      />
      <path
        d="M12.3203 3.51953V7.66684C13.445 7.65098 14.5651 7.51919 15.6628 7.27336C14.9034 5.15172 13.6968 3.71509 12.3203 3.51953Z"
        fill="#222222"
      />
      <path
        d="M12.3203 16.6928C13.5133 16.7087 14.7014 16.8504 15.8648 17.1157C16.2686 15.7201 16.4818 14.276 16.4981 12.8232H12.3203V16.6928Z"
        fill="#222222"
      />
      <path
        d="M18.9336 6.75781C18.1608 7.17642 17.3413 7.50252 16.4922 7.72934C16.9086 9.1768 17.1271 10.6739 17.1417 12.18H20.9989C20.9287 10.194 20.2024 8.28727 18.9336 6.75781Z"
        fill="#222222"
      />
      <path
        d="M18.501 6.27077C18.4916 6.26119 18.4827 6.25082 18.4734 6.24092C18.4225 6.18846 18.3697 6.13788 18.3179 6.08683C18.2582 6.02793 18.1987 5.96839 18.1371 5.91122C18.0838 5.86158 18.0287 5.81462 17.9741 5.76514C17.9117 5.70984 17.8493 5.65408 17.7852 5.60068C17.7296 5.55371 17.6726 5.50879 17.6161 5.4637C17.5517 5.41156 17.4857 5.35972 17.4194 5.30993C17.3613 5.2661 17.3024 5.22369 17.2432 5.18128C17.1759 5.1329 17.1084 5.08468 17.0399 5.03865C16.9798 4.99813 16.9186 4.95854 16.8575 4.91975C16.788 4.87498 16.7185 4.83115 16.6477 4.78874C16.5856 4.75152 16.523 4.71476 16.4601 4.6791C16.3882 4.63795 16.3161 4.59805 16.2434 4.5591C16.179 4.5269 16.1147 4.49187 16.0503 4.45967C15.9763 4.42228 15.9017 4.38631 15.8252 4.35097C15.7608 4.32034 15.6955 4.29049 15.6292 4.26144C15.553 4.22766 15.4761 4.19719 15.3992 4.16499C15.333 4.13797 15.267 4.11127 15.2001 4.08582C15.122 4.05598 15.0428 4.02802 14.9637 4.00069C14.8967 3.97713 14.8298 3.95372 14.762 3.93173C14.6816 3.90566 14.6011 3.88194 14.5185 3.85806C14.4516 3.83843 14.3844 3.81848 14.3167 3.80057C14.2907 3.7935 14.2644 3.788 14.2383 3.78125C15.1905 4.70581 15.897 5.85326 16.2943 7.11977C17.0586 6.9184 17.7988 6.63377 18.501 6.27077Z"
        fill="#222222"
      />
      <path
        d="M3 12.18H6.85719C6.8718 10.6739 7.09029 9.1768 7.50655 7.72934C6.65754 7.50267 5.83807 7.17658 5.06525 6.75781C3.79638 8.28712 3.07006 10.194 3 12.18Z"
        fill="#222222"
      />
      <path
        d="M11.6784 21.4836V17.3363C10.5537 17.3522 9.43359 17.484 8.33594 17.7298C9.09509 19.8514 10.3019 21.2881 11.6784 21.4836Z"
        fill="#222222"
      />
      <path
        d="M11.6778 12.8232H7.5C7.51634 14.276 7.72933 15.7201 8.13334 17.1157C9.29665 16.8502 10.4846 16.7085 11.6778 16.6928V12.8232Z"
        fill="#222222"
      />
      <path
        d="M11.6784 3.51953C10.3019 3.71509 9.09509 5.15172 8.33594 7.27336C9.43359 7.51935 10.5537 7.65113 11.6784 7.66684V3.51953Z"
        fill="#222222"
      />
      <path
        d="M11.6778 8.31015C10.4848 8.29444 9.29665 8.15275 8.13334 7.88745C7.72933 9.28308 7.51634 10.7269 7.5 12.1797H11.6778V8.31015Z"
        fill="#222222"
      />
      <path
        d="M9.75931 3.78125C9.73323 3.788 9.70716 3.7935 9.68108 3.80026C9.61291 3.81848 9.54568 3.83874 9.47782 3.85837C9.39709 3.88194 9.31698 3.90566 9.23593 3.93142C9.1676 3.95356 9.10037 3.97713 9.03314 4.00053C8.95444 4.02833 8.87559 4.05598 8.79768 4.08551C8.73045 4.11127 8.66416 4.13797 8.59756 4.1653C8.52107 4.1975 8.44441 4.22971 8.36886 4.26175C8.30257 4.29081 8.23707 4.32128 8.17141 4.35191C8.09712 4.38694 8.02313 4.4226 7.94978 4.45967C7.88538 4.4925 7.82097 4.52611 7.75673 4.56035C7.68463 4.59899 7.61316 4.63858 7.54216 4.6791C7.47776 4.71507 7.41446 4.75199 7.35241 4.78969C7.28251 4.83178 7.2134 4.87529 7.1446 4.91833C7.08271 4.95792 7.02129 4.99781 6.9605 5.03897C6.89265 5.08468 6.82573 5.13227 6.75913 5.17987C6.69929 5.22275 6.63975 5.26547 6.58116 5.30867C6.51535 5.35815 6.45252 5.40967 6.38623 5.46103C6.32905 5.50674 6.27141 5.55214 6.21549 5.59942C6.15109 5.65251 6.08998 5.70749 6.02794 5.76247C5.97296 5.81147 5.91751 5.85907 5.86379 5.9098C5.80269 5.96682 5.74316 6.02604 5.68347 6.08479C5.63163 6.136 5.57885 6.18657 5.52796 6.2392C5.51869 6.24893 5.50974 6.25962 5.5 6.26951C6.20151 6.63205 6.94071 6.91651 7.70427 7.11789C8.10136 5.85216 8.80758 4.70534 9.75931 3.78125Z"
        fill="#222222"
      />
      <path
        d="M5.68439 18.919C5.74423 18.9779 5.80377 19.0375 5.86518 19.0948C5.91859 19.1443 5.97357 19.1912 6.02839 19.2407C6.09075 19.2962 6.15311 19.3518 6.21719 19.4052C6.2728 19.4518 6.3295 19.4969 6.38637 19.5422C6.45077 19.5943 6.51501 19.6461 6.58318 19.6962C6.64115 19.7398 6.70005 19.7819 6.75895 19.8251C6.82618 19.8733 6.89404 19.9215 6.96253 19.9678C7.02269 20.0084 7.08379 20.0479 7.14489 20.0869C7.21448 20.1315 7.28391 20.1753 7.35475 20.2177C7.41679 20.2551 7.47947 20.2917 7.5423 20.3275C7.61424 20.3687 7.68634 20.4086 7.75906 20.4474C7.82331 20.4796 7.88771 20.5146 7.95211 20.5468C8.0261 20.5842 8.10071 20.6202 8.1772 20.6557C8.24161 20.686 8.30695 20.7158 8.37324 20.7451C8.44942 20.7788 8.52623 20.8093 8.6032 20.8415C8.66948 20.8685 8.73546 20.8952 8.80237 20.9207C8.88044 20.9507 8.95961 20.9786 9.03877 21.006C9.10569 21.0294 9.1726 21.0529 9.24046 21.0748C9.32088 21.1008 9.40131 21.1247 9.48393 21.1484C9.55084 21.1681 9.61807 21.188 9.68562 21.2061C9.71169 21.2131 9.73808 21.2186 9.76416 21.2254C8.81195 20.3007 8.10542 19.1532 7.70817 17.8867C6.94462 18.0882 6.20541 18.3727 5.50391 18.7354C5.51333 18.745 5.52228 18.7554 5.53171 18.7653C5.57993 18.8174 5.63192 18.868 5.68439 18.919Z"
        fill="#222222"
      />
      <path
        d="M5.06525 18.2454C5.83807 17.8268 6.65754 17.5007 7.50655 17.2739C7.09029 15.8264 6.8718 14.3293 6.85719 12.8232H3C3.07021 14.8092 3.79638 16.7159 5.06525 18.2454Z"
        fill="#222222"
      />
    </svg>
  );
};

export default WebsiteIconV2;
