import React from 'react';
import SingleSearchResultContainer from '../../CommunityPortal/pages/LibraryPage/components/SingleSearchResultContainer';

const SearchBoxDropdown = ({ searchData, setShowSearchDropDown }) => {
  return (
    <div className="relative w-full">
      <div className="absolute w-full hover:cursor-pointer bg-white shadow-md rounded-b-8  md:max-h-[484px] overflow-scroll z-20">
        {searchData?.map((data, idx) => {
          if (data?.folderType) return null;

          return (
            <SingleSearchResultContainer
              setShowSearchDropDown={setShowSearchDropDown}
              searchData={data}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SearchBoxDropdown;
