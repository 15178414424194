import {
  CHECKBOX_FIELD_TYPE,
  DROPDOWN_FIELD_TYPE,
  INPUT_FIELD_TYPE,
  TEXTAREA_FIELD_TYPE,
} from '../../../utility/formHelper';

import FloatingLabelInput from '../FloatingLabelInput';
import Input from '../Input';
import { PropTypes } from 'prop-types';
import React from 'react';
import Select from '../Select';

const FormField = (props) => {
  const {
    fieldType,
    name,
    label,
    prefix,
    placeholder,
    value,
    error,
    onChange,
    optionsList,
    ...rest
  } = props;
  const className = 'c-FormField';

  const renderField = () => {
    switch (fieldType) {
      case INPUT_FIELD_TYPE: {
        if (rest?.isFloating) {
          return (
            <FloatingLabelInput
              name={name}
              label={label}
              placeholder={placeholder}
              onChange={onChange}
              error={error}
              value={value}
              {...rest}
            />
          );
        }
        return (
          <Input
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            error={error}
            onChange={onChange}
            prefix={prefix}
            v2
            {...rest}
          />
        );
      }
      case DROPDOWN_FIELD_TYPE: {
        return (
          <Select
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            error={error}
            optionsList={optionsList}
            onChange={onChange}
            v2
            {...rest}
          />
        );
      }
      default:
        return null;
    }
  };

  return <div className={className}>{renderField()}</div>;
};

FormField.defaultProps = {
  placeholder: '',
  optionsList: [],
  error: '',
};

FormField.propTypes = {
  fieldType: PropTypes.oneOf([
    DROPDOWN_FIELD_TYPE,
    INPUT_FIELD_TYPE,
    CHECKBOX_FIELD_TYPE,
    TEXTAREA_FIELD_TYPE,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  error: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default FormField;
