import React from 'react';
import PropTypes from 'prop-types';

const SettingIcon = ({
  width,
  height,
  displayType,
  fillColor,
  strokeColor,
}) => {
  const className = 'c-SettingIcon';

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      className={className}
    >
      <path
        d="M13.7832 9.70654C13.8132 9.45654 13.8331 9.19654 13.8331 8.92654C13.8331 8.65654 13.8132 8.40654 13.7832 8.14654L15.4632 6.82654C15.6132 6.70654 15.6531 6.49654 15.5531 6.31654L13.9532 3.55654C13.8632 3.38654 13.6431 3.31654 13.4731 3.38654L11.4831 4.18654C11.0731 3.86654 10.6232 3.60654 10.1332 3.40654L9.83315 1.28654C9.81315 1.09654 9.64315 0.956543 9.44315 0.956543H6.24315C6.04315 0.956543 5.88315 1.09654 5.85315 1.28654L5.55315 3.40654C5.07315 3.60654 4.62315 3.87654 4.20315 4.18654L2.21315 3.38654C2.03315 3.31654 1.82315 3.38654 1.73315 3.55654L0.133148 6.31654C0.033148 6.48654 0.0831482 6.70654 0.223148 6.82654L1.90315 8.14654C1.87315 8.39654 1.85315 8.66654 1.85315 8.92654C1.85315 9.18654 1.87315 9.44654 1.90315 9.70654L0.223148 11.0265C0.0731482 11.1465 0.033148 11.3565 0.133148 11.5365L1.73315 14.2965C1.82315 14.4665 2.04315 14.5365 2.21315 14.4665L4.20315 13.6665C4.61315 13.9865 5.06315 14.2465 5.55315 14.4465L5.85315 16.5665C5.87315 16.7565 6.04315 16.8965 6.24315 16.8965H9.44315C9.64315 16.8965 9.80315 16.7565 9.83315 16.5665L10.1332 14.4465C10.6132 14.2465 11.0631 13.9765 11.4831 13.6665L13.4731 14.4665C13.6531 14.5365 13.8632 14.4665 13.9532 14.2965L15.5531 11.5365C15.6431 11.3665 15.6032 11.1465 15.4632 11.0265L13.7832 9.70654ZM7.84315 11.7165C6.30315 11.7165 5.04315 10.4665 5.04315 8.91654C5.04315 7.37654 6.29315 6.11654 7.84315 6.11654C9.38315 6.11654 10.6431 7.36654 10.6431 8.91654C10.6431 10.4565 9.39315 11.7165 7.84315 11.7165Z"
        fill={displayType === 'outline' ? '#AAABAE' : fillColor}
      />
    </svg>
  );
};

SettingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  displayType: PropTypes.oneOf(['outline', 'filled']),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

SettingIcon.defaultProps = {
  width: '18',
  height: '19',
  fillColor: '#FBC91B',
  strokeColor: '#AAABAE',
};
export default SettingIcon;
