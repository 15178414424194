import React from 'react';

const HouseIcon = () => {
  return (
    <svg
      className="c-HouseIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#1B1B18"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.659 9.701l-7-6.125a1 1 0 00-1.318 0l-7 6.125a1 1 0 00-.341.753V19a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h4a1 1 0 001-1v-8.546a1 1 0 00-.341-.753z"
      ></path>
    </svg>
  );
};

export default HouseIcon;
