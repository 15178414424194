import React from 'react';

function YoutubeGreyIcon({ customClassName }) {
  return (
    <svg
      viewBox="0 0 37 30"
      fill="none"
      className={'c-YoutubeGreyIcon ' + customClassName}
    >
      <path
        d="M33.3407 7.59895C32.9719 6.19165 31.8911 5.08229 30.5204 4.70335C28.0163 4 17.9996 4 17.9996 4C17.9996 4 7.98341 4 5.4793 4.67668C4.13496 5.05522 3.02774 6.19185 2.65899 7.59895C2 10.1693 2 15.5 2 15.5C2 15.5 2 20.8575 2.65899 23.401C3.02813 24.8081 4.1086 25.9175 5.4795 26.2964C8.00978 27 18 27 18 27C18 27 28.0163 27 30.5204 26.3233C31.8913 25.9446 32.9719 24.8352 33.3411 23.4281C33.9999 20.8575 33.9999 15.5271 33.9999 15.5271C33.9999 15.5271 34.0262 10.1693 33.3407 7.59895ZM14.8106 20.4246V10.5754L23.1399 15.5L14.8106 20.4246Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
  );
}

export default YoutubeGreyIcon;
