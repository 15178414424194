import React from 'react';

const className = 'c-TwitterIconV2Colored';
const TwitterIconV2Colored = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <path
        d="M8.84156 21.5C6.41531 21.5 4.15363 20.7943 2.25 19.5767C3.86624 19.6813 6.71855 19.4308 8.49268 17.7386C5.82381 17.6161 4.6202 15.5692 4.4632 14.6945C4.68997 14.7819 5.77148 14.8869 6.382 14.642C3.31192 13.8722 2.84095 11.178 2.94561 10.3557C3.52125 10.7581 4.49809 10.8981 4.88185 10.8631C2.02109 8.81618 3.05027 5.73707 3.55613 5.07226C5.60912 7.9165 8.6859 9.51393 12.4923 9.60278C12.4205 9.28802 12.3826 8.96032 12.3826 8.62373C12.3826 6.20819 14.3351 4.25 16.7435 4.25C18.0019 4.25 19.1358 4.78457 19.9318 5.63963C20.7727 5.44258 22.0382 4.9813 22.6569 4.5824C22.3451 5.70208 21.3742 6.63612 20.7869 6.98231C20.7918 6.99408 20.7821 6.97048 20.7869 6.98231C21.3028 6.90428 22.6986 6.63603 23.25 6.26192C22.9773 6.89094 21.948 7.9368 21.1033 8.52232C21.2605 15.4535 15.9574 21.5 8.84156 21.5Z"
        fill="#1DA1F2"
      />
    </svg>
  );
};

export default TwitterIconV2Colored;
