import React, { useRef } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from '@react-oauth/google';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { startGetTokens, startLoginCheck } from '../../actions/auth';
import AppleSignin from 'react-apple-signin-auth';
import config from '../../modules/config';
import Button from '../Button';
import './SocialLogins.scss';
import DiscordLogin from '../DiscordLogin';
import { setLoginFailed } from '../../actions/main';
import Auth from '../../modules/auth';
import { useTranslation } from 'react-i18next';

const { googleClientId, fbAppId, appleClientId, appleRedirectLink } = config;

const SocialLogins = ({ isSignup, history, isLoginForm = false }) => {
  const className = 'c-SocialLogins';

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isWebView = useSelector((state) => state.main.isWebView);

  const showGoogleLoginCta = !isWebView && googleClientId;

  const showAppleLoginCta = !isWebView && appleClientId;
  const ref = useRef();

  const handleSocialAuth = (response, provider) => {
    let token = '';
    let code = '';
    let appleUserData = null;
    let payload = {};

    switch (provider) {
      case 'facebook':
        token = response?.accessToken;
        payload = { authToken: token, provider };
        break;
      case 'google':
        token = response?.credential;
        payload = { authToken: token, provider };
        break;
      case 'apple':
        code = response?.authorization?.code;
        token = response?.authorization?.id_token;
        appleUserData = response?.user;
        payload = { code, provider, appleUserData };
        break;
      case 'discord':
        token = response?.token;
        payload = { authToken: token, provider };
        break;

      default:
    }
    //For Discord login
    if (payload.provider === 'discord') {
      const { authToken, refresh_token } = payload;

      Auth.setTokens(authToken, refresh_token);
      Auth.setCookies(authToken, refresh_token);
      dispatch(
        startLoginCheck(authToken, refresh_token, history.location.pathname)
      );
      history.replace({
        pathname: history.location.pathname,
        hash: '',
      });
      return;
    }

    if (token) {
      dispatch(startGetTokens(payload, history.location.pathname));
    }
  };

  const handleLoginFailed = (error) => {
    dispatch(setLoginFailed(error));
    history.replace({
      pathname: history.location.pathname,
      hash: '',
    });
  };
  const socialBtnClass = `${className}__social-btn-wrapper`;
  const googleBtnClass = `${socialBtnClass} ${socialBtnClass}--google`;
  const appleBtnClass = `${socialBtnClass} ${socialBtnClass}--apple`;
  const fbBtnClass = `${socialBtnClass} ${socialBtnClass}--facebook`;

  if (isWebView) return null;

  return (
    <div className={`${className} flex flex-col mx-32`}>
      {/* Google Login Button */}
      {showGoogleLoginCta && (
        <div className={googleBtnClass}>
          <GoogleLogin
            onSuccess={(res) => {
              console.log(res);
              handleSocialAuth(res, 'google');
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            useOneTap={true}
            size="large"
            text="continue_with"
          />
        </div>
      )}

      {/* Facebook Login Btn */}
      {fbAppId && (
        <FacebookLogin
          appId={fbAppId}
          fields="name,first_name,last_name,email,picture.type(large)"
          autoLoad={false}
          disableMobileRedirect={true}
          callback={(res) => handleSocialAuth(res, 'facebook')}
          render={(renderProps) => {
            const { onClick } = renderProps;
            return (
              <div className={fbBtnClass} ref={ref}>
                <Button fluid onClick={onClick} customClassNames={''}>
                  {isSignup
                    ? t('sign-up-with-facebook')
                    : t('continue-with-facebook')}
                </Button>
              </div>
            );
          }}
        />
      )}
      {/* Apple login button */}
      {showAppleLoginCta && (
        <AppleSignin
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            clientId: appleClientId,
            scope: 'email name ',
            redirectURI: appleRedirectLink,
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
          uiType="light"
          render={(props) => (
            <div className={appleBtnClass}>
              <Button fluid customClassNames={''} {...props}>
                {isSignup ? t('sign-up-with-apple') : t('continue-with-apple')}
              </Button>
            </div>
          )}
          onError={(res) => handleSocialAuth(res, 'apple')}
          onSuccess={(res) => handleSocialAuth(res, 'apple')}
        />
      )}

      {/* Discord Login button, there will only be a login for discord, no sign up*/}
      {!isSignup && (
        <DiscordLogin
          onSuccessHandler={(e) => handleSocialAuth(e, 'discord')}
          onFailureHandler={(e) => handleLoginFailed(e)}
          text={t('continue-with-discord')}
          isLoginForm={isLoginForm}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginFailed: state.main.loginFailed,
});

// export default SocialLogins;
export default connect(mapStateToProps)(withRouter(SocialLogins));
