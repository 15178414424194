import React from 'react';

const CheckWithCircleIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="9.99951" r="10" fill="#FFEFC8" />
      <path
        d="M8.08366 13.1874L5.54045 10.6442C5.4078 10.5119 5.33325 10.3322 5.33325 10.1448C5.33325 9.9574 5.4078 9.77771 5.54045 9.64536C5.81673 9.36907 6.26303 9.36907 6.53932 9.64536L8.58663 11.6856L13.4605 6.8117C13.7368 6.53542 14.1831 6.53542 14.4594 6.8117C14.7357 7.08798 14.7357 7.53428 14.4594 7.81057L9.08252 13.1874C8.81332 13.4637 8.35994 13.4637 8.08366 13.1874Z"
        fill="#191C1E"
      />
    </svg>
  );
};

export default CheckWithCircleIcon;
