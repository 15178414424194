import * as yup from 'yup';

import {
  DROPDOWN_FIELD_TYPE,
  INPUT_FIELD_TYPE,
  dialCodeOptions,
  getDialCodeById,
  getDialCodeIdFromPhoneNumber,
  getPhoneNumberWithoutDial,
} from '../utility/formHelper';
import { actionSetUserProfile, setUser } from '../actions/user';
import { useEffect, useState } from 'react';

import { isValidPhoneFormat } from '../utility/validation';
import { updateUserBasicProfile } from '../services/authService';
import { useDispatch } from 'react-redux';

const fields = {
  firstName: {
    fieldType: INPUT_FIELD_TYPE,
    name: 'firstName',
    placeholder: 'First name',
    label: 'First name',
    required: true,
  },
  lastName: {
    fieldType: INPUT_FIELD_TYPE,
    name: 'lastName',
    placeholder: 'Last name',
    label: 'Last name',
    required: true,
  },
  countryId: {
    fieldType: DROPDOWN_FIELD_TYPE,
    name: 'countryId',
    placeholder: 'Country',
    optionsList: dialCodeOptions,
    style: {
      width: '92px',
      height: '58px',
      border: '1px solid #C5C6C9',
      borderRadius: '12px',
    },
  },
  dialCodeId: {
    fieldType: DROPDOWN_FIELD_TYPE,
    name: 'dialCodeId',
    placeholder: 'Dial Code',
    optionsList: dialCodeOptions,
  },
  phoneNumber: {
    fieldType: INPUT_FIELD_TYPE,
    name: 'phoneNumber',
    placeholder: 'Phone number',
    label: 'Phone',
  },
  password: {
    fieldType: INPUT_FIELD_TYPE,
    type: 'password',
    name: 'password',
    placeholder: 'eg. Abcd1234',
    label: 'Create password',
    eyeIcon: true,
  },
};

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  countryId: null,
  dialCodeId: '',
  phoneNumber: '',
  password: '',
};

const getBasicInfoValidationSchema = ({
  withPassword,
  phoneNumberRequired,
}) => {
  const passwordSchema = {
    password: yup
      .string()
      .min(8, 'Password must be of at least 8 characters')
      .matches(/[A-Z]/, 'Password must have at least 1 upper case character')
      .matches(/[a-z]/, 'Password must have at least 1 lower case character')
      .matches(/[0-9]/, 'Password must have at least 1 number')
      .required('Password is required'),
  };

  const phoneRequiredSchema = {
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    countryId: yup.string().required('Country is required'),
    dialCodeId: yup.string().required(),
    phoneNumber: yup
      .string()
      .test('phoneNumberValidation', 'Invalid phone number', function (value) {
        if (!value) return true;
        const dialCodeId = this.parent['dialCodeId'];
        const dialCode = getDialCodeById(dialCodeId);
        const fullPhoneNumber = `${dialCode}${value}`;
        return isValidPhoneFormat(fullPhoneNumber);
      })
      .required(),
  };

  return yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    dialCodeId: yup.string().notRequired(),
    phoneNumber: yup
      .string()
      .test('phoneNumberValidation', 'Invalid phone number', function (value) {
        if (!value) return true;
        const dialCodeId = this.parent['dialCodeId'];
        const dialCode = getDialCodeById(dialCodeId);
        const fullPhoneNumber = `${dialCode}${value}`;
        return isValidPhoneFormat(fullPhoneNumber);
      })
      .notRequired(),
    ...(withPassword && passwordSchema),
    ...(phoneNumberRequired && phoneRequiredSchema),
  });
};

const formatAlumniLearnerData = (user) => {
  const learner = user?.learner;
  if (!learner) return null;

  const { firstName, lastName, countryId, phoneNumber } = learner;
  const countryIdString = countryId
    ? countryId.toString()
    : phoneNumber
    ? getDialCodeIdFromPhoneNumber(phoneNumber).toString()
    : '';
  return {
    firstName: firstName || '',
    lastName: lastName || '',
    countryId: countryIdString,
    dialCodeId: phoneNumber
      ? getDialCodeIdFromPhoneNumber(phoneNumber).toString()
      : countryIdString,
    phoneNumber: phoneNumber ? getPhoneNumberWithoutDial(phoneNumber) : '',
  };
};

const useUpdateBasicInfo = () => {
  const [user, _setUser] = useState(null);
  const [values, setValues] = useState(defaultInitialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');

  const dispatch = useDispatch();

  const resetFieldError = (fieldName) => {
    if (errors[fieldName]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };

  const onFieldChange = (fieldName, value) => {
    let update = { [fieldName]: value };

    if (fieldName === fields.countryId.name) {
      update[fields.dialCodeId.name] = value;
    }

    setValues((prevValues) => ({
      ...prevValues,
      ...update,
    }));

    setApiError('');
    resetFieldError(fieldName);
  };

  const validate = async () => {
    setErrors({});

    const schema = getBasicInfoValidationSchema({
      withPassword: user?.passwordResetRequired,
      phoneNumberRequired: user?.isWhatsappExperienceSubscriber,
    });

    const isValid = await schema
      .validate(values, {
        abortEarly: false,
      })
      .then(() => true)
      .catch((validationErr) => {
        if (Array.isArray(validationErr.inner)) {
          let errors = {};
          validationErr.inner.forEach(
            ({ path, message }) => (errors[path] = message)
          );
          setErrors(errors);
        }

        return false;
      });

    return isValid;
  };

  const handleApiError = (error) => {
    setApiError(error);

    // reset after 3 seconds
    setTimeout(() => {
      setApiError('');
    }, 3000);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);
      setApiError('');

      const isValid = await validate();

      if (!isValid) {
        setIsSubmitting(false);
        return false;
      }

      const phoneNumber = values.phoneNumber
        ? `${getDialCodeById(values.countryId)}${values.phoneNumber.trim()}`
        : null;

      const params = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        countryId: values.countryId.trim(),
        phoneNumber,
        password: values.password.trim(),
      };

      const res = await updateUserBasicProfile(params);
      const { data, error } = res;
      setIsSubmitting(false);

      if (error) {
        handleApiError(error);
        return false;
      }

      dispatch(actionSetUserProfile(data));
      dispatch(
        setUser(
          data?.learner ?? {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
          }
        )
      );

      return true;
    } catch (e) {
      setIsSubmitting(false);
      return false;
    }
  };

  useEffect(() => {
    const formattedUser = formatAlumniLearnerData(user);
    if (formattedUser)
      setValues((prevValues) => ({ ...prevValues, ...formattedUser }));
  }, [user]);

  // This is needed since the chrome and other browsers autofill the country but not the dialCode.
  // So need this on first field change to populate the dial code when the country changes.
  useEffect(() => {
    if (values?.countryId) {
      const update = {
        countryId: values?.countryId,
        dialCodeId: values?.countryId,
      };
      setValues((prevValues) => ({ ...prevValues, ...update }));
    }
  }, [values?.countryId]);

  return {
    setUser: _setUser,
    fields,
    values,
    errors,
    onFieldChange,
    onSubmit,
    isSubmitting,
    apiError,
    resetApiError: () => setApiError(''),
  };
};

export default useUpdateBasicInfo;
