import DiscordV2 from '../../../../components/Icons/DiscoardV2/DiscoardV2';
import DribbleIconV2Colored from '../../../../components/Icons/DribbleIconV2Colored/DribbleIconV2Colored';
import FacebookIconV2Colored from '../../../../components/Icons/FacebookIconV2Colored/FacebookIconV2Colored';
import InstagramIconV2Colored from '../../../../components/Icons/InstagramIconV2Colored/InstagramIconV2Colored';
import LinkedIconV2Colored from '../../../../components/Icons/LinkedIconV2Colored/LinkedIconV2Colored';
import OpenSeaV2Colored from '../../../../components/Icons/OpenSeaV2Colored/OpenSeaV2Colored';
import TiktokV2 from '../../../../components/Icons/TiktokV2/TiktokV2';
import TwitterIconV2Colored from '../../../../components/Icons/TwitterIconV2Colored/TwitterIconV2Colored';
import WebsiteIconV2 from '../../../../components/Icons/WebsiteIconV2/WebsiteIconV2';
import YoutubeIconV2Colored from '../../../../components/Icons/YoutubeIconV2Colored/YoutubeIconV2Colored';

export const renderLogo = (type) => {
  switch (type) {
    case 'facebook':
      return <FacebookIconV2Colored />;
    case 'instagram':
      return <InstagramIconV2Colored />;
    case 'youtube':
      return <YoutubeIconV2Colored />;
    case 'twitter':
      return <TwitterIconV2Colored />;

    case 'tiktok':
      return <TiktokV2 />;
    case 'linkedin':
      return <LinkedIconV2Colored />;
    case 'discord':
      return <DiscordV2 />;
    case 'opensea':
      return <OpenSeaV2Colored />;
    case 'website':
      return <WebsiteIconV2 />;

    case 'dribble':
      return <DribbleIconV2Colored />;
    default:
      return <FacebookIconV2Colored customClassName="w-16 h-16" />;
  }
};
