import PropTypes from 'prop-types';
import React from 'react';

export const ConversationIcon = ({
  width,
  height,
  displayType,
  fillColor,
  strokeColor,
}) => (
  <>
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      {displayType === 'filled' && (
        <path
          d="M15 10C15 13.3137 12.3137 16 9 16C7.81929 16 6.71824 15.659 5.78998 15.07L3 16L3.92999 13.21C3.34104 12.2818 3 11.1807 3 10C3 6.68629 5.68629 4 9 4C12.3137 4 15 6.68629 15 10Z"
          fill={fillColor}
        />
      )}
      <path
        d="M5.78998 15.07L6.32571 14.2256C6.07184 14.0645 5.75898 14.0262 5.47375 14.1213L5.78998 15.07ZM3 16L2.05132 15.6838C1.93154 16.0431 2.02506 16.4393 2.29289 16.7071C2.56073 16.9749 2.95689 17.0685 3.31623 16.9487L3 16ZM3.92999 13.21L4.87868 13.5262C4.97375 13.241 4.93545 12.9282 4.77438 12.6743L3.92999 13.21ZM9 17C12.866 17 16 13.866 16 10H14C14 12.7614 11.7614 15 9 15V17ZM5.25425 15.9144C6.33796 16.602 7.62403 17 9 17V15C8.01456 15 7.09852 14.7159 6.32571 14.2256L5.25425 15.9144ZM5.47375 14.1213L2.68377 15.0513L3.31623 16.9487L6.10621 16.0187L5.47375 14.1213ZM3.94868 16.3162L4.87868 13.5262L2.98131 12.8938L2.05132 15.6838L3.94868 16.3162ZM2 10C2 11.376 2.39802 12.662 3.08561 13.7458L4.77438 12.6743C4.28406 11.9015 4 10.9854 4 10H2ZM9 3C5.13401 3 2 6.13401 2 10H4C4 7.23858 6.23858 5 9 5V3ZM16 10C16 6.13401 12.866 3 9 3V5C11.7614 5 14 7.23858 14 10H16Z"
        fill={displayType === 'filled' ? fillColor : strokeColor}
      />
      <path
        d="M9.33789 15.9896C10.1586 18.3254 12.3838 20 15.0001 20C16.1808 20 17.2819 19.659 18.2101 19.07L21.0001 20L20.0701 17.21L20.2122 16.9744C20.7136 16.0977 21.0001 15.0823 21.0001 14C21.0001 10.6863 18.3138 8 15.0001 8L14.7752 8.00414L14.6623 8.01014"
        stroke={displayType === 'filled' ? fillColor : strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

ConversationIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  displayType: PropTypes.oneOf(['outline', 'filled']),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

ConversationIcon.defaultProps = {
  width: '24',
  height: '24',
  fillColor: '#FBC91B',
  strokeColor: '#AAABAE',
};
