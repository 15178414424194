import React from 'react';
import TriggerResetPasswordForm from '../../components/Authentication/TriggerResetPasswordForm';
import './ForgetPasswordPage.scss';

const ForgetPasswordPage = ({ title }) => {
  const className = 'c-ForgetPasswordPage';

  return (
    <div className={className}>
      <div className={`${className}__container`}>
        <TriggerResetPasswordForm title={title} />
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
