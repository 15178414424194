export const login_logo = {
  alt: 'nasacademy login',
  mobileImgData: {
    src:
      'https://d2oi1rqwb0pj00.cloudfront.net/ssr-academy/logo/naLogo_full_dark.svg',
    webpSrc:
      'https://d2oi1rqwb0pj00.cloudfront.net/ssr-academy/logo/naLogo_full_dark.svg',
  },
};

export const loginBackground = {
  alt: 'login background',
  mobileImgData: {
    src:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-portal/LoginPage/jpg/member-banner.jpg',
    webpSrc:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-portal/LoginPage/jpg/member-banner.jpg',
  },
};
