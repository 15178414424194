import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import isFuture from 'date-fns/isFuture';

export const formatDate = (date) =>
  typeof date === 'string' ? new Date(date) : date;

export const isDateInFuture = (date) => {
  try {
    const formattedDate = formatDate(date);
    const presentDate = new Date().getTime();

    return formattedDate.getTime() > presentDate;
  } catch (err) {
    return null;
  }
};

/**
 *
 * @param {*} t - translation
 * @param {*} date - the first date (date in the past)
 * @returns '1y' || '1m' || '1d' || '1h' || '1m' || '1s'
 */
export function timeSince(t, date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval <= 0) return t('just-now');

  if (interval > 1) {
    return Math.floor(interval) + 'y';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + 'mo';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval > 7) {
      const inWeeks = interval / 7;
      return Math.floor(inWeeks) + 'w';
    }

    return Math.floor(interval) + 'd';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + 'h';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + 'm';
  }
  return t('just-now');
}

export const isTimeinBetweenTwoTimes = (
  date1,
  date2,
  minus_minutes_on_start_time
) => {
  try {
    const minus_minutes_on_start_time_in_milliseconds =
      minus_minutes_on_start_time * 60 * 1000;
    const formattedDate1 = new Date(date1).getTime();
    const formattedDate2 = new Date(date2).getTime();
    const presentDate = new Date().getTime();
    const formattedDate1_minus_minutes_on_start_time =
      formattedDate1 - minus_minutes_on_start_time_in_milliseconds;

    return (
      formattedDate1_minus_minutes_on_start_time < presentDate &&
      formattedDate2 > presentDate
    );
  } catch (err) {
    return null;
  }
};

export const checkIfStartTimelessthanCustomMinutes = (date, minutes) => {
  const minutesinmilliseconds = minutes * 60 * 1000;

  const givendateinMilliseconds = new Date(date).getTime();
  const currentDateinMilliseconds = new Date().getTime();

  const difference = givendateinMilliseconds - currentDateinMilliseconds;

  return difference < minutesinmilliseconds;
};
export const getRelativeDescriptivePastDate = (date) => {
  try {
    const formattedDate = formatDate(date);
    return `${formatDistanceToNowStrict(formattedDate)} ago`;
  } catch (err) {
    return null;
  }
};

export const getDateItemsObject = (t, dateInput, isMobileView) => {
  try {
    const formattedDate = formatDate(dateInput);
    const month = new Intl.DateTimeFormat('en-US', {
      month: isMobileView ? 'short' : 'long',
    }).format(formattedDate);
    const date = formattedDate.getDate();

    let day = new Intl.DateTimeFormat('en-US', {
      weekday: isMobileView ? 'short' : 'long',
    }).format(formattedDate);

    if (isFuture(formattedDate)) {
      const presentDate = new Date();

      const presentDateDay = presentDate.getDate();
      const isCurrentMonth =
        presentDate.getMonth() === formattedDate.getMonth();
      const isCurrentYear =
        presentDate.getFullYear() === formattedDate.getFullYear();

      const isActivePeriod = isCurrentMonth && isCurrentYear;

      day =
        presentDateDay === date && isActivePeriod
          ? isMobileView
            ? t('now')
            : t('today')
          : (presentDateDay - date) * -1 === 1 && isActivePeriod
          ? isMobileView
            ? t('tomorrow-short')
            : t('tomorrow')
          : day;
    }

    return {
      month,
      date,
      day,
    };
  } catch (err) {
    return {};
  }
};

export const getPlainTime = (date) => {
  try {
    // am aaa
    // hour h
    // minute mm
    return format(formatDate(date), 'h:mm aaa');
  } catch (err) {
    return null;
  }
};

export const getLongTimeZoneName = (date) => {
  try {
    const timezoneWithDate = Intl.DateTimeFormat('en-US', {
      timeZoneName: 'longGeneric',
    })
      .format(formatDate(date))
      .split(',');

    return timezoneWithDate[1];
  } catch (err) {
    return null;
  }
};

export const getDifferenceBetweenDatesInMinutes = (startDate, endDate) => {
  try {
    const startDateInMilliseconds = new Date(startDate).getTime();
    const endDateInMilliseconds = new Date(endDate).getTime();
    const difference = endDateInMilliseconds - startDateInMilliseconds;
    return difference / (1000 * 60);
  } catch (err) {
    return null;
  }
};

export const getDifferenceBetweenDatesInDays = (startDate, endDate) => {
  const diffInMins = getDifferenceBetweenDatesInMinutes(startDate, endDate);
  return Math.floor(diffInMins / (60 * 24));
};

export const subtractMinutesFromDate = (date, minutes) => {
  try {
    const dateInMilliseconds = new Date(date).getTime();
    const minutesInMilliseconds = minutes * 60 * 1000;
    const newDateInMilliseconds = dateInMilliseconds - minutesInMilliseconds;
    return new Date(newDateInMilliseconds);
  } catch (err) {
    return null;
  }
};

export const getNextBillingDateFromDate = (
  date,
  statusOfSubscription,
  cancelledAt
) => {
  if (!date) return null;

  // handling all cancelled states
  if (statusOfSubscription === 'Cancelled') {
    if (!isDateInFuture(cancelledAt)) {
      return null;
    }
    return cancelledAt;
  } else {
    // Handling current State
    const result = date ? formatDate(date) : new Date();

    if (isDateInFuture(result)) {
      // This is the correct date already
      return result.toISOString();
    } else {
      result.setMonth(result.getMonth() + 1);
      return result.toISOString();
    }
  }
};

export const formatDateInDDMMYYYY = (date) => {
  if (!date) return null;
  return new Intl.DateTimeFormat().format(formatDate(date));
};
