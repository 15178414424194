import { CANCEL_OPTIONS } from './constants';
import {
  formatDateInDDMMYYYY,
  getDifferenceBetweenDatesInDays,
  subtractMinutesFromDate,
} from '../../../../../../CommunityPortal/utils/dateUtils';
import { useMemo, useState } from 'react';

import Button from '../../../../../../CommunityPortal/components/Button';
import CheckboxV2 from '../../../../../../components/CheckboxV2';
import Input from '../../../../../../components/Form/Input';
import Modal from '../../../../../../CommunityPortal/components/Modal';
import MultiSelect from 'react-multi-select-component';
import PlusFilledIcon from '../../../../../../CommunityPortal/components/Icons/PlusFilledIcon';
import Proptypes from 'prop-types';
import { formatDate } from '../../../../../../utility/courseHelper';
import style from './CancelModal.module.scss';
import { useTranslation } from 'react-i18next';

const className = 'c-CancelModal';
const CancelModal = ({ showModal, onClose, onConfirm, nextBillingDate }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [reason, setReason] = useState([]);
  const [isfirstClick, setIsFirstClick] = useState(true);
  const [otherReason, setOtherReason] = useState('');

  const MEMBER_BENEFITS_TEXT = [
    t('watch-the-classes-in-our-video-library'),
    t('attend-the-upcoming-live-events'),
    t('participate-in-the-community-chat'),
  ];

  const error = useMemo(() => {
    if (isfirstClick) {
      return { errorInput: false, errorOther: false };
    }
    if (reason.length > 0) {
      if (reason.some((f) => f.value === 'other')) {
        if (otherReason.length > 0) {
          return { errorInput: false, errorOther: false };
        } else {
          return {
            errorInput: false,
            errorOther: t('please-elaborate-on-the-other-reason'),
          };
        }
      }
      return { errorInput: false, errorOther: false };
    } else {
      return { errorInput: t('please-select-a-reason'), errorOther: false };
    }
  }, [isfirstClick, otherReason.length, reason]);

  const onCloseModal = () => {
    setStep(1);
    onClose();
  };

  const onConfirmClick = () => {
    if (!!error) {
      let reasonAsArray = [...reason];
      const hasOtherReason = reasonAsArray.some((f) => f.value === 'other');
      if (hasOtherReason && otherReason.length > 0) {
        reasonAsArray = reasonAsArray.filter((f) => f.value !== 'other');
        reasonAsArray.push({
          label: `Other: ${otherReason}`,
          value: otherReason,
        });
      }
      const reasonAsString = reasonAsArray.map((r) => r.label).join(', ');
      onConfirm(reasonAsString);
      onCloseModal();
    } else {
      console.log('Disabled');
    }
  };

  const renderStep1 = () => {
    const daysBeforeBillingDate =
      getDifferenceBetweenDatesInDays(
        new Date(),
        formatDate(nextBillingDate)
      ) ?? 0;
    return (
      <>
        <span className="font-medium text-heading-sm">
          {t('were-sorry-to-see-you-go')}
        </span>
        <div className="flex flex-col p-8 mt-24 rounded-8 bg-grey-neutral95">
          <span className="font-medium text-body-lg">
            {t('you-still-have-days-to-enjoy-your-benefits', {
              count: daysBeforeBillingDate,
            })}
          </span>
          <div className="mt-8 mb-16">
            <ul className="list-disc">
              {MEMBER_BENEFITS_TEXT.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          </div>
        </div>
        <span className="mt-24 text-body-md">
          {t('your-access-to-the-community-is-until-date', {
            date: formatDateInDDMMYYYY(
              subtractMinutesFromDate(nextBillingDate, 60 * 24)
            ),
          })}
        </span>
        <div className="flex flex-row items-center justify-end mt-32">
          <Button displayType={'primary'} size="md" onClick={() => setStep(2)}>
            {t('continue')}
          </Button>
        </div>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <span className="font-medium text-heading-sm">
          {t('anything-youd-like-us-to-know')}
        </span>
        <div className="flex flex-col">
          <div className="mt-24">
            <span className="font-medium text-grey-66 text-label-md">
              {t('please-tell-us-why-you-want-to-withdraw')}
            </span>
          </div>
          <div className="mt-8">
            <MultiSelect
              ArrowRenderer={({ expanded }) => <PlusFilledIcon />}
              options={CANCEL_OPTIONS(t)}
              value={reason}
              onChange={(value) => {
                setReason(value);
                setIsFirstClick(false);
              }}
              // isOpen={true}
              labelledBy={t('select-the-ones-applicable')}
              hasSelectAll={false}
              disableSearch={true}
              className={style['rmsc-global']}
              valueRenderer={(selected, _options) => {
                return selected.length ? (
                  <div className="flex flex-wrap w-full">
                    {selected.map((option) => (
                      <span
                        key={option.value}
                        className="px-8 py-4 mx-4 my-4 font-medium rounded-full bg-grey-neutral95 text-label-md"
                      >
                        {option.label}
                      </span>
                    ))}
                  </div>
                ) : (
                  t('select-the-ones-applicable')
                );
              }}
              ItemRenderer={({ checked, option, onClick, disabled }) => {
                return (
                  <div className={`item-renderer ${disabled && 'disabled'}`}>
                    <CheckboxV2
                      label={option.label}
                      checked={checked}
                      onClick={onClick}
                      disabled={disabled}
                    />
                  </div>
                );
              }}
            />
            {error.errorInput && (
              <span className="mt-4 text-error text-label-md">
                {error.errorInput}
              </span>
            )}
          </div>
        </div>
        {reason.some((f) => f.value === 'other') && (
          <div className="mt-16">
            <Input
              label={t('other-reason')}
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              name="other-reason"
              v2={true}
              error={error.errorOther}
            />
          </div>
        )}
        <span className="mt-24 text-body-md">
          {t(
            'you-will-not-be-charged-on-your-next-scheduled-billing-cycle-on-date',
            { date: formatDateInDDMMYYYY(nextBillingDate) }
          )}
        </span>
        <div className="flex flex-row items-center justify-end mt-32">
          <Button displayType={'plain'} size="md" onClick={onCloseModal}>
            {t('back')}
          </Button>
          <Button
            displayType={'primary'}
            size="md"
            onClick={onConfirmClick}
            customClassNames="ml-8"
            disabled={isfirstClick || !!error.errorInput || !!error.errorOther}
          >
            {t('cancel-subscription')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal open={showModal} onClose={onCloseModal}>
      <div className={`${className} flex flex-col font-poppins max-w-[500px]`}>
        {step === 1 && renderStep1()}
        {step === 2 && renderStep2()}
      </div>
    </Modal>
  );
};

CancelModal.propTypes = {
  showModal: Proptypes.bool,
  onClose: Proptypes.func,
  onConfirm: Proptypes.func,
  nextBillingDate: Proptypes.string,
};

CancelModal.defaultProps = {};

export default CancelModal;
