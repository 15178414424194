import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import Auth from '../../modules/auth';
import { startLoginCheck, startLogout } from '../../actions/auth';
import config from '../../modules/config';
import { isSessionInSandboxEnv } from '../../CommunityPortal/utils/productDemoUtils';

const AuthWrapper = ({ children, history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state?.auth?.user;
  });
  const isDemoUser = !!user?.isDemo;

  const setTokens = async (uriToken, uriRefreshToken) => {
    Auth.setUriTokens(uriToken, uriRefreshToken);
  };

  useEffect(() => {
    const loginWithToken = async () => {
      const parsedParamsObj = parse(window.location.search);
      const uriToken = parsedParamsObj?.token;
      const uriRefreshToken = parsedParamsObj?.refreshToken;

      const accessTokenCookie = Auth.getUserTokenFromCookies();
      const refreshTokenCookie = Auth.getRefreshTokenFromCookies();
      const areTokenInCookie = accessTokenCookie && refreshTokenCookie;

      const pathnameWithParams = `${history.location.pathname}${history.location.search}`;

      if (uriToken) {
        Auth.deauthenticateUser();
        await setTokens(uriToken, uriRefreshToken);
        dispatch(
          startLoginCheck(uriToken, uriRefreshToken, pathnameWithParams)
        );
      } else if (areTokenInCookie) {
        //if tokens exists in cookie then use those to login
        Auth.setTokens(accessTokenCookie, refreshTokenCookie);
        dispatch(
          startLoginCheck(
            accessTokenCookie,
            refreshTokenCookie,
            history.location.pathname
          )
        );
      } else {
        Auth.deleteUriTokens();
        const email = parsedParamsObj?.email;

        // For local development cookies are not set beacuse of the same origin policy, so need to use localStorage ONLY for local development
        if (config.isDevEnv) {
          const token = Auth.getUserToken();
          const refreshToken = Auth.getRefreshToken();
          dispatch(
            startLoginCheck(token, refreshToken, pathnameWithParams, email)
          );
        } else {
          // For production we need to use cookies to store tokens
          dispatch(
            startLoginCheck(
              accessTokenCookie,
              refreshTokenCookie,
              pathnameWithParams,
              email
            )
          );
        }
      }
    };

    loginWithToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDemoUser && !isSessionInSandboxEnv()) {
      dispatch(startLogout());
    }
  }, [dispatch, isDemoUser]);

  return <div className="c-AuthWrapper">{children}</div>;
};

export default withRouter(AuthWrapper);
