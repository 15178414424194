export const loginLogoStaticProps = {
  imgProps: [
    {
      className: 'camera-logo',
      src:
        'https://res.cloudinary.com/dgk6tzmvu/image/upload/v1592972697/academy/svg/camera.svg',
      alt: 'camera',
    },
    {
      className: 'login-na-logo',
      src:
        'https://res.cloudinary.com/dgk6tzmvu/image/upload/v1592538165/academy/nasacademy-new.svg',
      alt: 'na-logo',
    },
  ],
};
