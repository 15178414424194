import Cookies from 'js-cookie';
import localStorageService from './localStorageService';

export const LANGUAGE_KEY = 'NEXT_LOCALE';
export const langOptions = [
  {
    value: 'en',
    label: 'English (US)',
  },
  {
    value: 'es-MX',
    label: 'Español (LATAM)',
  },
  {
    value: 'pt-BR',
    label: 'Portugués (BR)',
  },
];

export const acceptedLanguages = langOptions.map(
  (langOption) => langOption.value
);

export const getRegionInUpperCase = (locale) => {
  const parts = locale?.split('-') || [];

  if (parts.length === 2) {
    parts[1] = parts[1].toUpperCase();
    return parts.join('-');
  }

  return locale;
};

export const getSelectedLangStr = (i18n) => {
  const { language } = i18n;
  switch (language) {
    case 'en':
      return 'English (US)';
    case 'es-MX':
      return 'Español (MX)';
    case 'pt-BR':
      return 'Portugués (BR)';
    default:
      return 'English (US)';
  }
};

const getLanguageInStorage = () => {
  let languageInStorage =
    Cookies.get(LANGUAGE_KEY) || localStorageService.getItem(LANGUAGE_KEY);

  languageInStorage = getRegionInUpperCase(languageInStorage);
  if (acceptedLanguages.includes(languageInStorage)) return languageInStorage;

  return '';
};

export const setLanguageInStorage = (language) => {
  const localeInLowerCase = language.toLowerCase();
  const isLocalhost = window.location.hostname === 'localhost';
  let domain = '';
  if (!isLocalhost) {
    const domainParts = window.location.hostname.split('.');
    domain = '.' + domainParts.slice(-2).join('.'); // .nas.io or .dev-nas.io
  }
  const cookieOptions = isLocalhost ? {} : { domain };
  Cookies.set(LANGUAGE_KEY, localeInLowerCase, cookieOptions);
  localStorageService.setItem(LANGUAGE_KEY, localeInLowerCase);
};

export const switchToUserPreferredLanguage = (user) => {
  const languagePreference = user?.learner?.languagePreference || '';
  const languageInStorage = getLanguageInStorage() || 'en';

  if (languageInStorage.toLowerCase() !== languagePreference.toLowerCase()) {
    setLanguageInStorage(languagePreference);
    window.location.reload();
  }
};

const getBrowserLanguagePreference = () => {
  const browserLang = navigator?.language;

  // use es-MX for all spanish languages.
  if (browserLang.includes('es')) return 'es-MX';

  switch (browserLang) {
    case 'es-MX':
    case 'pt-BR':
      return browserLang;
    case 'en':
    case 'en-US':
    default:
      return 'en';
  }
};

export const getInitialLocale = () => {
  const browserLang = getBrowserLanguagePreference();
  const langInStorage = getLanguageInStorage();
  let initialLocale = langInStorage || browserLang || 'en';
  return getRegionInUpperCase(initialLocale);
};
