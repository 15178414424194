import React from 'react';

const className = 'c-TiktokV2';
const TiktokV2 = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1475 2C16.5313 4.88747 18.4943 6.9248 21.67 7.10448V9.84321L21.6494 9.84491V7.51846C18.4738 7.33891 16.648 5.6169 16.2643 2.72943L12.9002 2.72936V14.8772C13.3856 20.3451 8.55139 20.5075 6.70221 18.4344C8.87121 19.6299 12.393 18.8528 11.9754 14.1478V2H16.1476H16.1475ZM7.10875 22.138C5.20789 21.7933 3.47946 20.7924 2.46444 19.3289C-0.00150561 15.7733 2.22132 9.97835 9.37354 9.38198V12.8058L9.36181 12.8075V10.2387C2.73094 11.1694 2.0652 17.4469 3.90158 20.0582C4.60971 21.0652 5.77227 21.7736 7.10868 22.138H7.10875Z"
        fill="#26F4EE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1189 2.72969C17.3694 4.61469 18.2347 6.21908 19.6886 7.15609C17.7212 6.48816 16.5682 4.95521 16.2723 2.7295L17.1189 2.72969ZM21.6574 7.92496C21.928 7.97561 22.2102 8.00989 22.504 8.02646V11.2729C20.6619 11.4327 19.0498 10.8983 17.1713 9.90269L17.4596 15.4626C17.4596 17.2556 17.4672 18.0751 16.3755 19.7245C13.9264 23.4248 9.51814 23.7173 6.53715 21.9548C10.4352 23.3725 16.3361 21.6518 16.3246 15.4626V9.39434C18.2031 10.3899 19.8152 10.9244 21.6574 10.7645V7.92496ZM9.36981 10.2716C9.75511 10.2009 10.1596 10.1465 10.5849 10.111V13.5348C9.03612 13.7597 8.05241 14.1788 7.59241 14.9541C6.14362 17.3957 8.00895 19.3321 10.0956 19.6232C7.67004 19.9784 4.82565 17.8119 6.52142 14.9541C6.98135 14.179 7.82102 13.7597 9.36981 13.5348V10.2716ZM13.9791 2.72913H14.1083H13.9791Z"
        fill="#FB2C53"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2632 2.72919C16.6468 5.61666 18.4717 7.33861 21.6457 7.51822V10.7645C19.8044 10.9245 18.1932 10.39 16.3156 9.3944V15.4627C16.3303 23.383 6.6674 23.9857 3.90675 20.0579C2.07136 17.4466 2.73669 11.1691 9.36426 10.2384V13.5348C7.81624 13.7597 6.97699 14.179 6.51729 14.9541C3.67632 19.7444 13.5855 22.5945 12.9007 14.877V2.72913L16.2631 2.72919H16.2632Z"
        fill="black"
      />
    </svg>
  );
};

export default TiktokV2;
