import {
  COPY_LINK,
  MAKE_REFERRAL_TITLE,
  SHARE_PERSONAL_LINK_TEXT,
  SHARE_SUBTITLE_TEXT,
  headerStaticPropsReferral,
} from './contants';
import RedeemBox, {
  RedeemBoxPropTypes,
} from '../SettingPage/components/RedeemBox';
import { useCallback, useState } from 'react';

import Button from '../../components/Button';
import CopyIcon from '../../components/Icons/CopyIcon';
import Input from '../../components/Form/Input';
import PageTemplate from '../../CommunityPortal/components/PageTemplate';
import PropTypes from 'prop-types';
import SuccessMessage from '../../components/Profile/components/SuccessMessage/SuccessMessage';
import { TextType } from '../../components/Text';

const className = 'c-SettingPage-newReferralPage';

const NewReferralPage = ({
  header,
  text,
  referralProps,
  shareCTALabel,
  shareCTAOnClick,
  isDesktop,
  redeemBox,
  user,
}) => {
  const [copyReferralSuccess, setCopyReferralSuccess] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const copyReferralCode = () => {
    navigator.clipboard.writeText(referralProps.url);
    setTimeout(() => {
      setCopyReferralSuccess(true);
    }, 0);
    setTimeout(() => {
      setCopyReferralSuccess(false);
    }, 2000);
  };
  const ReferralCard = useCallback(() => {
    return (
      <>
        <div
          className={
            className + '__referralcard bg-grey-neutral95 p-12 mt-24 rounded-8'
          }
        >
          <div className={className + '__referralcard__header mb-12'}>
            <p className="mb-12 font-semibold text-black text-label-lg">
              {MAKE_REFERRAL_TITLE}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="mb-12 font-medium text-poppins text-label-md text-grey-66">
              {SHARE_PERSONAL_LINK_TEXT}
            </p>
            <Input value={referralProps.url} />
            {copyReferralSuccess && (
              <SuccessMessage
                msg={'Copied!'}
                // TODO: Check if this is alright
                // position="absolute"
                top="top"
                right="right"
              />
            )}
          </div>
          <div className="flex flex-row-reverse mt-12 ">
            <Button
              style={{ width: 'fit-content', marginBottom: '0px' }}
              onClick={shareCTAOnClick}
              displayType="primary"
              disabled={!navigator.share}
            >
              {shareCTALabel}
            </Button>
            <div
              onClick={copyReferralCode}
              className={`${className}__icon flex items-center  justify-end ml-auto sm:w-[100%]`}
            >
              <button className="flex flex-row items-center justify-end mr-12 ">
                <CopyIcon />{' '}
                <span className="ml-4 text-button-md"> {COPY_LINK} </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }, [
    copyReferralCode,
    copyReferralSuccess,
    referralProps.url,
    shareCTALabel,
    shareCTAOnClick,
  ]);

  const renderWidgetContent = () => (
    <>
      <div className={`${className}__widget-content`}>
        <div className={`${className}__content`}>
          <div className={`${className}__description `}>
            <span className="font-medium text-body-lg">{text[0].text}</span>
          </div>
          <div
            className={`${className}__share_text mt-12 text-label-lg text-grey-neutral10`}
          >
            {SHARE_SUBTITLE_TEXT}
          </div>

          {ReferralCard()}
        </div>
      </div>
    </>
  );

  function renderContent() {
    return (
      <div className={`${className} px-16 mt-32 lg:max-w-[600px]`}>
        {renderWidgetContent()}
        <hr className="mt-24 border-grey-neutral95" />
        <RedeemBox {...redeemBox} />
      </div>
    );
  }
  return (
    <>
      <PageTemplate
        headerProps={isDesktop ? headerStaticPropsReferral : null}
        toRender={renderContent}
      />
    </>
  );
};

export const ReferralWidgetPropTypes = {
  header: PropTypes.string.isRequired,
  text: TextType,
  referralProps: PropTypes.shape({
    url: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  shareCTAOnClick: PropTypes.func.isRequired,
  shareCTALabel: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool,
  redeemBox: RedeemBoxPropTypes.isRequired,
};

NewReferralPage.propTypes = ReferralWidgetPropTypes;

NewReferralPage.defaultProps = {
  socialMediaProps: [],
};

export default NewReferralPage;
