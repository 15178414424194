import {
  COMMUNITY_PORTAL_EVENTS_PAGE_ENDPOINT,
  COMMUNITY_PORTAL_HOMEPAGE_ENDPOINT,
  COMMUNITY_PORTAL_LIBRARY_FOLDER_PATH,
  COMMUNITY_PORTAL_LIBRARY_PAGE_ENDPOINT,
  COMMUNITY_PORTAL_MEET_AN_EXPERT_PAGE_ENDPOINT,
  COMMUNITY_PORTAL_MEMBERS_PAGE_ENDPOINT,
} from '../../PortalModeWrapper/constants';

import BookOpenFilledIcon from '../components/Icons/BookOpenFilledIcon';
import BookOpenIcon from '../components/Icons/BookOpenIcon';
import CalendarDaysFilledIcon from '../components/Icons/CalendarDaysFilledIcon';
import CalendarDaysIcon from '../components/Icons/CalendarDaysIcon';
import ChatFilledIcon from '../components/Icons/ChatFilledIcon';
import ChatIcon from '../components/Icons/ChatIcon';
import ConversationIcon from '../components/Icons/ConversationIcon';
import HomeIcon from '../components/Icons/HomeIcon';
import HouseFilledIcon from '../components/Icons/HouseFilledIcon';
import HouseIcon from '../components/Icons/HouseIcon';
import LibraryIcon from '../components/Icons/LibraryIcon';
import LiveSessionIcon from '../components/Icons/LiveSessionIcon';
import MembersIcon from '../components/Icons/MembersIcon';
import ProfileFilledIcon from '../components/Icons/ProfileFilledIcon';
import ProfileIcon from '../components/Icons/ProfileIcon';
import RowFilledIcon from '../components/Icons/RowFilledIcon';
import RowIcon from '../components/Icons/RowIcon';

export const PROFILE_LINK = '/setting/profile';

export const HOME_PAGE = {
  name: 'HOME_PAGE',
  label: 'Home',
  labelKey: 'home',
  dataTestId: 'nav-item-home',
  Icon: HomeIcon,
  endpoint: COMMUNITY_PORTAL_HOMEPAGE_ENDPOINT,
  mobileIcon: HouseIcon,
  mobileActiveIcon: HouseFilledIcon,
};
export const EVENTS_PAGE = {
  name: 'EVENTS_PAGE',
  label: 'Events',
  labelKey: 'events',
  dataTestId: 'nav-item-events',
  Icon: LiveSessionIcon,
  endpoint: COMMUNITY_PORTAL_EVENTS_PAGE_ENDPOINT,
  mobileIcon: CalendarDaysIcon,
  mobileActiveIcon: CalendarDaysFilledIcon,
};
export const LIBRARY_PAGE = {
  name: 'LIBRARY_PAGE',
  label: 'Content',
  labelKey: 'content',
  dataTestId: 'nav-item-library',
  Icon: RowIcon,
  endpoint: COMMUNITY_PORTAL_LIBRARY_PAGE_ENDPOINT,
  mobileIcon: RowFilledIcon,
  mobileActiveIcon: RowFilledIcon,
};
export const LIBRARY_FOLDER_PAGE = {
  name: 'LIBRARY_FOLDER_PAGE',
  label: 'Library Folder',
  labelKey: 'library-folder',
  dataTestId: 'nav-item-folder',
  Icon: LibraryIcon,
  endpoint: COMMUNITY_PORTAL_LIBRARY_FOLDER_PATH,
  mobileIcon: RowFilledIcon,
  mobileActiveIcon: RowFilledIcon,
};
export const MEMBERS_PAGE = {
  name: 'MEMBERS_PAGE',
  label: 'Members',
  labelKey: 'members',
  dataTestId: 'nav-item-member',
  Icon: MembersIcon,
  endpoint: COMMUNITY_PORTAL_MEMBERS_PAGE_ENDPOINT,
  mobileIcon: ProfileIcon,
  mobileActiveIcon: ProfileFilledIcon,
};
export const MEET_AN_EXPERT_PAGE = {
  name: 'MEET_AN_EXPERT_PAGE',
  label: 'Meet an Expert',
  labelKey: 'meet-an-expert',
  dataTestId: 'nav-item-expert',
  Icon: ConversationIcon,
  endpoint: COMMUNITY_PORTAL_MEET_AN_EXPERT_PAGE_ENDPOINT,
  mobileIcon: ChatIcon,
  mobileActiveIcon: ChatFilledIcon,
};

export const pagesList = [
  HOME_PAGE,
  EVENTS_PAGE,
  LIBRARY_PAGE,
  MEMBERS_PAGE,
  MEET_AN_EXPERT_PAGE,
];
