import { formatDate, getNextBillingDateFromDate } from '../utils/dateUtils';
import { useCallback, useEffect, useState } from 'react';

import { cancelSubscription } from '../apiServices/cancelSubscriptionService';
import { getCommunityData } from '../apiServices';
import { storageUtils } from '../../PortalModeWrapper/utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PENDING_STATUS = 'Pending';

const useCommunitiesData = (communityId) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const userEmail = useMemo(() => user?.email, [user]);
  const paramActiveCommunityId = useMemo(() => {
    const activePagePaths = window.location.pathname.split('/');
    const [root, community_portal, activeCommunityId, route] = activePagePaths;
    return activeCommunityId;
  }, [window.location.pathname]);
  const communityCode = useMemo(
    () => data?.communities?.find((c) => c.id === communityId)?.code,
    [communityId, data?.communities]
  );
  const { t } = useTranslation();

  const doesCommunityIdExist = useMemo(() => {
    if (data) {
      // If the data exists
      if (communityCode) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [communityCode, data]);

  const getDiscountTagText = ({ intervalCount, value, trialDays }) => {
    // Ensure backward compatibility by making all that does not have a intervalAccount = forever/EY
    if (trialDays) {
      if (trialDays === 30) {
        return t('1-month-free-trial');
      } else {
        return t('trialdays-days-free-trial', { trialDays });
      }
    } else {
      if (intervalCount) {
        if (intervalCount === 0) {
          return t('value-forever', { percentage: value });
        } else {
          return t('percentage-discount-tag', {
            percentage: value,
            intervalCount: intervalCount === 12 ? '1' : intervalCount,
            interval:
              intervalCount === 12
                ? t('year')
                : intervalCount > 1
                ? t('months')
                : t('month'),
          });
        }
      } else {
        return t('value-forever', { percentage: value });
      }
    }
  };

  const fetchAndSetData = useCallback(async () => {
    if (!userEmail) return;

    setLoading(true);

    const currentActiveCommunity =
      paramActiveCommunityId || storageUtils.readActiveCommunity() || data?._id;
    const { communities, activeCommunity } = await getCommunityData(
      currentActiveCommunity
    );

    if (activeCommunity) {
      storageUtils.storeActiveCommunity(activeCommunity?._id);
    }
    const formattedCommunities = communities
      ?.filter((item) => {
        const subscription = item?.subscriptions?.find(
          (f) => userEmail === f.email
        );

        //if the subscription is available, we need to make sure the status is not pending
        //`Pending` means that user has not paid yet or the application is still pending
        if (subscription) {
          return subscription.status !== PENDING_STATUS;
        }

        //if the subscription is not available, that community has to be a free one
        return item.isFreeCommunity;
      })
      .map((item) => {
        const subscription = item?.subscriptions?.find(
          (f) => userEmail === f.email
        );

        const {
          stripeCurrency,
          xenditCurrency,
          xenditPrice,
          stripePrice,
          intervalCount,
          interval,
          amount,
        } = subscription ?? {};

        const isManualEnrolment =
          item.isPaidCommunity &&
          !stripePrice &&
          !stripeCurrency &&
          !xenditPrice &&
          !xenditCurrency;
        const isManager =
          item?.membersWithRoles?.[0]?.role.some((f) => f === 'manager') ??
          false;
        const isFreeTrial =
          subscription?.discountdetails?.promoDetails?.trialDays > 0;
        const isIntervalDiscount =
          subscription?.discountdetails?.promoDetails?.intervalCount;
        const discount = subscription?.discountdetails?.promoDetails?.value;

        const price = (stripePrice ? stripePrice : xenditPrice) / 100 || 0;
        const paidPrice = isFreeTrial
          ? price
          : isIntervalDiscount
          ? price
          : discount
          ? (price - (price * discount) / 100).toFixed(2)
          : price;

        return {
          code: item?.code,
          name: item?.title,
          By: item?.By,
          img: item?.thumbnailImgData,
          isFreeTrial,
          isIntervalDiscount,
          discountTagText: discount
            ? getDiscountTagText(
                subscription?.discountdetails?.promoDetails ?? {}
              )
            : '',
          isFreeCommunity: item.isFreeCommunity ? true : false,
          isPaidCommunity: item.isPaidCommunity ? true : false,
          isTokenGated: item?.isTokenGated ?? false,
          isManualEnrolment: isManualEnrolment ?? false,
          nextBillingDate: subscription?.nextBillingDate
            ? formatDate(subscription.nextBillingDate)
            : getNextBillingDateFromDate(
                subscription?.applicationReviewDate,
                subscription?.status,
                subscription?.cancelledAt
              ),
          status: subscription?.status,
          pricePerMonthString: `${
            stripeCurrency ? stripeCurrency : xenditCurrency ?? 'USD'
          } ${paidPrice}/${(intervalCount ?? 1) > 1 ? intervalCount : ''}${
            interval ?? 'month'
          }`,
          id: item?._id,
          discountedCommunity: {
            couponName: subscription?.discountdetails?.promoDetails?.code,
            couponValue: subscription?.discountdetails?.promoDetails?.value,
          },
          isDiscountedPricing: subscription?.discountdetails?.promoDetails
            ?.value
            ? true
            : false,
          isSubscriptionFree: (amount ?? 0) === 0 ? true : false,
          isSubscriptionPaid: amount > 0 ? true : false,
          subscription: item?.subscriptions?.[0] ?? {},
          isMember: true,
          isManager,
          communityEventsPurchased: item?.communityEventsPurchased ?? {},
          communityFoldersPurchased: item?.communityFoldersPurchased ?? {},
          subscriptionObjectId: subscription?._id,
          stripeSubscriptionId: subscription?.stripeSubscriptionId,
          membersWithRoles: item?.membersWithRoles,
        };
      });
    setData({ communities: formattedCommunities, activeCommunity });
    setLoading(false);
  }, [data?._id, paramActiveCommunityId, userEmail]);

  const onCancelSubscriptionClick = useCallback(
    async (cancellationReason) => {
      const community = data?.communities?.find((f) => f.code === communityId);
      setLoading(true);
      const response = await cancelSubscription(
        community?.stripeSubscriptionId,
        cancellationReason
      );
      setLoading(false);
      await fetchAndSetData();
    },
    [communityId, data?.communities, fetchAndSetData]
  );

  useEffect(() => {
    fetchAndSetData();
    // dont want it fetched twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id, paramActiveCommunityId, userEmail]);

  return {
    doesCommunityIdExist,
    communities: data.communities,
    activeCommunityData: data.activeCommunity,
    loading,
    onCancelSubscriptionClick,
    getCommunitiesData: fetchAndSetData,
  };
};

export default useCommunitiesData;
