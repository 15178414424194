export const UPCOMING_EVENTS_TAB = 'upcomingEvents';
export const PAST_EVENTS_TAB = 'pastEvents';
export const ONGOING_EVENTS_TAB = 'ongoing';

export const ZOOM_LABEL = (t) => t('on-zoom');
export const ZOOM_LINK = 'https://zoom.us/';

export const [EVENT_TYPE_LIVE, EVENT_TYPE_IN_PERSON] = ['live', 'inPerson'];

export const defaultHostProfileImage =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/png/placeholder-guest-profile-picture.png';
