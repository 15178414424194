import {
  adminCommunityURL,
  communitiesURL,
  lastInteractedCommunityURL,
  sharedRequestOptions,
} from './constants';

import { getAxiosError } from '../../services/helper';
import protectedAxiosRequest from '../../services/helper/protectedAxiosRequest';

export const getCommunityData = async (communityId = '') => {
  try {
    const response = await protectedAxiosRequest.get(
      communitiesURL(communityId),
      sharedRequestOptions
    );

    return {
      communities: response?.data?.communities,
      activeCommunity: response?.data?.activeCommunity?.[0],
    };
  } catch (error) {
    return getAxiosError(error);
  }
};

export const getLastInteractedCommunity = async () => {
  try {
    const response = await protectedAxiosRequest.get(
      lastInteractedCommunityURL(),
      sharedRequestOptions
    );
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};

export const getAdminCommunityData = async () => {
  try {
    const response = await protectedAxiosRequest.get(adminCommunityURL);
    return response?.data;
  } catch (error) {
    return getAxiosError(error);
  }
};
