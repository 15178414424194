import TimeFormatter from './timeFormatter';

export const convertCourseTimeFromToMinAndHours = (timeInSecs) => {
  const hours = Math.floor(timeInSecs / 3600);
  const minutes = Math.floor((timeInSecs - hours * 3600) / 60);
  const remainingSecs = timeInSecs - hours * 3600 - minutes * 60;
  return {
    hours: hours > 0 ? hours : null,
    minutes: minutes > 0 ? minutes : null,
    secs: remainingSecs,
  };
};

// return 10h 30m 20s format
export const getFormattedTimeStrFromMs = (timeInMs) => {
  const { hours, minutes, secs } = convertCourseTimeFromToMinAndHours(
    timeInMs / 1000
  );
  let formattedStr = '';

  if (hours) formattedStr += `${hours}h`;
  if (minutes) formattedStr += ` ${minutes}m`;
  if (secs) formattedStr += ` ${secs}s`;

  return formattedStr.trim();
};

export const convertSubUnitWatchTime = (durationInSecs, currentTimeInSecs) => {
  const formattedDuration = convertCourseTimeFromToMinAndHours(durationInSecs);
  const formattedRemainingTime = convertCourseTimeFromToMinAndHours(
    durationInSecs - currentTimeInSecs
  );
  return {
    durationMin: formattedDuration?.minutes,
    durationSecs: formattedDuration?.secs,
    durationHours: formattedDuration?.hours,
    remainingMin: formattedRemainingTime?.minutes,
    remainingSecs: formattedRemainingTime?.secs,
    remainingHours: formattedRemainingTime?.hours,
  };
};

export const formatDateFromNow = (startDate) => {
  const now = new Date().toISOString();
  const countdown = TimeFormatter.calculateCountdown(startDate, now);
  const { sec, min, hours, days, years } = countdown;
  if (years > 0) {
    return new Date(startDate).toLocaleDateString();
  } else if (days > 0) {
    if (days > 31) {
      return new Date(startDate).toLocaleDateString();
    } else {
      return `${days} days ago`;
    }
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (min > 0) {
    return `${min} minutes ago`;
  } else if (sec > 0) {
    return `${sec} secs ago`;
  }
};
