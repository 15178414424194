import './Select.scss';

import DiscordV2 from '../../Icons/DiscoardV2/DiscoardV2';
import FacebookGreyIcon from '../../Icons/FacebookGreyIcon';
import FacebookIconV2Colored from '../../Icons/FacebookIconV2Colored/FacebookIconV2Colored';
import InstagramIconV2Colored from '../../Icons/InstagramIconV2Colored/InstagramIconV2Colored';
import LinkedIconV2Colored from '../../Icons/LinkedIconV2Colored/LinkedIconV2Colored';
import OpenSeaV2Colored from '../../Icons/OpenSeaV2Colored/OpenSeaV2Colored';
import { PropTypes } from 'prop-types';
import React from 'react';
import TiktokV2 from '../../Icons/TiktokV2/TiktokV2';
import TwitterIconV2Colored from '../../Icons/TwitterIconV2Colored/TwitterIconV2Colored';
import WebsiteIconV2 from '../../Icons/WebsiteIconV2/WebsiteIconV2';
import YoutubeIconV2Colored from '../../Icons/YoutubeIconV2Colored/YoutubeIconV2Colored';

const Select = ({
  label,
  placeholder,
  optionsList,
  value,
  onChange,
  error,
  register,
  name,
  defaultValue,
  v2,
  renderLogo,
  customInnerStyle,
  noChevron = true,
  ...props
}) => {
  const className = 'c-Select';
  const baseClassNames = [
    className,
    error ? ` ${className}--error` : '',
    v2 ? ` ${className}--v2` : '',
    v2 && error ? ` ${className}--v2--error` : '',
    !value ? ` ${className}--show-placeholder` : '',
  ].join('');

  const hasOptionsDisplay =
    optionsList && optionsList.some((option) => option.optionDisplay);

  const renderLogoIcon = (type) => {
    switch (type) {
      case 'facebook':
        return <FacebookIconV2Colored />;
      case 'instagram':
        return <InstagramIconV2Colored />;
      case 'youtube':
        return <YoutubeIconV2Colored />;
      case 'twitter':
        return <TwitterIconV2Colored />;
      case 'Tiktok':
        return <TiktokV2 />;
      case 'linkedin':
        return <LinkedIconV2Colored />;
      case 'Discord':
        return <DiscordV2 />;
      case 'OpenSea':
        return <OpenSeaV2Colored />;
      case 'Website':
        return <WebsiteIconV2 />;
      default:
        return <FacebookGreyIcon customClassName={'w-16 h-16'} />;
    }
  };
  return (
    <div className={baseClassNames}>
      <div
        className={`${className}__container ${
          noChevron && `${className}__container--no-chevron`
        } relative`}
      >
        {label ? (
          <label className={`${className}__label`}>{label}</label>
        ) : null}
        <select
          value={value}
          onChange={onChange}
          {...props}
          ref={register}
          name={name}
          defaultValue={defaultValue}
        >
          <option value="" disabled>
            {placeholder || '--- Select ---'}
          </option>
          {optionsList?.map(({ label, value }, key) => {
            return (
              <option key={key} value={value} selected={value === defaultValue}>
                {label}
              </option>
            );
          })}
        </select>
        {hasOptionsDisplay && (
          <select
            {...props}
            value={value}
            onChange={onChange}
            className={`absolute top-0 right-0 opacity-0 ${
              props.className ?? ''
            }`}
            ref={register}
            name={name}
            defaultValue={defaultValue}
          >
            <option value="" disabled>
              {placeholder || '--- Select ---'}
            </option>
            {optionsList?.map(({ label, optionDisplay, value }, key) => {
              return (
                <option
                  key={key}
                  value={value}
                  selected={value === defaultValue}
                >
                  {optionDisplay ? optionDisplay : label}
                </option>
              );
            })}
          </select>
        )}
        {error ? <p className={`${className}__error`}>{error}</p> : null}
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  error: PropTypes.string,
  v2: PropTypes.bool,
};

export default Select;
