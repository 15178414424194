import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import bookingReducer from '../reducers/booking';
import courseOverviewReducer from '../reducers/courseOverview';
import featureFlagReducer from '../reducers/featureFlag';
import mainReducer from '../reducers/main';
import openModalsDataReducer from '../reducers/openModalsData';
import routesReducer from '../reducers/routes';
import userReducer from '../reducers/user';

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      main: mainReducer,
      routes: routesReducer,
      booking: bookingReducer,
      courseOverview: courseOverviewReducer,
      user: userReducer,
      featureFlag: featureFlagReducer,
      openModalsData: openModalsDataReducer,
    }),
    applyMiddleware(thunk)
  );

  return store;
};
