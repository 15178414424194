import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCommunityPortalContext } from '../../CommunityPortal/contexts/CommunityPortalContext';
import { COUNTRY_CHECKOUT_TYPE } from '../../hooks/payment/usePayment';
import { STORED_ACTIVE_COMMUNITY_SESSION_CODE } from '../../PortalModeWrapper/constants';
import {
  getCommunityEventInfoForEventCheckoutById,
  getCommunityInfoForCheckout,
  getPaymentMethods,
} from '../../services/checkoutServices/communityEventCheckoutServices';
import { getQueryParams } from '../../utility/domHelpers';
import sessionStorageService from '../../utility/sessionStorageService';
import { redirectToUnifiedCheckout } from '../../utility/checkoutHelper';
export const EventsCheckoutContext = createContext();
export const EventsCheckoutContextProvider = ({ children }) => {
  const [checkoutEventInfo, setCheckoutEventInfo] = useState('');
  const [eventCommunityInfo, setEventCommunityInfo] = useState();
  const userData = useSelector((state) => state?.user?.user);
  const [eventCommunityInfoLoader, setEventCommunityInfoLoader] = useState(
    true
  );
  const queryParams = getQueryParams();
  const [fetchedPaymentMethods, setFetchedPaymentMethods] = useState(null);
  const { country } = useCommunityPortalContext();

  // This deterimines what payment methods gets shown. The list of methods allowed in country is returned from BE.
  const getPaymentMethodsHandler = async (communityId) => {
    const { data, error } = await getPaymentMethods({
      country,
      communityId,
      checkoutType: COUNTRY_CHECKOUT_TYPE.ONETIME,
    });
    if (error) return;

    setFetchedPaymentMethods(data?.paymentModesAllowed);
  };

  const getEventInformation = async (eventObjectId, communityObjectId) => {
    try {
      const { data, error } = await getCommunityEventInfoForEventCheckoutById(
        communityObjectId,
        eventObjectId
      );
      if (error) {
        throw new Error(error);
      }

      if (data) {
        setCheckoutEventInfo(data);
        setEventCommunityInfoLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCommunityInfo = async (communityObjectId) => {
    try {
      const { data, error } = await getCommunityInfoForCheckout(
        communityObjectId
      );

      if (data) {
        setEventCommunityInfo(data);
      }
      if (error) {
        throw new Error(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (queryParams) {
      const { entityId, communityObjectId } = queryParams;

      if (entityId && communityObjectId) {
        getEventInformation(entityId, communityObjectId);
        getCommunityInfo(communityObjectId);

        sessionStorageService.setItem(
          STORED_ACTIVE_COMMUNITY_SESSION_CODE,
          communityObjectId
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (queryParams && country && userData) {
      const { communityObjectId } = queryParams;
      getPaymentMethodsHandler(communityObjectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, userData]);

  useEffect(() => {
    if (userData && eventCommunityInfo && checkoutEventInfo) {
      redirectToUnifiedCheckout({
        communityInfo: eventCommunityInfo,
        entityType: 'event',
        entityInfo: checkoutEventInfo,
      });
    }
  }, [checkoutEventInfo, eventCommunityInfo, userData]);

  const value = {
    checkoutEventInfo,
    setCheckoutEventInfo,
    eventCommunityInfo,
    setEventCommunityInfo,
    eventCommunityInfoLoader,
    fetchedPaymentMethods,
    country,
  };

  return (
    <EventsCheckoutContext.Provider value={value}>
      {children}
    </EventsCheckoutContext.Provider>
  );
};

export const useEventsCheckoutContext = () => useContext(EventsCheckoutContext);
