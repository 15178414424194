import PropTypes from 'prop-types';
import classNames from 'classnames';

const buttonType = {
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  outline: 'outline',
  neutral: 'neutral',
  'static-primary': 'static-primary',
  danger: 'danger',
  custom: 'custom',
  plain: 'plain',
};

const buttonSize = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

const RoundedButton = ({
  displayType,
  size,
  children,
  disabled,
  customClassNames,
  isLoading,
  ...btnProps
}) => {
  const getDisplayTypeClassnames = ({ type }) => {
    switch (type) {
      case buttonType.primary:
        return 'bg-npl-yellow-light-9 hover:bg-npl-yellow-light-10 active:bg-npl-yellow-light-10 text-npl-text-icon-on-light-surface-primary';
      case buttonType.secondary:
        return 'bg-none';
      case buttonType.disabled:
        return 'bg-npl-neutral-light-5 text-neutral-70 cursor cursor-not-allowed';
      case buttonType.outline:
        return 'bg-none border-1 border-neutral-70 hover:bg-button-outline-hover';
      case buttonType.neutral:
        return 'bg-neutral-10 text-npl-text-icon-on-dark-primary hover:bg-neutral-40 active:bg-neutral-50';
      case buttonType.danger:
        return 'bg-npl-error-light-9 text-npl-text-icon-on-dark-primary';
      case buttonType['static-primary']:
        return 'bg-npl-neutral-dark-3 text-npl-text-icon-on-dark-primary';
      case buttonType.plain:
        return 'text-npl-text-icon-on-light-surface-primary hover:bg-npl-neutral-light-3 active:bg-npl-neutral-light-5';
      default:
        return 'bg-primary';
    }
  };

  const getSizeClassnames = ({ size }) => {
    switch (size) {
      case buttonSize.sm:
        return 'h-32 text-button-md font-medium px-16';
      case buttonSize.md:
        return 'h-[44px] text-button-md font-medium px-24';
      case buttonSize.lg:
        return 'h-[56px] text-18 font-medium px-24';
      default:
        return 'h-[44px] text-16 font-medium px-24';
    }
  };

  const allClassNames = classNames(
    'font-poppins rounded-full flex space-x-8 items-center cursor-pointer text-neutral-10 duration-150',
    getDisplayTypeClassnames({
      type: disabled ? buttonType.disabled : displayType,
    }),
    getSizeClassnames({ size }),
    customClassNames
  );

  return (
    <button
      className={`${allClassNames} disabled:opacity-50`}
      disabled={disabled || isLoading}
      {...btnProps}
    >
      {isLoading && (
        <div className="w-16 h-16 border-2 rounded-full animate-spin border-neutral-10 border-l-transparent"></div>
      )}
      <span>{children}</span>
    </button>
  );
};

RoundedButton.defaultProps = {
  displayType: buttonType.secondary,
  size: buttonSize.md,
  isLoading: false,
};

RoundedButton.propTypes = {
  displayType: PropTypes.oneOf(Object.values(buttonType)),
  size: PropTypes.oneOf(Object.values(buttonSize)),
  disabled: PropTypes.bool,
  customClassNames: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default RoundedButton;
