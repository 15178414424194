import './CommonSettingWidget.scss';

const CommonSettingWidget = ({ header, children }) => {
  const className = 'c-CommonSettingWidget';

  return (
    <div className={`${className} px-16 mt-32`}>
      <div className={`${className}__header`}>{header}</div>
      <>{children}</>
    </div>
  );
};

export default CommonSettingWidget;
