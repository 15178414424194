import React from 'react';

const ProfileFilledIcon = () => {
  return (
    <svg
      className="c-ProfileFilledIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1B1B18"
        fillRule="evenodd"
        d="M17.294 7.291A5.274 5.274 0 0112 12.583a5.275 5.275 0 01-5.294-5.292A5.274 5.274 0 0112 2a5.273 5.273 0 015.294 5.291zM12 22c-4.338 0-8-.705-8-3.425 0-2.721 3.685-3.401 8-3.401 4.339 0 8 .705 8 3.425C20 21.32 16.315 22 12 22z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ProfileFilledIcon;
