import * as Sentry from '@sentry/react';
import axios from 'axios';
import keys from '../config/keys';
import Auth from '../modules/auth';
import history from '../modules/history';
import protectedAxiosRequest from '../services/helper/protectedAxiosRequest';
import { clearTrackUserId, initializeTrackUserId } from '../utility/analytics';
import {
  setLoading,
  setLoginFailed,
  setResetPasswordFailed,
  setSuccess,
} from './main';
import { setToken, setUser } from './user';
import { setCurrentRoute } from './routes';
import sessionStorageService from '../utility/sessionStorageService';
import { switchToUserPreferredLanguage } from '../utility/locale';

const { baseURL, authApiBaseUrl } = keys;

export const login = (id, user) => ({
  type: 'LOGIN',
  id,
  user,
});

export const signup = (user) => ({
  type: 'SIGNUP',
  user,
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const setCreatorFeatureFlag = (flag) => ({
  type: 'SET_CREATOR_FF',
  payload: flag,
});

export const resetPassword = () => ({
  type: 'TRIGGER_RESETPASSWORD',
});

export const verifyEmail = () => ({
  type: 'VERIFY_EMAIL',
});

export const updateAuthUserData = (user) => ({
  type: 'UPDATE_USER_DATA',
  user,
});

const _checkFieldNotEmpty = (field) => {
  return field && field !== '';
};

const _passwordIsStrong = (password) => {
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  return strongRegex.test(password);
};

export const startSignUp = (email, password, confirmPassword, token) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    let dt = {};
    // Check token
    if (token) {
      dt = { email, password, password2: confirmPassword, token };
    } else {
      dt = { email, password, password2: confirmPassword };
    }
    if (
      _checkFieldNotEmpty(password) &&
      _checkFieldNotEmpty(confirmPassword) &&
      password === confirmPassword
    ) {
      if (password !== confirmPassword) {
        dispatch(setLoading(false));
        dispatch(setLoginFailed('Confirm Password does not match.'));
      } else if (!_passwordIsStrong(password)) {
        dispatch(setLoading(false));
        dispatch(
          setLoginFailed(
            'Please choose a stronger password. Password has to contain at least 1 uppercase alphabetical character, 1 lowercase alphabetical character, 1 numeric character and eight characters or longer'
          )
        );
      } else {
        axios
          .post(`${baseURL}/api/v1/sign-up`, dt, {
            withCredentials: true,
          })
          .then((response) => {
            const { token, refresh_token, user } = response.data;
            let userData = {};
            if (user.learner_role) {
              userData = user.learner;
              userData.learner_role = user.learner_role;
            } else if (user.instructor_role) {
              userData = user.trainer;
              userData.instructor_role = user.instructor_role;
            }
            userData.user_id = user.user_id;

            // Set user data
            Auth.authenticateUser(userData, token);
            Auth.setTokens(token, refresh_token);

            dispatch(setUser(userData));

            dispatch(setLoginFailed(false));
            dispatch(login(user._id, user));
            dispatch(setLoading(false));
            // clear token on route params and route to home page
            history.push({
              pathname: '/',
              search: '',
            });
          })
          .catch((err) => {
            Sentry.captureException(err.response);
            if (err.response) {
              dispatch(setResetPasswordFailed(err.response.data.message));
            } else {
              dispatch(setResetPasswordFailed(err.response));
            }
          });
      }
    } else {
      dispatch(setLoginFailed('Invalid password'));
      dispatch(setLoading(false));
    }
  };
};

const _authenticationPromise = (callback) => {
  return new Promise((resolve, reject) => {
    resolve(callback);
  });
};

const _postLoginProcess = (userData, token, refreshToken, dispatch) => {
  Auth.authenticateUser(userData, token);
  Auth.setTokens(token, refreshToken);
  dispatch(setUser(userData));
};

export const startLogin = (email, password, currentRoute) => {
  return (dispatch) => {
    const dt = { email, password };
    dispatch(setLoading(true));

    axios
      .post(`${baseURL}/api/v1/log-in`, dt, {
        withCredentials: true,
      })
      .then((response) => {
        const { token, refresh_token } = response.data;
        const { user } = response.data;
        let userData = {};
        if (user.learner_role) {
          userData = user.learner;
          userData.learner_role = user.status;
          userData.status = user.learner_role;
        } else if (user.instructor_role) {
          userData = user.trainer;
          userData.instructor_role = user.instructor_role;
        }
        userData.user_id = user.user_id;

        switchToUserPreferredLanguage(user);

        // Set user data
        _authenticationPromise(
          _postLoginProcess(userData, token, refresh_token, dispatch)
        ).then(() => {
          dispatch(setLoginFailed(false));
          dispatch(login(user._id, user));
          dispatch(setLoading(false));
          if (!currentRoute || currentRoute.includes('/login'))
            history.push({
              pathname: '/',
              search: '',
            });
          else history.push(currentRoute);
        });
      })
      .catch((err) => {
        Sentry.captureException(err.response);
        if (err.response) {
          dispatch(setLoginFailed(err.response.data.message));
        } else {
          dispatch(setLoginFailed(err.response));
        }
        dispatch(setLoading(false));
      });
  };
};

export const getTokensService = (payload) => {
  return axios
    .post(`${baseURL}/api/v1/get-token`, payload, {
      withCredentials: true,
    })
    .then((response) => {
      const { token, refresh_token } = response.data;
      Auth.setTokens(token, refresh_token);
      Auth.deleteUriTokens(token);
      return response;
    });
};

/**
 * Payload should send either authToken or refreshToken
 * @param {*} payload | Object
 * @param {*} payload.authToken | String
 * @param {*} payload.refreshToken | String
 * @param {*} currentRoute
 * @returns Promise
 */
// export const startGetTokens = (socialToken, currentRoute) => {
export const startGetTokens = (payload, currentRoute) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    getTokensService(payload)
      .then((response) => {
        const { token, refresh_token } = response.data;

        Auth.setTokens(token, refresh_token);
        dispatch(setLoading(false));
        dispatch(startLoginCheck(token, refresh_token, currentRoute));
      })
      .catch((err) => {
        Sentry.captureException(err.response);
        if (err.response) {
          dispatch(setLoginFailed(err.response.data.message));
        } else {
          dispatch(setLoginFailed(err.response));
        }
        dispatch(setLoading(false));
      });
  };
};

const setData = async (dispatch, userData, token) => {
  Auth.setUserData(userData);
  Auth.authenticateUser(userData, token);
  dispatch(setUser(userData));
  dispatch(setToken(token));
};

export const startLoginCheck = (
  token,
  refreshToken,
  currentRoute,
  email = ''
) => {
  return (dispatch) => {
    if (token) {
      // Check if token expired
      const AuthStr = 'Bearer '.concat(token);
      protectedAxiosRequest
        .get(`${baseURL}/api/v1/user-profile`, {
          headers: {
            Authorization: AuthStr,
          },
        })
        .then(async (response) => {
          const promoCode = response?.data?.learner?.promoCode;
          Auth.setReferralCode(promoCode);
          const user = response.data;
          let userData = {};
          if (user.learner_role) {
            userData = user.learner;
            userData.learner_role = user.learner_role;
            userData.status = user.status;
          } else if (user.instructor_role) {
            userData = user.trainer;
            userData.instructor_role = user.instructor_role;
          }
          userData.user_id = user.user_id;

          switchToUserPreferredLanguage(user);

          //GA user tracking
          initializeTrackUserId(user.user_id);

          await setData(dispatch, userData, token);

          dispatch(setLoginFailed(false));
          dispatch(login(user._id, user));
          dispatch(setLoading(false));

          if (currentRoute === '/login') {
            history.push({ pathname: '/', search: '' });
          } else if (currentRoute === '/') {
            history.push({ pathname: currentRoute, search: '' });
          }
        })
        .catch((err) => {
          Sentry.captureException(err.response);
          Auth.deauthenticateUser();
          dispatch(setLoginFailed(true));
          history.push({ pathname: '/login', search: '' });
        });
    } else if (currentRoute === '/login') {
      // Already in /Login route so dont redirect again
      dispatch(setLoginFailed(true));
    } else {
      if (currentRoute === '/login') return;

      const redirectTo = currentRoute === '/login' ? '' : window.location.href;
      let queryStr = redirectTo ? `?redirectTo=${redirectTo}&` : '';

      if (email) {
        queryStr += `email=${encodeURIComponent(email)}`;
      }
      window.location.href = `${process.env.REACT_APP_NASIO_BASE_URL}/login${queryStr}`;
    }
  };
};

export const startLogout = (currentRoute) => {
  return (dispatch) => {
    Auth.deauthenticateUser();
    sessionStorageService.clear();
    dispatch(setCurrentRoute(currentRoute));
    dispatch(setLoginFailed(false));
    dispatch(logout());

    //GA user tracking
    clearTrackUserId();
    if (currentRoute !== '/login') {
      window.location.href = `${process.env.REACT_APP_NASIO_BASE_URL}/login${window.location.search}`;
    }
  };
};

export const triggerResetPassword = ({ email = '', phoneNumber = '' }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setSuccess(false));
    axios
      .post(`${authApiBaseUrl}/api/v1/forget-password`, { email, phoneNumber })
      .then((response) => {
        dispatch(setSuccess('A request for reset password has been sent.'));
        dispatch(setResetPasswordFailed(''));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        Sentry.captureException(err.response);
        dispatch(
          setResetPasswordFailed(err?.response?.data?.message || err?.message)
        );
        dispatch(setLoading(false));
      });
  };
};

export const resetTriggerResetPasswordSuccess = () => {
  return (dispatch) => {
    dispatch(setSuccess(''));
  };
};

export const sendResetPassword = (
  password,
  confirmPassword,
  token,
  oldPassword = '',
  bearerToken = '',
  afterResetHandler
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    if (password === '' || confirmPassword === '') {
      dispatch(setLoading(false));
      dispatch(setResetPasswordFailed('Please key in password.'));
    } else if (password !== confirmPassword) {
      dispatch(setLoading(false));
      dispatch(setResetPasswordFailed('Confirm Password does not match.'));
    } else if (password === oldPassword) {
      dispatch(setLoading(false));
      dispatch(
        setResetPasswordFailed(
          'New password cannot be the same as your previous password.'
        )
      );
    } else if (!_passwordIsStrong(password)) {
      dispatch(setLoading(false));
      dispatch(
        setResetPasswordFailed(
          'Password is not strong enough.\nPassword has to contain at least 1 uppercase alphabetical character.\n1 lowercase alphabetical character.\n1 numeric character and eight characters or longer\n'
        )
      );
    } else {
      let dt;
      let apiUrl;
      let headers;

      // Reset password with previous password
      if (
        !token &&
        oldPassword &&
        oldPassword !== '' &&
        bearerToken &&
        bearerToken !== ''
      ) {
        dt = {
          password,
          password2: confirmPassword,
          oldPassword,
        };
        // protected endpoint so not on auth-service, its on learning-portal-backend
        apiUrl = `${baseURL}/api/v1/auth/reset-password`;
        headers = { headers: { Authorization: bearerToken } };
      }

      // Reset password with token
      else if (token && token !== '') {
        dt = {
          password,
          password2: confirmPassword,
        };
        apiUrl = `${authApiBaseUrl}/api/v1/reset-password/${token}`;
        headers = { headers: {} };
      }

      axios
        .post(apiUrl, dt, headers)
        .then((response) => {
          if (afterResetHandler) {
            afterResetHandler();
          }
          dispatch(setSuccess('Password has been reset.'));
          dispatch(setLoading(false));
          dispatch(setResetPasswordFailed(false));
        })
        .catch((err) => {
          Sentry.captureException(err.response);
          if (err.response) {
            dispatch(setResetPasswordFailed(err.response.data.message));
          } else {
            dispatch(setResetPasswordFailed(err.response));
          }
          dispatch(setLoading(false));
        });
    }
  };
};

export const setLoginFailStatus = (message) => {
  return (dispatch) => {
    dispatch(setLoginFailed(message));
  };
};

export const sendVerifyEmailToken = (token) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setSuccess(false));
    axios
      .post(`${baseURL}/api/v1/verify-email/${token}`)
      .then((response) => {
        dispatch(
          setSuccess(
            'Email has been verified. You will be redirected to login.'
          )
        );
        _redirect('/login', 4000, dispatch);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        Sentry.captureException(err.response);
        if (err.response) {
          dispatch(
            setResetPasswordFailed(
              `${err.response.data.message}. You will be redirected to login`
            )
          );
        } else {
          dispatch(
            setResetPasswordFailed(
              `${err.response}. You will be redirected to login`
            )
          );
        }
        _redirect('/login', 4000, dispatch);
        dispatch(setLoading(false));
      });
  };
};

const _redirect = (route, delay, dispatch) => {
  setTimeout(() => {
    dispatch(setLoginFailed(false));
    dispatch(setSuccess(false));
    history.replace({
      pathname: route,
      search: '',
    });
  }, delay);
};
