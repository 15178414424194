import React from 'react';

const CheckIcon = () => {
  const className = 'c-CheckIcon';

  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#007a45"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 5.25l-5.5 5.5L4 8.25"
      ></path>
    </svg>
  );
};

export default CheckIcon;
