import { PropTypes } from 'prop-types';
import React from 'react';
import keys from '../../../config/keys';
import { Link } from 'react-router-dom';
import Image from '../../Image';
import './AuthenticationWrapper.scss';
import { loginBackground, login_logo } from './constant';

const { mainWebsiteUrl } = keys;

const AuthenticationWrapper = ({ formChildren }) => {
  const className = 'c-AuthenticationWrapper';

  return (
    <div className={className}>
      <div className={`flex flex-row justify-center`}>
        <Image customImgClassname={'h-screen'} {...loginBackground} />
        <div className="flex flex-col justify-center px-24">
          <Link
            to={{ pathname: mainWebsiteUrl }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={`${className}__logo__wrapper`}>
              <Image {...login_logo} />
            </div>
          </Link>
          <div className={`${className}__subtext`}>
            Login For a Peek Into the World of People
          </div>
          <div className={`${className}__form__wrapper `}>{formChildren}</div>
        </div>
      </div>
    </div>
  );
};

AuthenticationWrapper.propTypes = {
  formChildren: PropTypes.object,
};

export default AuthenticationWrapper;
