export const COMMUNITY_PORTAL = 'COMMUNITY_PORTAL';
export const CLASSES_PORTAL = 'CLASSES_PORTAL';
export const CREATOR_PORTAL = 'CREATOR_PORTAL';

// page endpoints
export const COMMUNITY_PORTAL_PATH = 'communities';
export const CREATOR_PORTAL_PATH = 'creator';
export const COMMUNITY_PORTAL_HOMEPAGE_ENDPOINT = 'home';
export const COMMUNITY_PORTAL_EVENTS_PAGE_ENDPOINT = 'events';
export const COMMUNITY_PORTAL_LIBRARY_PAGE_ENDPOINT = 'library';
export const COMMUNITY_PORTAL_MEMBERS_PAGE_ENDPOINT = 'members';
export const COMMUNITY_PORTAL_MEET_AN_EXPERT_PAGE_ENDPOINT = 'meet-an-trainer';
// routes
export const CLASSES_PORTAL_ROOT = '/';
export const COMMUNITY_PORTAL_ROOT = '/communities';
export const CREATOR_PORTAL_ROOT = '/creator';
export const SETTINGS_PORTAL_ROOT = '/setting';

export const COMMUNITY_PORTAL_HOME_PAGE_PATH = `/communities/:communityId/${COMMUNITY_PORTAL_HOMEPAGE_ENDPOINT}`;
export const COMMUNITY_PORTAL_EVENTS_PAGE_PATH = `/communities/:communityId/${COMMUNITY_PORTAL_EVENTS_PAGE_ENDPOINT}`;
export const COMMUNITY_PORTAL_LIBRARY_PAGE_PATH = `/communities/:communityId/${COMMUNITY_PORTAL_LIBRARY_PAGE_ENDPOINT}`;
export const COMMUNITY_PORTAL_LIBRARY_FOLDER_PATH = `/communities/:communityId/${COMMUNITY_PORTAL_LIBRARY_PAGE_ENDPOINT}/:folderId`;
export const COMMUNITY_PORTAL_MEMBERS_PAGE_PATH = `/communities/:communityId/${COMMUNITY_PORTAL_MEMBERS_PAGE_ENDPOINT}`;
export const COMMUNITY_PORTAL_TRAIN_A_MENTOR_PAGE_PATH = `/communities/:communityId/${COMMUNITY_PORTAL_MEET_AN_EXPERT_PAGE_ENDPOINT}`;
export const COMMUNITY_PORTAL_SINGLE_POST_PAGE_PATH = `/communities/:communityId/post/:postId`;
export const communityPortalPaths = [
  COMMUNITY_PORTAL_ROOT,
  COMMUNITY_PORTAL_HOME_PAGE_PATH,
  COMMUNITY_PORTAL_EVENTS_PAGE_PATH,
  COMMUNITY_PORTAL_LIBRARY_PAGE_PATH,
  COMMUNITY_PORTAL_LIBRARY_FOLDER_PATH,
  COMMUNITY_PORTAL_MEMBERS_PAGE_PATH,
  COMMUNITY_PORTAL_TRAIN_A_MENTOR_PAGE_PATH,
  COMMUNITY_PORTAL_SINGLE_POST_PAGE_PATH,
];

export const STORED_ACTIVE_COMMUNITY_SESSION_CODE =
  'STORED_ACTIVE_COMMUNITY_SESSION_CODE';
