import Input from '../../../../components/Form/Input';
import Modal from '../../../../components/Modal';
import PropTypes from 'prop-types';
import { SOCIAL_MEDIA_MODAL } from '../NewProfilePage/constants';
import Select from '../../../../components/Form/Select';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
const className = 'c-ShowSocialMediaUpdateInputs';

const ShowSocialMediaUpdateInputs = ({
  showModal,
  onDiscardChanges,
  socialLinks,
  HandleInputChangeForSocialMediaField,
  selectedSocialmediaElement,
  onSocialMediaSelectChange,
  getLabelfromSocialMediaArray,
  onSaveTheChanges,
  allsocialMediaLinks,
}) => {
  const { t } = useTranslation();
  const renderContent = useCallback(
    () => (
      <>
        <Modal
          open={showModal === SOCIAL_MEDIA_MODAL}
          onClose={onDiscardChanges}
          customContainerClass={' modelClass  '}
        >
          <div className="flex flex-col">
            {socialLinks?.map((value, index) => (
              <div
                key={index}
                className={'flex flex-col ' + className + '__socialmedia_links'}
              >
                <Input
                  customClassforInputContainer={'w-full'}
                  // label={value.type}
                  label={value.label || value.type}
                  type="text"
                  value={value.link}
                  onChange={(e) =>
                    HandleInputChangeForSocialMediaField(e, index)
                  }
                  renderLogobesidelabel={true}
                  typeoflogo={value.type}
                  showLogoBeside={true}
                  customClassForLabel="ml-8 items-center mb-0"
                  v2
                />
              </div>
            ))}

            <Select
              renderLogo={true}
              label={t('add-another')}
              value={selectedSocialmediaElement}
              onChange={(e) => {
                onSocialMediaSelectChange(e);
              }}
              optionsList={getLabelfromSocialMediaArray(allsocialMediaLinks)}
              v2
            />
            <div
              className={`${className} mt-24 flex flex-row items-center justify-end discard-changes`}
            >
              <button
                onClick={onDiscardChanges}
                className="font-medium button-md font-poppins text-neutral-60 "
              >
                {t('discard-changes')}
              </button>
              <button
                onClick={onSaveTheChanges}
                className="ml-12 c-Button bg-button-primary"
              >
                {t('save')}
              </button>
            </div>
          </div>
        </Modal>
      </>
    ),
    [
      HandleInputChangeForSocialMediaField,
      allsocialMediaLinks,
      getLabelfromSocialMediaArray,
      onDiscardChanges,
      onSaveTheChanges,
      onSocialMediaSelectChange,
      selectedSocialmediaElement,
      showModal,
      socialLinks,
    ]
  );
  return <div>{renderContent()}</div>;
};

ShowSocialMediaUpdateInputs.propTypes = {
  showModel: PropTypes.string,
  onDiscardChanges: PropTypes.func,
  socialLinks: PropTypes.arrayOf(PropTypes.object),
  HandleInputChangeForSocialMediaField: PropTypes.func,
  selectedSocialmediaElement: PropTypes.string,
  onSocialMediaSelectChange: PropTypes.func,
  getLabelfromSocialMediaArray: PropTypes.func,
  onSaveTheChanges: PropTypes.func,
  allsocialMediaLinks: PropTypes.arrayOf(PropTypes.object),
};

export default ShowSocialMediaUpdateInputs;
