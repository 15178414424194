import {
  PAST_EVENTS_ENDPOINT,
  UPCOMING_EVENTS_ENDPOINT,
} from '../apiServices/constants';
import {
  PAST_EVENTS_TAB,
  UPCOMING_EVENTS_TAB,
} from '../pages/LiveSessionsPage/constants';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getEventsData, postEventRegistration } from '../apiServices';
import usePrevious from './usePrevious';
import { cancelEventAttendanceService } from '../apiServices/eventsService';
import {
  showErrorToast,
  showToast,
} from '../components/ToastContainer/ToastContainer';
import { useTranslation } from 'react-i18next';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULTPAGE_SIZE = 10;

// TODO: refactor the hook
const useCommunityEventsData = (communityId) => {
  const { t } = useTranslation();
  const [pastEventList, setPastEventList] = useState([]);
  const [pastEventListMetadata, setPastEventListMetadata] = useState([]);
  const [isPastEventsLoading, setIsPastEventsLoading] = useState(false);
  const [pastEventPageNumber, setPastEventPageNumber] = useState(0);

  const [upcomingEventList, setUpcomingEventList] = useState([]);
  const [upcomingEventListMetadata, setUpcomingEventListMetadata] = useState(
    []
  );
  const [isUpcomingEventsLoading, setIsUpcomingEventsLoading] = useState(false);
  const [upcomingPageNumber, setUpcomingPageNumber] = useState(0);

  const isLoading = isPastEventsLoading || isUpcomingEventsLoading;

  const eventsData = useMemo(
    () => ({
      [UPCOMING_EVENTS_TAB]: upcomingEventList,
      [PAST_EVENTS_TAB]: pastEventList,
    }),
    [pastEventList, upcomingEventList]
  );

  const prevPastPageNumber = usePrevious(pastEventPageNumber);
  const prevUpcomingPageNumber = usePrevious(upcomingPageNumber);

  const registerForEvent = useCallback(
    async (eventId) => {
      const response = await postEventRegistration(eventId);

      // if success, update registered field
      if (response.status === 200) {
        setUpcomingEventList((prevEvents) => {
          const eventToUpdateIdx = prevEvents.findIndex(
            ({ _id }) => _id === eventId
          );
          if (eventToUpdateIdx === -1) return prevEvents;

          // get event to update
          let eventToUpdate = JSON.parse(
            JSON.stringify(prevEvents[eventToUpdateIdx])
          );

          // modify event status
          eventToUpdate = {
            ...eventToUpdate,
            registered: true,
            eventAttendee: {
              _id: response?.data?._id,
              status: response?.data?.status, // 'GOING'
            },
          };

          // replace old event data with modified event data
          let updatedEvents = [...prevEvents];
          updatedEvents[eventToUpdateIdx] = eventToUpdate;

          return updatedEvents;
        });

        showToast({ text: t('registration-successful') });
      }
    },
    [t]
  );

  const cancelEventRegistration = useCallback(
    async (event) => {
      const eventId = event?._id;
      const attendeeId = event?.eventAttendee?._id;

      const { error } = await cancelEventAttendanceService({
        communityId,
        eventId,
        userId: attendeeId,
      });

      if (error) {
        showErrorToast(error);
        return;
      }

      setUpcomingEventList((prevEvents) => {
        const eventToUpdateIdx = prevEvents.findIndex(
          ({ _id }) => _id === eventId
        );
        if (eventToUpdateIdx === -1) return prevEvents;

        // get event to update
        let eventToUpdate = JSON.parse(
          JSON.stringify(prevEvents[eventToUpdateIdx])
        );

        // modify event status
        eventToUpdate = {
          ...eventToUpdate,
          registered: false,
          eventAttendee: {
            ...eventToUpdate.eventAttendee,
            status: 'NOT_GOING',
          },
        };

        // replace old event data with modified event data
        let updatedEvents = [...prevEvents];
        updatedEvents[eventToUpdateIdx] = eventToUpdate;

        return updatedEvents;
      });
      showToast({ text: t('your-attendance-is-cancelled') });
    },
    [communityId, t]
  );

  const fetchPastEvents = useCallback(async () => {
    if (!pastEventPageNumber) return;

    try {
      setIsPastEventsLoading(true);
      const { data, error } = await getEventsData(
        communityId,
        PAST_EVENTS_ENDPOINT,
        {
          pageNum: pastEventPageNumber,
          pageSize: DEFAULTPAGE_SIZE,
          paginate: 1,
        }
      );

      if (error) throw new Error(error);

      const { data: pastEventsList, metadata } = data;

      if (pastEventPageNumber === prevPastPageNumber)
        setPastEventList(pastEventsList);
      else
        setPastEventList((prev) => {
          return [...prev, ...pastEventsList];
        });

      setPastEventListMetadata(metadata);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPastEventsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, pastEventPageNumber]);

  const fetchUpcomingEvents = useCallback(async () => {
    if (!upcomingPageNumber) return;

    try {
      setIsUpcomingEventsLoading(true);
      const { data, error } = await getEventsData(
        communityId,
        UPCOMING_EVENTS_ENDPOINT,
        {
          pageNum: upcomingPageNumber,
          pageSize: DEFAULTPAGE_SIZE,
          paginate: 1,
        }
      );

      if (error) throw new Error(error);

      const { data: upcomingEventsList, metadata } = data;

      if (upcomingPageNumber === prevUpcomingPageNumber)
        setUpcomingEventList(upcomingEventsList);
      else
        setUpcomingEventList((prev) => {
          return [...prev, ...upcomingEventsList];
        });

      setUpcomingEventListMetadata(metadata);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpcomingEventsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, upcomingPageNumber]);

  const fetchMoreUpcomingEvents = useCallback(() => {
    if (upcomingEventListMetadata?.next)
      setUpcomingPageNumber((prev) => prev + 1);
  }, [upcomingEventListMetadata?.next]);

  const fetchMorePastEvents = useCallback(() => {
    if (pastEventListMetadata?.next) setPastEventPageNumber((prev) => prev + 1);
  }, [pastEventListMetadata?.next]);

  useEffect(() => {
    fetchPastEvents();
  }, [fetchPastEvents]);

  useEffect(() => {
    if (communityId) {
      setPastEventPageNumber(DEFAULT_PAGE_NUMBER);
    }
  }, [communityId]);

  useEffect(() => {
    fetchUpcomingEvents();
  }, [fetchUpcomingEvents]);

  useEffect(() => {
    if (communityId) {
      setUpcomingPageNumber(DEFAULT_PAGE_NUMBER);
    }
  }, [communityId]);

  return {
    loading: isLoading,
    data: eventsData,
    fetchMorePastEvents,
    fetchMoreUpcomingEvents,
    registerForEvent,
    cancelEventRegistration,
  };
};

export default useCommunityEventsData;
