import { Children, useCallback } from 'react';

import Button from '../../../../CommunityPortal/components/Button';
import CaretLeftIcon from '../../../../components/Icons/CaretLeftIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../../../../modules/history';
import style from './SettingsNavigation.module.scss';
import { useWindowWidthContext } from '../../../../contexts/WindowWidthContext';
import { urlUtils } from '../../../../PortalModeWrapper/utils';
import { COMMUNITY_PORTAL_ROOT } from '../../../../PortalModeWrapper/constants';
import SettingIcon01 from '../../../../components/Icons/SettingIcon01/SettingIcon01';
import sessionStorageService from '../../../../utility/sessionStorageService';
import { useTranslation } from 'react-i18next';

const className = 'c-SettingsNavigation';

const borderClasses =
  'border-l-0 border-1 lg:border-0 lg:border-r-1 border-grey-neutral95';
const flexClasses = 'lg:flex lg:justify-end';
const paddingClasses = 'lg:py-32 lg:px-12';

const SettingsNavigation = ({ pageItems, user }) => {
  const { t } = useTranslation();
  const { isGtEqLg } = useWindowWidthContext();

  //https://app.asana.com/0/1202420995243052/1202797335823229/f
  const onBackClick = () => {
    const prevLocation = sessionStorageService.getItem('prevLocation');

    if (!prevLocation) {
      urlUtils.goToPortalPage(COMMUNITY_PORTAL_ROOT);
      return;
    }

    if (prevLocation.startsWith('/communities')) {
      urlUtils.goToPortalPage(COMMUNITY_PORTAL_ROOT);
    } else {
      history.push('/');
    }
  };

  const renderNavItems = useCallback(
    () =>
      Children.toArray(
        pageItems.map(({ label, link, isActive, hidden }) => {
          if (hidden) return null;
          const headingColorClass = isActive
            ? 'text-npl-text-icon-on-light-surface-primary'
            : 'text-npl-text-icon-on-light-surface-secondary';

          return (
            <Link to={link}>
              <div>
                <Button
                  displayType={'plain'}
                  fluid={isGtEqLg}
                  size={'md'}
                  customClassNames={'px-8 md:p-8 py-10 rounded-8'}
                  customInnerClassNames={'lg:justify-start'}
                >
                  <div className="flex flex-row items-center">
                    <span
                      className={`text-label-lg whitespace-normal	text-left ${headingColorClass}`}
                      style={{ whiteSpace: isGtEqLg ? 'normal' : 'inherit' }}
                    >
                      {label}
                    </span>
                  </div>
                </Button>
              </div>
            </Link>
          );
        })
      ),
    [isGtEqLg, pageItems]
  );
  return (
    <div
      className={`${className} z-101 flex-1 fixed lg:relative lg:h-full w-full lg:max-w-[216px] bg-white ${flexClasses} ${paddingClasses} ${borderClasses}`}
    >
      {!isGtEqLg && (
        <div
          className={`${className} h-[72px] w-full sticky top-0 z-999 border-b-1 border-neutral-90 bg-white font-poppins`}
        >
          <div className="w-full h-full px-16 flex items-center justify-between space-x-[28px]">
            <div className="flex items-center space-x-[28px]">
              <div className="p-12 cursor-pointer" onClick={onBackClick}>
                <CaretLeftIcon />
              </div>
              <p className="font-semibold text-heading-md text-neutral-10">
                {t('settings')}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-row my-8 md:ml-20 lg:mx-0 lg:my-0 lg:w-full lg:flex-col">
        {isGtEqLg && (
          <button
            onClick={onBackClick}
            className="flex flex-row items-center px-12 py-8 font-medium text-button-md text-npl-text-icon-on-light-surface-primary font-poppins w-min"
          >
            <CaretLeftIcon customClassName="mr-12 h-[10px]" /> {t('back')}
          </button>
        )}

        {/* Page Navigation */}
        <nav
          className={`${className}__left_nav_panel ${
            style[`${className}__left_nav_panel`]
          } liner overflow-x-auto lg:overflow-hidden whitespace-nowrap ml-24 flex items-center lg:items-stretch lg:flex-col lg:mx-0 lg:mt-24`}
        >
          {isGtEqLg && (
            <div className="flex items-center mb-24 space-x-12 font-semibold text-heading-sm text-npl-text-icon-on-light-surface-tertiary">
              <SettingIcon01 />
              <p>{t('settings')}</p>
            </div>
          )}
          <div className="flex items-center lg:block lg:space-y-4 lg:ml-24">
            {renderNavItems()}
          </div>
        </nav>
      </div>
    </div>
  );
};

const PageItemPropTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  isActive: PropTypes.bool,
};

SettingsNavigation.propTypes = {
  pageItems: PropTypes.arrayOf(PropTypes.shape(PageItemPropTypes)),
};

SettingsNavigation.defaultProps = {};

export default SettingsNavigation;
