import {
  COMMUNITY_PORTAL,
  COMMUNITY_PORTAL_ROOT,
  SETTINGS_PORTAL_ROOT,
} from './constants';
import { Route, Switch, withRouter } from 'react-router';
import { lazy, useCallback, useEffect, useMemo } from 'react';
import {
  openManagerPortal,
} from '../CommunityPortal/utils/linkUtils';

import SettingsPageV2 from '../pages/SettingPage/SettingsPageV2';
import { urlUtils } from './utils';
import { useCommunityPortalContext } from '../CommunityPortal/contexts/CommunityPortalContext';
import { useRouterContext } from './contexts/RouterContext';

const CommunityPortal = lazy(() => import('../CommunityPortal'));
const className = 'c-PortalModeWrapper';

const PortalModeWrapper = ({ children, match }) => {
  const { activePortal, switchToCommunityPortal } = useRouterContext();

  const {
    activeCommunity,
    updateActiveCommunity,
    doesCommunityIdExist,
    activeCommunityData,
    communities,
    loading,
  } = useCommunityPortalContext();
  const communityItems = useMemo(
    () =>
      communities?.map(
        ({
          name,
          img,
          code,
          id,
          isManager,
          isMember,
          _id,
          title,
          subscription,
        }) => {
          return {
            id: _id || id,
            name: title || name,
            img,
            onClick: () => {
              if (isMember) {
                updateActiveCommunity(id);
                return;
              }
              if (isManager) {
                if (id) {
                  openManagerPortal(id);
                  return;
                }
                if (_id) {
                  openManagerPortal(_id);
                  return;
                }
              }
            },
            isActive: activeCommunity === id,
            isManager,
            isMember,
            subscription,
          };
        }
      ),
    [communities, activeCommunity, updateActiveCommunity]
  );

  const renderActiveNonClassesPortal = useCallback(
    () => (
      <Switch>
        <Route path={SETTINGS_PORTAL_ROOT}>
          <SettingsPageV2 />
        </Route>
        <Route path={COMMUNITY_PORTAL_ROOT}>
          <CommunityPortal
            activeCommunityData={activeCommunityData}
            communityItems={communityItems}
            loading={loading}
            match={match}
          />
        </Route>
        <Route>{children}</Route>
      </Switch>
    ),
    [activeCommunityData, children, communityItems, loading, match]
  );

  useEffect(() => {
    if (!urlUtils.isOnSettingsPortal()) {
      switchToCommunityPortal();
    }
  }, [switchToCommunityPortal]);

  useEffect(() => {
    if (!doesCommunityIdExist && activeCommunityData?.id) {
      updateActiveCommunity(
        activeCommunityData?._id,
        !window.location.pathname.split('/').includes('setting')
      );
    }
  }, [
    doesCommunityIdExist,
    updateActiveCommunity,
    activeCommunityData,
    activeCommunityData?._id,
  ]);

  useEffect(() => {
    if (activePortal === COMMUNITY_PORTAL) {
      const activePagePaths = window.location.pathname.split('/');
      const [
        root,
        community_portal,
        activeCommunityId,
        route,
      ] = activePagePaths;
      if (activeCommunityId) {
        // Safety checks to make sure that the data is correct (Avoid unnecessary rerenders)
        if (activeCommunity != null && doesCommunityIdExist) {
          // This is in the case of a new link with a different commmunity that you are a part of.
          if (activeCommunityId !== activeCommunity) {
            updateActiveCommunity(activeCommunityId);
          }
        }
      }
    }
  }, [
    activeCommunity,
    activePortal,
    doesCommunityIdExist,
    updateActiveCommunity,
  ]);

  return (
    <div
      className={`${className} w-full relative flex justify-center font-poppins`}
    >
      <div
        className={`${className}__container w-full mb-64 md:mb-64 lg:mb-0 lg:flex`}
      >
        {renderActiveNonClassesPortal()}
      </div>
    </div>
  );
};

export default withRouter(PortalModeWrapper);
