import classNames from 'classnames';
import style from './CheckboxV2.module.scss';

const CheckboxV2 = ({ label, checked, dataTestId, ...props }) => {
  const className = 'c-CheckboxV2';
  const baseClassNames = classNames(className, style[className], {
    [`${className}--checked`]: checked,
    [style[`${className}--checked`]]: checked,
  });

  const checkmarkClassName = `${className}__checkmark`;
  const checkmarkClassNames = classNames(
    checkmarkClassName,
    style[checkmarkClassName],
    {
      [`${checkmarkClassName}--checked`]: checked,
      [style[`${checkmarkClassName}--checked`]]: checked,
    }
  );

  return (
    <div className="flex items-center">
      <label className={`${baseClassNames} font-poppins text-16 text-dark-33`}>
        {label}
        <input
          type="checkbox"
          {...props}
          data-testid={`${dataTestId}-checkbox-${label}`}
        />
        <span className={checkmarkClassNames}></span>
        {props.error && <p className={`${className}__error`}>{props.error}</p>}
      </label>
    </div>
  );
};

CheckboxV2.defaultProps = {
  dataTestId: '',
};

export default CheckboxV2;
