import axios from 'axios';
import { communityInvoicesURL } from './constants';
import { getAxiosError } from '../../services/helper';
import sign from 'jwt-encode';

export const getCommunityInvoices = async (communityCode, email) => {
  try {
    const mainWebsiteJwtToken = sign(
      { email },
      process.env.REACT_APP_JWT_SECRET
    );
    const response = await axios.get(`${communityInvoicesURL()}`, {
      params: { communityCode },
      headers: { Authorization: `Bearer ${mainWebsiteJwtToken}` },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return getAxiosError(err);
  }
};
