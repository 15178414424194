import './FormInput.scss';

import Checkbox from '../../../../components/Checkbox';
import FacebookGreyIcon from '../../../../components/Icons/FacebookGreyIcon';
import InstagramGreyIcon from '../../../../components/Icons/InstagramGreyIcon';
import PropTypes from 'prop-types';
import Select from '../../../../components/Form/Select';
import YoutubeGreyIcon from '../../../../components/Icons/YoutubeGreyIcon';

const FormInput = ({
  label,
  name,
  placeholder,
  defaultValue,
  withCheckbox,
  checkboxLabel,
  checkboxName,
  checked,
  handleChecking,
  disabled,
  required,
  errors,
  register,
  type,
  optionsList,
  onChange,
  showLogo,
  showLogoBesideLabel,
  fluid,
  customClassForInputWrapper,
  v2,
}) => {
  const className = 'c-FormInput';

  const renderCheckbox = () => (
    <div className={`${className}__checkbox-wrapper`}>
      <Checkbox
        name={checkboxName}
        onChange={handleChecking}
        checked={checked}
      />
      <label className={`${className}__checkbox-label`}>{checkboxLabel}</label>
    </div>
  );

  const checkboxClass = withCheckbox ? `${className}__wrapper-vertical` : '';

  const renderLogo = (type) => {
    switch (type) {
      case 'facebook':
        return <FacebookGreyIcon />;
      case 'instagram':
        return <InstagramGreyIcon />;
      case 'youtube':
        return <YoutubeGreyIcon />;
      default:
        return <FacebookGreyIcon />;
    }
  };

  const renderErrors = () => {
    let messages = [];

    if (errors) {
      errors.forEach((err, i) => {
        if (err?.message)
          messages.push(
            <span key={i} className={`${className}__error`}>
              {err.message}
            </span>
          );
      });
    }

    return messages.length > 0 ? messages : null;
  };

  const inputWrapperBaseClass = `${className}__wrapper`;
  const inputWrapperBaseClassNames = [
    inputWrapperBaseClass,
    fluid ? `${inputWrapperBaseClass}--fluid` : '',
  ].join(' ');

  const inputWrapperClassName = `${className}__input-wrapper`;
  const inputWrapperClassNames = [
    inputWrapperClassName,
    fluid ? `${inputWrapperClassName}--fluid` : '',
  ].join(' ');

  return (
    <div className={`${inputWrapperBaseClassNames} ${checkboxClass}`}>
      {showLogo && (
        <div className={`${className}__logo h-20 w-20  `}>
          {renderLogo(name)}
        </div>
      )}
      <label
        className={`${className}__label  ${
          showLogoBesideLabel && ' flex flex-row items-center'
        }`}
      >
        {showLogoBesideLabel && (
          <div className={`${className}__logo h-20 w-20 mr-4 items-center`}>
            {renderLogo(name)}
          </div>
        )}{' '}
        {`${label} ${required ? '*' : ''}`}
      </label>
      {withCheckbox && renderCheckbox()}
      <div
        className={`${inputWrapperClassNames} ${customClassForInputWrapper}}`}
      >
        {!checked && type === 'text' && (
          <input
            type={type}
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            disabled={disabled || checked}
            onChange={onChange}
            ref={register}
            className={`${className}__input`}
          />
        )}
        {type === 'dropdown' && (
          <Select
            name={name}
            optionsList={optionsList}
            register={register}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            v2={v2}
          />
        )}
        {!checked && renderErrors()}
      </div>
    </div>
  );
};

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  withCheckbox: PropTypes.bool,
  checkboxLabel: PropTypes.string,
  checkboxName: PropTypes.string,
  checked: PropTypes.bool,
  handleChecking: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  errors: PropTypes.array,
  register: PropTypes.func,
  showLogo: PropTypes.bool,
  showLogoBesideLabel: PropTypes.bool,
  fluid: PropTypes.bool,
  v2: PropTypes.bool,
};

FormInput.defaultProps = {
  required: false,
  type: 'text',
  showLogo: false,
  fluid: false,
  v2: false,
};

export default FormInput;
