import React from 'react';

const CopyIcon = () => {
  const className = 'c-CopyIcon';

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M10.6667 0.666656H2.66671C1.93337 0.666656 1.33337 1.26666 1.33337 1.99999V11.3333H2.66671V1.99999H10.6667V0.666656ZM10 3.33332L14 7.33332V14C14 14.7333 13.4 15.3333 12.6667 15.3333H5.32671C4.59337 15.3333 4.00004 14.7333 4.00004 14L4.00671 4.66666C4.00671 3.93332 4.60004 3.33332 5.33337 3.33332H10ZM9.33337 7.99999H13L9.33337 4.33332V7.99999Z"
        fill="black"
      />
    </svg>
  );
};

export default CopyIcon;
