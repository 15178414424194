import './NewProfilePage.scss';

import {
  allSocialMediaValues,
  EDIT_PROFILE_MODAL,
  PASSWORD_DESIGN,
  PASSWORD_MODAL,
  SOCIAL_MEDIA_MODAL,
  constantvalues,
  findCountryWithCountryID,
} from './constants';
import { useCallback, useEffect, useMemo, useState } from 'react';

import EditProfilePage from '../EditProfileComponent';
import PageTemplate from '../../../../CommunityPortal/components/PageTemplate';
import ProfileImageSetting from '../ProfileImageSetting';
import PropTypes from 'prop-types';
import ShowPasswordResetUpdateInput from '../PasswordResetComponent';
import ShowSocialMediaUpdateInputs from '../SocialMediaUpdateComponent';
import { countryList } from '../../../../data/countryList';
import { renderLogo } from './utils';
import { useWindowWidthContext } from '../../../../contexts/WindowWidthContext';
import { useTranslation } from 'react-i18next';
import useNewMPPageRedirection from '../../../../CommunityPortal/hooks/useNewMPPageRedirection';
import { withRouter } from 'react-router';

const className = 'c-NewProfilePage';
const NewProfilePage = ({
  user,
  updateProfile,
  inputProps,
  isDesktopView,
  userSocialMediaLinks,
  updateSocialMedia,
  errorsBasicInfo,
  registerBasicInfo,
  getBasicInfoValues,
  isBasicInfoUpdateLoading,
  location,
}) => {
  const { t } = useTranslation();
  const DEFAULT_SOCIAL_MEDIA_OPTION = t('select-social-media');
  const [socialLinks, setSocailLinks] = useState([]);
  const [socialLinksdefault, setSocialLinksdefault] = useState({});
  const [selectedSocialmediaElement, setSocialMediaelement] = useState(
    DEFAULT_SOCIAL_MEDIA_OPTION
  );
  const [showModal, setShowModal] = useState(null);

  const isRedirecting = useNewMPPageRedirection({
    queryString: location.search,
    newPagePath: '/user/profile',
  });

  const fullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;

  const textStyleforEmail = useMemo(() => {
    return isDesktopView ? 'break-normal' : 'break-all';
  }, [isDesktopView]);
  const allsocialMediaLinks = useMemo(() => {
    const commonElements = allSocialMediaValues(t).filter((element) =>
      socialLinks?.find((socialLink) => socialLink.type === element.type)
    );
    const updated_allSocialMediaValues = allSocialMediaValues(t).filter(
      (element) =>
        !commonElements?.find(
          (commonElement) => commonElement.type === element.type
        )
    );
    return updated_allSocialMediaValues;
  }, [t, socialLinks]);

  const { isGtEqMd } = useWindowWidthContext();
  // this function is used to save the un-changed state of the social media links
  const saveCopyOfSocialMediaLinkStates = (socialMediaParameter) => {
    let oldArray = socialMediaParameter;
    let newArray = JSON.stringify({ value: oldArray });
    setSocialLinksdefault(newArray);
  };
  const setSocialLinksAndSaveTheCopyOfSocialMediaLink = (
    socialLinks,
    socilMediaLinks
  ) => {
    setSocailLinks(socialLinks);
    saveCopyOfSocialMediaLinkStates(socilMediaLinks);
  };

  const RunStarterCodeForFilteringSocialMediaList = useCallback(() => {
    if (userSocialMediaLinks?.length < 5) {
      let socialMediaLinks = userSocialMediaLinks.map((social) => {
        return {
          iconLink: social.iconLink,
          link: social.link,
          type: social.type,
        };
      });

      let updated_constantvalues_with_socialMediaLinks = constantvalues(t).map(
        (constantvalue) => {
          let socialMediaLink = socialMediaLinks.find(
            (socialMediaLink) => socialMediaLink.type === constantvalue.type
          );
          if (socialMediaLink) {
            return {
              ...constantvalue,
              link: socialMediaLink.link,
            };
          } else {
            return constantvalue;
          }
        }
      );
      updated_constantvalues_with_socialMediaLinks = [
        ...new Set(updated_constantvalues_with_socialMediaLinks),
      ];

      setSocialLinksAndSaveTheCopyOfSocialMediaLink(
        updated_constantvalues_with_socialMediaLinks,
        updated_constantvalues_with_socialMediaLinks
      );
    } else if (user?.socialMedia.length > 5) {
      let socialMediaLinks_with_link_not_empty = userSocialMediaLinks?.filter(
        (social) => social.link !== null && social.link !== ''
      );
      let socialMediaLinks = socialMediaLinks_with_link_not_empty.map(
        (social) => {
          return {
            iconLink: social.iconLink,
            link: social.link,
            type: social.type,
          };
        }
      );
      setSocialLinksAndSaveTheCopyOfSocialMediaLink(
        socialMediaLinks,
        socialMediaLinks
      );
    } else {
      setSocialLinksAndSaveTheCopyOfSocialMediaLink(
        user?.socialMedia,
        userSocialMediaLinks
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.socialMedia, userSocialMediaLinks]);

  const onSaveTheChanges = useCallback(() => {
    let answer = {};
    socialLinks.forEach((value) => {
      answer[value.type] = value.link;
    });
    updateSocialMedia(answer);
    setShowModal(null);
  }, [socialLinks, updateSocialMedia]);

  const onDiscardChanges = useCallback(() => {
    let value = JSON.parse(socialLinksdefault);

    const updatedvalue = value?.value?.map((value, index) => {
      return {
        ...value,
        link: value.link == null ? '' : value.link,
      };
    });
    setSocailLinks(updatedvalue);
    setShowModal(null);
  }, [socialLinksdefault]);

  const getLabelfromSocialMediaArray = useCallback(
    (socialMediaArray) => {
      let label = [];
      if (socialMediaArray.length > 0) {
        label = socialMediaArray.map((socialMedia) => {
          return {
            label: socialMedia.type,
            value: socialMedia.type,
          };
        });
      }
      return [
        {
          type: t('select'),
          label: t('select'),
        },
        ...label,
      ];
    },
    [t]
  );

  const onSocialMediaSelectChange = useCallback(
    (e) => {
      if (e.target.value === DEFAULT_SOCIAL_MEDIA_OPTION) {
        return;
      }
      const newSocialMediaLink = {
        iconLink: e.target.value,
        link: '',
        type: e.target.value,
      };
      setSocailLinks([...socialLinks, newSocialMediaLink]);
      setSocialMediaelement(DEFAULT_SOCIAL_MEDIA_OPTION);
    },
    [socialLinks]
  );

  const showSocialMediaModel = useCallback(() => {
    setShowModal(SOCIAL_MEDIA_MODAL);
  }, []);
  const showPasswordModel = useCallback(() => {
    setShowModal(PASSWORD_MODAL);
  }, []);
  const showEditProfileModel = useCallback(() => {
    setShowModal(EDIT_PROFILE_MODAL);
  }, []);
  const handleInputChangeForSocialMediaField = useCallback(
    (e, index) => {
      socialLinks[index].link = e.target.value;
      setSocailLinks([...socialLinks]);
    },
    [socialLinks]
  );

  const renderSocialMediaIcons = useCallback(() => {
    if (!socialLinks) {
      RunStarterCodeForFilteringSocialMediaList();
    }
    return socialLinks?.map((item, index) => (
      <div className="social-media-icon" key={index}>
        {item.link && (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="social-media-link"
          >
            {renderLogo(item.type)}
          </a>
        )}
      </div>
    ));
  }, [socialLinks, RunStarterCodeForFilteringSocialMediaList]);

  const getSocialMediaComponentProps = useCallback(() => {
    return {
      onDiscardChanges,
      showModal: showModal,
      socialLinks,
      HandleInputChangeForSocialMediaField: handleInputChangeForSocialMediaField,
      selectedSocialmediaElement,
      onSocialMediaSelectChange,
      getLabelfromSocialMediaArray,
      onSaveTheChanges,
      allsocialMediaLinks,
    };
  }, [
    onDiscardChanges,
    showModal,
    socialLinks,
    handleInputChangeForSocialMediaField,
    selectedSocialmediaElement,
    onSocialMediaSelectChange,
    getLabelfromSocialMediaArray,
    onSaveTheChanges,
    allsocialMediaLinks,
  ]);

  useEffect(() => {
    RunStarterCodeForFilteringSocialMediaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = useCallback(() => {
    return (
      <div className="flex flex-col px-16 mt-32 bg-white">
        <h1 className="font-medium font-poppins text-body-lg">
          {t('account-information')}
        </h1>
        <div className="flex flex-col">
          <div
            className={`w-full max-w-[568px] flex flex-col mb-8 border outer-div-profile-page border-sky-500 p-16 mt-32 border-1 neutral90 border-grey-neutral90 `}
          >
            <div className="flex flex-row items-center justify-between ">
              <div className="flex flex-row">
                <div className="flex flex-col">
                  <ProfileImageSetting user={user} />
                </div>
                <div className="flex flex-col justify-center ml-24">
                  <p className="mb-8 font-semibold text-label-lg">{fullName}</p>
                  <p
                    className={`${textStyleforEmail} text-body-sm font-regular text-grey-neutral40 mt-8 `}
                  >
                    {user?.email}
                  </p>
                </div>
              </div>
              {isGtEqMd && (
                <div>
                  {' '}
                  <button
                    onClick={showEditProfileModel}
                    className="font-medium text-button-md font-poppins text-bluple-60 "
                  >
                    {t('edit-profile')}
                  </button>
                </div>
              )}
            </div>
            <div>
              <div className="">
                <table class="mt-12">
                  <tbody>
                    <tr className="ml-12">
                      <td className="p-12 whitespace-nowrap">
                        {t('phone-number')}
                      </td>
                      <td className="p-12 ml-12 whitespace-nowrap text-grey-neutral40">
                        {user?.phoneNumber}
                      </td>
                    </tr>
                    <tr className="">
                      <td className="p-12 ">{t('country')}</td>
                      <td className="p-12 text-grey-neutral40">
                        {findCountryWithCountryID(
                          t,
                          countryList,
                          user?.countryId
                        )}
                      </td>
                    </tr>
                    <tr className="ml-12">
                      <td className="p-12">{t('bio')}</td>
                      <td className="p-12 text-grey-neutral40">
                        {user?.description}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!isGtEqMd && (
                  <div className="flex justify-end w-full mb-24">
                    <button
                      onClick={showEditProfileModel}
                      className="font-medium text-button-md font-poppins text-bluple-60"
                    >
                      {t('edit-profile')}
                    </button>
                  </div>
                )}
                <hr className="flex justify-center" />
                <div className="mt-24">
                  <div className="flex flex-col justify-between md:flex-row ">
                    <p className="ml-10">{t('your-social-links')}</p>
                    <div className="flex flex-row justify-between flex-grow ml-4 ">
                      <div
                        className={
                          className +
                          '__social_media_Newprofilepage  flex flex-row'
                        }
                      >
                        {renderSocialMediaIcons()}
                      </div>
                      <button
                        onClick={showSocialMediaModel}
                        className="font-medium text-button-md font-poppins text-bluple-60 "
                      >
                        {t('update-links')}
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between mt-24 md:flex-row">
                    <p className="ml-10">{t('your-password')}</p>
                    <div className="flex flex-row justify-between flex-grow ml-4 ">
                      <div
                        className={
                          className +
                          '__social_media_Newprofilepage_socialIcons  flex flex-row text-grey-neutral40 ml-24'
                        }
                      >
                        {PASSWORD_DESIGN}
                      </div>
                      <button
                        onClick={showPasswordModel}
                        className="font-medium text-button-md font-poppins text-bluple-60"
                      >
                        {t('change-password')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ShowSocialMediaUpdateInputs {...getSocialMediaComponentProps()} />
        <ShowPasswordResetUpdateInput
          showModal={showModal}
          setShowModal={setShowModal}
        />

        <EditProfilePage
          showModal={showModal}
          setShowModal={setShowModal}
          user={user}
          inputProps={inputProps}
          updateProfile={updateProfile}
          errorsBasicInfo={errorsBasicInfo}
          registerBasicInfo={registerBasicInfo}
          getBasicInfoValues={getBasicInfoValues}
          isBasicInfoUpdateLoading={isBasicInfoUpdateLoading}
        />
      </div>
    );
  }, [
    user,
    fullName,
    textStyleforEmail,
    isGtEqMd,
    showEditProfileModel,
    renderSocialMediaIcons,
    showSocialMediaModel,
    showPasswordModel,
    getSocialMediaComponentProps,
    showModal,
    inputProps,
    updateProfile,
    errorsBasicInfo,
    registerBasicInfo,
    getBasicInfoValues,
    isBasicInfoUpdateLoading,
    t,
  ]);

  if (isRedirecting) {
    return null;
  }

  return (
    <>
      <PageTemplate
        headerProps={
          isDesktopView
            ? {
                title: t('profile'),
              }
            : null
        }
        toRender={renderContent}
      />
    </>
  );
};

NewProfilePage.propTypes = {
  user: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  inputProps: PropTypes.object.isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  userSocialMediaLinks: PropTypes.array.isRequired,
  updateSocialMedia: PropTypes.func.isRequired,
};

export default withRouter(NewProfilePage);
