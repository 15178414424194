export const countryList = [
  {
    name: 'Singapore',
    id: 153,
    dialCode: '+65',
    wisenetCode: '5205',
    shortCode: 'SG',
  },
  {
    name: 'United Kingdom',
    id: 63,
    dialCode: '+44',
    wisenetCode: '2100',
    shortCode: 'GB',
  },
  {
    name: 'United States of America',
    id: 184,
    dialCode: '+1',
    wisenetCode: '8104',
    shortCode: 'US',
  },
  {
    name: 'Afghanistan',
    id: 1,
    dialCode: '+93',
    wisenetCode: '7201',
    shortCode: 'AF',
  },
  {
    name: 'Albania',
    id: 3,
    dialCode: '+355',
    wisenetCode: '3201',
    shortCode: 'AL',
  },
  {
    name: 'Algeria',
    id: 50,
    dialCode: '+213',
    wisenetCode: '4101',
    shortCode: 'DZ',
  },
  {
    name: 'Andorra',
    id: 4,
    dialCode: '+376',
    wisenetCode: '3101',
    shortCode: 'AD',
  },
  {
    name: 'Angola',
    id: 2,
    dialCode: '+244',
    wisenetCode: '9201',
    shortCode: 'AO',
  },
  {
    name: 'Antigua and Barbuda',
    id: 8,
    dialCode: '+1268',
    wisenetCode: '8402',
    shortCode: 'AG',
  },
  {
    name: 'Argentina',
    id: 6,
    dialCode: '+54',
    wisenetCode: '8201',
    shortCode: 'AR',
  },
  {
    name: 'Armenia',
    id: 7,
    dialCode: '+374',
    wisenetCode: '7202',
    shortCode: 'AM',
  },
  {
    name: 'Australia',
    id: 9,
    dialCode: '+61',
    wisenetCode: '1101',
    shortCode: 'AU',
  },
  {
    name: 'Austria',
    id: 10,
    dialCode: '+43',
    wisenetCode: '2301',
    shortCode: 'AT',
  },
  {
    name: 'Azerbaijan',
    id: 11,
    dialCode: '+994',
    wisenetCode: '7203',
    shortCode: 'AZ',
  },
  {
    name: 'Bahamas',
    id: 19,
    dialCode: '+1242',
    wisenetCode: '8404',
    shortCode: 'BS',
  },
  {
    name: 'Bahrain',
    id: 18,
    dialCode: '+973',
    wisenetCode: '4201',
    shortCode: 'BH',
  },
  {
    name: 'Bangladesh',
    id: 16,
    dialCode: '+880',
    wisenetCode: '7101',
    shortCode: 'BD',
  },
  {
    name: 'Barbados',
    id: 25,
    dialCode: '+1246',
    wisenetCode: '8405',
    shortCode: 'BB',
  },
  {
    name: 'Belarus',
    id: 21,
    dialCode: '+375',
    wisenetCode: '3301',
    shortCode: 'BY',
  },
  {
    name: 'Belgium',
    id: 13,
    dialCode: '+32',
    wisenetCode: '2302',
    shortCode: 'BE',
  },
  {
    name: 'Belize',
    id: 22,
    dialCode: '+501',
    wisenetCode: '8301',
    shortCode: 'BZ',
  },
  {
    name: 'Benin',
    id: 14,
    dialCode: '+229',
    wisenetCode: '9101',
    shortCode: 'BJ',
  },
  {
    name: 'Bhutan',
    id: 27,
    dialCode: '+975',
    wisenetCode: '7102',
    shortCode: 'BT',
  },
  {
    name: 'Bolivia',
    id: '23',
    dialCode: '+591',
    wisenetCode: '8202',
    shortCode: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    id: 20,
    dialCode: '+387',
    wisenetCode: '3202',
    shortCode: 'BA',
  },
  {
    name: 'Botswana',
    id: 28,
    dialCode: '+267',
    wisenetCode: '9202',
    shortCode: 'BW',
  },
  {
    name: 'Brazil',
    id: 24,
    dialCode: '+55',
    wisenetCode: '8203',
    shortCode: 'BR',
  },
  {
    name: 'Brunei Darussalam',
    id: 26,
    dialCode: '+673',
    wisenetCode: '5201',
    shortCode: 'BN',
  },
  {
    name: 'Bulgaria',
    id: 17,
    dialCode: '+359',
    wisenetCode: '3203',
    shortCode: 'BG',
  },
  {
    name: 'Burkina Faso',
    id: 15,
    dialCode: '+226',
    wisenetCode: '9102',
    shortCode: 'BF',
  },
  {
    name: 'Burundi',
    id: 12,
    dialCode: '+257',
    wisenetCode: '9203',
    shortCode: 'BI',
  },
  {
    name: 'Cambodia',
    id: 92,
    dialCode: '+855',
    wisenetCode: '5102',
    shortCode: 'KH',
  },
  {
    name: 'Cameroon',
    id: 35,
    dialCode: '+237',
    wisenetCode: '9103',
    shortCode: 'CM',
  },
  {
    name: 'Canada',
    id: 30,
    dialCode: '+1',
    wisenetCode: '8102',
    shortCode: 'CA',
  },
  {
    name: 'CapeVerde',
    id: '40',
    dialCode: '+238',
    wisenetCode: '9104',
    shortCode: 'CV',
  },
  {
    name: 'Central African Republic',
    id: 29,
    dialCode: '+236',
    wisenetCode: '9105',
    shortCode: 'CF',
  },
  {
    name: 'Chad',
    id: 168,
    dialCode: '+235',
    wisenetCode: '9106',
    shortCode: 'TD',
  },
  {
    name: 'Chile',
    id: 32,
    dialCode: '+56',
    wisenetCode: '8204',
    shortCode: 'CL',
  },
  {
    name: 'China',
    id: 33,
    dialCode: '+86',
    wisenetCode: '6101',
    shortCode: 'CN',
  },
  {
    name: 'Colombia',
    id: 38,
    dialCode: '+57',
    wisenetCode: '8205',
    shortCode: 'CO',
  },
  {
    name: 'Comoros',
    id: 39,
    dialCode: '+269',
    wisenetCode: '9204',
    shortCode: 'KM',
  },
  {
    name: "People's Republic of the Congo",
    id: '37',
    dialCode: '+242',
    wisenetCode: '9107',
    shortCode: 'CG',
  },
  {
    name: 'Democratic Republic of the Congo',
    id: '36',
    dialCode: '+243',
    wisenetCode: '9108',
    shortCode: 'CD',
  },
  {
    name: 'Costa Rica',
    id: 41,
    dialCode: '+506',
    wisenetCode: '8302',
    shortCode: 'CR',
  },
  {
    name: 'Croatia',
    id: 75,
    dialCode: '+385',
    wisenetCode: '3204',
    shortCode: 'HR',
  },
  {
    name: 'Cuba',
    id: 42,
    dialCode: '+53',
    wisenetCode: '8407',
    shortCode: 'CU',
  },
  {
    name: 'Cyprus',
    id: 43,
    dialCode: '+357',
    wisenetCode: '3205',
    shortCode: 'CY',
  },
  {
    name: 'Czech Republic',
    id: 44,
    dialCode: '+420',
    wisenetCode: '3302',
    shortCode: 'CZ',
  },
  {
    name: 'Denmark',
    id: 48,
    dialCode: '+45',
    wisenetCode: '2401',
    shortCode: 'DK',
  },
  {
    name: 'Djibouti',
    id: 46,
    dialCode: '+253',
    wisenetCode: '9205',
    shortCode: 'DJ',
  },
  {
    name: 'Dominica',
    id: 47,
    dialCode: '+1767',
    wisenetCode: '8408',
    shortCode: 'DM',
  },
  {
    name: 'Dominican Republic',
    id: 49,
    dialCode: '+1',
    wisenetCode: '8411',
    shortCode: 'DO',
  },
  {
    name: 'Timor-Leste',
    id: 173,
    dialCode: '+670',
    wisenetCode: '5206',
    shortCode: 'TL',
  },
  {
    name: 'Ecuador',
    id: 51,
    dialCode: '+593',
    wisenetCode: '8206',
    shortCode: 'EC',
  },
  {
    name: 'Egypt',
    id: 52,
    dialCode: '+20',
    wisenetCode: '4102',
    shortCode: 'EG',
  },
  {
    name: 'El Salvador',
    id: 156,
    dialCode: '+503',
    wisenetCode: '8303',
    shortCode: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    id: 69,
    dialCode: '+240',
    wisenetCode: '9112',
    shortCode: 'GQ',
  },
  {
    name: 'Eritrea',
    id: 53,
    dialCode: '+291',
    wisenetCode: '9206',
    shortCode: 'ER',
  },
  {
    name: 'Estonia',
    id: 56,
    dialCode: '+372',
    wisenetCode: '3303',
    shortCode: 'EE',
  },
  {
    name: 'Ethiopia',
    id: 57,
    dialCode: '+251',
    wisenetCode: '9207',
    shortCode: 'ET',
  },
  {
    name: 'Fiji',
    id: 59,
    dialCode: '+679',
    wisenetCode: '1502',
    shortCode: 'FJ',
  },
  {
    name: 'Finland',
    id: 58,
    dialCode: '+358',
    wisenetCode: '2403',
    shortCode: 'FI',
  },
  {
    name: 'France',
    id: 60,
    dialCode: '+33',
    wisenetCode: '2303',
    shortCode: 'FR',
  },
  {
    name: 'Gabon',
    id: 62,
    dialCode: '+241',
    wisenetCode: '9113',
    shortCode: 'GA',
  },
  {
    name: 'Gambia',
    id: 67,
    dialCode: '+220',
    wisenetCode: '9114',
    shortCode: 'GM',
  },
  {
    name: 'Georgia',
    id: 64,
    dialCode: '+995',
    wisenetCode: '7204',
    shortCode: 'GE',
  },
  {
    name: 'Germany',
    id: 45,
    dialCode: '+49',
    wisenetCode: '2304',
    shortCode: 'DE',
  },
  {
    name: 'Ghana',
    id: 65,
    dialCode: '+233',
    wisenetCode: '9115',
    shortCode: 'GH',
  },
  {
    name: 'Greece',
    id: 70,
    dialCode: '+30',
    wisenetCode: '3207',
    shortCode: 'GR',
  },
  {
    name: 'Grenada',
    id: 71,
    dialCode: '+1473',
    wisenetCode: '8412',
    shortCode: 'GD',
  },
  {
    name: 'Guatemala',
    id: 72,
    dialCode: '+502',
    wisenetCode: '8304',
    shortCode: 'GT',
  },
  {
    name: 'Guinea',
    id: 66,
    dialCode: '+224',
    wisenetCode: '9116',
    shortCode: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    id: 68,
    dialCode: '+245',
    wisenetCode: '9117',
    shortCode: 'GW',
  },
  {
    name: 'Guyana',
    id: 73,
    dialCode: '+592',
    wisenetCode: '8211',
    shortCode: 'GY',
  },
  {
    name: 'Haiti',
    id: 76,
    dialCode: '+509',
    wisenetCode: '8414',
    shortCode: 'HT',
  },
  {
    name: 'Honduras',
    id: 74,
    dialCode: '+504',
    wisenetCode: '8305',
    shortCode: 'HN',
  },
  {
    name: 'Hong Kong',
    id: 0,
    dialCode: '+852',
    wisenetCode: '',
    shortCode: 'HK',
  },
  {
    name: 'Hungary',
    id: 77,
    dialCode: '+36',
    wisenetCode: '3304',
    shortCode: 'HU',
  },
  {
    name: 'Iceland',
    id: 83,
    dialCode: '+354',
    wisenetCode: '2405',
    shortCode: 'IS',
  },
  {
    name: 'India',
    id: 79,
    dialCode: '+91',
    wisenetCode: '7103',
    shortCode: 'IN',
  },
  {
    name: 'Indonesia',
    id: 78,
    dialCode: '+62',
    wisenetCode: '5202',
    shortCode: 'ID',
  },
  {
    name: 'Iran',
    id: '81',
    dialCode: '+98',
    wisenetCode: '4203',
    shortCode: 'IR',
  },
  {
    name: 'Iraq',
    id: 82,
    dialCode: '+964',
    wisenetCode: '4204',
    shortCode: 'IQ',
  },
  {
    name: 'Ireland',
    id: 80,
    dialCode: '+353',
    wisenetCode: '2201',
    shortCode: 'IE',
  },
  {
    name: 'Israel',
    id: 84,
    dialCode: '+972',
    wisenetCode: '4205',
    shortCode: 'IL',
  },
  {
    name: 'Italy',
    id: 85,
    dialCode: '+39',
    wisenetCode: '3104',
    shortCode: 'IT',
  },
  {
    name: 'Ivory Coast',
    id: '34',
    dialCode: '+225',
    wisenetCode: '9111',
    shortCode: 'IC',
  },
  {
    name: 'Jamaica',
    id: 86,
    dialCode: '+1876',
    wisenetCode: '8415',
    shortCode: 'JM',
  },
  {
    name: 'Japan',
    id: 88,
    dialCode: '+81',
    wisenetCode: '6201',
    shortCode: 'JP',
  },
  {
    name: 'Jordan',
    id: 87,
    dialCode: '+962',
    wisenetCode: '4206',
    shortCode: 'JO',
  },
  {
    name: 'Kazakhstan',
    id: 89,
    dialCode: '+77',
    wisenetCode: '7205',
    shortCode: 'KZ',
  },
  {
    name: 'Kenya',
    id: 90,
    dialCode: '+254',
    wisenetCode: '9208',
    shortCode: 'KE',
  },
  {
    name: 'Kiribati',
    id: 93,
    dialCode: '+686',
    wisenetCode: '1402',
    shortCode: 'KI',
  },
  {
    name: 'North Korea',
    id: '',
    dialCode: '+850',
    wisenetCode: '6202',
    shortCode: 'KP',
  },
  {
    name: 'South Korea',
    id: '95',
    dialCode: '+82',
    wisenetCode: '6203',
    shortCode: 'KR',
  },
  {
    name: 'Kosovo',
    id: '',
    dialCode: '+383',
    wisenetCode: '3216',
    shortCode: 'XK',
  },
  {
    name: 'Kuwait',
    id: 96,
    dialCode: '+965',
    wisenetCode: '4207',
    shortCode: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    id: 91,
    dialCode: '+996',
    wisenetCode: '7206',
    shortCode: 'KG',
  },
  {
    name: 'Laos',
    id: '97',
    dialCode: '+856',
    wisenetCode: '5103',
    shortCode: 'LA',
  },
  {
    name: 'Latvia',
    id: 107,
    dialCode: '+371',
    wisenetCode: '3305',
    shortCode: 'LV',
  },
  {
    name: 'Lebanon',
    id: 98,
    dialCode: '+961',
    wisenetCode: '4208',
    shortCode: 'LB',
  },
  {
    name: 'Lesotho',
    id: 104,
    dialCode: '+266',
    wisenetCode: '9211',
    shortCode: 'LS',
  },
  {
    name: 'Liberia',
    id: 99,
    dialCode: '+231',
    wisenetCode: '9118',
    shortCode: 'LR',
  },
  {
    name: 'Libya',
    id: 100,
    dialCode: '+218',
    wisenetCode: '4103',
    shortCode: 'LY',
  },
  {
    name: 'Liechtenstein',
    id: 102,
    dialCode: '+423',
    wisenetCode: '2305',
    shortCode: 'LI',
  },
  {
    name: 'Lithuania',
    id: 105,
    dialCode: '+370',
    wisenetCode: '3306',
    shortCode: 'LT',
  },
  {
    name: 'Luxembourg',
    id: 106,
    dialCode: '+352',
    wisenetCode: '2306',
    shortCode: 'LU',
  },
  {
    name: 'Macedonia',
    id: '115',
    dialCode: '+389',
    wisenetCode: '3206',
    shortCode: 'MK',
  },
  {
    name: 'Madagascar',
    id: 111,
    dialCode: '+261',
    wisenetCode: '9212',
    shortCode: 'MG',
  },
  {
    name: 'Malawi',
    id: 124,
    dialCode: '+265',
    wisenetCode: '9213',
    shortCode: 'MW',
  },
  {
    name: 'Malaysia',
    id: 125,
    dialCode: '+60',
    wisenetCode: '5203',
    shortCode: 'MY',
  },
  {
    name: 'Maldives',
    id: 112,
    dialCode: '+960',
    wisenetCode: '7104',
    shortCode: 'MV',
  },
  {
    name: 'Mali',
    id: 116,
    dialCode: '+223',
    wisenetCode: '9121',
    shortCode: 'ML',
  },
  {
    name: 'Malta',
    id: 117,
    dialCode: '+356',
    wisenetCode: '3105',
    shortCode: 'MT',
  },
  {
    name: 'Marshall Islands',
    id: 114,
    dialCode: '+692',
    wisenetCode: '1403',
    shortCode: 'MH',
  },
  {
    name: 'Mauritania',
    id: 122,
    dialCode: '+222',
    wisenetCode: '9122',
    shortCode: 'MR',
  },
  {
    name: 'Mauritius',
    id: 123,
    dialCode: '+230',
    wisenetCode: '9214',
    shortCode: 'MU',
  },
  {
    name: 'Mexico',
    id: 113,
    dialCode: '+52',
    wisenetCode: '8306',
    shortCode: 'MX',
  },
  {
    name: 'Micronesia',
    id: '61',
    dialCode: '+691',
    wisenetCode: '1404',
    shortCode: 'FM',
  },
  {
    name: 'Moldova',
    id: '110',
    dialCode: '+373',
    wisenetCode: '3208',
    shortCode: 'MD',
  },
  {
    name: 'Monaco',
    id: 109,
    dialCode: '+377',
    wisenetCode: '2307',
    shortCode: 'MC',
  },
  {
    name: 'Mongolia',
    id: 120,
    dialCode: '+976',
    wisenetCode: '6104',
    shortCode: 'MN',
  },
  {
    name: 'Montenegro',
    id: 119,
    dialCode: '+382',
    wisenetCode: '3214',
    shortCode: 'ME',
  },
  {
    name: 'Morocco',
    id: 108,
    dialCode: '+212',
    wisenetCode: '4104',
    shortCode: 'MA',
  },
  {
    name: 'Mozambique',
    id: 121,
    dialCode: '+258',
    wisenetCode: '9216',
    shortCode: 'MZ',
  },
  {
    name: 'Myanmar (Burma)',
    id: '118',
    dialCode: '+95',
    wisenetCode: '5101',
    shortCode: 'MM',
  },
  {
    name: 'Namibia',
    id: 126,
    dialCode: '+264',
    wisenetCode: '9217',
    shortCode: 'NA',
  },
  {
    name: 'Nauru',
    id: 133,
    dialCode: '+674',
    wisenetCode: '1405',
    shortCode: 'NR',
  },
  {
    name: 'Nepal',
    id: 132,
    dialCode: '+977',
    wisenetCode: '7105',
    shortCode: 'NP',
  },
  {
    name: 'Netherlands',
    id: 130,
    dialCode: '+31',
    wisenetCode: '2308',
    shortCode: 'NL',
  },
  {
    name: 'New Zealand',
    id: 134,
    dialCode: '+64',
    wisenetCode: '1201',
    shortCode: 'NZ',
  },
  {
    name: 'Nicaragua',
    id: 129,
    dialCode: '+505',
    wisenetCode: '8307',
    shortCode: 'NI',
  },
  {
    name: 'Niger',
    id: 127,
    dialCode: '+227',
    wisenetCode: '9123',
    shortCode: 'NE',
  },
  {
    name: 'Nigeria',
    id: 128,
    dialCode: '+234',
    wisenetCode: '9124',
    shortCode: 'NG',
  },
  {
    name: 'Norway',
    id: 131,
    dialCode: '+47',
    wisenetCode: '2406',
    shortCode: 'NO',
  },
  {
    name: 'Oman',
    id: 135,
    dialCode: '+968',
    wisenetCode: '4211',
    shortCode: 'OM',
  },
  {
    name: 'Pakistan',
    id: 136,
    dialCode: '+92',
    wisenetCode: '7106',
    shortCode: 'PK',
  },
  {
    name: 'Palau',
    id: 140,
    dialCode: '+680',
    wisenetCode: '1407',
    shortCode: 'PW',
  },
  {
    name: 'Panama',
    id: 137,
    dialCode: '+507',
    wisenetCode: '8308',
    shortCode: 'PA',
  },
  {
    name: 'Papua New Guinea',
    id: 141,
    dialCode: '+675',
    wisenetCode: '1302',
    shortCode: 'PG',
  },
  {
    name: 'Paraguay',
    id: 144,
    dialCode: '+595',
    wisenetCode: '8212',
    shortCode: 'PY',
  },
  {
    name: 'Peru',
    id: 138,
    dialCode: '+51',
    wisenetCode: '8213',
    shortCode: 'PE',
  },
  {
    name: 'Philippines',
    id: 139,
    dialCode: '+63',
    wisenetCode: '5204',
    shortCode: 'PH',
  },
  {
    name: 'Poland',
    id: 142,
    dialCode: '+48',
    wisenetCode: '3307',
    shortCode: 'PL',
  },
  {
    name: 'Portugal',
    id: 143,
    dialCode: '+351',
    wisenetCode: '3106',
    shortCode: 'PT',
  },
  {
    name: 'Qatar',
    id: 146,
    dialCode: '+974',
    wisenetCode: '4212',
    shortCode: 'QA',
  },
  {
    name: 'Romania',
    id: 147,
    dialCode: '+40',
    wisenetCode: '3211',
    shortCode: 'RO',
  },
  {
    name: 'Russia',
    id: '148',
    dialCode: '+7',
    wisenetCode: '3308',
    shortCode: 'RU',
  },
  {
    name: 'Rwanda',
    id: 149,
    dialCode: '+250',
    wisenetCode: '9221',
    shortCode: 'RW',
  },
  {
    name: 'Saint Kitts and Nevis',
    id: 94,
    dialCode: '+1869',
    wisenetCode: '8422',
    shortCode: 'KN',
  },
  {
    name: 'Saint Lucia',
    id: 101,
    dialCode: '+1758',
    wisenetCode: '8423',
    shortCode: 'LC',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    id: '187',
    dialCode: '+1784',
    wisenetCode: '8424',
    shortCode: 'VC',
  },
  {
    name: 'Samoa',
    id: 191,
    dialCode: '+685',
    wisenetCode: '1505',
    shortCode: 'WS',
  },
  {
    name: 'San Marino',
    id: 157,
    dialCode: '+378',
    wisenetCode: '3107',
    shortCode: 'SM',
  },
  {
    name: 'Sao Tomé and Principe',
    id: '160',
    dialCode: '+239',
    wisenetCode: '9125',
    shortCode: 'ST',
  },
  {
    name: 'Saudi Arabia',
    id: 150,
    dialCode: '+966',
    wisenetCode: '4213',
    shortCode: 'SA',
  },
  {
    name: 'Senegal',
    id: 152,
    dialCode: '+221',
    wisenetCode: '9126',
    shortCode: 'SN',
  },
  {
    name: 'Serbia',
    id: 159,
    dialCode: '+381',
    wisenetCode: '3215',
    shortCode: 'RS',
  },
  {
    name: 'Seychelles',
    id: 166,
    dialCode: '+248',
    wisenetCode: '9223',
    shortCode: 'SC',
  },
  {
    name: 'Sierra Leone',
    id: 155,
    dialCode: '+232',
    wisenetCode: '9127',
    shortCode: 'SL',
  },
  {
    name: 'Slovakia',
    id: 162,
    dialCode: '+421',
    wisenetCode: '3311',
    shortCode: 'SK',
  },
  {
    name: 'Slovenia',
    id: 163,
    dialCode: '+386',
    wisenetCode: '3212',
    shortCode: 'SI',
  },
  {
    name: 'Solomon Islands',
    id: 154,
    dialCode: '+677',
    wisenetCode: '1303',
    shortCode: 'SB',
  },
  {
    name: 'Somalia',
    id: 158,
    dialCode: '+252',
    wisenetCode: '9224',
    shortCode: 'SO',
  },
  {
    name: 'South Africa',
    id: 193,
    dialCode: '+27',
    wisenetCode: '9225',
    shortCode: 'ZA',
  },
  {
    name: 'South Sudan',
    id: 197,
    dialCode: '+211',
    wisenetCode: '4111',
    shortCode: 'SS',
  },
  {
    name: 'Spain',
    id: 55,
    dialCode: '+34',
    wisenetCode: '3108',
    shortCode: 'ES',
  },
  {
    name: 'Sri Lanka',
    id: 103,
    dialCode: '+94',
    wisenetCode: '7107',
    shortCode: 'LK',
  },
  {
    name: 'Sudan',
    id: 151,
    dialCode: '+249',
    wisenetCode: '4105',
    shortCode: 'SD',
  },
  {
    name: 'Suriname',
    id: 161,
    dialCode: '+597',
    wisenetCode: '8214',
    shortCode: 'SR',
  },
  {
    name: 'Swaziland',
    id: 165,
    dialCode: '+268',
    wisenetCode: '9226',
    shortCode: 'SZ',
  },
  {
    name: 'Sweden',
    id: 164,
    dialCode: '+46',
    wisenetCode: '2407',
    shortCode: 'SE',
  },
  {
    name: 'Switzerland',
    id: 31,
    dialCode: '+41',
    wisenetCode: '2311',
    shortCode: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    id: 167,
    dialCode: '+963',
    wisenetCode: '4214',
    shortCode: 'SY',
  },
  {
    name: 'Taiwan',
    id: '179',
    dialCode: '+886',
    wisenetCode: '6105',
    shortCode: 'TW',
  },
  {
    name: 'Tajikistan',
    id: 171,
    dialCode: '+992',
    wisenetCode: '7207',
    shortCode: 'TJ',
  },
  {
    name: 'Tanzania',
    id: '180',
    dialCode: '+255',
    wisenetCode: '9227',
    shortCode: 'TZ',
  },
  {
    name: 'Thailand',
    id: 170,
    dialCode: '+66',
    wisenetCode: '5104',
    shortCode: 'TH',
  },
  {
    name: 'Togo',
    id: 169,
    dialCode: '+228',
    wisenetCode: '9128',
    shortCode: 'TG',
  },
  {
    name: 'Tonga',
    id: 174,
    dialCode: '+676',
    wisenetCode: '1508',
    shortCode: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    id: 175,
    dialCode: '+1868',
    wisenetCode: '8425',
    shortCode: 'TT',
  },
  {
    name: 'Tunisia',
    id: 176,
    dialCode: '+216',
    wisenetCode: '4106',
    shortCode: 'TN',
  },
  {
    name: 'Turkey',
    id: 177,
    dialCode: '+90',
    wisenetCode: '4215',
    shortCode: 'TR',
  },
  {
    name: 'Turkmenistan',
    id: 172,
    dialCode: '+993',
    wisenetCode: '7208',
    shortCode: 'TM',
  },
  {
    name: 'Tuvalu',
    id: 178,
    dialCode: '+688',
    wisenetCode: '1511',
    shortCode: 'TV',
  },
  {
    name: 'Uganda',
    id: 181,
    dialCode: '+256',
    wisenetCode: '9228',
    shortCode: 'UG',
  },
  {
    name: 'Ukraine',
    id: 182,
    dialCode: '+380',
    wisenetCode: '3312',
    shortCode: 'UA',
  },
  {
    name: 'United Arab Emirates',
    id: 5,
    dialCode: '+971',
    wisenetCode: '4216',
    shortCode: 'AE',
  },
  {
    name: 'Uruguay',
    id: 183,
    dialCode: '+598',
    wisenetCode: '8215',
    shortCode: 'UY',
  },
  {
    name: 'Uzbekistan',
    id: 185,
    dialCode: '+998',
    wisenetCode: '7211',
    shortCode: 'UZ',
  },
  {
    name: 'Vanuatu',
    id: 190,
    dialCode: '+678',
    wisenetCode: '1304',
    shortCode: 'VU',
  },
  {
    name: 'Vatican City',
    id: '186',
    dialCode: '+379',
    wisenetCode: '3103',
    shortCode: 'VA',
  },
  {
    name: 'Venezuela',
    id: '188',
    dialCode: '+58',
    wisenetCode: '8216',
    shortCode: 'VE',
  },
  {
    name: 'Vietnam',
    id: 189,
    dialCode: '+84',
    wisenetCode: '5105',
    shortCode: 'VN',
  },
  {
    name: 'Yemen',
    id: 192,
    dialCode: '+967',
    wisenetCode: '4217',
    shortCode: 'YE',
  },
  {
    name: 'Zambia',
    id: 194,
    dialCode: '+260',
    wisenetCode: '9231',
    shortCode: 'ZM',
  },
  {
    name: 'Zimbabwe',
    id: 195,
    dialCode: '+263',
    wisenetCode: '9232',
    shortCode: 'ZW',
  },
];
