import React from 'react';
import PropTypes from 'prop-types';

export const ProfileIconLight = ({
  width,
  height,
  displayType,
  fillColor,
  strokeColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={displayType === 'outline' ? '#AAABAE' : fillColor}
      stroke={'#AAABAE'}
    >
      <path
        id="Stroke 1"
        d="M11.9848 15.3462C8.11719 15.3462 4.81433 15.931 4.81433 18.2729C4.81433 20.6148 8.09624 21.2205 11.9848 21.2205C15.8524 21.2205 19.1543 20.6348 19.1543 18.2938C19.1543 15.9529 15.8734 15.3462 11.9848 15.3462Z"
        stroke={displayType === 'outline' ? strokeColor : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Stroke 3"
        d="M11.9848 12.0059C14.5229 12.0059 16.58 9.94779 16.58 7.40969C16.58 4.8716 14.5229 2.81445 11.9848 2.81445C9.44667 2.81445 7.38858 4.8716 7.38858 7.40969C7.38001 9.93922 9.42382 11.9973 11.9524 12.0059H11.9848Z"
        stroke={displayType === 'outline' ? strokeColor : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ProfileIconLight.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  displayType: PropTypes.oneOf(['outline', 'filled']),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

ProfileIconLight.defaultProps = {
  width: '18',
  height: '19',
  fillColor: '#FBC91B',
  strokeColor: '#AAABAE',
};
