import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutsideComponentClickDetect } from '../../../../../../CommunityPortal/hooks';
import { handleLeaveCommunityClickInDemo } from '../../../../../../CommunityPortal/utils/productDemoUtils';
import MoreVerticalIcon from '../../../../../../components/Icons/MoreVerticalIcon';
import Tool02 from '../../../../../../components/Icons/Tool02';
import UserLeft01 from '../../../../../../components/Icons/UserLeft01/UserLeft01';
import Image from '../../../../../../components/Image';
import LeaveCommunityModal from '../LeaveCommunityModal';
import {
  NEXT_BILLING_DATE,
  SUBSCRIPTION_END_DATE,
} from '../SubscriptionCard/constants';

function checkIfUserIsManagerOrAdmin({ membersWithRoles = [], userId }) {
  const isEnrolledDetail = membersWithRoles.find(
    (roles) => roles.userObjectId === userId
  );

  if (!isEnrolledDetail) return false;

  const roles = isEnrolledDetail?.role ?? [];

  const isAdminOrMember = roles.find((role) =>
    ['owner', 'admin', 'manager'].includes(role)
  );

  if (!isAdminOrMember) return false;

  return true;
}

const SubscriptionCardV2 = ({
  community,
  onCtaClick,
  isDemoCommunity,
  communities = [],
}) => {
  const { t } = useTranslation();
  const {
    id,
    code,
    name,
    By,
    img,
    nextBillingDate,
    pricePerMonthString,
    status,
    isPaidCommunity,
    isTokenGated,
    isManualEnrolment,
    discountedCommunity,
    isDiscountedPricing,
    isSubscriptionFree,
    communityEventsPurchased,
    communityFoldersPurchased,
    stripeSubscriptionId,
    subscriptionObjectId,
    membersWithRoles,
    subscription,
    discountTagText,
  } = community;

  const { discountdetails } = subscription;

  const isFreeTrial = discountdetails?.promoDetails?.trialDays > 0;

  const user = useSelector((state) => state.auth.user);
  const optionMenuRef = useRef();
  const [isOptionMenuOpen, setIsOptionMenuOpen] = useState(false);
  const [isLeaveCommunityModalOpen, setIsLeaveCommunityModalOpen] = useState(
    false
  );
  const isAlreadyCancelled = status === 'Cancelled';
  const isAdminOrManager = checkIfUserIsManagerOrAdmin({
    membersWithRoles: membersWithRoles,
    userId: user?._id,
  });

  const getPricePerMonthString = () => {
    if (isSubscriptionFree) {
      return t('free');
    }
    if (isTokenGated) {
      return t('nft-gated');
    }
    if (isManualEnrolment) {
      return t('free-access');
    }
    if (isFreeTrial) {
      return 'Free Trial';
    }
    return t('paid-amount', { amount: pricePerMonthString });
  };

  const onLeaveCommunityModalOpen = () => {
    setIsLeaveCommunityModalOpen(true);
  };

  const onLeaveCommunityModalClose = () => {
    setIsLeaveCommunityModalOpen(false);
  };

  useOutsideComponentClickDetect(optionMenuRef, () =>
    setIsOptionMenuOpen(false)
  );

  return (
    <div className="md:max-w-[576px] border-1 border-npl-neutral-light-7 rounded-8 flex flex-col h-full">
      <div className="p-16 flex flex-row items-center bg-npl-neutral-light-2 rounded-t-8 rouded-r-8 space-x-12 border-b-1 border-npl-neutral-light-7">
        <div className="min-w-[40px] max-w-[40px] h-40">
          <Image
            {...img}
            customImgClassname={
              'bg-cover bg-center min-w-[40px] max-w-[40px] h-40 rounded-8'
            }
          />
        </div>
        <div className="space-y-4">
          <p className="font-semibold text-label-md text-npl-text-icon-on-light-surface-primary">
            {name}
          </p>
          <p className="text-label-sm text-npl-text-icon-on-light-surface-primary">
            {By}
          </p>
        </div>
      </div>

      <div className="p-16 space-y-4 relative">
        <div className="font-semibold text-label-md text-npl-text-icon-on-light-surface-primary flex items-center space-x-4 w-max">
          <p>{getPricePerMonthString()}</p>
          {isDiscountedPricing && !isFreeTrial && (
            <div className="text-npl-succes-light-11 text-overline-xs px-[6px] bg-npl-succes-light-3 border-1 border-npl-succes-light-6 rounded-4">
              {discountTagText}
            </div>
          )}
        </div>
        {isPaidCommunity && (
          <p className="text-label-sm text-npl-text-icon-on-light-surface-secondary">
            {isAlreadyCancelled
              ? `${SUBSCRIPTION_END_DATE(t)} ${format(
                  new Date(nextBillingDate),
                  'dd MMM, yyyy'
                )}`
              : nextBillingDate &&
                `${NEXT_BILLING_DATE(t)} ${format(
                  new Date(nextBillingDate),
                  'dd MMM, yyyy'
                )}`}
          </p>
        )}

        {(!isAdminOrManager || isPaidCommunity) && (
          <div
            ref={optionMenuRef}
            className="absolute p-8 top-4 right-4 cursor-pointer"
            onClick={() => setIsOptionMenuOpen((prev) => !prev)}
          >
            <MoreVerticalIcon
              fill="rgba(27, 27, 24, 0.5)"
              width={18}
              height={18}
            />
            {isOptionMenuOpen && (
              <div className="p-8 absolute top-full right-0 rounded-12 bg-white shadow-npl-styles-shadow-02 z-1 w-[200px]">
                {isPaidCommunity && (
                  <div
                    className="flex items-center px-8 py-4 space-x-12 cursor-pointer"
                    onClick={() => {
                      if (isDemoCommunity) {
                        handleLeaveCommunityClickInDemo();
                        return;
                      }
                      onCtaClick(code);
                    }}
                  >
                    <div className="flex-shrink-0">
                      <Tool02 />
                    </div>
                    <p className="text-label-md text-npl-text-icon-on-light-surface-primary">
                      {t('manage-membership')}
                    </p>
                  </div>
                )}

                {!isAdminOrManager && (
                  <div
                    className="flex items-center px-8 py-12 space-x-12 cursor-pointer"
                    onClick={() => {
                      if (isDemoCommunity) {
                        handleLeaveCommunityClickInDemo();
                        return;
                      }

                      if (isPaidCommunity) {
                        onCtaClick(code);
                        return;
                      }

                      onLeaveCommunityModalOpen();
                    }}
                  >
                    <div className="flex-shrink-0">
                      <UserLeft01 />
                    </div>
                    <p className="flex-grow whitespace-nowrap text-label-md text-npl-error-light-11">
                      {t('leave-community')}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <LeaveCommunityModal
        open={isLeaveCommunityModalOpen}
        onClose={onLeaveCommunityModalClose}
        communityEventsPurchased={communityEventsPurchased}
        communityFoldersPurchased={communityFoldersPurchased}
        isPaidCommunity={isPaidCommunity}
        subscriptionObjectId={subscriptionObjectId}
        stripeSubscriptionId={stripeSubscriptionId}
        communityCode={code}
        communityId={id}
        communityName={name}
        nextBillingDate={nextBillingDate}
        membersWithRoles={membersWithRoles}
        communities={communities}
      />
    </div>
  );
};

export default SubscriptionCardV2;
