import PropTypes from 'prop-types';
import React from 'react';

const MembersIcon = ({
  width,
  height,
  displayType,
  fillColor,
  strokeColor,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path
      fill={displayType === 'outline' ? 'none' : fillColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
      stroke={displayType === 'outline' ? strokeColor : fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill={displayType === 'outline' ? 'none' : fillColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
      stroke={displayType === 'outline' ? strokeColor : fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

MembersIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  displayType: PropTypes.oneOf(['outline', 'filled']),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

MembersIcon.defaultProps = {
  width: '24',
  height: '24',
  fillColor: '#FBC91B',
  strokeColor: '#AAABAE',
};

export default MembersIcon;
