export const formatCentsIntToUSD = (cents) => cents / 100;

// 100000 -> 100,000
// 100000.0322 -> 100,000.03
export const formatNumber = (price) => {
  try {
    if (!price) return 0;

    return new Intl.NumberFormat().format(price);
  } catch {
    return price;
  }
};
