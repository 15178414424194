import React from 'react';

const className = 'c-YoutubeIconV2Colored';
const YoutubeIconV2Colored = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <path
        d="M1.40282 6.71319C1.48315 5.47646 2.47747 4.50723 3.71529 4.4459C5.50774 4.3571 8.06967 4.25 10 4.25C11.9303 4.25 14.4923 4.3571 16.2847 4.4459C17.5225 4.50723 18.5169 5.47646 18.5972 6.71319C18.6741 7.89808 18.75 9.35604 18.75 10.5C18.75 11.644 18.6741 13.1019 18.5972 14.2868C18.5169 15.5235 17.5225 16.4928 16.2847 16.5541C14.4923 16.6429 11.9303 16.75 10 16.75C8.06967 16.75 5.50774 16.6429 3.71529 16.5541C2.47747 16.4928 1.48315 15.5235 1.40282 14.2868C1.32585 13.1019 1.25 11.644 1.25 10.5C1.25 9.35604 1.32585 7.89808 1.40282 6.71319Z"
        fill="#FF0000"
      />
      <path d="M8.125 8V13L13.125 10.5L8.125 8Z" fill="white" />
    </svg>
  );
};

export default YoutubeIconV2Colored;
