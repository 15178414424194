import React from 'react';
import './Text.scss';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

const Text = ({ text }) => {
  const className = 'c-Text';

  const isArrText = Array.isArray(text);

  const renderArrayTitle = () => {
    return text.map((textData, key) => {
      const {
        text: strText,
        isHighlighted,
        isBolder,
        isAnchorText,
        isNewLine,
        isNewParagraph,
        isStrikeThrough,
        isUnderlined,
        isCustomColor,
        link,
        ...rest
      } = textData;

      const spanClass = [
        isHighlighted ? `${className}--highlighted` : '',
        isBolder ? ` ${className}--bolder` : '',
        isAnchorText ? ` ${className}--anchor` : '',
        isNewLine ? ` ${className}--newline` : '',
        isStrikeThrough ? ` ${className}--striked` : '',
        isUnderlined ? ` ${className}--underline` : '',
        isCustomColor ? ` ${className}--custom-color` : '',
        isNewParagraph ? `${className}--new-paragraph` : '',
        link ? ` ${className}--link` : '',
      ].join('');

      if (link) {
        return (
          <span key={key} className={spanClass} {...rest}>
            <Link
              to={{ pathname: link }}
              title={strText}
              target="_blank"
              rel="noopener"
            >
              {strText}
            </Link>
          </span>
        );
      }

      return (
        <span key={key} className={spanClass} {...rest}>
          {strText}
        </span>
      );
    });
  };

  return <p className={className}>{isArrText ? renderArrayTitle() : text}</p>;
};

export const TextArrType = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    isHighlighted: PropTypes.bool,
    isBolder: PropTypes.bool,
    isAnchorText: PropTypes.bool,
    isCustomColor: PropTypes.bool,
    link: PropTypes.string,
    isNewLine: PropTypes.bool,
    isNewParagraph: PropTypes.bool,
    isStrikeThrough: PropTypes.bool,
    isUnderlined: PropTypes.bool,
  })
);

export const TextType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  TextArrType,
]);

Text.propTypes = {
  text: TextType,
};

export default Text;
