import React from 'react';

const className = 'c-DribbleIconV2Colored';
const DribbleIconV2Colored = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0.5C4.4794 0.5 0 4.9794 0 10.5C0 16.0206 4.4794 20.5 10 20.5C15.5098 20.5 20 16.0206 20 10.5C20 4.9794 15.5098 0.5 10 0.5ZM16.6052 5.10954C17.7983 6.5629 18.5141 8.41758 18.5358 10.4241C18.2538 10.3698 15.4338 9.79502 12.5922 10.1529C12.5271 10.0119 12.4729 9.86008 12.4078 9.70824C12.2343 9.2961 12.039 8.8731 11.8438 8.4718C14.9892 7.19198 16.4208 5.34816 16.6052 5.10954ZM10 1.97505C12.1692 1.97505 14.154 2.7885 15.6616 4.12256C15.5098 4.33948 14.2191 6.064 11.1822 7.20282C9.78308 4.63232 8.2321 2.5282 7.9935 2.20282C8.6334 2.05098 9.30586 1.97505 10 1.97505ZM6.3666 2.77766C6.59436 3.08134 8.1128 5.19632 9.53362 7.71258C5.5423 8.77548 2.01736 8.7538 1.63774 8.7538C2.19088 6.10738 3.98048 3.90564 6.3666 2.77766ZM1.45336 10.5108C1.45336 10.4241 1.45336 10.3373 1.45336 10.2505C1.82213 10.2614 5.9653 10.3156 10.2278 9.0358C10.4772 9.51302 10.705 10.0011 10.9219 10.4892C10.8134 10.5217 10.6941 10.5542 10.5857 10.5868C6.18222 12.0076 3.83948 15.8905 3.64426 16.2158C2.2885 14.7082 1.45336 12.7017 1.45336 10.5108ZM10 19.0466C8.02604 19.0466 6.2039 18.3742 4.76138 17.2462C4.91324 16.9317 6.6486 13.5911 11.4642 11.91C11.4859 11.8991 11.4967 11.8991 11.5184 11.8883C12.7223 15.0011 13.2104 17.615 13.3406 18.3633C12.3102 18.808 11.1822 19.0466 10 19.0466ZM14.7614 17.5824C14.6746 17.0618 14.2191 14.5672 13.102 11.4978C15.7809 11.0748 18.1236 11.769 18.4165 11.8666C18.0477 14.2419 16.6811 16.2918 14.7614 17.5824Z"
        fill="#C32361"
      />
    </svg>
  );
};

export default DribbleIconV2Colored;
