import { getAxiosError } from '../../services/helper';
import keys from '../../config/keys';
import protectedAxiosRequest from '../../services/helper/protectedAxiosRequest';

export const getDiscordUserData = async (accessToken, learnerId) => {
  const url = `${keys.baseURL}/api/v1/discord-user-info`;
  try {
    const response = await protectedAxiosRequest.post(url, {
      accessToken,
      learnerId,
    });
    return {
      ...response,
      ...(response.avatar && {
        avatarUrl: `https://cdn.discordapp.com/avatars/${response.id}/${response.avatar}`,
      }),
    };
  } catch (error) {
    return getAxiosError(error);
  }
};

export const getDiscordUserRole = async (guildId, discordUserId) => {
  const url = `${keys.baseURL}/api/v1/communities/getDiscordRoles/${guildId}/${discordUserId}`;
  try {
    const response = await protectedAxiosRequest.post(url, {});
    return {
      ...response,
    };
  } catch (error) {
    return getAxiosError(error);
  }
};

export const loginUsingDiscord = async (access_token) => {
  const url = `${keys.baseURL}/api/v1/discord-login`;
  try {
    const response = await protectedAxiosRequest.post(url, {
      accessToken: access_token,
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};
