import { DEFAULT_MSG, TOAST_TYPE } from './ToastContainer';

import Button from '../Button';
import CheckWithCircleIcon from '../../../components/Icons/CheckWithCircleIcon';
import ErrorAlertWarningIcon from '../../../components/Icons/ErrorAlertWarningIcon';
import style from './ToastContainer.module.scss';
import { useRef } from 'react';

const Toast = (props) => {
  const {
    id,
    type,
    text = DEFAULT_MSG,
    dismissOnClick = false,
    removeToast,
    customClassNames,
    isVisible,
    nplVersion,
    description,
    CTAButtonProps,
    customIcon,
  } = props;

  const toastRef = useRef();

  const getTypeClassNames = ({ type }) => {
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        return 'bg-success-dark text-neutral-99';
      case TOAST_TYPE.ERROR:
        return 'bg-error text-neutral-99';
      default:
        return 'bg-neutral-10 text-neutral-99';
    }
  };

  const getTypeClassNamesForVersion_2 = ({ type }) => {
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        return 'bg-npl-succes-light-3 text-npl-text-icon-on-light-surface-primary';
      case TOAST_TYPE.ERROR:
        return 'bg-npl-error-light-3 text-npl-text-icon-on-light-surface-primary';
      case TOAST_TYPE.INFORMATION:
        return 'bg-npl-blurple-light-3 text-npl-text-icon-on-light-surface-primary';
      case TOAST_TYPE.WARNING:
        return 'bg-npl-yellow-light-3 text-npl-text-icon-on-light-surface-primary';
      case TOAST_TYPE.NEUTRAL:
        return 'bg-npl-neutral-dark-3 text-npl-text-icon-on-dark-primary';
      default:
        return 'bg-neutral-10 text-neutral-99';
    }
  };

  const getIconForNPLv2 = ({ type }) => {
    if (customIcon) return customIcon;

    switch (type) {
      case TOAST_TYPE.SUCCESS:
        return <CheckWithCircleIcon fill={'#18774C'} />;
      case TOAST_TYPE.ERROR:
        return <ErrorAlertWarningIcon fill={'#CE2C31'} />;
      case TOAST_TYPE.INFORMATION:
        return <ErrorAlertWarningIcon fill={'#3442B2'} />;
      case TOAST_TYPE.WARNING:
        return <ErrorAlertWarningIcon fill={'#946800'} />;
      case TOAST_TYPE.NEUTRAL:
        return <ErrorAlertWarningIcon fill={'#A2A19A'} />;
      default:
        return <></>;
    }
  };
  const visibilityClassNames = isVisible ? style.showToast : style.hideToast;

  const shadowClassName = style.boxShadowForToast;
  if (nplVersion == 2) {
    return (
      <div
        tabIndex={0}
        role="button"
        className={`w-full lg:w-[560px] ${shadowClassName} flex flex-row justify-between max-w-[560px] box-border font-poppins p-16 text-16 rounded-12 ${visibilityClassNames} ${getTypeClassNamesForVersion_2(
          { type }
        )} ${customClassNames}`}
        onClick={() =>
          dismissOnClick && removeToast && id ? removeToast(id) : null
        }
        ref={toastRef}
      >
        <div className="flex flex-row gap-6">
          <div className="mr-18">{getIconForNPLv2({ type })}</div>
          <div className="flex flex-col">
            <span className="font-medium text-body-md"> {text} </span>
            {description && (
              <span className="mt-4 font-normal text-body-sm">
                {description}
              </span>
            )}
            {CTAButtonProps && (
              <Button
                displayType={'outline'}
                customClassNames={
                  '!border-npl-neutral-light-7 self-start mt-16 !text-14 !font-medium'
                }
                slim={true}
                rounded={true}
                onClick={CTAButtonProps.onClick}
              >
                {CTAButtonProps.text}
              </Button>
            )}
          </div>
        </div>
        <button
          className="scale-[1.5] mb-auto"
          onClick={() => (removeToast && id ? removeToast(id) : null)}
        >
          &#215;
        </button>
      </div>
    );
  } else {
    return (
      <div
        tabIndex={0}
        role="button"
        className={`w-full lg:w-[500px] lg:max-w-[500px] box-border font-poppins p-16 text-16 rounded-12 ${visibilityClassNames} ${getTypeClassNames(
          { type }
        )} ${customClassNames}`}
        onClick={() =>
          dismissOnClick && removeToast && id ? removeToast(id) : null
        }
        ref={toastRef}
      >
        {text}
      </div>
    );
  }
};

Toast.defaultProps = {
  nplVersion: 1,
  customIcon: null,
};

export default Toast;
