import React, { useEffect, useRef, useState } from 'react';

import EyeVisible from '../../Icons/EyeVisible';
import PropTypes from 'prop-types';
import style from './FloatingLabelInput.module.scss';
import { useWindowWidthContext } from '../../../contexts/WindowWidthContext';

const className = 'c-FloatingLabelInput';

// TODO: Add Icons and hover states
// https://www.figma.com/file/uypc3B2NlqSGqbcB7M4oG8/Nas.io-Onboarding-Flow?node-id=301%3A27166

const FloatingLabelInput = (props) => {
  const {
    type,
    name,
    label,
    value,
    placeholder,
    onChange,
    customInputClasses,
    customLabelClasses,
    disabled,
    error,
    focus,
    ...rest
  } = props;
  const ref = useRef();
  const [isEyeIconVisible, setIsEyeIconVisible] = useState(false);

  const handleLabelOnClick = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);

  const toggleInputVisibility = () => {
    const inputType = ref?.current?.type;
    if (inputType === 'password') ref.current.type = 'text';
    else ref.current.type = 'password';

    setIsEyeIconVisible((prevBool) => !prevBool);
  };

  const renderEyeIcon = () => {
    return (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus
      <div
        role="button"
        className={'absolute w-fit h-fit top-16 right-16 z-20'}
        onClick={toggleInputVisibility}
      >
        <EyeVisible isOpen={isEyeIconVisible} />
      </div>
    );
  };

  return (
    <div className={`${className} ${style[className]} relative`}>
      <input
        ref={ref}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={`w-full h-full text-label-lg text-dark-netural10 p-8 px-16 pt-24 z-1 border-1 border-neutral-80 ${
          error && 'border-error'
        } rounded-12 ${customInputClasses}`}
        disabled={disabled}
        {...rest}
      />
      {
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <label
          htmlFor={name}
          onClick={handleLabelOnClick}
          tabIndex={-1}
          className={`absolute px-8 whitespace-nowrap overflow-ellipsis flex items-center text-label-sm font-medium text-neutral-50 ${customLabelClasses}`}
        >
          <span className="whitespace-nowrap overflow-ellipsis">
            {label}
            {rest.required && '*'}
            {rest.optional && (
              <span className="ml-8 text-label-sm">(Optional)</span>
            )}
          </span>
        </label>
      }
      {type === 'password' && renderEyeIcon()}
      {error && (
        <span className="mt-4 ml-8 font-medium -bottom-12 text-label-md text-error">
          {error}
        </span>
      )}
    </div>
  );
};

FloatingLabelInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};
FloatingLabelInput.defaultProps = {
  type: 'text',
};

export default FloatingLabelInput;
