import React from 'react';

const className = 'c-DiscordV2';
const DiscordV2 = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className + ' ' + customClassName}
    >
      <path
        d="M19.2774 5.82357C17.9389 5.20942 16.5036 4.75694 15.0029 4.49779C14.9756 4.49279 14.9483 4.50529 14.9342 4.53029C14.7496 4.8586 14.5451 5.28691 14.402 5.62356C12.7879 5.38191 11.182 5.38191 9.60103 5.62356C9.45782 5.27943 9.24592 4.8586 9.0605 4.53029C9.04642 4.50612 9.01912 4.49362 8.99179 4.49779C7.49192 4.75611 6.05662 5.20859 4.71732 5.82357C4.70572 5.82856 4.69579 5.83691 4.68919 5.84773C1.96672 9.91505 1.22092 13.8824 1.58678 17.8005C1.58844 17.8197 1.5992 17.8381 1.6141 17.8497C3.41031 19.1688 5.15024 19.9696 6.85787 20.5004C6.8852 20.5087 6.91416 20.4988 6.93155 20.4762C7.33549 19.9246 7.69556 19.343 8.00429 18.7313C8.02251 18.6955 8.00512 18.653 7.96788 18.6388C7.39674 18.4222 6.8529 18.158 6.32976 17.858C6.28838 17.8339 6.28507 17.7747 6.32314 17.7464C6.43323 17.6639 6.54334 17.578 6.64846 17.4914C6.66748 17.4755 6.69398 17.4722 6.71634 17.4822C10.1531 19.0513 13.8739 19.0513 17.2701 17.4822C17.2924 17.4714 17.3189 17.4747 17.3388 17.4905C17.4439 17.5772 17.554 17.6639 17.6649 17.7464C17.703 17.7747 17.7005 17.8339 17.6591 17.858C17.136 18.1638 16.5922 18.4222 16.0202 18.638C15.983 18.6522 15.9664 18.6955 15.9846 18.7313C16.3 19.3421 16.66 19.9238 17.0565 20.4754C17.0731 20.4988 17.1029 20.5087 17.1302 20.5004C18.8461 19.9696 20.586 19.1688 22.3823 17.8497C22.398 17.8381 22.4079 17.8205 22.4096 17.8014C22.8474 13.2716 21.6762 9.33675 19.3047 5.84856C19.2989 5.83691 19.289 5.82856 19.2774 5.82357ZM8.51752 15.4148C7.48281 15.4148 6.63024 14.4649 6.63024 13.2982C6.63024 12.1316 7.46628 11.1817 8.51752 11.1817C9.57701 11.1817 10.4213 12.14 10.4048 13.2982C10.4048 14.4649 9.56873 15.4148 8.51752 15.4148ZM15.4954 15.4148C14.4607 15.4148 13.6082 14.4649 13.6082 13.2982C13.6082 12.1316 14.4442 11.1817 15.4954 11.1817C16.5549 11.1817 17.3992 12.14 17.3827 13.2982C17.3827 14.4649 16.5549 15.4148 15.4954 15.4148Z"
        fill="#5865F2"
      />
    </svg>
  );
};

export default DiscordV2;
