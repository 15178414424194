import React from 'react';
import { useEffect } from 'react';
import LoginForm from '../../components/Authentication/LoginForm';
import Image from '../../components/Image';
import { sideImageData } from './constants';
import config from '../../modules/config';
import './LoginPage.scss';

const { isDevEnv } = config;

const LoginPage = () => {
  const className = 'c-LoginPage';

  useEffect(() => {
    if (!isDevEnv) {
      // redirect from login page with all the query params on the url to nas io login page
      window.location.href = `${process.env.REACT_APP_NASIO_BASE_URL}/login${window.location.search}`;
    }
  }, []);
  return (
    <div className="flex items-center h-screen c-LoginPage--container bg-grey-f6">
      <div className={className}>
        <div className={`${className}__container`}>
          <div className={`${className}__media`}>
            <Image {...sideImageData} />
          </div>
          <div className={`${className}__form`}>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
