import React from 'react';

const Info = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#3442B2"
        fillRule="evenodd"
        d="M12 4a8 8 0 100 16 8 8 0 000-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm8.95-4a1 1 0 011-1h.1a1 1 0 011 1v.1a1 1 0 01-1 1h-.1a1 1 0 01-1-1V8zM12 10a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Info;
