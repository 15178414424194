import './index.css';
import './styles/tailwind.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';

import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom';
import { WindowWidthContextProvider } from './contexts/WindowWidthContext';
import { initiateGTM } from './utility/analytics';
import './i18n';
import { loadTranslations } from './i18n';

const IS_DEV_ENV = process?.env?.NODE_ENV === 'development';
const GOOGLE_CLIENT_ID = process?.env?.REACT_APP_GOOGLE_CLIENT_ID;

console.info('>>> Active Env: ', process?.env?.NODE_ENV);

if (!IS_DEV_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: IS_DEV_ENV ? 1 : 0.25,
    environment: IS_DEV_ENV ? 'development' : 'production',
  });
}

initiateGTM();

loadTranslations().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <WindowWidthContextProvider>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </WindowWidthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
