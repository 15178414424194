import React from 'react';

const ProfileIcon = () => {
  return (
    <svg
      className="c-ProfileIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M6.631 14.983c1.47-.493 3.399-.637 5.354-.637 1.967 0 3.896.15 5.365.65.74.25 1.435.615 1.954 1.16a3.02 3.02 0 01.85 2.138c0 .849-.31 1.572-.856 2.138-.522.542-1.22.903-1.96 1.151-1.47.493-3.398.638-5.353.638-1.967 0-3.897-.15-5.366-.65-.74-.25-1.435-.615-1.955-1.16a3.021 3.021 0 01-.85-2.138c0-.85.31-1.572.857-2.14.522-.541 1.22-.902 1.96-1.15zm-.52 2.538a1.01 1.01 0 00-.297.752c0 .323.103.555.297.758.218.228.585.454 1.152.647 1.144.39 2.8.543 4.722.543 1.912 0 3.57-.15 4.717-.534.569-.19.938-.416 1.156-.642a1.01 1.01 0 00.296-.751c0-.324-.102-.555-.297-.758-.217-.228-.584-.454-1.151-.647-1.144-.39-2.8-.543-4.721-.543-1.913 0-3.57.148-4.718.533-.569.191-.938.416-1.156.642zM6.389 7.408a5.596 5.596 0 115.596 5.598H11.949a5.58 5.58 0 01-5.56-5.598zm0 0v-.002l1 .004h-1v-.002zm5.596-3.594A3.596 3.596 0 008.389 7.41v.003a3.58 3.58 0 003.565 3.593h.03a3.596 3.596 0 000-7.192z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ProfileIcon;
