/**
 * Sample queryString = '&coupon=ABC&email=abc@gmail.com
 * Sample Response : {
 *  coupon: 'ABC',
 *  email: 'abc@gmail.com'
 * }
 */
export const convertQueryStringToJson = (queryString) => {
  if (!queryString) return {};
  const pairs = queryString.slice(1).split('&');

  const responseJson = pairs.reduce((acc, pair) => {
    const keyValPair = pair.split('=');
    const key = keyValPair[0];
    const val = decodeURIComponent(keyValPair[1] || '');
    acc[key] = val;

    return acc;
  }, {});

  return responseJson;
};

export const getPrimaryDomain = () => {
  //server-side rendering does not have window object
  if (typeof window === 'undefined') {
    return '';
  }

  const parts = window.location.hostname.split('.');
  let primaryDomain = parts.slice(-2).join('.');

  //the preview links are dynamic, so we cannot apply any specific domain to cookies
  if (primaryDomain === 'vercel.app') {
    primaryDomain = '';
  }

  return primaryDomain;
};
