import { PhoneNumberUtil } from 'google-libphonenumber';
import { countryList } from '../data/wisenetMapCountryList';

export const INPUT_FIELD_TYPE = 'input';
export const DROPDOWN_FIELD_TYPE = 'select';
export const CHECKBOX_FIELD_TYPE = 'checkbox';
export const TEXTAREA_FIELD_TYPE = 'textarea';

export const countryOptions = countryList.map(({ name, id }) => ({
  value: id,
  label: name,
}));
const getFlagEmoji = (countryCode) => {
  if (countryCode) {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }
  return '';
};

export const dialCodeOptions = [...countryList]
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(({ id, countryCode, dialCode, flag, name }) => ({
    value: id,
    label: `${flag ?? ''} ${dialCode}`,
    optionDisplay: `${name} ${flag && flag} :(${dialCode}) `,
  }));

export const getCountryById = (countryId) => {
  const country = countryList.find(({ id }) => id === parseInt(countryId, 10));
  return country;
};

export const getCountryNameById = (countryId) => {
  const country = countryList.find(({ id }) => id === parseInt(countryId, 10));
  return country?.name || '';
};

export const getDialCodeById = (countryId) => {
  const country = countryList.find(({ id }) => id === parseInt(countryId, 10));
  return country?.dialCode || '';
};

export const getCountryIdByName = (countryName) => {
  return countryList.find(({ name }) => name === countryName);
};

/**
 * Return the id of the country object
 *
 * @param {string} dial //+65
 * @returns {number} //153
 */
export const getDialCodeIdByDialCode = (dial) => {
  const country = countryList.find(({ dialCode }) => dialCode === dial);
  return country?.id?.toString() ? country?.id : ''; // id - undefined or null
};

/**
 * Extract the country id from phone number
 *
 * @param {string} phoneNumber //+65 87932774
 * @returns {number} //153
 */
export const getDialCodeIdFromPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  // if phone number doesn't start with +, add it
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = `+${phoneNumber}`;
  }

  const phoneUtil = PhoneNumberUtil.getInstance();
  const parsedNumber = phoneUtil.parse(phoneNumber);
  const dialCode = parsedNumber.getCountryCode();
  return getDialCodeIdByDialCode(`+${dialCode}`);
};

export const getPhoneNumberWithoutDial = (phoneNumber) => {
  try {
    if (!phoneNumber) return '';
    // if phone number doesn't start with +, add it
    if (!phoneNumber.startsWith('+')) {
      phoneNumber = `+${phoneNumber}`;
    }

    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return parsedNumber.getNationalNumber().toString();
  } catch (e) {
    return '';
  }
};
