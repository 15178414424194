const defaultState = {
  bookingStart: new Date(),
  bookingEnd: new Date(),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "SET_BOOKING_TIME":
      return {
        ...state,
        bookingStart: action.bookingStart,
        bookingEnd: action.bookingEnd,
      };

    default:
      return state;
  }
};
