const config = {
  app_env: process.env.REACT_APP_ENV,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  fbAppId: process.env.REACT_APP_FB_APP_ID,
  appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
  appleRedirectLink: process.env.REACT_APP_APPLE_REDIRECT_LINK,
  isDevEnv: process.env.NODE_ENV === 'development',
  apiStaticDataPath: process.env.REACT_APP_BACKEND_STATIC_DATA_SEVER,
  authCookieDomain: process.env.REACT_APP_AUTH_COOKIE_DOMAIN,
  imagesBasePath: process.env.REACT_APP_COURSE_ASSET_BASE_URL,
  nasIOBaseUrl: process.env.REACT_APP_NASIO_BASE_URL,
  checkoutEndpoint: process.env.REACT_APP_CHECKOUT_ENDPOINT,
  nasIOWeb3BaseUrl: process.env.REACT_APP_NASIO_WEB3_BASE_URL,
  nftCertificateContractAdress: process.env.REACT_APP_NFT_CERT_CONTRACT_ADDRESS,
  getStreamApiKey: process.env.REACT_APP_GETSTREAM_API_KEY,
  getStreamAppId: process.env.REACT_APP_GETSTREAM_APP_ID,
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID,
  intercomApiBase: process.env.REACT_APP_INTERCOM_API_BASE,
  pixelId: process.env.REACT_APP_FB_PIXEL_ID,
};

export default config;
