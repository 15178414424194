import React from 'react';
import PropTypes from 'prop-types';

const BackToLoginButton = ({ successMsg }) => (
  <div>
    <a href="/login">
      <div className="buttons-smaller">Back to login</div>
    </a>
    <div className="success-message">{successMsg}</div>
  </div>
);

BackToLoginButton.propTypes = {
  successMsg: PropTypes.string.isRequired,
};

export default BackToLoginButton;
