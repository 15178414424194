import React from 'react';
import PropTypes from 'prop-types';
import './SuccessMessage.scss';

const SuccessMessage = (props) => {
  return (
    <div
      className={`success-box ${props.position} ${props.top} ${props.right}`}
    >
      {props.msg}
    </div>
  );
};

SuccessMessage.propTypes = {
  msg: PropTypes.string.isRequired,
  position: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
};

export default SuccessMessage;
