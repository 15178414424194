import * as yup from 'yup';

export const sideImageData = {
  alt: 'Sidebar Login',
  mobileImgData: {
    src:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-portal/LoginPage/jpg/member-banner.jpg',
    webpSrc:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-portal/LoginPage/jpg/member-banner.jpg',
  },
  customImgClassname: 'h-full rounded-tl-4 rounded-bl-4',
};

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be in a valid format like “name@example.com”')
    .required('Email is required.'),
  password: yup.string().required('Password is required.'),
});
