import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import EyeVisible from '../../../components/Icons/EyeVisible';
import './NPLFloatingLabelInput.scss';

const className = 'c-NPLFloatingLabelInput';

const NPLFloatingLabelInput = (props) => {
  const {
    type,
    name,
    label,
    value,
    placeholder,
    onChange,
    customInputClasses,
    customLabelClasses,
    disabled,
    error,
    focus,
    ...rest
  } = props;
  const ref = useRef();
  const [isEyeIconVisible, setIsEyeIconVisible] = useState(false);

  const handleLabelOnClick = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  const toggleInputVisibility = () => {
    const inputType = ref?.current?.type;
    if (inputType === 'password') ref.current.type = 'text';
    else ref.current.type = 'password';

    setIsEyeIconVisible((prevBool) => !prevBool);
  };

  const renderEyeIcon = () => {
    return (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus
      <div
        role="button"
        className={'absolute w-fit h-fit top-16 right-16 z-20'}
        onClick={toggleInputVisibility}
      >
        <EyeVisible isOpen={isEyeIconVisible} />
      </div>
    );
  };

  return (
    <div className={`${className} relative`}>
      <input
        ref={ref}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={classNames(
          'w-full h-full rounded-12 text-label-lg text-npl-text-icon-on-light-surface-primary p-8 px-16 pt-24 z-1 border-1 border-npl-neutral-light-7',
          { 'border-error': error },
          {
            'border-npl-neutral-light-3 bg-npl-neutral-light-3 text-npl-text-icon-on-light-surface-tertiary': disabled,
          }
        )}
        disabled={disabled}
        {...rest}
      />
      {
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <label
          htmlFor={name}
          onClick={handleLabelOnClick}
          tabIndex={-1}
          className={classNames(
            `absolute px-8 whitespace-nowrap overflow-ellipsis flex items-center text-body-md text-npl-text-icon-on-light-surface-secondary`,
            {
              'border-npl-neutral-light-3 bg-npl-neutral-light-3 text-npl-text-icon-on-light-surface-tertiary': disabled,
            }
          )}
        >
          <span
            className={classNames('whitespace-nowrap overflow-ellipsis', {
              'text-npl-text-icon-on-light-surface-tertiary': disabled,
            })}
          >
            {label}
            {rest.required && '*'}
            {rest.optional && (
              <span className="ml-8 text-label-sm">(Optional)</span>
            )}
          </span>
        </label>
      }
      {type === 'password' && renderEyeIcon()}
      {error && (
        <span className="mt-4 ml-8 font-medium text-label-md text-error">
          {error}
        </span>
      )}
    </div>
  );
};

export default NPLFloatingLabelInput;
