import React from 'react';
import { PropTypes } from 'prop-types';
import CommonSettingWidget from '../CommonSettingWidget';
import FormInput from '../FormInput';

import './CommentSettingWidget.scss';
import { useTranslation } from 'react-i18next';

const CommentSettingWidget = ({ inputProps }) => {
  const className = 'c-CommentSettingWidget';
  const { t } = useTranslation();

  const renderInputs = () =>
    inputProps?.map((input) => <FormInput {...input} key={input.name} />);

  const renderMainWidgetContent = () => {
    return (
      <>
        <div className={`${className}__setting mb-24 `}>
          <div className={`${className}__subheader`}>
            <p className="font-poppins text-body-md font-normal text-grey-neutral40">
              {t(
                'receive-email-notifications-when-a-student-replies-to-your-comment'
              )}
            </p>
          </div>
          <form className={`${className}__form block`}>{renderInputs()}</form>
        </div>
      </>
    );
  };

  function HeaderContentForCommonSettingWidget() {
    return (
      <>
        <p className="text-heading-xs text-grey-neutral10 font-medium">
          {t('comment-reply-email-preferences')}
        </p>
      </>
    );
  }

  return (
    <div className={`${className} ${className}__mainContainer`}>
      <CommonSettingWidget
        header={<HeaderContentForCommonSettingWidget />}
        children={renderMainWidgetContent()}
      />
    </div>
  );
};

CommentSettingWidget.propTypes = {
  inputProps: PropTypes.array,
};

export default CommentSettingWidget;
