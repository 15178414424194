export const headerStaticPropsReferral = {
  title: 'Referral',
};

export const SHARE_SUBTITLE_TEXT =
  'Share this code, you and your friend will get 8% benefits on the class fees.';

export const MAKE_REFERRAL_TITLE = 'Make a referral to start earning credits';
export const SHARE_PERSONAL_LINK_TEXT = 'Share your personal link';
export const COPY_LINK = 'Copy Link';
