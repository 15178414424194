import './Button.scss';

import { PropTypes } from 'prop-types';

const buttonDisplayTypes = {
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  flat: 'flat',
  'form-primary': 'form-primary',
  'form-secondary': 'form-secondary',
  'standard-inverse': 'standard-inverse',
  'dark-inverse': 'dark-inverse',
  inverse: 'inverse',
  'table-btn': 'table-btn',
};

const buttonDisplayTypesArr = [...Object.keys(buttonDisplayTypes), ''];

const Button = ({
  displayType,
  fluid,
  withShadow,
  narrow,
  children,
  newVersion,
  customClassNames,
  ...btnProps
}) => {
  const className = 'c-Button';
  const classNames = [
    className,
    displayType ? ` ${className}--${displayType}` : '',
    fluid ? ` ${className}--fluid` : '',
    withShadow ? ` ${className}--shadow` : '',
    narrow ? ` ${className}--narrow` : '',
    newVersion ? ` ${className}--new-version` : '',
    customClassNames ? customClassNames : '',
  ].join('');

  return (
    <button className={classNames} {...btnProps}>
      {children}
    </button>
  );
};

Button.propTypes = {
  fluid: PropTypes.bool,
  displayType: PropTypes.oneOf(buttonDisplayTypesArr),
  withShadow: PropTypes.bool,
  narrow: PropTypes.bool,
  newVersion: PropTypes.bool,
};

export default Button;
