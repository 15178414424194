// until we have more complete picture of what do we want to do with config setup
// this file can be used as a default location for any configs

const config = {
  BASE_BE_URL: `${process.env.REACT_APP_AUTH_SERVER_URL}/api/v1/communities`,
  MAIN_WEBSITE_URL: `${process.env.REACT_APP_PAYMENT_SERVER_URL}/api/v1`, // TODO: Make this configurable
  NAS_IO_GET_MOBILE_APP: `${process.env.REACT_APP_NASIO_BASE_URL}/install`,
};

export default config;
