// export const headerStaticPropsProfile = {
//   title: t('profile'),
// };

export const findCountryWithCountryID = (t, countryList, countryId) => {
  let country = countryList.find((country) => {
    return country.id === countryId;
  });

  return t(country?.name);
};

export const constantvalues = (t) => [
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/fb-transparent-black.svg',
    link: '',
    type: 'facebook',
    label: t('facebook'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'instagram',
    label: t('instagram'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'twitter',
    label: t('twitter'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'youtube',
    label: t('youtube'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'linkedin',
    label: t('linkedin'),
  },
];

export const allSocialMediaValues = (t) => [
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/fb-transparent-black.svg',
    link: '',
    type: 'facebook',
    label: t('facebook'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'instagram',
    label: t('instagram'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'twitter',
    label: t('twitter'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'youtube',
    label: t('youtube'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'linkedin',
    label: t('linkedin'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'tiktok',
    label: t('tiktok'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'opensea',
    label: t('opensea'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'discord',
    label: t('discord'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'dribble',
    label: t('dribble'),
  },
  {
    iconLink:
      'https://d2oi1rqwb0pj00.cloudfront.net/icons/ig-transparent-black.svg',
    link: '',
    type: 'website',
    label: t('website'),
  },
];

// export const DEFAULT_SOCIAL_MEDIA_OPTION = t('select-social-media');
export const SOCIAL_MEDIA_MODAL = 'SOCIAL_MEDIA_MODAL';
export const PASSWORD_MODAL = 'PASSWORD_MODAL';
export const EDIT_PROFILE_MODAL = 'EDIT_PROFILE_MODAL';
export const PASSWORD_DESIGN = '***********';
// export const ACCOUNT_INFORMATION = t('account-information');
// export const EDIT_PROFILE_TEXT = t('edit-profile');
// export const PHONE_NUMBER_LABEL = t('phone-number');
// export const COUNTRY_LABEL = t('country');
// export const BIO_LABEL = t('bio');
// export const SOCIAL_LINKS_LABEL = t('your-social-links');
// export const UPDATE_LINKS_LABEL = t('update-links');
// export const PASSWORD_LABEL = t('your-password');
// export const CHANGE_PASSWORD_LABEL = t('change-password');
