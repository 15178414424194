import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { startLogout } from '../../../../../../actions/auth';
import {
  cancelFreeOrTokenGatedSubscription,
  cancelSubscription,
} from '../../../../../../CommunityPortal/apiServices/cancelSubscriptionService';
import CloseIcon from '../../../../../../CommunityPortal/components/Icons/CloseIcon';
import Modal from '../../../../../../CommunityPortal/components/Modal';
import RoundedButton from '../../../../../../CommunityPortal/components/RoundedButton/RoundedButton';
import { showToast } from '../../../../../../CommunityPortal/components/ToastContainer/ToastContainer';
import CheckboxV2 from '../../../../../../components/CheckboxV2';
import Info from '../../../../../../components/Icons/Info';

const MSG_MAX_LENGTH = 200;
const OTHER_MSG_NAME = 'other_msg';

const MODAL_STEP = {
  PAID_MSG: 'paid_msg',
  SELECT_MSG: 'select_msg',
  FINAL_MSG: 'final_msg',
};

const LeaveCommunityModal = ({
  open,
  onClose,
  communityEventsPurchased,
  communityFoldersPurchased,
  isPaidCommunity,
  subscriptionObjectId,
  stripeSubscriptionId,
  communityCode,
  communityId,
  communityName,
  nextBillingDate,
  communities,
}) => {
  const { t } = useTranslation();
  const OTHER_CHECK_NAME = t('other');
  const FORM_CHECKBOX_LIST = [
    {
      label: t('community-was-not-suitable'),
      name: 'Community_was_not_suitable',
    },
    {
      label: t('community-was-not-engaging-for-me'),
      name: 'Community_was_not_engaging_for_me',
    },
    {
      label: t('community-manager-sends-too-many-emails'),
      name: 'Community_Manager_sends_too_many_emails',
    },
    {
      label: t('guest-speaker-s-were-not-exciting'),
      name: 'Guest_speaker(s)_were_not_exciting',
    },
    { label: t('presonal-reasons'), name: 'Presonal_reasons' },
    { label: t('work-commitments'), name: 'Work_commitments' },
    { label: t('technical-issues'), name: 'Technical_issues' },
    { label: t('language-barriers'), name: 'Language_barriers' },
    { label: t('other-please-explain'), name: OTHER_CHECK_NAME },
  ];

  const FORM_CHECKBOX_MAP = FORM_CHECKBOX_LIST.reduce((acc, check) => {
    return Object.assign(acc, { [check.name]: check.label });
  }, {});
  const eventsPurchased = Object.keys(communityEventsPurchased)?.length;
  const foldersPurchased = Object.keys(communityFoldersPurchased)?.length;
  const hasPaidContent = eventsPurchased > 0 || foldersPurchased > 0;

  const dispatch = useDispatch();
  const [step, setStep] = useState(
    hasPaidContent ? MODAL_STEP.PAID_MSG : MODAL_STEP.SELECT_MSG
  );
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const isOtherChecked = formData[OTHER_CHECK_NAME];
  const isAtleastOneSelected = checkIfAtleastOneCheckboxSelected();

  const onInputChange = (e) => {
    e.persist();
    setFormData((prev) => {
      const name = e.target.name;
      if (name === OTHER_MSG_NAME) {
        if (errors[OTHER_MSG_NAME])
          setErrors((prev) => {
            return { ...prev, [OTHER_MSG_NAME]: '' };
          });

        return { ...prev, [e.target.name]: e.target.value };
      }

      if (errors.check)
        setErrors((prev) => {
          return { ...prev, check: '' };
        });
      return { ...prev, [e.target.name]: e.target.checked };
    });
  };

  const onModalClose = () => {
    onClose();
    setFormData({});
    setErrors({});
    setStep(hasPaidContent ? MODAL_STEP.PAID_MSG : MODAL_STEP.SELECT_MSG);
  };

  function checkIfAtleastOneCheckboxSelected() {
    // Atleast one should be seleted
    let isAtleastOneSelected = false;
    let formDataKeys = Object.keys(formData);

    for (let i = 0; i < formDataKeys.length; i++) {
      if (i === OTHER_MSG_NAME) continue;

      if (formData[formDataKeys[i]]) {
        isAtleastOneSelected = true;
        break;
      }
    }

    return isAtleastOneSelected;
  }

  const submitForm = () => {
    if (!isAtleastOneSelected) {
      setErrors((prev) => {
        return { ...prev, check: t('select-one') };
      });
      return;
    }

    if (isOtherChecked && !formData?.[OTHER_MSG_NAME]?.trim()?.length > 0) {
      setErrors((prev) => {
        return { ...prev, [OTHER_MSG_NAME]: t('cannot-be-empty') };
      });
      return;
    }

    setStep(MODAL_STEP.FINAL_MSG);
  };

  const confirmLeave = async () => {
    try {
      setIsLoading(true);

      // check paid - can cel subscription
      // free / nft - new api

      const formattedCancellationReason = (() => {
        const result = Object.keys(formData).reduce((acc, checkKey) => {
          const isChecked = formData[checkKey];

          if (checkKey === OTHER_CHECK_NAME || checkKey === OTHER_MSG_NAME)
            return acc;

          if (isChecked)
            return acc
              ? `${acc}, ${FORM_CHECKBOX_MAP[checkKey]}`
              : FORM_CHECKBOX_MAP[checkKey];

          return acc;
        }, '');

        if (isOtherChecked)
          return result
            ? `${result}, Other: ${formData[OTHER_MSG_NAME]}`
            : `Other: ${formData[OTHER_MSG_NAME]}`;

        return result;
      })();

      const { error } = isPaidCommunity
        ? await cancelSubscription(
            stripeSubscriptionId,
            formattedCancellationReason
          )
        : await cancelFreeOrTokenGatedSubscription({
            cancellationReason: formattedCancellationReason,
            communityCode,
            communityId,
            subscriptionObjectId,
          });

      if (error) throw new Error(error);

      onModalClose();
      showToast({
        text: t('successfully-left-communityname', { communityName }),
      });

      //if user is enrolled to single community which is free or token gated log him out after he has successfully left the community
      const isEnrolledToSingleCommunity = communities?.length === 1;
      const isEnrolledCommunityFreeOrGated =
        communities?.[0]?.isFreeCommunity || communities?.[0]?.isTokenGated;

      if (isEnrolledToSingleCommunity && isEnrolledCommunityFreeOrGated) {
        dispatch(startLogout());
        return;
      }

      window.location.reload();
    } catch (e) {
      showToast({ type: 'error', text: e.message });
    } finally {
      setIsLoading(false);
    }
  };

  const renderModalContent = () => {
    switch (step) {
      case MODAL_STEP.PAID_MSG:
        return (
          <div>
            <p className="text-heading-sm font-semibold text-npl-text-icon-on-light-surface-primary mb-8">
              {t('are-you-sure-you-want-to-leave-the-community')}
            </p>
            <div className="text-body-sm text-npl-text-icon-on-light-surface-secondary mb-24">
              <p>{t('you-have-the-following-purchased-items')}</p>
              <ul className="ml-20">
                {!!eventsPurchased && (
                  <li className="list-disc">
                    {t('count-upcoming-paid-event', { count: eventsPurchased })}
                  </li>
                )}
                {!!foldersPurchased && (
                  <li className="list-disc">
                    {t('count-purchased-content-in-the-library', {
                      count: foldersPurchased,
                    })}
                  </li>
                )}
              </ul>
            </div>
            <div className="flex justify-end space-x-12">
              <RoundedButton displayType="outline" onClick={onModalClose}>
                {t('cancel')}
              </RoundedButton>
              <RoundedButton
                displayType="primary"
                onClick={() => setStep(MODAL_STEP.SELECT_MSG)}
              >
                {t('proceed')}
              </RoundedButton>
            </div>
          </div>
        );
      case MODAL_STEP.SELECT_MSG:
        return (
          <div>
            <p className="text-heading-sm font-semibold text-npl-text-icon-on-light-surface-primary mb-8">
              {t('were-sad-to-see-you-go')}
            </p>
            <div className="text-body-sm text-npl-text-icon-on-light-surface-secondary mb-24">
              <p>
                {t(
                  'before-you-leave-the-community-we-would-appreciate-if-you-could-share-your-reasons-for-leaving'
                )}
              </p>
            </div>
            <div className="space-y-16 mb-24 text-npl-text-icon-on-light-surface-primary text-label-md">
              {FORM_CHECKBOX_LIST.map(({ label, name }, index) => (
                <CheckboxV2
                  key={index}
                  label={label}
                  name={name}
                  checked={formData?.[name]}
                  onChange={onInputChange}
                />
              ))}
              {errors.check && (
                <p className="mt-4 text-body-sm text-npl-error-light-9">
                  {errors.check}
                </p>
              )}
              {isOtherChecked && (
                <div>
                  <input
                    type="text"
                    name={OTHER_MSG_NAME}
                    maxLength={MSG_MAX_LENGTH}
                    value={formData?.[OTHER_MSG_NAME]}
                    onChange={onInputChange}
                    placeholder={t('please-specify-a-reason')}
                    className="p-16 border-1 border-npl-neutral-light-7 rounded-12 w-full"
                  />
                  {errors[OTHER_MSG_NAME] && (
                    <p className="mt-4 text-body-sm text-npl-error-light-9">
                      {errors[OTHER_MSG_NAME]}
                    </p>
                  )}
                  <p className="mt-4 text-body-sm text-npl-text-icon-on-light-surface-tertiary text-right">
                    {`${
                      formData?.[OTHER_MSG_NAME]?.length ?? 0
                    }/${MSG_MAX_LENGTH}`}
                  </p>
                </div>
              )}
            </div>
            <div className="flex justify-end space-x-12">
              <RoundedButton displayType="outline" onClick={onModalClose}>
                {t('cancel')}
              </RoundedButton>
              <RoundedButton
                displayType="primary"
                onClick={submitForm}
                disabled={!isAtleastOneSelected}
              >
                {t('next')}
              </RoundedButton>
            </div>
          </div>
        );
      case MODAL_STEP.FINAL_MSG:
        return (
          <div>
            <p className="text-heading-sm font-semibold text-npl-text-icon-on-light-surface-primary mb-8">
              {t('are-you-sure-you-want-to-leave-the-community')}
            </p>
            <div className="text-body-sm text-npl-text-icon-on-light-surface-secondary mb-24">
              <p>{t('you-will-lose-access-to')} </p>
              <ul className="ml-20">
                {!!eventsPurchased && (
                  <li className="list-disc">
                    {t('a-paid-event-that-youre-attending')}
                  </li>
                )}
                {!!foldersPurchased && (
                  <li className="list-disc">
                    {t('paid-content-in-the-library-that-you-purchased')}
                  </li>
                )}
                {communities?.length === 1 ? (
                  <li className="list-disc">{t('access-to-nas-io-portal')}</li>
                ) : (
                  <li className="list-disc">
                    {t('access-to-the-community-portal')}
                  </li>
                )}
                <li className="list-disc">{t('your-community-chat')}</li>
              </ul>
            </div>
            <div className="flex justify-end space-x-12">
              <RoundedButton displayType="outline" onClick={onModalClose}>
                {t('cancel')}
              </RoundedButton>
              <RoundedButton
                displayType="danger"
                onClick={confirmLeave}
                isLoading={isLoading}
              >
                {t('leave-community')}
              </RoundedButton>
            </div>
            {isPaidCommunity && nextBillingDate && (
              <div className="py-32 px-24 bg-npl-blurple-light-3 !-mb-24 !-mx-32 rounded-b-[28px] mt-32 !md:-mb-16 !md:-mx-24">
                <div className="flex space-x-12">
                  <Info />
                  <p className="text-body-sm text-npl-text-icon-on-light-surface-primary font-medium">
                    {t(
                      'access-to-the-community-will-remain-available-until-date',
                      {
                        date: format(new Date(nextBillingDate), 'dd MMM, yyyy'),
                      }
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      size="md"
      customContainerClass="!py-24 !px-32 !max-w-[500px] !rounded-[28px] font-poppins !md:py-16 !md:px-24"
      customCloseIconClass="!top-24 !right-24"
      customCloseIcon={
        <CloseIcon width={18} height={18} strokeColor="#1b1b18" />
      }
    >
      {renderModalContent()}
    </Modal>
  );
};

export default LeaveCommunityModal;
