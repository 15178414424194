import { getQueryParams } from '../../utility/domHelpers';
import sessionStorageService from '../../utility/sessionStorageService';
import { STORED_ACTIVE_COMMUNITY_SESSION_CODE } from '../constants';

const readActiveCommunity = () => {
  const activeCommunityInSessionStorage = sessionStorageService.getItem(
    STORED_ACTIVE_COMMUNITY_SESSION_CODE
  );

  if (activeCommunityInSessionStorage) {
    return sessionStorageService.getItem(STORED_ACTIVE_COMMUNITY_SESSION_CODE);
  }
  const { activeCommunityId } = getQueryParams() || {};
  if (activeCommunityId) {
    storeActiveCommunity(activeCommunityId);
  }
  return activeCommunityId;
};

const storeActiveCommunity = (code) => {
  sessionStorageService.setItem(STORED_ACTIVE_COMMUNITY_SESSION_CODE, code);
};

const storageUtils = {
  storeActiveCommunity,
  readActiveCommunity,
};

export default storageUtils;
