import React from 'react';

const DiscordIcon = () => {
  return (
    <svg
      className="c-DiscordIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill="#5865F2"
        d="M19.277 5.835a17.318 17.318 0 00-4.274-1.326.065.065 0 00-.069.033c-.184.328-.389.757-.532 1.093a15.987 15.987 0 00-4.801 0 11.064 11.064 0 00-.54-1.093.067.067 0 00-.07-.033c-1.5.259-2.934.711-4.274 1.326a.061.061 0 00-.028.024c-2.722 4.068-3.468 8.035-3.102 11.953.001.02.012.038.027.05a17.414 17.414 0 005.244 2.65.068.068 0 00.074-.024c.403-.552.764-1.133 1.072-1.745a.067.067 0 00-.036-.093 11.46 11.46 0 01-1.638-.78.067.067 0 01-.007-.112c.11-.082.22-.168.325-.255a.065.065 0 01.068-.01c3.437 1.57 7.158 1.57 10.554 0a.065.065 0 01.069.01c.105.086.215.172.326.255a.067.067 0 01-.006.112c-.523.305-1.067.564-1.639.78a.067.067 0 00-.035.093c.315.61.675 1.192 1.072 1.744a.067.067 0 00.073.025 17.358 17.358 0 005.252-2.65.068.068 0 00.028-.049c.437-4.53-.734-8.465-3.105-11.953a.053.053 0 00-.028-.025zm-10.76 9.591c-1.034 0-1.887-.95-1.887-2.116 0-1.167.836-2.117 1.888-2.117 1.059 0 1.903.959 1.887 2.117 0 1.166-.836 2.116-1.887 2.116zm6.978 0c-1.034 0-1.887-.95-1.887-2.116 0-1.167.836-2.117 1.887-2.117 1.06 0 1.904.959 1.888 2.117 0 1.166-.828 2.116-1.888 2.116z"></path>
    </svg>
  );
};

export default DiscordIcon;
