import './SetUserBasicDataModal.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AcademyLogoShortIcon from '../Icons/AcademyLogoShortIcon';
import FormField from '../Form/FormField';
import Modal from '../Modal';
import Text from '../Text';
import { modalDataActions } from '../../reducers/openModalsData';
import useUpdateBasicInfo from '../../hooks/useUpdateBasicInfo';
import NPLFloatingLabelInput from '../../CommunityPortal/components/NPLFloatingLabelInput/NPLFloatingLabelInput';
import RoundedButton from '../../CommunityPortal/components/RoundedButton';
import { t } from 'i18next';

const modalName = 'SetUserBasicDataModal';

const SetUserBasicDataModal = () => {
  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const signupOriginData = useSelector((state) => state.main.signupOriginData);
  const dispatch = useDispatch();

  const className = 'c-SetUserBasicDataModal';

  const {
    setUser,
    fields,
    values,
    errors,
    onFieldChange,
    onSubmit,
    isSubmitting,
    apiError,
  } = useUpdateBasicInfo();

  const passwordResetRequired = user?.passwordResetRequired;

  const renderFormField = (field) => {
    const { name, fieldType, placeholder, optionsList } = field;
    const value = values?.[name] || '';
    const error = errors?.[name] || '';
    return (
      <FormField
        fieldType={fieldType}
        name={name}
        onChange={(e) => onFieldChange(e.target.name, e.target.value)}
        value={value}
        error={error}
        placeholder={placeholder}
        optionsList={optionsList}
        isFloating={true}
        {...field}
      />
    );
  };

  // if user basic profile is incomplete then open modal or close modal
  useEffect(() => {
    // check if the user is not on events checkout
    // TODO: ask aman to add a better way to check if user is on checkout page
    if (
      user?.isUserBasicProfileIncomplete &&
      window.location.pathname !== '/event-checkout' &&
      window.location.pathname !== '/library-checkout' &&
      !signupOriginData?.show // Check if signup origin success prompt is not showing. If it's showing, we should not show this modal.
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    if (user) setUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, signupOriginData]);

  // Set modal is open in global store, so other modals can check and not open multiple modals.
  useEffect(() => {
    if (showModal) {
      dispatch({
        type: modalDataActions.open,
        modalName: modalName,
      });
    } else {
      dispatch({
        type: modalDataActions.close,
        modalName: modalName,
      });
    }
  }, [dispatch, showModal]);

  if (!showModal) return null;

  return (
    <div className={className}>
      <Modal
        open={showModal}
        customContainerClass={`${className}__modal-container`}
        showCloseIcon={false}
        onClose={() => {}}
      >
        <div className={`${className}__modal-inner font-poppins`}>
          <div className={`${className}__modal-title-banner mb-56`}>
            <div className={`${className}__logo`}>
              <AcademyLogoShortIcon variant="light" />
            </div>
            <h2 className={`${className}__title`}>
              {t('welcome-to-your-community-portal')}
            </h2>
          </div>

          <form onSubmit={onSubmit}>
            <div className="space-y-56 mb-[40px]">
              <div className="space-y-24">
                <NPLFloatingLabelInput
                  type="text"
                  {...fields.firstName}
                  onChange={(e) => onFieldChange(e.target.name, e.target.value)}
                  value={values?.firstName}
                  error={errors?.firstName}
                  data-test-id="basicInfo-firstName"
                />
                <NPLFloatingLabelInput
                  type="text"
                  {...fields.lastName}
                  onChange={(e) => onFieldChange(e.target.name, e.target.value)}
                  value={values?.lastName}
                  error={errors?.lastName}
                  data-test-id="basicInfo-lastName"
                />
                <div>
                  <div className="flex items-center space-x-16">
                    {renderFormField(fields.countryId)}
                    <NPLFloatingLabelInput
                      type="number"
                      {...fields.phoneNumber}
                      optional={!user?.isWhatsappExperienceSubscriber}
                      onChange={(e) =>
                        onFieldChange(e.target.name, e.target.value)
                      }
                      value={values?.phoneNumber}
                      error={errors?.phoneNumber}
                    />
                  </div>
                  <p className="mt-16 text-body-sm text-npl-text-icon-on-light-surface-secondary">
                    {t(
                      'this-number-will-be-used-for-all-methods-of-communication'
                    )}{' '}
                  </p>
                </div>
              </div>
              {passwordResetRequired && (
                <div className="space-y-24">
                  <NPLFloatingLabelInput
                    type="email"
                    label={t('email')}
                    value={user?.email}
                    disabled={true}
                  />
                  <NPLFloatingLabelInput
                    type="password"
                    data-test-id="basicInfo-password"
                    {...fields.password}
                    onChange={(e) =>
                      onFieldChange(e.target.name, e.target.value)
                    }
                    error={errors?.password}
                  />
                </div>
              )}
            </div>
            <RoundedButton
              displayType="primary"
              customClassNames="w-full flex justify-center"
              data-test-id="basicInfo-submit"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? t('submitting-dot-dot-dot')
                : t('complete-your-profile')}
            </RoundedButton>
            {apiError && (
              <div className={`${className}__err-msg-container`}>
                <Text text={apiError} />
              </div>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SetUserBasicDataModal;
