import React from 'react';

const AcademyLogoShortIcon = ({variant = 'dark'}) => {
  const className = 'c-AcademyLogoShortIcon';
  const fillColor = variant === 'dark' ? '#fff': '#2c2c2c';
  return (
    <svg
      className={className}
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <g clipPath="url(#clip0)">
        <path
          fill={fillColor}
          d="M2.667 18.667A3.771 3.771 0 118 13.333L18.667 24a3.771 3.771 0 01-5.334 5.333L2.667 18.667z"
        ></path>
        <path
          fill={fillColor}
          d="M18.667 29.333A3.771 3.771 0 0113.333 24L24 13.333a3.771 3.771 0 115.333 5.334L18.667 29.333z"
        ></path>
        <path
          fill="#FBC91B"
          d="M15.99 9.956a4.123 4.123 0 100-8.246 4.123 4.123 0 000 8.246z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={fillColor} d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AcademyLogoShortIcon;
