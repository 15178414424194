import {
  DISPLAY_TYPE_OPTIONS,
  SIZE_OPTIONS,
  displayTypeToClassNamesMap,
  sizeToClassNamesMap,
} from './constants';

import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    displayType,
    size,
    fluid,
    customClassNames,
    customInnerClassNames,
    customClassForButtonDiv,
    leftIcon,
    rightIcon,
    children,
    disabled,
    loading,
    rounded,
    ...btnProps
  } = props;

  const className = 'p-CommunityPortal-c-Button';

  const classNames = [
    className,
    displayTypeToClassNamesMap?.[displayType] ?? '',
    sizeToClassNamesMap?.[size] ?? '',
    fluid ? 'w-full' : '',
    rounded ? 'rounded-[100px]' : '',
    disabled ? 'pointer-events-none cursor-not-allowed' : '',
  ].join(' ');

  return (
    <button className={`${classNames} ${customClassNames}`} {...btnProps}>
      <div
        className={`flex flex-row items-center ${customClassForButtonDiv} ${customInnerClassNames}`}
      >
        {loading && (
          <div className="w-16 h-16 mr-4 border-2 rounded-full animate-spin border-neutral-10 border-l-transparent "></div>
        )}
        {leftIcon && <div className="mr-4">{leftIcon}</div>}
        {children}
        {rightIcon && <div className="ml-4">{rightIcon}</div>}
      </div>
    </button>
  );
};

Button.propTypes = {
  displayType: PropTypes.oneOf(DISPLAY_TYPE_OPTIONS),
  size: PropTypes.oneOf(SIZE_OPTIONS),
  fluid: PropTypes.bool,
  customClassNames: PropTypes.string,
  customInnerClassNames: PropTypes.string,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  children: PropTypes.arrayOf(PropTypes.element),
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  displayType: 'primary',
  size: 'md',
  fluid: false,
  customClassNames: '',
  customInnerClassNames: '',
  leftIcon: null,
  rightIcon: null,
  disabled: false,
  customClassForButtonDiv: 'justify-center',
  loading: false,
};

export default Button;
