const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "SET_LESSON":
      return {
        ...state,
        lesson: action.lesson,
      };

    case "SET_TRAINER":
      return {
        ...state,
        trainer: action.trainer,
      };

    default:
      return state;
  }
};
