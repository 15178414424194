import React from 'react';
import PropTypes from 'prop-types';
import Text, { TextType } from '../../../../components/Text';
import { formatCentsIntToUSD } from '../../../../utility/formatHelper';

import './RedeemBox.scss';
import { useTranslation } from 'react-i18next';

const className = 'c-SettingPage-RedeemBox';

const RedeemBox = ({
  title,
  referralCredit,
  giftCredit,
  currency,
  ctaHandler,
  ctaLabel,
  note,
  noCreditText,
}) => {
  const { t } = useTranslation();
  let disabledButtonClassName = `${className}__cta--disabled`;
  let ctaDisplayType = 'standard-inverse';
  const shouldRenderAmount = referralCredit > 0;
  const formattedReferralCredit = formatCentsIntToUSD(referralCredit);
  const formattedGiftCredit = formatCentsIntToUSD(giftCredit);

  if (ctaHandler) {
    disabledButtonClassName = '';
    // eslint-disable-next-line no-unused-vars
    ctaDisplayType = 'flex';
  }

  // TODO: Aman
  const giftCreditNote = [
    {
      text: t(
        'you-also-have-credit-currency-gift-credits-which-are-not-redeemable',
        { credit: formattedGiftCredit, currency }
      ),
    },
  ];

  return (
    <div className={className + ' mt-6 mb-24 pl-12 '}>
      <div className={`${className}__credit-card`}>
        <p className="p-6" style={{ padding: '12px' }}>
          {' '}
          {t('you-have')}{' '}
        </p>
        {shouldRenderAmount ? (
          <p
            className={`${className}__credit-card__amount`}
          >{`${currency} ${formattedReferralCredit} `}</p>
        ) : (
          <p className={`${className}__credit-card__amount`}>{`USD 0.00 `}</p>
        )}
        <div
          className={`flex  ${
            !shouldRenderAmount
              ? 'flex-col justify-start pl-6 pr-24 '
              : ' flex-row justify-between px-4 '
          }  `}
        >
          <h5 className={`${className}__credit-card__title text-black `}>
            {title}
          </h5>
          {!shouldRenderAmount && (
            <p className={`${className}__no-credit-text`}>{noCreditText}</p>
          )}
          {shouldRenderAmount && (
            <div className={`${className}__cta ${disabledButtonClassName}`}>
              <button
                onClick={ctaHandler}
                // displayType={ctaDisplayType}
                // newVersion={true}
                // fluid
                className="text-button-md text-bluple-60 font-medium"
                disabled={!ctaHandler}
              >
                {ctaLabel}
              </button>
            </div>
          )}
        </div>
      </div>

      {giftCredit > 0 && (
        <div className={`${className}__redeem-note`}>
          <Text text={giftCreditNote} />
        </div>
      )}
      <div className={`${className}__redeem-note`}>
        <Text text={note} />
      </div>
    </div>
  );
};

export const RedeemBoxPropTypes = {
  title: PropTypes.string.isRequired,
  referralCredit: PropTypes.number.isRequired,
  giftCredit: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  ctaHandler: PropTypes.func,
  note: TextType.isRequired,
  noCreditText: PropTypes.string.isRequired,
};

RedeemBox.propTypes = RedeemBoxPropTypes;

RedeemBox.defaultProps = {
  currency: 'USD',
};

export default RedeemBox;
