import { getAxiosError } from '../../services/helper';
import protectedAxiosRequest from '../../services/helper/protectedAxiosRequest';
import {
  libraryFolderClickedURL,
  libraryFolderGeneralURL,
  libraryFolderURL,
  librarySearchURL,
  librarySingleFolderURL,
  libraryURL,
  sharedRequestOptions,
} from './constants';

export const getLibraryData = async (communityId, dataType) => {
  try {
    return await protectedAxiosRequest.get(
      libraryURL(communityId, dataType),
      sharedRequestOptions
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const getLibraryFoldersData = async (communityId, folderType) => {
  try {
    return await protectedAxiosRequest.get(
      libraryFolderGeneralURL(communityId, folderType),
      sharedRequestOptions
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const getLibrarySingleFolderData = async (communityId, folderId) => {
  try {
    return await protectedAxiosRequest.get(
      librarySingleFolderURL(communityId, folderId),
      sharedRequestOptions
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const getLibrarySearchData = async (communityId, search) => {
  try {
    return await protectedAxiosRequest.get(
      librarySearchURL(communityId, search),
      sharedRequestOptions
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const folderClickLogOnLibraryPage = async (communityFolderObjId) => {
  try {
    return await protectedAxiosRequest.post(
      libraryFolderClickedURL(communityFolderObjId)
    );
  } catch (error) {
    return getAxiosError(error);
  }
};
