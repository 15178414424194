import { useMemo, useState } from 'react';

import BillingHistory from './components/BillingHistory';
import CancelModal from './components/CancelModal';
import PageTemplate from '../../../../CommunityPortal/components/PageTemplate';
import PropTypes from 'prop-types';
import SubscriptionCard from './components/SubscriptionCard';
import { useCallback } from 'react';
import SubscriptionCardV2 from './components/SubscriptionCardV2/SubscriptionCardV2';
import Loading from '../../../../components/Loading';
import { getExpiredStatus } from '../../../../CommunityPortal/util';
import { COMMUNITY_EXPIRED } from '../../../../CommunityPortal/constants';
import { useTranslation } from 'react-i18next';
import useNewMPPageRedirection from '../../../../CommunityPortal/hooks/useNewMPPageRedirection';
import { withRouter } from 'react-router';

const className = 'c-SettingsPage-p-ProfilePage';
const MembershipPage = ({
  communities,
  activeCommunityCode,
  setActiveCommunityCode,
  loading,
  onCancelSubscriptionClick,
  invoices,
  invoiceLoading,
  location,
}) => {
  const { t } = useTranslation();
  const headerStaticProps = {
    title: t('membership'),
  };
  const [showModal, setShowModal] = useState(false);

  const isRedirecting = useNewMPPageRedirection({
    queryString: location.search,
    newPagePath: '/user/membership',
  });

  // filter out expired communities
  const activeCommunities = useMemo(() => {
    if (!communities || communities.length === 0) return null;
    return communities.filter(
      (community) =>
        getExpiredStatus(community?.subscription) !== COMMUNITY_EXPIRED
    );
  }, [communities]);

  const activeCommunityData = useMemo(() => {
    if (!activeCommunityCode || !communities || communities.length === 0)
      return null;
    if (!activeCommunityCode) return null;
    return communities.find(
      (community) => community.code === activeCommunityCode
    );
  }, [activeCommunityCode, communities]);

  const onManageSubscriptionClick = useCallback(
    (communityCode) => {
      setActiveCommunityCode(communityCode);
    },
    [setActiveCommunityCode]
  );

  const onConfirmCancelClick = async (reason) => {
    await onCancelSubscriptionClick(reason);
    setActiveCommunityCode(null);
  };

  const renderSubtitle = useCallback(() => {
    if (!activeCommunityCode) {
      return (
        <span className="font-medium text-body-lg text-grey-neutral10">
          {t('my-subscriptions')}
        </span>
      );
    } else {
      return (
        <span className="font-medium text-body-lg">
          <span
            onClick={() => setActiveCommunityCode(null)}
            className="cursor-pointer text-grey-neutral40"
          >
            {t('memberships-greater-than')}{' '}
          </span>
          <span className="text-grey-neutral10">
            {t('manage-subscription')}
          </span>
        </span>
      );
    }
  }, [activeCommunityCode, setActiveCommunityCode]);

  const renderSkeleton = () => {
    return [1, 2].map((t) => (
      <div
        key={t}
        className="mt-16 md:max-w-[576px] border-1 border-grey-neutral90 rounded-8 p-16 flex flex-col animate-pulse"
      >
        <div className="flex flex-row items-center">
          <div className="min-w-[56px] max-w-[56px] h-56 bg-grey-aa rounded-full"></div>
          <div className="w-full h-24 ml-16 rounded-4 bg-grey-aa"></div>
        </div>
        <div className="flex flex-row items-center justify-between mt-20">
          <div className="w-full h-32 px-16 py-6 bg-grey-aa rounded-4"></div>
        </div>
      </div>
    ));
  };

  const renderContent = useCallback(() => {
    return (
      <div className={`${className} mx-16`}>
        <div className="hidden mt-32 lg:block">{renderSubtitle()}</div>
        <div className="mt-64 lg:mt-24 md:grid md:grid-cols-2 md:gap-24">
          {!activeCommunityCode && loading && renderSkeleton()}
          {loading && <Loading />}
          {!activeCommunityCode &&
            !loading &&
            activeCommunities?.map((community, i) => (
              <div className="mt-16 last:mb-16 md:mt-0 md:last:mb-0" key={i}>
                <SubscriptionCardV2
                  community={community}
                  onCtaClick={onManageSubscriptionClick}
                  isDemoCommunity={activeCommunityData?.isDemo}
                  communities={communities}
                />
              </div>
            ))}
          {activeCommunityCode && !loading && (
            <SubscriptionCard
              {...activeCommunityData}
              onCtaClick={() => setShowModal(true)}
              isActive={true}
            />
          )}
        </div>
        {activeCommunityCode && !invoiceLoading && invoices.length !== 0 && (
          <BillingHistory invoices={invoices} loading={invoiceLoading} />
        )}
        {invoiceLoading && (
          <div className="m-auto">
            <Loading />
          </div>
        )}
      </div>
    );
  }, [
    activeCommunityCode,
    activeCommunityData,
    communities,
    invoiceLoading,
    invoices,
    loading,
    onManageSubscriptionClick,
    renderSubtitle,
  ]);

  if (isRedirecting) {
    return null;
  }

  return (
    <>
      <PageTemplate headerProps={headerStaticProps} toRender={renderContent} />
      {/* Render Modal */}
      {activeCommunityCode && (
        <CancelModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={onConfirmCancelClick}
          nextBillingDate={activeCommunityData?.nextBillingDate}
        />
      )}
    </>
  );
};

MembershipPage.propTypes = {
  communities: PropTypes.array,
  activeCommunityCode: PropTypes.string,
  setActiveCommunityCode: PropTypes.func,
  loading: PropTypes.bool,
  onCancelSubscriptionClick: PropTypes.func,
  invoices: PropTypes.array,
  invoiceLoading: PropTypes.bool,
};
MembershipPage.defaultProps = {
  activeCommunityCode: null,
  loading: false,
  invoiceLoading: false,
};

export default withRouter(MembershipPage);
