import { convertQueryStringToJson } from '../modules/stringHelpers';

export const COMMUNITY_PORTAL_BREAKPOINTS = {
  sm: 480,
  md: 768, // Tablet
  lg: 992, // Desktop Small
  xl: 1200, // Desktop Large is 1440px
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const scrollToRef = (ref, negOffset = 0) =>
  window.scrollTo(0, ref?.current?.offsetTop - negOffset);
export const checkIfLessThanBreakpoint = (breakpoint) =>
  window.innerWidth <= breakpoint;
export const checkIfMoreThanBreakpoint = (breakpoint) =>
  window.innerWidth >= breakpoint;

export const minDesktopWidth = 1200;
export const isDesktop = window.innerWidth >= minDesktopWidth;
export const isMobileLandScape =
  window.innerWidth >= 320 && window.innerHeight <= 640;

export const checkIsDesktop = () => window.innerWidth >= minDesktopWidth;
export const checkIsMobileLandscape = () =>
  window.innerWidth >= 320 && window.innerHeight <= 640;

export const getQueryHashParams = () => {
  const queryStr = window.location.hash || '';
  const queryParamsJson = convertQueryStringToJson(queryStr);
  return queryParamsJson;
};

export const getQueryParams = () => {
  const queryStr = window.location.search || '';
  const queryParamsJson = convertQueryStringToJson(queryStr);
  return queryParamsJson;
};

export const resetQueryParams = () => {
  window.history.pushState(
    {
      path: window.location.origin + window.location.pathname,
    },
    '',
    window.location.origin + window.location.pathname
  );
};
