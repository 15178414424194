const { useCallback } = require('react');
const { useEffect } = require('react');
const { useState } = require('react');
const { useSelector } = require('react-redux');
const {
  getCommunityInvoices,
} = require('../apiServices/communityInvoiceService');

const useCommunitiesInvoiceData = (communityCode) => {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [activeCommunityCode, setActiveCommunityCode] = useState(communityCode);

  const fetchAndSetData = useCallback(async () => {
    if (user?.email && communityCode) {
      setLoading(true);
      const invoices = await getCommunityInvoices(communityCode, user.email);
      if (invoices?.error) {
        setError(invoices.error);
      } else {
        setData(invoices.data);
      }
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  }, [communityCode, user?.email]);

  useEffect(() => {
    if (activeCommunityCode !== communityCode) {
      setActiveCommunityCode(communityCode);
      fetchAndSetData();
    }
  }, [activeCommunityCode, communityCode, fetchAndSetData]);

  return {
    invoices: data,
    loading,
  };
};

export default useCommunitiesInvoiceData;
