import classNames from 'classnames';
import React from 'react';
import CloseIcon from '../Icons/CloseIcon';
import InfoCircleOutlineIcon from '../Icons/InfoCircleOutlineIcon/InfoCircleOutlineIcon';

export const NPLALERT_TYPE = { WARNING: 'warning' };

const getAlertClassnames = (type) => {
  switch (type) {
    case NPLALERT_TYPE.WARNING:
      return 'bg-npl-yellow-light-3 border-npl-yellow-light-6';
    default:
      return {};
  }
};

const getAlertIcon = (type) => {
  switch (type) {
    case NPLALERT_TYPE.WARNING:
      return (
        <InfoCircleOutlineIcon width="24" height="24" fillColor="#946800" />
      );
    default:
      return {};
  }
};

const NPLAlert = ({
  title,
  subtitle,
  type,
  customClassName,
  chin,
  onClose,
}) => {
  const alertClassNames = getAlertClassnames(type);

  return (
    <div
      className={classNames(
        'border-1 p-16 rounded-12 relative',
        alertClassNames,
        customClassName,
        ''
      )}
    >
      <div className="flex space-x-12 items-start">
        <div className="flex-shrink-0">{getAlertIcon(type)}</div>
        <div className="space-y-4">
          {title && (
            <p className="text-body-md font-medium text-npl-text-icon-on-light-surface-primary">
              {title}
            </p>
          )}
          {subtitle && (
            <p className="text-body-sm text-npl-text-icon-on-light-surface-primary">
              {subtitle}
            </p>
          )}
        </div>
      </div>
      {chin}
      {onClose && (
        <div className="absolute top-8 right-8 p-8 cursor-pointer">
          <CloseIcon width="16" height="16" />
        </div>
      )}
    </div>
  );
};

export default NPLAlert;
