import { membersURL, sharedRequestOptions } from './constants';

import { getAxiosError } from '../../services/helper';
import protectedAxiosRequest from '../../services/helper/protectedAxiosRequest';

export const getMembersData = async ({
  communityId,
  pageSize,
  sort,
  filter,
  countryId,
  paginate = 1,
  pageNum,
}) => {
  try {
    if (!pageNum) pageNum = 1;
    if (!pageSize) pageSize = 10;
    const params = { pageNum, pageSize, sort, filter, countryId, paginate };
    if (!sort) delete params.sort;
    if (!filter) delete params.filter;

    return await protectedAxiosRequest.get(membersURL(communityId), {
      ...sharedRequestOptions,
      params,
    });
  } catch (error) {
    return getAxiosError(error);
  }
};
