import CommentSettingWidget from '../CommentSettingWidget';
import PageTemplate from '../../../../CommunityPortal/components/PageTemplate';
import style from './NewPreferences.module.scss';
import { useTranslation } from 'react-i18next';
const className = 'c-SettingPage-NewPreferencesPage';

const NewPreferencesPage = ({
  isLoading,
  updateProfile,
  updateProfileStatus,
  updateCommentPreference,
  updateVideoPreference,
  commentPreferenceInputProps,
  videoPreferenceInputProps,
  showStatus,
  inputProps,
}) => {
  const { t } = useTranslation();

  const headerStaticPropsPreferences = {
    title: t('preferences'),
  };
  const renderContent = () => {
    return (
      <div className={`${className} ${style[className]} `}>
        <CommentSettingWidget
          isLoading={isLoading}
          updateProfile={updateCommentPreference}
          updateProfileStatus={updateProfileStatus}
          showStatus={showStatus}
          inputProps={commentPreferenceInputProps}
        />
      </div>
    );
  };

  return (
    <>
      <PageTemplate
        headerProps={headerStaticPropsPreferences}
        toRender={renderContent}
      />
    </>
  );
};

export default NewPreferencesPage;
