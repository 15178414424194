import React from 'react';
import PropTypes from 'prop-types';
import PasswordInput, { PasswordInputDataStructure } from '../PasswordInput';
import { Children } from 'react';
import LoadingCircle from '../../../Common/LoadingCircle/LoadingCircle';
import BackToLoginButton from '../BackToLoginButton';

import './ResetPasswordForm.scss';
import Button from '../../../Button';
import { useTranslation } from 'react-i18next';

const className = 'c-ResetPasswordContainer-ResetPasswordForm';

const ResetPasswordForm = ({
  inputs,
  onSubmit,
  includeBackToLogin,
  loading,
  successMsg,
  resetPasswordFailed,
  btnText,
  btnIsFluid,
  showLabel,
  showForgotPassword,
}) => {
  const { t } = useTranslation();
  const renderInputs = () =>
    Children.toArray(
      inputs.map((item) => <PasswordInput {...item} showLabel={showLabel} />)
    );

  const passwordIsFilled = inputs[0].value !== '';

  return (
    <section>
      <div className="login-container">
        <div className={`main-page-form ${className}__main-page-form`}>
          <div
            className={`reset-password-cards cards ${className}__inner-main-form-wrapper`}
          >
            <div className="form-wrapper">
              <form className="input-form" onSubmit={onSubmit}>
                <div className="input-wrapper">
                  {renderInputs()}

                  {loading || successMsg ? null : (
                    <div className={`${className}__cta__container`}>
                      <div className={`${className}__cta__left`} />
                      <div className={`${className}__cta__wrapper`}>
                        <Button
                          displayType={
                            passwordIsFilled ? 'primary' : 'disabled'
                          }
                          onClick={onSubmit}
                          fluid={btnIsFluid}
                        >
                          {btnText || t('submit')}
                        </Button>
                        {showForgotPassword && (
                          <div
                            className={`${className}__cta__link`}
                            onClick={() =>
                              window.location.replace('/forget-password')
                            }
                          >
                            {t('forgot-password')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {loading && <LoadingCircle />}

                  {resetPasswordFailed && (
                    <div className="error-message whitespace-pre-line	text-left">
                      {resetPasswordFailed}
                    </div>
                  )}
                  {successMsg && (
                    <div className="success-message">{successMsg}</div>
                  )}
                  {includeBackToLogin && successMsg && (
                    <BackToLoginButton successMsg={successMsg} />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ResetPasswordForm.propTypes = {
  inputProps: PropTypes.arrayOf(PropTypes.shape(PasswordInputDataStructure)),
  onSubmit: PropTypes.func.isRequired,
  includeBackToLogin: PropTypes.bool,
  loading: PropTypes.bool,
  successMsg: PropTypes.string,
  resetPasswordFailed: PropTypes.bool,
  btnText: PropTypes.string,
  btnIsFluid: PropTypes.bool,
};

export default ResetPasswordForm;
