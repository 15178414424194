const defaultState = {
  isAnyModalOpen: false,
  listOfOpenModals: [],
};

export const modalDataActions = {
  open: 'OPEN_MODAL',
  close: 'CLOSE_MODAL',
};

const openModalsDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case modalDataActions.open: {
      let updatedListOfOpenModals = [
        ...state.listOfOpenModals,
        action.modalName,
      ];

      return {
        listOfOpenModals: updatedListOfOpenModals,
        isAnyModalOpen: true,
      };
    }

    case modalDataActions.close: {
      let updatedListOfOpenModals = state.listOfOpenModals.filter(
        (modalName) => modalName !== action.modalName
      );

      return {
        listOfOpenModals: updatedListOfOpenModals,
        isAnyModalOpen: updatedListOfOpenModals.length > 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default openModalsDataReducer;
