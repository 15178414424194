import React, { Children, useCallback } from 'react';

import Input from '../../../components/Form/Input';
import PropTypes from 'prop-types';
import SearchBoxDropdown from '../../../components/SearchBoxDropdown';

const Header = ({
  title,
  description,
  tabItems,
  CTA,
  body,
  selectedPathOfFile,
  contentOfSelectedContent,
  titleOfTheSelectedContent,
  marginForHeaderComponent,
  searchBar,
  searchData,
  showSearchDropDown,
  searchDropDownRef,
  searchInputProps,
  setShowSearchDropDown,
  showBorder = true,
}) => {
  const className = 'c-CommunityPageHeader';

  const renderTabItems = useCallback(
    (items) =>
      Children.toArray(
        items.map(({ label, isActive, onClick }) => (
          <li
            className={`mx-8 first:ml-0 px-8 cursor-pointer font-medium font-poppins text-label-md ${
              isActive ? '' : 'text-grey-neutral70'
            }`}
            onClick={onClick}
          >
            {label}
            {isActive && (
              <hr className="mt-12 border-t-4 border-cyellow rounded-t-8" />
            )}
          </li>
        ))
      ),
    []
  );

  const renderSearchBar = useCallback(() => {
    return (
      searchBar && (
        <div ref={searchDropDownRef}>
          <Input {...searchInputProps()} />
          {showSearchDropDown && (
            <SearchBoxDropdown
              searchData={searchData}
              setShowSearchDropDown={setShowSearchDropDown}
            />
          )}
        </div>
      )
    );
  }, [
    searchBar,
    searchData,
    searchDropDownRef,
    searchInputProps,
    setShowSearchDropDown,
    showSearchDropDown,
  ]);
  return (
    <header
      className={`${className} w-full ${
        marginForHeaderComponent === 0 && 'px-[0px!important] '
      }  ${!tabItems ? 'lg:pb-20' : ''} flex ${
        body ? 'flex-col' : 'flex-row'
      } justify-between ${
        showBorder ? 'lg:border-b-1' : ''
      } lg:border-grey-neutral95 lg:px-16 lg:pt-32`}
    >
      {/* file path  */}
      {selectedPathOfFile && (
        <div
          className={`flex flex-row mx-16 mt-16 ${
            marginForHeaderComponent === 0 && 'px-24'
          } md:px-0 md:mx-0 lg:mt-0`}
        >
          <span
            onClick={() => selectedPathOfFile.onClick()}
            className="text-[#AAAAAA] text-label-lg whitespace-pre font-normal capitalize hover:cursor-pointer"
          >
            {selectedPathOfFile.activeTab} &gt;
          </span>
          <span className="ml-4 font-normal capitalize text-label-lg">
            {titleOfTheSelectedContent}
          </span>
        </div>
      )}
      {contentOfSelectedContent && (
        <div className="flex flex-col mt-24">{contentOfSelectedContent()}</div>
      )}
      {/* Left Wrapper */}
      <div className="flex flex-col w-full">
        {/* Title */}
        <div className="flex flex-col-reverse pt-16 px-16 md:flex-row lg:pt-0 lg:px-0">
          <h1 className="flex-grow font-semibold text-heading-md hidden lg:block">
            {title}
          </h1>
          {/* Search bar */}
          {renderSearchBar()}
        </div>
        {/* Description */}
        {description && (
          <p className="mt-12 whitespace-pre-line text-body-sm text-grey-neutral10 mx-16 lg:mx-0">
            {description}
          </p>
        )}

        {/* Tabs */}
        {tabItems && (
          <ul className="border-b-1 border-grey-neutral95 lg:border-none">
            <div className="flex flex-row mt-16 mx-16 lg:mx-0">
              {renderTabItems(tabItems)}
            </div>
          </ul>
        )}
      </div>

      {/* Right Wrapper */}
      {CTA && (
        <div className="block md:hidden">
          {/* <br /> */}
          {/* PassedCta component */}
          <CTA />
        </div>
      )}
      {body && (
        <div className="py-5 " style={{ paddingTop: '1.25rem' }}>
          {/* <br /> */}
          {/* body text */}
          <p>{body}</p>
        </div>
      )}
    </header>
  );
};

const TabItemPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
});

export const HeaderPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  tabItems: PropTypes.arrayOf(TabItemPropTypes),
  CTA: PropTypes.any,
  body: PropTypes.string,
  selectedPathOfFile: PropTypes.shape({
    activeTab: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  contentOfSelectedContent: PropTypes.func,
  titleOfTheSelectedContent: PropTypes.string,
  marginForHeaderComponent: PropTypes.number,
  searchBar: PropTypes.bool,
  searchData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

Header.propTypes = HeaderPropTypes;

export default Header;
