import PropTypes from 'prop-types';
const PlusFilledIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.2046 4C10.6188 4 10.9546 4.33579 10.9546 4.75V9.45454H15.25C15.6642 9.45454 16 9.79033 16 10.2045C16 10.6188 15.6642 10.9545 15.25 10.9545H10.9546V15.25C10.9546 15.6642 10.6188 16 10.2046 16C9.79035 16 9.45456 15.6642 9.45456 15.25V10.9545L4.75 10.9545C4.33579 10.9545 4 10.6188 4 10.2045C4 9.79033 4.33579 9.45455 4.75 9.45455L9.45456 9.45454V4.75C9.45456 4.33579 9.79035 4 10.2046 4Z"
        fill={fillColor}
      />
    </svg>
  );
};

PlusFilledIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
};

PlusFilledIcon.defaultProps = {
  width: '20',
  height: '20',
  fillColor: '#191C1E',
};

export default PlusFilledIcon;
