import { COMMUNITY_ONE_TIME_PAYMENT_ENTITIES } from '../../hooks/payment/constants';
import { getTimezoneId } from '../../utility/dateHelper';
import protectedAxiosRequest from '../helper/protectedAxiosRequest';
const learnPortalBasePath = process.env.REACT_APP_AUTH_SERVER_URL;
const mainBackendBasePath = process.env.REACT_APP_PAYMENT_SERVER_URL;
const paymemtBackendPath = process.env.REACT_APP_PAYMENT_BE_URL;
export const signUpForPaymentAndGetClientInfoService = async (
  params,
  communityId,
  endpoint
) => {
  const path =
    learnPortalBasePath +
    `/api/v1/communities/${communityId}/entity/${endpoint}`;

  const response = await protectedAxiosRequest.post(path, params);
  return response;
};

export const getCurrentCountry = async () => {
  try {
    const path = mainBackendBasePath + `/api/v1/get-country-data`;
    const { data, error } = await protectedAxiosRequest.get(path);
    if (error) {
      throw new Error(error);
    }
    return { data: data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

/**
 * This function gets the payment method for a given country and checkout type.
 *
 * @param {string} country The country name.
 * @param {string} checkoutType The checkout type. Can be one of "recurring" or "onetime".
 */
export const getPaymentMethods = async ({
  country,
  checkoutType,
  communityId,
}) => {
  try {
    const path =
      paymemtBackendPath +
      `/api/v1/local-payment-methods?country=${country}&communityId=${communityId}&checkoutType=${checkoutType}`;
    const { data, error } = await protectedAxiosRequest.get(path);

    if (error) {
      throw new Error(error);
    }
    return { data: data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const verifyPaymentForEntitySignUp = async (
  communityId,
  entityType,
  entitySignUpId
) => {
  try {
    const path =
      learnPortalBasePath +
      `/api/v1/communities/${communityId}/entity/verify-payment?entitySignupId=${entitySignUpId}&entityType=${entityType}`;
    const { data, error } = await protectedAxiosRequest.get(path);

    if (error) {
      throw new Error(error);
    }
    return { data: data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

const getTypeFromEnum = (type) => {
  return Object.keys(COMMUNITY_ONE_TIME_PAYMENT_ENTITIES).find(
    (key) => COMMUNITY_ONE_TIME_PAYMENT_ENTITIES[key] === type
  );
};

export const signUpForEntity = async (entityId, entityType, communityId) => {
  try {
    console.log(getTypeFromEnum(entityType));
    if (!getTypeFromEnum(entityType))
      return {
        data: null,
        error: `request not sent, invalid entityType, ${entityType}`,
      };
    const path =
      learnPortalBasePath + `/api/v1/communities/${communityId}/entity/signup`;
    const options = {
      entityId: entityId,
      entityType: entityType,
      timezone: getTimezoneId(),
    };
    const { data, error } = await protectedAxiosRequest.post(path, options);
    if (error) {
      throw new Error(error);
    }
    return { data: data, error: null };
  } catch (error) {
    console.log(error);
    return { data: null, error };
  }
};

export const getCommunityInfoForCheckout = async (communityId) => {
  try {
    const path = learnPortalBasePath + `/api/v1/communities/${communityId}`;
    const { data, error } = await protectedAxiosRequest.get(path);
    if (error) {
      throw new Error(error);
    }
    return { data: data, error: null };
  } catch (error) {
    console.log(error);
    return { data: null, error };
  }
};

export const getCommunityEventInfoForEventCheckoutById = async (
  communityId,
  eventId
) => {
  try {
    const path =
      learnPortalBasePath +
      `/api/v1/communities/${communityId}/event/${eventId}`;
    const { data, error } = await protectedAxiosRequest.get(path);
    if (error) {
      throw new Error(error);
    }
    return { data: data, error: null };
  } catch (error) {
    console.log(error);
    return { data: null, error };
  }
};

// Xendit

export const createEwalletChargeForEntityId = async (params, communityId) => {
  const path =
    // Neil-todo
    learnPortalBasePath +
    `/api/v1/communities/${communityId}/entity/xendit-ewallet-checkout`;

  const response = await protectedAxiosRequest.post(path, params);
  return response;
};

export const getEwalletChargeStatus = async (chargeId) => {
  const path = learnPortalBasePath + `/api/v1/charge-status/${chargeId}`;

  const response = await protectedAxiosRequest.get(path);
  return response;
};
