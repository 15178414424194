import React, { useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Button from '../../components/Button';
import Input from '../../components/Form/Input';
import Image from '../../components/Image';
import { shortLogoImageData } from '../../utility/constants';
import { sendResetPassword } from '../../actions/auth';
import LoadingCircle from '../../components/Common/LoadingCircle/LoadingCircle';
import './ResetPasswordPage.scss';
import { validate } from '../../utility/validationHelper';
import history from '../../modules/history';
import { useTranslation } from 'react-i18next';
import useNewResetPasswordPageRedirection from './hooks/useNewResetPasswordPageRedirection';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const className = 'c-ResetPasswordPage';

  const token = queryString.parse(window.location.search)?.token;
  const mainState = useSelector((state) => state?.main);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});

  const isRedirecting = useNewResetPasswordPageRedirection({
    newPagePath: '/reset-password',
    queryString: window.location.search,
  });

  if (isRedirecting) {
    return null;
  }

  const { loading, resetPasswordFailed, success } = mainState;
  const titleText = success
    ? t('password-successfuly-updated')
    : t('create-new-password');

  const resetPasswordFormSchema = yup.object().shape({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('password-must-have-at-least-1-uppercase-1-lowercase-and-1-number')
      )
      .min(8, t('password-must-be-atleast-8-characters'))
      .required(t('this-field-is-required')),
    confirmPassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('password-must-have-at-least-1-uppercase-1-lowercase-and-1-number')
      )
      .min(8, t('password-must-be-atleast-8-characters'))
      .required(t('this-field-is-required')),
  });

  const rulesList = [
    t('8-characters'),
    t('1-uppercase-and-1-lowercase-character'),
    t('1-number'),
  ];

  const onFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: '' });
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const isValid = await validate({
      schema: resetPasswordFormSchema,
      formData,
      setErrors,
    });

    if (!isValid) {
      return;
    }

    dispatch(
      sendResetPassword(formData?.password, formData?.confirmPassword, token)
    );
  };

  const goToLoginPage = () => {
    history.push('/login');
  };

  const renderRulesList = () =>
    rulesList?.map((rule, index) => (
      <li className={`${className}__rules__list__item`} key={index}>
        {rule}
      </li>
    ));

  return (
    <div className={className}>
      <div className={`${className}__container`}>
        <div className={`${className}__form`}>
          <Image {...shortLogoImageData} />
          <p className={`${className}__title`}>{titleText}</p>
          {success ? (
            <Button displayType="primary" fluid onClick={goToLoginPage}>
              {t('back-to-login')}
            </Button>
          ) : (
            <>
              <div className={`${className}__rules`}>
                <p className={`${className}__rules__title`}>
                  {t('your-password-must-be-have-at-least')}
                </p>
                <ul className={`${className}__rules__list`}>
                  {renderRulesList()}
                </ul>
              </div>
              <form onSubmit={onFormSubmit}>
                <Input
                  type="password"
                  value={formData?.password}
                  name="password"
                  onChange={onFieldChange}
                  placeholder={t('enter-password')}
                  eyeIcon
                  label={t('password')}
                  error={errors?.password}
                  v2
                />
                <Input
                  type="password"
                  value={formData?.confirmPassword}
                  name="confirmPassword"
                  onChange={onFieldChange}
                  placeholder={t('enter-password')}
                  eyeIcon
                  label={t('re-enter-password')}
                  error={errors?.confirmPassword}
                  v2
                />
                {resetPasswordFailed ? (
                  <div className={`${className}__error-message`}>
                    {resetPasswordFailed}
                  </div>
                ) : (
                  <div />
                )}
                {loading ? (
                  <LoadingCircle />
                ) : (
                  <Button displayType="primary" fluid>
                    {t('set-new-password')}
                  </Button>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
