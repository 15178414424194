import React, { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import CloseIcon from '../Icons/CloseIcon';

import './Modal.scss';

const Modal = ({
  open,
  onClose,
  closeOnBackdropClick,
  customBaseClass,
  backgroundBlur,
  customContainerClass,
  customInnerClass,
  children,
  showCloseIcon,
  customCloseIconClass,
}) => {
  const className = 'c-Modal';
  const baseClassNames = [className, open ? ` ${className}--open` : ''].join(
    ''
  );
  const modalEl = document.getElementById('modal');

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (document.body) {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = originalStyle;
      }
    }

    return () => {
      if (document.body) return (document.body.style.overflow = 'visible');
    };
  }, [open]);

  if (!modalEl) return null;

  const handleBackdropClick = () => {
    if (closeOnBackdropClick) onClose();
  };

  return createPortal(
    <div className={`${baseClassNames} ${customBaseClass}`}>
      <div
        className={`${className}__backdrop ${
          backgroundBlur && '!opacity-100 backdrop-blur-lg !bg-black/50'
        }`}
        onClick={handleBackdropClick}
      />
      <div className={`${className}__container ${customContainerClass}`}>
        {showCloseIcon && (
          <div
            className={`${className}__close-modal ${customCloseIconClass}`}
            onClick={onClose}
          >
            <CloseIcon />
          </div>
        )}
        <div className={`${className}__inner ${customInnerClass}`}>
          {children}
        </div>
      </div>
    </div>,
    modalEl
  );
};

Modal.defaultProps = {
  open: false,
  showCloseIcon: true,
  closeOnBackdropClick: true,
  customBaseClass: '',
  customContainerClass: '',
  customInnerClass: '',
  backgroundBlur: false,
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  closeOnBackdropClick: PropTypes.bool,
  customBaseClass: PropTypes.string.isRequired,
  customContainerClass: PropTypes.string.isRequired,
  customInnerClass: PropTypes.string.isRequired,
  showCloseIcon: PropTypes.bool,
  backgroundBlur: PropTypes.bool,
};

export default Modal;
