import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import {
  resetTriggerResetPasswordSuccess,
  triggerResetPassword,
} from '../../../actions/auth';
import { withRouter } from 'react-router-dom';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import Button from '../../Button';
import Input from '../../Form/Input';
import { shortLogoImageData } from '../../../utility/constants';
import Image from '../../Image';
import { validate } from '../../../utility/validationHelper';
import { isValidEmailFormat } from '../../../utility/validation';
import './TriggerResetPasswordForm.scss';
import CloseIcon from '../../Icons/CloseIcon';
import CheckIcon from '../../Icons/CheckIcon';
import useQueryParams from '../../../hooks/useQueryParams';

const className = 'c-TriggerResetPasswordForm';
const resetPasswordFormSchema = yup.object().shape({
  value: yup.string().required('This field is required.'),
});

const TriggerResetPasswordForm = (props) => {
  const { title } = props;
  const { queryParams } = useQueryParams();
  const { email } = queryParams || {};
  const [formData, setFormData] = useState({ value: email });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (queryParams.email) {
      setFormData({ value: email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const reset = async (e) => {
    e.preventDefault();

    const isValid = await validate({
      schema: resetPasswordFormSchema,
      formData,
      setErrors,
    });

    if (!isValid) {
      return;
    }

    const requestBody = {};
    if (!isValidEmailFormat(formData.value)) {
      setErrors({
        ...errors,
        value: 'Your input field have to be a valid email',
      });
      return;
    } else {
      requestBody.email = formData.value.toLowerCase();
    }

    props.dispatch(triggerResetPassword(requestBody));
  };

  const onFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: '' });
    }
  };

  return (
    <div className={className}>
      <Image {...shortLogoImageData} />
      <p className={`${className}__title`}>
        {title ?? 'Forgot your password?'}
      </p>
      <p className={`${className}__desc`}>
        Rest assured, you're not alone. To reset your password, please enter
        your registered email below.
      </p>
      <form onSubmit={reset}>
        <Input
          type="text"
          value={formData.value}
          name="value"
          onChange={onFieldChange}
          placeholder="Enter your email"
          label="Email"
          error={errors.value}
          v2
        />
        {props.resetPasswordFailed ? (
          <div className={`${className}__error-message`}>
            {props.resetPasswordFailed}
          </div>
        ) : (
          <div />
        )}
        {props.loading ? (
          <LoadingCircle />
        ) : (
          <Button displayType="primary" fluid>
            Send Me A Recovery Link
          </Button>
        )}
      </form>
      {!!props.success && (
        <div className={`${className}__success-card`}>
          <div className={`${className}__success-card__check`}>
            <CheckIcon />
          </div>
          <p className={`${className}__success-card__text`}>Email Sent!</p>
          <div
            onClick={() => props.dispatch(resetTriggerResetPasswordSuccess())}
          >
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.main.loading,
  resetPasswordFailed: state.main.resetPasswordFailed,
  success: state.main.success,
});

export default connect(mapStateToProps)(withRouter(TriggerResetPasswordForm));
