import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { sendResetPassword } from '../../actions/auth';
import Auth from '../../modules/auth';
import ResetPasswordForm from '../ResetPassword/components/ResetPasswordForm/ResetPasswordForm';
import { loginLogoStaticProps } from '../ResetPassword/constants';

import './ResetPasswordContainer.scss';
import Divider from '../Divider';
import { useTranslation } from 'react-i18next';

const ResetPasswordContainer = ({
  flowType,
  redirectToLogin,
  resetToken,
  onSuccessHandler,
  renderSubtext,
  showLabel,
}) => {
  const { t } = useTranslation();
  const baseClassName = 'c-ResetPasswordContainer';

  const token = resetToken || queryString.parse(window.location.search)?.token;
  const mainState = useSelector((state) => state?.main);

  const { loading, resetPasswordFailed, success } = mainState;

  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const isFlowType = flowType === 'changeFlow';

  const changeField = (e) => {
    e.preventDefault();
    const field = e.target.name;
    const value = e.target.value;

    const handlers = {
      oldPassword: setOldPassword,
      newPassword: setNewPassword,
      confirmNewPassword: setConfirmNewPassword,
    };

    handlers[field](value);
  };

  const resetStateData = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const resetPasswordWithToken = (e) => {
    e.preventDefault();
    dispatch(sendResetPassword(newPassword, confirmNewPassword, token));
  };

  if (success && onSuccessHandler) onSuccessHandler();

  const resetPasswordWithCurrentPassword = (e) => {
    e.preventDefault();

    const bearerToken = 'Bearer '.concat(Auth.getUserToken());

    dispatch(
      sendResetPassword(
        newPassword,
        confirmNewPassword,
        false,
        oldPassword,
        bearerToken,
        resetStateData
      )
    );
  };

  const formInputProps = {
    oldPassword: {
      name: 'oldPassword',
      label: t('current-password'),
      placeholder: t('enter-your-old-password'),
      value: oldPassword,
      onChange: changeField,
    },
    newPassword: {
      name: 'newPassword',
      label: isFlowType ? t('password') : t('new-password'),
      value: newPassword,
      placeholder: t('enter-your-password'),
      legend: t(
        'require-at-least-8-characters-with-at-least-1-uppercase-1-lowercase-and-1-number'
      ),
      onChange: changeField,
    },
    confirmPassword: {
      name: 'confirmNewPassword',
      value: confirmNewPassword,
      placeholder: t('confirm-your-password'),
      label: t('confirm-password'),
      onChange: changeField,
    },
  };

  const flowProps = {
    setupFlow: {
      inputs: [
        formInputProps.oldPassword,
        formInputProps.newPassword,
        formInputProps.confirmPassword,
      ],
      onSubmit: resetPasswordWithCurrentPassword,
      includeBackToLogin: redirectToLogin,
    },
    changeFlow: {
      inputs: [formInputProps.newPassword, formInputProps.confirmPassword],
      onSubmit: resetPasswordWithToken,
    },
  };

  const passToken = () => (flowType === 'setupFlow' ? { token } : null);

  const className = `${baseClassName} ${baseClassName}${
    isFlowType ? '--change-flow' : ''
  }`;

  return (
    <div className={className}>
      {isFlowType && (
        <div className={`${className}__title__wrapper`}>
          <div className={`${className}__title`}>
            {t('create-new-password')}
          </div>
          {renderSubtext && (
            <div className={`${className}__subtext`}>
              {t('this-is-the-last-step-we-promise')}
            </div>
          )}

          <Divider narrow />
        </div>
      )}
      <ResetPasswordForm
        {...flowProps[flowType]}
        {...loginLogoStaticProps}
        title={t('teaching-the-language-of-the-future')}
        loading={loading}
        successMsg={success}
        {...passToken()}
        resetPasswordFailed={resetPasswordFailed}
        btnText={isFlowType ? t('set-password') : t('save-password')}
        btnIsFluid={isFlowType}
        showLabel={showLabel}
        showForgotPassword={!isFlowType}
      />
    </div>
  );
};

ResetPasswordContainer.defaultProps = {
  renderSubtext: true,
  showLabel: false,
};

ResetPasswordContainer.propTypes = {
  renderSubtext: PropTypes.bool,
  flowType: PropTypes.oneOf(['setupFlow', 'changeFlow']),
  showLabel: PropTypes.bool,
};

export default withRouter(ResetPasswordContainer);
