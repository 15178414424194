import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Modal from '../Modal';
import './PasswordExpiredModal.scss';

const className = 'c-PasswordExpiredModal';

const PasswordExpiredModal = ({
  show,
  onClose,
  onClickReset,
  onClickLogin,
  customContainerClassName,
}) => {
  return (
    <Modal
      open={show}
      onClose={onClose}
      customContainerClass={`${className}__container ${customContainerClassName}`}
    >
      <div className={className}>
        {/* Content */}
        <div className={`${className}__content`}>
          {/* Title */}
          <h3 className={`${className}__title`}>
            Hey, it seems your session has expired.
          </h3>

          {/* Text */}
          <p className={`${className}__text`}>
            Please reset your password or login again.
          </p>

          {/* CTA Area */}
          <div className={`${className}__cta`}>
            <Button onClick={onClickReset} displayType="primary">
              Reset password
            </Button>
            <div className={`ml-12`}>
              <Button onClick={onClickLogin} displayType="primary">
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PasswordExpiredModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

PasswordExpiredModal.defaultProps = {
  show: false,
  onClose: () => {},
  onConfirm: () => {},
};

export default PasswordExpiredModal;
