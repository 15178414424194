import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Toast from './Toast';
import { createPortal } from 'react-dom';
import { nanoid } from 'nanoid';

export const DEFAULT_MSG = '';
export const TOAST_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFORMATION: 'information',
  NEUTRAL: 'neutral',
  NEUTRAL_LIGHT: 'neutral-light',
};

//this is a global store for toast event listener
class GlobalStore {
  constructor() {
    this.func = [];
  }

  // we store listener function and subscribe them to executes on an event emit
  on(listener) {
    this.func.push(listener);
  }

  //this runs all the listerner functions that are subscribed to global store
  emit(options) {
    this.func.forEach((listener) => listener(options));
  }
}

// creating a reference to the global store
const globalStore = new GlobalStore();

export const showToast = (options) => {
  globalStore.emit({ ...options });
};

export const showSuccessToast = (text) => {
  showToast({ text, type: 'success', nplVersion: 2 });
};

export const showErrorToast = (error) => {
  //capture the error for logs
  console.error(error);
  showToast({ text: error, type: 'error', nplVersion: 2 });
};

export const removeAllToasts = () => {
  globalStore.emit({ removeAll: true });
};

export const ToastContainer = () => {
  const toastRef = useRef();

  useEffect(() => {
    const addToastListener = (options) => {
      if (options.removeAll) {
        toastRef.current?.removeAllToasts();
        return;
      }
      toastRef.current?.addToast(options);
    };
    globalStore.on(addToastListener);
  }, []);

  return <ToastPortal ref={toastRef} />;
};

// eslint-disable-next-line react/display-name
const ToastPortal = forwardRef((props, ref) => {
  const [_document, setDocument] = useState(null);
  const [toastList, setToastList] = useState([]);
  const [removeId, setRemoveId] = useState('');

  const modalEl = _document?.getElementById('toast-container');

  useImperativeHandle(ref, () => ({
    addToast(options) {
      setToastList([
        ...toastList,
        { ...options, isVisible: true, id: nanoid() },
      ]);
    },
    removeAllToasts() {
      toastList.forEach((toast) => {
        removeToast(toast.id);
      });
    },
  }));

  const removeToast = (id) => {
    if (id) {
      setToastList((toasts) =>
        toasts.map((toast) => {
          if (toast.id === id) {
            return { ...toast, isVisible: false };
          }

          return toast;
        })
      );

      setTimeout(() => {
        setToastList((toasts) => toasts.filter((toast) => toast.id !== id));
      }, 200);
    }
  };

  useEffect(() => {
    if (removeId) {
      removeToast(removeId);
    }
  }, [removeId]);

  useEffect(() => {
    if (toastList.length) {
      const { id, autoCloseTime, dismissOnClick = false } = toastList[
        toastList.length - 1
      ];
      if (!dismissOnClick) {
        setTimeout(() => {
          setRemoveId(id);
        }, autoCloseTime || 3000);
      }
    }
  }, [toastList]);

  // Access document in Next after page renders
  useEffect(() => {
    setDocument(document);
  }, []);

  if (!toastList) return null;
  if (toastList && !toastList.length) return null;

  // backup - fixed bottom-[48px] left-1/2 -translate-x-1/2 z-[10000] flex flex-col justify-center space-y-12

  return createPortal(
    <div
      className="
      fixed top-16 left-[16px] right-[16px] z-[10000] flex flex-col justify-center space-y-12
      lg:top-auto lg:right-auto lg:bottom-[48px] lg:left-1/2 lg:-translate-x-1/2"
    >
      {toastList.map((toastItemData) => {
        const { id, ...propsToFwd } = toastItemData;
        return (
          <Toast key={id} id={id} {...propsToFwd} removeToast={removeToast} />
        );
      })}
    </div>,
    modalEl
  );
});

export default ToastContainer;
