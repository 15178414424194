class TimeFormatter {
  static setTime(date, hour, min, sec = 0) {
    return new Date(
      new Date(new Date(date.setHours(hour)).setMinutes(min)).setSeconds(0)
    );
  }

  static presentStandardDateTime(date) {
    date = new Date(date);
    const hours = date.getHours();
    const mins = date.getMinutes();
    const time =
      (hours > 12 ? hours - 12 : hours) +
      ':' +
      (mins > 10 ? mins : '0' + mins) +
      (hours > 11 ? ' PM' : ' AM');
    const month_list = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const week_date_list = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    return (
      date.getDate() +
      ' ' +
      month_list[date.getMonth()] +
      ' ' +
      date.getFullYear() +
      ' (' +
      week_date_list[date.getDay()] +
      '), ' +
      time
    );
  }

  static convertTime12To24 = (time12h, hourMinuteSeparator, amPmSeparator) => {
    let time, modifier, hours, minutes;

    // IF format is 1.03AM
    let trimmedTime12h = time12h.trim().toUpperCase();
    if (!amPmSeparator && trimmedTime12h.match(/AM|PM/)) {
      let index = trimmedTime12h.match(/AM|PM/).index;
      time = trimmedTime12h.slice(0, index);
      modifier = trimmedTime12h.slice(index);
    } else {
      // IF format is 1.03 PM
      [time, modifier] = trimmedTime12h.split(' ');
    }

    [hours, minutes] = time.split(hourMinuteSeparator);

    hours = hours.trim();
    minutes = minutes.trim();
    if (hours === '12') {
      hours = '00';
    }

    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  static getFullDateISOString = (date, time) => {
    let time24Hour;
    try {
      try {
        time24Hour = this.convertTime12To24(time, ':');
      } catch {
        try {
          time24Hour = this.convertTime12To24(time, '.');
        } catch {}
      }
    } catch {}
    let [hour, min] = time24Hour.split(':');
    let dateCopy = new Date(date);
    dateCopy.setUTCHours(parseInt(hour));
    dateCopy.setUTCMinutes(parseInt(min));
    return dateCopy.toISOString(); // to get it in YYYY-MM-DDTHH:mm:ss.sssZ format
  };

  static getFullDateStartEndISOString = (date, timeFromToEnd) => {
    // timeFromToEnd will be something like 1.30pm - 3.30pm
    // date is purely date such as 2020-08-20T00:00:00Z without the time

    // split the start time and end time
    let [startTime, endTime] = timeFromToEnd.split('-');
    startTime = startTime.trim();
    endTime = endTime.trim();

    let startFullDate = this.getFullDateISOString(date, startTime);
    let endFullDate = this.getFullDateISOString(date, endTime);
    return {
      start: startFullDate,
      end: endFullDate,
    };
  };

  static getMonthName = (monthIndex) => {
    // monthIndex starts from 0 -- 0 = Jan, 11 = Dec
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return monthNames[monthIndex];
  };

  static getDayName = (dayIndex, short = false) => {
    var days = short
      ? ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
      : [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];
    return days[dayIndex];
  };

  static formatAMPM = (date, cap = false) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm;
    if (cap) {
      ampm = hours >= 12 ? 'PM' : 'AM';
    } else {
      ampm = hours >= 12 ? 'pm' : 'am';
    }
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  static formatDDMMYYYY = (date) => {
    return (
      date.getDate() +
      ' ' +
      TimeFormatter.getMonthName(date.getMonth()) +
      ' ' +
      date.getFullYear()
    );
  };

  /**
   *
   * @param {String} timing refers to the start time to end time in 24 hour format
   */
  static getTimeDiffFrom24HourFormat = (timing) => {
    // example 07:00 - 09:30
    // example 23:00 - 01:30
    // example 7:30 - 09:15

    let [startTime, endTime] = timing.split('-');
    let [startHour, startMin] = startTime.split(':');
    let [endHour, endMin] = endTime.split(':');

    startHour = parseInt(startHour);
    startMin = parseInt(startMin);

    endHour = parseInt(endHour);
    endMin = parseInt(endMin);

    let diffHour,
      diffMin = 0;
    if (startHour <= endHour) diffHour = endHour - startHour;
    else diffHour = endHour - startHour + 24;

    if (startMin <= endMin) diffMin = endMin - startMin;
    else {
      diffMin = endMin - startMin + 60;
      diffHour = diffHour - 1;
    }

    return {
      diffHours: diffHour,
      diffMinutes: diffMin,
    };
  };

  static getFormattedDate = (date, short = false) => {
    let formattedDate = new Intl.DateTimeFormat('en-US', {
      month: short ? 'short' : 'long',
      day: 'numeric',
    }).format(new Date(date));

    return formattedDate;
  };

  static getFormattedTime = (date) => {
    let formattedDate = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(date));

    return formattedDate;
  };

  static getFormattedDay = (date) => {
    let formattedDate = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
    }).format(new Date(date));

    return formattedDate;
  };

  static calculateCountdown(startDate, endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(startDate)) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  static getFormattedTimeFromSeconds = (seconds) => {
    // Hours, minutes and seconds
    var hrs = ~~(seconds / 3600);
    var mins = ~~((seconds % 3600) / 60);
    var secs = ~~seconds % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = '';

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
  };

  static getFormattedTimeFromMs = (ms) => {
    const sec = ms ? ~~(ms / 1000) : 0; // ~~ = Bitwise operator for Math.floor. Is Faster.
    return TimeFormatter.getFormattedTimeFromSeconds(sec);
  };

  static getCommentTime = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);
    var numyears = Math.floor(seconds / 31536000);
    var nummonths = Math.floor((seconds % 31536000) / 2628000);
    var numweeks = Math.floor(((seconds % 31536000) % 2628000) / 604800);
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    return numyears > 0
      ? `${numyears}y`
      : nummonths > 0
      ? `${nummonths}mo`
      : numweeks > 0
      ? `${numweeks}w`
      : numdays > 0
      ? `${numdays}d`
      : numhours > 0
      ? `${numhours}h`
      : numminutes > 0
      ? `${numminutes}m`
      : 'Just now';
  };
}

export default TimeFormatter;
