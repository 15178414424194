export const CANCEL_OPTIONS = (t) => [
  { label: t('community-was-not-suitable'), value: 'not-suitable' },
  {
    label: t('community-was-not-engaging-for-me'),
    value: 'not-engaging',
  },
  {
    label: t('guest-speaker-s-were-not-exciting'),
    value: 'guest-speaker-not-exciting',
  },
  { label: t('personal-reasons'), value: 'personal-reasons' },
  { label: t('work-commitments'), value: 'work-commitments' },
  { label: t('technical-issues'), value: 'technical-issues' },
  { label: t('language-barriers'), value: 'language-barriers' },
  {
    label: t('community-is-too-expensive-for-the-value'),
    value: 'too-expensive',
  },
  { label: t('others-please-state'), value: 'other' },
];
