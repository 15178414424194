import Header, { HeaderPropTypes } from '../Header';

import Aside from '../Aside';
import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-CommunityPageTemplate';

const PageTemplate = ({
  headerProps,
  chidren,
  toRender,
  renderAside,
  isAsideHidden,
  showAsideCloseIcon,
  onAsideHide,
  customContentClass,
}) => {
  return (
    <div
      className={`${className}  relative flex flex-row justify-between  lg:h-full lg:min-h-screen`}
    >
      <main className={`${className}__wrapper w-full flex-1`}>
        {headerProps && <Header {...headerProps} />}

        {/* Content */}
        <div className={`${className}__content ${customContentClass}`}>
          {chidren}
          {toRender && toRender()}
        </div>
      </main>
      {renderAside && (
        <Aside
          toRender={renderAside}
          isHidden={isAsideHidden}
          onHide={onAsideHide}
          showCloseIcon={showAsideCloseIcon}
        />
      )}
    </div>
  );
};

PageTemplate.propTypes = {
  headerProps: PropTypes.shape(HeaderPropTypes).isRequired,
  toRender: PropTypes.func,
  renderAside: PropTypes.func,
  isAsideHidden: PropTypes.bool,
  showAsideCloseIcon: PropTypes.bool,
  onAsideHide: PropTypes.func,
};

PageTemplate.defaultProps = {
  isAsideHidden: true,
  showAsideCloseIcon: true,
};

export default PageTemplate;
