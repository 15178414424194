import './Input.scss';

import { useRef, useState } from 'react';

import DiscordV2 from '../../Icons/DiscoardV2/DiscoardV2';
import DribbleIconV2Colored from '../../Icons/DribbleIconV2Colored/DribbleIconV2Colored';
import EyeVisible from '../../Icons/EyeVisible';
import FacebookGreyIcon from '../../Icons/FacebookGreyIcon';
import FacebookIconV2Colored from '../../Icons/FacebookIconV2Colored/FacebookIconV2Colored';
import InstagramIconV2Colored from '../../Icons/InstagramIconV2Colored/InstagramIconV2Colored';
import LinkedIconV2Colored from '../../Icons/LinkedIconV2Colored/LinkedIconV2Colored';
import OpenSeaV2Colored from '../../Icons/OpenSeaV2Colored/OpenSeaV2Colored';
import TiktokV2 from '../../Icons/TiktokV2/TiktokV2';
import TwitterIconV2Colored from '../../Icons/TwitterIconV2Colored/TwitterIconV2Colored';
import WebsiteIconV2 from '../../Icons/WebsiteIconV2/WebsiteIconV2';
import YoutubeIconV2Colored from '../../Icons/YoutubeIconV2Colored/YoutubeIconV2Colored';
import { useEffect } from 'react';

const Input = ({
  type,
  name,
  label,
  onChange,
  error,
  eyeIcon,
  icon,
  iconPosition,
  v2,
  prefix,
  focusOnLoad,
  customClassforInputContainer,
  renderLogobesidelabel,
  typeoflogo,
  customClassForLabel,
  customClassForTopContainer,
  customInnerClassName = '',
  disabled,
  register,
  ...rest
}) => {
  const className = 'c-Input';
  const baseClassNames = [
    className,
    error ? ` ${className}--error` : '',
    disabled ? ` ${className}--disabled` : '',
    v2 ? ` ${className}--v2` : '',
    v2 && error ? ` ${className}--v2--error` : '',
  ].join('');

  const hasIcon = !!icon;
  const hasPrefix = !!prefix;

  const containerClassName = [
    `${className}__container`,
    v2 ? ` ${className}--v2__container` : '',
  ].join('');
  const containerClassNames = [
    containerClassName,
    hasIcon ? ` ${containerClassName}--w-${iconPosition}-icon` : '',
    hasPrefix ? ` ${containerClassName}--w-left-icon` : '',
    eyeIcon ? ` ${containerClassName}--w-right-icon` : '',
  ].join('');

  const inputRef = useRef(null);
  const [isEyeIconVisible, setIsEyeIconVisible] = useState(false);

  // Set focus of field once loaded
  useEffect(() => {
    if (focusOnLoad) inputRef.current?.focus();
  }, [focusOnLoad]);

  function toggleInputVisibility() {
    const inputType = inputRef?.current?.type;
    if (inputType === 'password') inputRef.current.type = 'text';
    else inputRef.current.type = 'password';

    setIsEyeIconVisible((prevBool) => !prevBool);
  }

  function renderEyeIcon() {
    return (
      eyeIcon && (
        <div
          className={`${className}__container__icon ${className}__container__icon--right`}
          onClick={toggleInputVisibility}
        >
          <EyeVisible isOpen={isEyeIconVisible} />
        </div>
      )
    );
  }

  const renderLogo = (type) => {
    switch (type) {
      case 'facebook':
        return <FacebookIconV2Colored />;
      case 'instagram':
        return <InstagramIconV2Colored />;
      case 'youtube':
        return <YoutubeIconV2Colored />;
      case 'twitter':
        return <TwitterIconV2Colored />;
      case 'tiktok':
        return <TiktokV2 />;
      case 'linkedin':
        return <LinkedIconV2Colored />;
      case 'discord':
        return <DiscordV2 />;
      case 'opensea':
        return <OpenSeaV2Colored />;
      case 'website':
        return <WebsiteIconV2 />;
      case 'dribble':
        return <DribbleIconV2Colored />;
      default:
        return <FacebookGreyIcon customClassName={'w-16 h-16'} />;
    }
  };

  return (
    <div
      className={`${baseClassNames} ${customClassForTopContainer} lg:min-w-[450px]`}
    >
      {renderLogobesidelabel && (
        <>
          <div className="flex flex-row items-center mb-10 ">
            {renderLogobesidelabel && renderLogo(typeoflogo)}{' '}
            {label && (
              <label
                className={customClassForLabel}
                style={{ marginBottom: '0px' }}
                htmlFor={name}
              >
                {label}
              </label>
            )}
          </div>
        </>
      )}
      {label && !renderLogobesidelabel && (
        <label className={customClassForLabel} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={containerClassNames + ' ' + customClassforInputContainer}>
        {hasIcon ? (
          <span
            className={`${className}__container__icon ${className}__container__icon--${iconPosition}`}
          >
            {icon}
          </span>
        ) : null}
        {hasPrefix ? (
          <span
            className={`${className}__container__icon ${className}__container__icon--left`}
          >
            {prefix}
          </span>
        ) : null}
        <input
          ref={register ?? inputRef}
          name={name}
          type={type}
          onChange={onChange}
          className={customInnerClassName}
          {...rest}
          disabled={disabled}
        />
        {renderEyeIcon()}
      </div>
      {error && <p className={`${className}__error`}>{error}</p>}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  name: '',
  label: '',
  error: '',
  eyeIcon: false,
  iconPosition: 'left',
};

export default Input;
