import {
  bookingCancellationURL,
  bookingRegistrationURL,
  sharedRequestOptions,
} from './constants';

import { getAxiosError } from '../../services/helper';
import protectedAxiosRequest from '../../services/helper/protectedAxiosRequest';

export const bookTrainerForCalendly = async (
  communityId,
  eventUri,
  inviteeUri
) => {
  try {
    return await protectedAxiosRequest.post(
      bookingRegistrationURL(communityId),
      { eventUri, inviteeUri },
      sharedRequestOptions
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const cancelTrainerBookingForCalendly = async (
  communityId,
  cancelReason
) => {
  try {
    return await protectedAxiosRequest.put(
      bookingCancellationURL(communityId),
      { cancelReason },
      sharedRequestOptions
    );
  } catch (error) {
    return getAxiosError(error);
  }
};
