export const displayTypeToClassNamesMap = {
  primary:
    'rounded-4 bg-button-primary focus:bg-button-primary-focus hover:bg-button-primary-hover',
  secondary:
    'rounded-4 bg-button-secondary active:bg-button-primary-active hover:bg-button-secondary-hover',
  outline: 'rounded-4 border-1 border-neutral-80 hover:bg-button-outline-hover',
  plain: 'rounded-4 hover:bg-button-outline-hover',
  disabled: 'rounded-4 bg-[#FCCB1D] opacity-40',
};

export const sizeToClassNamesMap = {
  sm: 'font-medium text-button-md px-12 py-8',
  md: 'font-medium text-button-md px-24 py-12',
  lg: 'font-medium text-button-lg px-24 py-12',
  xl: 'font-medium text-button-lg px-24 py-16',
  bt: 'font-medium text-button-lg px-15 py-16',
};

export const DISPLAY_TYPE_OPTIONS = [
  'primary',
  'secondary',
  'plain',
  'outline',
  'disabled',
];
export const SIZE_OPTIONS = ['sm', 'md', 'lg', 'xl'];
