import {
  CLASSES_PORTAL_ROOT,
  COMMUNITY_PORTAL_EVENTS_PAGE_ENDPOINT,
  COMMUNITY_PORTAL_LIBRARY_FOLDER_PATH,
  COMMUNITY_PORTAL_HOMEPAGE_ENDPOINT,
  COMMUNITY_PORTAL_LIBRARY_PAGE_ENDPOINT,
  COMMUNITY_PORTAL_MEET_AN_EXPERT_PAGE_ENDPOINT,
  COMMUNITY_PORTAL_MEMBERS_PAGE_ENDPOINT,
  COMMUNITY_PORTAL_ROOT,
} from '../../constants';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getActivePortal, getCommunityPortalPath } from './utils';

import history from '../../../modules/history';
import { urlUtils } from '../../utils';

const RouterContext = createContext();

export const RouterContextProvider = ({ children }) => {
  const [activePath, setActivePath] = useState(urlUtils.readCurrentPath());

  const currUrlPath = window.location.pathname;

  const switchToClassesPortal = useCallback(() => {
    if (urlUtils.isOnCommunityPortal() || urlUtils.isOnCreatorPortal()) {
      urlUtils.goToPortalPage(CLASSES_PORTAL_ROOT);
    }
  }, []);
  const switchToCommunityPortal = useCallback(() => {
    if (!urlUtils.isOnSettingsPortal() && !urlUtils.isOnCommunityPortal()) {
      urlUtils.goToPortalPage(COMMUNITY_PORTAL_ROOT);
    }
  }, []);

  const getCommunityPortalHomePageLink = useCallback((communityId) => {
    return getCommunityPortalPath(COMMUNITY_PORTAL_HOMEPAGE_ENDPOINT)(
      communityId
    );
  }, []);

  const getMeetAnExpertPageLink = useCallback((communityId) => {
    return getCommunityPortalPath(
      COMMUNITY_PORTAL_MEET_AN_EXPERT_PAGE_ENDPOINT
    )(communityId);
  }, []);
  const getCommunityPortalEventsPageLink = useCallback(
    (communityId) =>
      getCommunityPortalPath(COMMUNITY_PORTAL_EVENTS_PAGE_ENDPOINT)(
        communityId
      ),
    []
  );
  const getCommunityPortalLibraryPageLink = useCallback(
    (communityId) =>
      getCommunityPortalPath(COMMUNITY_PORTAL_LIBRARY_PAGE_ENDPOINT)(
        communityId
      ),
    []
  );
  const getCommunityPortalLibraryFolderPageLink = useCallback(
    (communityId) =>
      getCommunityPortalPath(COMMUNITY_PORTAL_LIBRARY_FOLDER_PATH)(communityId),
    []
  );

  const getCommunityPortalMembersPageLink = useCallback(
    (communityId) =>
      getCommunityPortalPath(COMMUNITY_PORTAL_MEMBERS_PAGE_ENDPOINT)(
        communityId
      ),
    []
  );

  const activePortal = useMemo(() => getActivePortal(activePath), [activePath]);

  // Reaction to update the active path whenever a manual URL change occurs
  useEffect(() => {
    setActivePath(urlUtils.readCurrentPath());
  }, [currUrlPath]);

  const value = {
    switchToClassesPortal,
    switchToCommunityPortal,
    activePortal,
    getCommunityPortalHomePageLink,
    getCommunityPortalEventsPageLink,
    getCommunityPortalLibraryPageLink,
    getCommunityPortalMembersPageLink,
    getCommunityPortalLibraryFolderPageLink,
    getMeetAnExpertPageLink,
  };

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  );
};

export const useRouterContext = () => useContext(RouterContext);
