import Button from '../../CommunityPortal/components/Button';
import { DISCORD_OAUTH_REDIRECT_URL } from '../../CommunityPortal/components/DiscordButton/constants';
import DiscordIcon from '../Icons/DiscordIcon';
import React from 'react';
import { getQueryHashParams } from '../../utility/domHelpers';
import { loginUsingDiscord } from '../../CommunityPortal/apiServices/discordService';
import { useEffect } from 'react';
import { useState } from 'react';

const DiscordLogin = ({
  text,
  onSuccessHandler,
  onFailureHandler,
  isLoginForm = false,
}) => {
  const queryParams = getQueryHashParams();
  const [loading, setLoading] = useState(false);
  const ctaHandler = () => {
    window.location.href = DISCORD_OAUTH_REDIRECT_URL(
      `${window.location.protocol}//${window.location.host}/login`
    );
  };

  useEffect(() => {
    if (!!queryParams.access_token) {
      const asyncCallback = async () => {
        const response = await loginUsingDiscord(queryParams?.access_token);
        if (response.error) {
          onFailureHandler(response.error);
          return;
        }
        onSuccessHandler(response?.data);
        setLoading(true);
      };
      asyncCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBoardingCustomClasses = '!border-grey-neutral60 !py-8 hover:!bg-button-google-hover hover:!border-button-google-border-hover';
  return (
    <Button
      fluid={true}
      displayType="outline"
      onClick={ctaHandler}
      customClassNames={`mt-12 ${isLoginForm ? onBoardingCustomClasses : ''}`}
      disabled={loading}
      size="bt"
    >
      <div className="relative flex flex-row items-center w-full">
        <div className="absolute top-0 left-0 w-24 h-full">
          <DiscordIcon />
        </div>
        <span
          className={`flex-grow font-regular ${
            isLoginForm
              ? 'font-googleSans text-grey-3c'
              : 'font-poppins text-brand-2c'
          } text-button-md`}
        >
          {text}
        </span>
      </div>
    </Button>
  );
};

export default DiscordLogin;
