import React from 'react';

const CalendarDaysFilledIcon = () => {
  return (
    <svg
      className="c-CalendarDaysFilledIcon"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#1B1B18"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 4H5a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1V5a1 1 0 00-1-1z"
      ></path>
      <path
        fill="#1B1B18"
        fillRule="evenodd"
        d="M5 8h14v11H5V8zm10 8a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H16a1 1 0 01-1-1V16zm-3-1a1 1 0 00-1 1v.002a1 1 0 001 1h.002a1 1 0 001-1V16a1 1 0 00-1-1H12zm-5 1a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H8a1 1 0 01-1-1V16zm9-5a1 1 0 00-1 1v.002a1 1 0 001 1h.002a1 1 0 001-1V12a1 1 0 00-1-1H16zm-5 1a1 1 0 011-1h.002a1 1 0 011 1v.002a1 1 0 01-1 1H12a1 1 0 01-1-1V12zm-3-1a1 1 0 00-1 1v.002a1 1 0 001 1h.002a1 1 0 001-1V12a1 1 0 00-1-1H8z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#1B1B18"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 8h16M16 2v2M8 2v2"
      ></path>
    </svg>
  );
};

export default CalendarDaysFilledIcon;
